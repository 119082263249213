import { Radio, RadioGroup } from 'common/components/forms/Radio/Radio';
import { DropDownOption, ScreenMode } from 'constants/types';
import { OwnerType, SigningCombination } from 'features/onboardingV2/store/types';
import { useDocumentTitle } from 'hooks';
import OnboardingModalLayout from 'layout/OnboardingModalLayout';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Dropdown } from 'common/components/forms/Dropdown/Dropdown';
import { useEffect, useState } from 'react';
import { TextInput } from 'common/components/forms/TextInput/TextInput';
import { patterns } from 'utils/validation';
import { OwnerForm } from './OwnerEditForm';
import { Grid, GridItem } from 'common/components/Grid';
import styled from 'styled-components/macro';
import useOwnersView from './useOwnersView';

export type OwnerFormWithSignees = {
  fullNameDropdown: DropDownOption<string>;
  fullName: string;
  personalId?: string;
  type?: OwnerType;
  shares?: '25-49' | '50-74' | '75-100';
};

interface Props {
  owner: OwnerForm;
  closeUrl: string;
  mode: ScreenMode;
  onSubmit: (ownerForm: OwnerForm) => void;
}

const OwnerEditFormWithSignees = ({ owner, closeUrl, mode, onSubmit }: Props) => {
  const { t } = useTranslation();
  const title =
    mode === ScreenMode.add ? t('onboarding.v2.owners.addOwnerTitle') : t('onboarding.v2.owners.editOwnerTitle');

  useDocumentTitle(title);
  const label = mode === ScreenMode.add ? t('onboarding.v2.owners.addLabel') : t('onboarding.v2.owners.saveLabel');

  const ownerForm = useForm<OwnerFormWithSignees>({
    mode: 'onChange',
  });
  const { owners, signees } = useOwnersView();

  const isScreenCompleted = ownerForm.formState.isValid;
  const modalGridContentGutter = 16;

  const onlyUnique = (value: string, index: number, self: string[]) => self.indexOf(value) === index;
  const onlyUnused = (name: string) => !owners.find(owner => owner.fullName === name);

  const addNewNameOption = { label: t('onboarding.v2.owners.addNewOwnerName'), value: 'NEW' };
  const buildOwnersListBasedOnSigningCombinations = (combinations: SigningCombination[]) => {
    return combinations.flatMap(combination => combination.persons).map(person => person.fullName);
  };

  const signingCombinationsOwnersList = buildOwnersListBasedOnSigningCombinations(signees);

  const buildDropdownOptionsBasedOnUniqueOwners = (ownersList: string[]) => {
    return ownersList
      .filter(onlyUnique)
      .filter(onlyUnused)
      .map(name => ({ label: name, value: name }));
  };
  const options: DropDownOption<string>[] = buildDropdownOptionsBasedOnUniqueOwners(signingCombinationsOwnersList);
  options.push(addNewNameOption);

  const noOwnersInDropdown = options.length === 1;
  const ownerDoesNotAppearInDropdownOptions = !options.find(option => option.value === owner.fullName);
  const ownerAppearsInSigningCombinationsOwnersList = signingCombinationsOwnersList.includes(owner.fullName);
  const [isCustomOwner, setIsCustomOwner] = useState(
    mode === ScreenMode.add ? noOwnersInDropdown : ownerDoesNotAppearInDropdownOptions
  );

  const onSubmitWithDropdown = (ownerFormWithSignees: OwnerFormWithSignees) => {
    const ownerForm: OwnerForm = {
      ...ownerFormWithSignees,
      fullName:
        !ownerFormWithSignees.fullNameDropdown || ownerFormWithSignees.fullNameDropdown.value === 'NEW'
          ? ownerFormWithSignees.fullName
          : ownerFormWithSignees.fullNameDropdown.value,
    };
    onSubmit(ownerForm);
  };

  const getDefaultDropdownOption = () => {
    if (owner.fullName) {
      if (isCustomOwner) {
        return addNewNameOption;
      } else {
        return options.find(option => option.value === owner.fullName);
      }
    }
    return options[0];
  };

  const canBeABO = owners.length === 0 || (mode === ScreenMode.edit && owners.length === 1);

  useEffect(() => {
    mode === ScreenMode.add && setIsCustomOwner(noOwnersInDropdown);
  }, [mode, noOwnersInDropdown]);

  useEffect(() => {
    mode === ScreenMode.edit && setIsCustomOwner(ownerDoesNotAppearInDropdownOptions);
  }, [mode, ownerDoesNotAppearInDropdownOptions]);

  return (
    <OnboardingModalLayout
      title={title}
      closeUrl={closeUrl}
      label={label}
      isScreenCompleted={isScreenCompleted}
      formId="owner-form"
      gridGutter={modalGridContentGutter}
      noGutter={true}
    >
      <EditForm id="owner-form" onSubmit={ownerForm.handleSubmit(onSubmitWithDropdown)}>
        <Grid gutter={modalGridContentGutter} vGap="6">
          {mode === ScreenMode.add && !noOwnersInDropdown && (
            <GridItem s={4} m={4} l={12}>
              <Dropdown
                label={t('common.form.name')}
                name="fullNameDropdown"
                options={options}
                defaultValue={getDefaultDropdownOption()}
                onChange={option => {
                  const dropDownOption = option as DropDownOption<string>;
                  setIsCustomOwner(dropDownOption.value === 'NEW');
                }}
                control={ownerForm.control}
                errors={ownerForm.errors}
                testId="fullName-dropdown"
              />
            </GridItem>
          )}

          {isCustomOwner && (
            <GridItem s={4} m={4} l={6}>
              <TextInput
                label={t('onboarding.v2.owners.nameInputLabel')}
                name="fullName"
                setValue={(value?: string) => ownerForm.setValue('fullName', value)}
                rules={{
                  required: t('common.form.validationMessages.required'),
                  pattern: {
                    value: patterns.fullName,
                    message: t('common.form.validationMessages.fullName'),
                  },
                }}
                disabled={ownerAppearsInSigningCombinationsOwnersList}
                defaultValue={owner.fullName}
                control={ownerForm.control}
                errors={ownerForm.errors}
                testId="fullName"
                trimOnBlur
              />
            </GridItem>
          )}

          <GridItem s={4} m={4} l={4}>
            <RadioGroup
              label={t('onboarding.v2.owners.ownership')}
              name="shares"
              size="sm"
              defaultValue={owner.type === 'ALTERNATIVE_BENEFICIARY_OWNER' ? 'ABO' : owner.shares}
              control={ownerForm.control}
              errors={ownerForm.errors}
              required
            >
              <Radio value="25-49" label="25% - 49%" />
              <Radio value="50-74" label="50% - 74%" />
              <Radio value="75-100" label="75% - 100%" />
              {canBeABO && <Radio value="ABO" label="Alternative beneficial owner" />}
            </RadioGroup>
          </GridItem>
          <GridItem s={0} m={0} l={6} />
        </Grid>
      </EditForm>
    </OnboardingModalLayout>
  );
};

const EditForm = styled.form`
  display: flex;
  padding-bottom: ${({ theme }) => theme.spacings['6']};
`;

export default OwnerEditFormWithSignees;
