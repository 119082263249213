import { generatePath, useLocation, useNavigate, useParams } from 'react-router-dom';
import { CountryCode, ScreenMode } from 'constants/types';
import { Owner } from '../../../store/types';
import OwnerEditForm, { OwnerForm } from './OwnerEditForm';
import OwnerEditFormWithSignees from './OwnerEditFormWithSigners';
import { onboardingRoutes } from '../../../OnboardingRouter';
import { BaseQueryParams } from 'features/onboardingV2/data/types';
import useOwnersView from './useOwnersView';
import { useEffect } from 'react';

type RouteParameters = {
  ownerId: string;
} & BaseQueryParams;

function handleShares(minSharesPercentage: number | undefined): '25-49' | '50-74' | '75-100' | undefined {
  switch (minSharesPercentage) {
    case 25:
      return '25-49';
    case 50:
      return '50-74';
    case 75:
      return '75-100';
    default:
      return undefined;
  }
}

function buildOwnerFromForm(ownerForm: OwnerForm) {
  let [minSharesPercentage, maxSharesPercentage]: number[] = [];
  if (ownerForm.shares !== 'ABO') {
    [minSharesPercentage, maxSharesPercentage] = ownerForm.shares?.split('-').map(str => parseInt(str, 10)) || [];
  }
  const owner: Owner = {
    fullName: ownerForm.fullName,
    personalId: ownerForm.personalId,
    type: ownerForm.shares === 'ABO' ? 'ALTERNATIVE_BENEFICIARY_OWNER' : 'BENEFICIARY_OWNER',
    minSharesPercentage: minSharesPercentage,
    maxSharesPercentage: maxSharesPercentage,
    pepInfo: {},
    isManuallyAdded: true,
  };
  return owner;
}

const OwnerEdit = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { countryCode, offerRef, ownerId } = useParams<RouteParameters>();
  const ownersPath = generatePath(onboardingRoutes.ownersV2.path, { countryCode, offerRef });
  const personalIdNeeded = countryCode === 'SE';

  const mode = pathname.includes('/add') ? ScreenMode.add : ScreenMode.edit;

  const { owners, hasFetchedOwners, hasUpdatedOwners, upsertOwner } = useOwnersView();
  const ownerIndex = ownerId ? parseInt(ownerId) : undefined;

  const owner = owners.find((_o, i) => i === ownerIndex);
  const ownerIds = owners.map(o => o.personalId);
  const ownerForm: OwnerForm = {
    fullName: owner?.fullName || '',
    personalId: owner?.personalId,
    type: owner?.type,
    shares: handleShares(owner?.minSharesPercentage),
  };

  const onSubmit = (ownerForm: OwnerForm) => {
    const owner = buildOwnerFromForm(ownerForm);
    upsertOwner(owner, ownerIndex);
  };

  const editOwnerForm = () => {
    if (countryCode === 'ES') {
      return <OwnerEditFormWithSignees owner={ownerForm} closeUrl={ownersPath} mode={mode} onSubmit={onSubmit} />;
    }
    return (
      <OwnerEditForm
        owner={ownerForm}
        existingPersonalIds={ownerIds.filter(o => o !== ownerForm.personalId)}
        closeUrl={ownersPath}
        mode={mode}
        onSubmit={onSubmit}
        personalIdNeeded={personalIdNeeded}
        countryCode={countryCode as CountryCode}
      />
    );
  };

  useEffect(() => {
    if (hasUpdatedOwners && ownersPath) {
      navigate(ownersPath);
    }
  }, [hasUpdatedOwners, navigate, ownersPath]);

  return !hasFetchedOwners || (mode === ScreenMode.edit && !owners.length) ? null : editOwnerForm();
};

export default OwnerEdit;
