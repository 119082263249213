import { RouteConfig } from 'common/route/createFeatureRouter';
import { OfferType } from 'features/onboardingV2/store/types';
import { salesRoutes } from '../../SalesRouter';

export const getSalesOnboardingFlow = ({
  cashEnabled = false,
  offerType = 'PAY',
}: { cashEnabled?: boolean; offerType?: OfferType } = {}): RouteConfig[] => {
  const salesPayRoutes: Record<string, RouteConfig | false> = {
    products: salesRoutes.products,
    transactionFees: salesRoutes.transactionFees,
    pricing: salesRoutes.pricing,
  };

  const salesCashRoutes: Record<string, RouteConfig | false> = {
    cashServices: cashEnabled ? salesRoutes.cashServices : false,
  };

  const salesOnboardingFlow: Record<string, RouteConfig | false> = {
    companyInformation: salesRoutes.companyInformation,
    salesLocations: salesRoutes.salesLocations,
    ...(['PAY', 'PAY_AND_CASH'].includes(offerType) && salesPayRoutes),
    ...(['CASH', 'PAY_AND_CASH'].includes(offerType) && salesCashRoutes),
    overview: salesRoutes.overview,
    offerSent: salesRoutes.offerSent,
  };

  // Returning only routes that are enabled
  return Object.values(salesOnboardingFlow).filter(isEnabled => isEnabled !== false) as RouteConfig[];
};
