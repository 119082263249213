import { useMutation, UseMutationResult, useQueryClient } from 'react-query';
import {
  assignOfferToMerchant,
  deleteCompanyDocument,
  deletePersonalDocument,
  finaliseOnboarding,
  putPartial,
  uploadCompanyDocument,
  uploadPersonalDocument,
  sendAnswers,
} from './api';
import { QueryKeys } from './keys';
import { useMutationWrapper } from './utils';
import { MerchantOnboardingData } from '../../common/types';
import { PostAnswersResponse } from './types';

const usePartialUpdate = () => {
  const queryClient = useQueryClient();
  return useMutationWrapper(
    useMutation(putPartial, {
      onSuccess: data => {
        if (data) queryClient.setQueryData<MerchantOnboardingData>(QueryKeys.ONBOARDING_DATA, data);
        else queryClient.invalidateQueries([QueryKeys.ONBOARDING_DATA]);
      },
    }) as UseMutationResult<MerchantOnboardingData>
  );
};

const useSubmitOnboarding = () =>
  useMutationWrapper(useMutation(finaliseOnboarding) as UseMutationResult<MerchantOnboardingData>);

const useSendAdditionalQuestionsAnswersMutation = () =>
  useMutationWrapper(useMutation(sendAnswers) as UseMutationResult<PostAnswersResponse>);

const useUploadCompanyDocument = () =>
  useMutationWrapper(useMutation(uploadCompanyDocument) as UseMutationResult<File>);

const useDeleteCompanyDocument = () => useMutationWrapper(useMutation(deleteCompanyDocument) as UseMutationResult);

const useUploadPersonalDocument = () =>
  useMutationWrapper(useMutation(uploadPersonalDocument) as UseMutationResult<File>);

const useDeletePersonalDocument = () => useMutationWrapper(useMutation(deletePersonalDocument) as UseMutationResult);

const useAssignOfferToMerchantMutation = () =>
  useMutationWrapper(useMutation(assignOfferToMerchant) as UseMutationResult<number>);

export {
  usePartialUpdate,
  useSubmitOnboarding,
  useAssignOfferToMerchantMutation,
  useUploadCompanyDocument,
  useDeleteCompanyDocument,
  useUploadPersonalDocument,
  useDeletePersonalDocument,
  useSendAdditionalQuestionsAnswersMutation,
};
