import { Heading, Text } from '@loomispay/vault';
import { Store } from 'features/common/types';
import { useTranslation } from 'react-i18next';
import { OfferSummaryRow, OfferSummarySectionBox } from '../../OfferOverview.styles';

export default function OfferOverviewLocations({ stores }: { stores: Store[] }) {
  const { t } = useTranslation();

  return (
    <>
      {stores.map((store, i) => (
        <section key={`store${i}`} data-testid={`location.${i}.summary`}>
          <Heading size="xxs" sansSerif>
            {t('onboarding.v2.overview.location')} {i + 1}
          </Heading>
          <OfferSummarySectionBox>
            <OfferSummaryRow>
              <Text size="s">{t('onboarding.v2.overview.location.name')}</Text>
              <Text size="s">{store.name}</Text>
            </OfferSummaryRow>
            <OfferSummaryRow>
              <Text size="s">{t('onboarding.v2.overview.location.street')}</Text>
              <Text size="s">{store.address.address}</Text>
            </OfferSummaryRow>
            <OfferSummaryRow>
              <Text size="s">{t('onboarding.v2.overview.location.zip')}</Text>
              <Text size="s">{store.address.zipCode}</Text>
            </OfferSummaryRow>
            <OfferSummaryRow>
              <Text size="s">{t('onboarding.v2.overview.location.city')}</Text>
              <Text size="s">{store.address.city}</Text>
            </OfferSummaryRow>
          </OfferSummarySectionBox>
        </section>
      ))}
    </>
  );
}
