import { ReactNode } from 'react';
import styled from 'styled-components/macro';

export type HoverOverlayProps = {
  overlay?: ReactNode;
  triggerDistance?: string;
  children: ReactNode;
  testId?: string;
};

type StyledProps = {
  triggerDistance?: string;
};

const StyledOverlay = styled.div<StyledProps>`
  background: ${({ theme }) => theme.color.base.opacityWhite[13]};
  z-index: 1;
  display: none;
  position: absolute;
  top: ${({ triggerDistance }) => (triggerDistance ? triggerDistance : '0')};
  left: ${({ triggerDistance }) => (triggerDistance ? triggerDistance : '0')};
  right: ${({ triggerDistance }) => (triggerDistance ? triggerDistance : '0')};
  bottom: ${({ triggerDistance }) => (triggerDistance ? triggerDistance : '0')};
`;

const Wrapper = styled.div<StyledProps>`
  position: relative;
  margin: ${({ triggerDistance }) => (triggerDistance ? `-${triggerDistance}` : '0')};
  padding: ${({ triggerDistance }) => (triggerDistance ? triggerDistance : '0')};

  :hover > ${StyledOverlay} {
    display: block;
  }
`;

export const HoverOverlay = ({ children, overlay, testId, triggerDistance }: HoverOverlayProps) => (
  <Wrapper data-testid={testId} triggerDistance={triggerDistance}>
    {children}
    <StyledOverlay triggerDistance={triggerDistance}>{overlay}</StyledOverlay>
  </Wrapper>
);
