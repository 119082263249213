import { RouteConfig } from 'common/route/createFeatureRouter';
import { FlowName } from 'constants/types';
import { generatePath, useLocation, useParams } from 'react-router-dom';
import { getCurrentRoute } from 'services/flowRoutingService';
import { merchantOnboardingV2_2Flow } from 'features/onboardingV2/screens/merchant/merchantFlows';
import { getSalesOnboardingFlow } from 'features/onboardingV2/screens/sales/salesFlows';
import { useSelector } from 'react-redux';
import { getCountryCode, getOfferType } from 'features/onboardingV2/store/sales/selectors';
import { BaseQueryParams } from '../../../../features/onboardingV2/data/types';
import { useEffect, useMemo, useState } from 'react';
import { useOffer } from '../../../../features/onboardingV2/data/queries';

type FlowForRoleType = RouteConfig[] | null;
export const useFlowForRole = (flowName: FlowName): FlowForRoleType => {
  // Support for new views in onboarding-web
  const { countryCode: countryCodeFromUrl, offerRef } = useParams<BaseQueryParams>();
  const { data: dataOffer } = useOffer({ countryCode: countryCodeFromUrl, offerRef });

  const [flow, setFlow] = useState<FlowForRoleType>(null);

  const offerTypeRedux = useSelector(getOfferType);
  const countryCode = useSelector(getCountryCode) || countryCodeFromUrl;
  const cashEnabled = countryCode === 'ES';

  const offerType = useMemo(() => offerTypeRedux || dataOffer?.offerType, [offerTypeRedux, dataOffer]);

  useEffect(() => {
    if (flowName === 'onboarding') {
      setFlow(merchantOnboardingV2_2Flow({ offerType }));
    } else if (flowName === 'sales') {
      setFlow(getSalesOnboardingFlow({ cashEnabled, offerType }));
    }
  }, [flowName, offerType, cashEnabled]);

  return flow;
};

export const useFlowProgressBar = (
  currentFlow: RouteConfig[] | null
): { progress: number | undefined; shouldHideNavigation: boolean } => {
  const { pathname } = useLocation();
  const { countryCode, offerRef } = useParams<BaseQueryParams>();

  const [progress, setProgress] = useState<number | undefined>(undefined);
  const [shouldHideNavigation, setShouldHideNavigation] = useState<boolean>(true);

  // useMemo instead of useState&&useEffect is an aware decision.
  // We are dealing with non-primitive variable.
  // In this case performance loss (yes, paradoxically, useMemo could be a reason of performance loss ) should be marginal, so we go with better code readability
  const stepsWithNavigation = useMemo(() => (currentFlow || []).filter(step => !step.hideNavigation), [currentFlow]);

  useEffect(() => {
    if (currentFlow) {
      const flag = !stepsWithNavigation.some(step =>
        countryCode && offerRef
          ? generatePath(step.path, { countryCode, offerRef }) === pathname
          : step.path === pathname
      );
      if (flag !== shouldHideNavigation) setShouldHideNavigation(flag);
    }
    if (stepsWithNavigation.length > 0 && currentFlow) {
      const allSteps = stepsWithNavigation?.length;
      // back to catching error because of other (than merchant) flows
      try {
        const currentStep = stepsWithNavigation.indexOf(getCurrentRoute(currentFlow, pathname, countryCode, offerRef));
        setProgress((currentStep / allSteps) * 100);
      } catch {
        setProgress(undefined);
      }
    } else setProgress(undefined);
  }, [currentFlow, countryCode, offerRef, pathname, shouldHideNavigation, stepsWithNavigation]);

  return { progress, shouldHideNavigation };
};
