import useCognitoUser from 'features/loginPasswordless/useCognitoUser';
import { userService } from 'services/userService';
import Navigation from './Navigation/Navigation';
import styled from 'styled-components/macro';
import { ReactNode, useEffect, useState } from 'react';
import { useFlowForRole, useFlowProgressBar } from './Navigation/useFlowProgressBar';
import useFlowName from 'hooks/useFlowName';
import { NAVIGATION_WITH_PROGRESS_HEIGHT } from '@loomispay/vault';
import { FOOTER_HEIGHT } from 'features/onboardingV2/components/OnboardingFooter';
import { generatePath, useLocation, useNavigate, useParams } from 'react-router-dom';
import { BaseQueryParams } from '../../../features/onboardingV2/data/types';
import { onboardingRoutes } from '../../../features/onboardingV2/OnboardingRouter';
import { notifications } from '../Notification/notifications';

export default function OnboardingMainWrapper({ children }: { children: ReactNode }) {
  const { pathname } = useLocation();
  const { countryCode, offerRef } = useParams<BaseQueryParams>();
  const { isAuthenticated } = useCognitoUser();
  const [isUserAuthenticated, setIsUserAuthenticated] = useState(false);
  const flowName = useFlowName();
  const flowForRole = useFlowForRole(flowName);
  const { shouldHideNavigation } = useFlowProgressBar(flowForRole);
  const isShowingNavigation = !shouldHideNavigation && isUserAuthenticated;
  const navigate = useNavigate();

  useEffect(() => {
    userService.isSalesRepLoggedIn().then(salesRepLoggedIn => {
      setIsUserAuthenticated(salesRepLoggedIn || isAuthenticated);
    });
  }, [isAuthenticated]);

  useEffect(() => {
    if (flowName === 'onboarding' && !isUserAuthenticated) {
      if (onboardingRoutes.done.path !== pathname) {
        const loginPath = generatePath(onboardingRoutes.login.path, { countryCode, offerRef });
        if (
          loginPath !== pathname &&
          generatePath(onboardingRoutes.merchantOfferOverview.path, { offerRef }) !== pathname
        ) {
          if (!isAuthenticated()) {
            notifications.warn('Your session has expired, please login');
            navigate(loginPath);
          }
        }
      }
    }
  }, [isUserAuthenticated, flowName, navigate, countryCode, offerRef, isAuthenticated, pathname]);

  return (
    <>
      {isShowingNavigation && <Navigation />}
      <PageContent isShowingNavigation={isShowingNavigation}>{children}</PageContent>
    </>
  );
}

const PageContent = styled.main<{ isShowingNavigation: boolean }>`
  padding-top: ${({ isShowingNavigation }) => (isShowingNavigation ? NAVIGATION_WITH_PROGRESS_HEIGHT : 'initial')};
  padding-bottom: ${({ isShowingNavigation }) => (isShowingNavigation ? FOOTER_HEIGHT : 'initial')};
  margin-bottom: ${({ theme }) => theme.spacings['3']};
`;
