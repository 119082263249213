import styled from 'styled-components/macro';
import { cardWidth } from './ProductCard/ProductCard.styles';

const maxCardsPerRow = 4;
const totalCardWidth = (cardWidth + 24) * maxCardsPerRow - 24;

export const CampaignDisclaimer = styled.div`
  margin-top: ${({ theme }) => theme.spacings[1]};
`;

export const CardContainerWidthWrapper = styled.div`
  max-width: ${totalCardWidth}px;
`;

export const CardContainer = styled.div`
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;
  margin: 0 -${({ theme }) => theme.spacings['1.5']};
`;
