import { ReactNode } from 'react';
import styled from 'styled-components/macro';

import { spacings } from '@loomispay/vault';
import { FOOTER_HEIGHT } from 'features/onboardingV2/components/OnboardingFooter';
import { CONTAINER_DEFAULT_WIDTH } from 'common/components/MainLayout/Container';

type Props = {
  header?: ReactNode;
  footer?: ReactNode;
  isWide?: boolean;
  children?: ReactNode;
};

const Page = styled.div`
  display: flex;
  flex-direction: column;
`;

const Main = styled.main`
  flex: 1;
`;

const Header = styled.header`
  display: flex;
  justify-content: space-between;
  padding: ${spacings['3']} ${spacings['4']};
`;

const NarrowColumn = styled.div`
  max-width: ${CONTAINER_DEFAULT_WIDTH}px;
  margin: 0 auto;
  padding-bottom: ${spacings['4']};
`;

const WideColumn = styled(NarrowColumn)`
  max-width: 1100px;
`;

const Footer = styled.footer`
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: ${FOOTER_HEIGHT};
  border-top: 1px solid ${({ theme }) => theme.color.border.disabledBorder};
  background-color: ${({ theme }) => theme.color.background.primaryBg};
  z-index: 1;
`;

const FooterContent = styled.div<{ isWide?: boolean }>`
  height: 100%;
  max-width: ${({ isWide }) => (isWide ? '1100px' : `${CONTAINER_DEFAULT_WIDTH}px`)};
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const SingleColumn = ({ header, footer, isWide = false, children }: Props) => {
  return (
    <Page>
      {header && <Header>{header}</Header>}
      <Main>{isWide ? <WideColumn>{children}</WideColumn> : <NarrowColumn>{children}</NarrowColumn>}</Main>
      {footer && (
        <Footer>
          <FooterContent isWide={isWide}>{footer}</FooterContent>
        </Footer>
      )}
    </Page>
  );
};

export default SingleColumn;
