import { Banner, Heading, Link, Text } from '@loomispay/vault';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Radio, RadioGroup } from 'common/components/forms/Radio/Radio';
import { BorderedBox, BorderedBoxRow } from 'features/common/components/BorderedBox/BorderedBox';
import { useForm } from 'react-hook-form';
import { OfferType } from 'features/onboardingV2/store/types';
import { getCompanyAvailableFlows, getOfferType } from 'features/onboardingV2/store/sales/selectors';
import { updateOfferType } from 'features/onboardingV2/store/sales/actions';
import styled from 'styled-components/macro';
import Container from 'common/components/MainLayout/Container';
import { Fragment, useEffect } from 'react';

type OfferTypeForm = {
  offerType: OfferType;
};

export const CompanyOfferType = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const offerType = useSelector(getOfferType);
  const availableFlows = useSelector(getCompanyAvailableFlows);

  const { watch, control, errors } = useForm<OfferTypeForm>({
    mode: 'onChange',
    defaultValues: { offerType },
  });

  const formOfferType = watch('offerType');

  useEffect(() => {
    if (formOfferType !== offerType) {
      dispatch(updateOfferType(formOfferType));
    }
  }, [formOfferType, offerType, dispatch]);

  // all PAY-only offer creations were moved to sales-tools-web
  const availableFlowsInOnboardingWeb = availableFlows.filter(flow => flow.name === 'CASH');

  return (
    <Container spacing="2" fullWidth>
      <Heading size="xxs" noGutter sansSerif>
        {t('onboarding.v2.companyInfo.sectionHeader')}
      </Heading>
      <FormStyled>
        <Banner type="warning">
          {t('onboarding.v2.companyInfo.salesToolsWeb')}{' '}
          <Link href={`${process.env.REACT_APP_SALES_TOOLS_URL + '/new-offer'}`}>Sales Tools</Link>.
        </Banner>
        <RadioGroup
          size="sm"
          name="offerType"
          required
          defaultValue={offerType || availableFlowsInOnboardingWeb.find(flow => flow.status.isEnabled)?.name}
          control={control}
          errors={errors}
        >
          <OfferTypeWrapper>
            {availableFlowsInOnboardingWeb.map(({ name, description, label, status: { isEnabled, message } }) => {
              return (
                <Fragment key={name}>
                  <BorderedBox disabled={!isEnabled}>
                    <BorderedBoxRow>
                      <Radio testId={name} value={name} verticalAlign="top" disabled={!isEnabled}>
                        <Heading level="h3" size="m" noGutter>
                          {label}
                        </Heading>
                        <Text size="s">
                          {isEnabled
                            ? description
                            : `${description} ${t(
                                `onboarding.v2.companyInfo.offerTypeMessage.${
                                  message || 'DEFAULT_ONBOARDING_UNAVAILABLE'
                                }`
                              )}`}
                        </Text>
                      </Radio>
                    </BorderedBoxRow>
                  </BorderedBox>
                </Fragment>
              );
            })}
          </OfferTypeWrapper>
        </RadioGroup>
      </FormStyled>
    </Container>
  );
};

export const OfferTypeWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.spacings[3]};
  justify-content: space-between;

  > div {
    width: 40%;
    flex-grow: 1;
  }

  span {
    color: ${({ theme }) => theme.color.base.grey[7]};
  }
`;

const FormStyled = styled.form`
  display: grid;
  gap: ${({ theme }) => theme.spacings[2]};
`;
