import styled from 'styled-components/macro';

import { Text, useTheme } from '@loomispay/vault';

type Props = {
  value: number;
  color?: 'primary' | 'disabled';
  children?: never;
};

const Wrapper = styled.div<{ borderColor: string }>`
  display: inline-block;
  width: 24px;
  height: 24px;
  line-height: 24px;
  border-radius: 50px;
  border: 1px solid ${({ borderColor }) => borderColor};
  text-align: center;
`;

export const CircledNumber = ({ value, color }: Props) => {
  const theme = useTheme();
  const borderColor = color === 'primary' ? theme.color.primary : theme.color.invertedDisabled;

  return (
    <Wrapper borderColor={borderColor}>
      <Text size="s" color={color === 'primary' ? 'primary' : 'invertedDisabled'}>
        {value.toString()}
      </Text>
    </Wrapper>
  );
};
