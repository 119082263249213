import { Text } from '@loomispay/vault';
import { BoxWrapper } from 'common/components/Box';
import styled from 'styled-components/macro';

export const RepresentativeWrapper = styled(BoxWrapper)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: ${({ theme }) => theme.spacings[3]};
  gap: ${({ theme }) => theme.spacings[2]};
`;

export const RepresentativeData = styled.div`
  display: flex;
  align-items: baseline;
  gap: ${({ theme }) => theme.spacings[2]};
`;

export const RepresentativeDataDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings[1]};
`;

export const RepresentativeActionButtons = styled.div`
  display: flex;
  justify-content: end;
  margin-right: -${({ theme }) => theme.spacings[2]};
`;

export const RepresentativeActionButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const EllipsisText = styled(Text)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
