import { CallToActionGridItem } from 'common/components/GridLayout';
import { Button, MediumIcons, spacings } from '@loomispay/vault';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Grid } from 'common/components/Grid';
import { fontFace } from 'constants/typography';

export const FOOTER_HEIGHT = '80px';

const FooterContainer = styled.footer<{ isMobile?: boolean }>`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  justify-content: flex-end;
  height: ${FOOTER_HEIGHT};
  border-top: 1px solid ${({ theme }) => theme.color.border.disabledBorder};
  background-color: ${({ theme }) => theme.color.background.primaryBg};
`;

const FooterContent = styled.div<{ isMobile?: boolean }>`
  height: 100%;
  max-width: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 ${({ isMobile }) => (isMobile ? 0 : spacings['4'])};
  font-family: ${fontFace.body};
`;

const BackButton = styled(Button)`
  flex-grow: 1;
`;

type Props = {
  isMobile?: boolean;
  isScreenCompleted?: boolean;
  prevPage?: { (): string };
  nextPage?: { (): void };
  nextButtonLabel?: string;
  nextButtonIcon?: MediumIcons;
  children?: React.ReactNode;
};
export const Footer = ({
  isMobile,
  isScreenCompleted = true,
  prevPage,
  nextPage,
  nextButtonLabel,
  nextButtonIcon,
  children,
}: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Grid>
      <FooterContainer isMobile={isMobile}>
        <FooterContent isMobile={isMobile}>
          <CallToActionGridItem>
            {prevPage !== undefined ? (
              <div>
                {isMobile ? (
                  <BackButton
                    onClick={() => {
                      navigate(prevPage());
                    }}
                    variant="tertiary"
                    icon="arrowLeft"
                    iconPosition="left"
                  />
                ) : (
                  <Button
                    onClick={() => {
                      navigate(prevPage());
                    }}
                    variant="tertiary"
                    label={t('common.backButton')}
                    fullWidth={true}
                    icon="arrowLeft"
                    iconPosition="left"
                  />
                )}
              </div>
            ) : (
              <div />
            )}
            {children}
            <Button
              onClick={nextPage}
              disabled={!isScreenCompleted}
              label={nextButtonLabel ? nextButtonLabel : t('onboarding.v2.continueButton')}
              testId="onboarding-v22-continueButton"
              fullWidth={isMobile}
              icon={nextButtonIcon}
              type={!nextPage ? 'submit' : 'button'}
            />
          </CallToActionGridItem>
        </FooterContent>
      </FooterContainer>
    </Grid>
  );
};
