import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useMerchantFlow } from './merchantFlowRoutingService';

const OnboardingDispatcher = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLoading, getContinuationPage } = useMerchantFlow();

  useEffect(() => {
    if (!isLoading) {
      navigate(getContinuationPage());
    }
  }, [dispatch, navigate, isLoading, getContinuationPage]);

  return null;
};

export default OnboardingDispatcher;
