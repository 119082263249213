import { DropDownOption } from 'constants/types';
import { Control, FieldErrors, UseFormMethods } from 'react-hook-form';
import { cashContractGuaranteeTypes, cashContractMonthlyFeeTypes } from './fixtures';
import { CommercialRepresentative } from '../../../../common/types';

export const cashServicesSupportedCountryCodes = ['es'] as const;
export type CashServicesSupportedCountryCodes = typeof cashServicesSupportedCountryCodes[number];

export type CashServicesSectionTypes = {
  storeIndex: number;
  control: Control;
  errors: FieldErrors;
  setValue?: UseFormMethods['setValue'];
};

/** CashOfferConfiguration Types */

export type CashOfferConfiguration = {
  paymentMethods: CashContractPaymentMethods;
  solutionTypes: CashSolutionTypes;
  loomisCommercialRepresentative?: CommercialRepresentative;
  loomisBankDetails?: LoomisBankDetails[];
};

export type CashSolutionTypes = {
  [key: string]: string;
};

export type CashContractPaymentMethods = {
  [key: string]: string;
};

export type LoomisBankDetails = {
  loomisBankName: string;
};

export type CashContractGuaranteeType = typeof cashContractGuaranteeTypes[number];

export type CashContractMonthlyFeeType = typeof cashContractMonthlyFeeTypes[number];

export type CashServicesFormFieldsES = {
  isCashEnabled?: boolean;
  generalInfo: {
    solutionType?: keyof CashSolutionTypes | DropDownOption<keyof CashSolutionTypes> | string;
    solutionTypeDescription?: string;
    basicSafePointIncluded?: boolean;
    isLoomisBeneficiary?: boolean;
    insuranceIncluded?: boolean;
    changeServiceIncluded?: boolean;
  };
  contractDates: {
    startDate?: string;
    durationInMonths?: number;
  };
  details: {
    pickupsPerMonth?: number;
    banknotesLimitValuePerMonth?: number;
    coinsLimitValuePerMonth?: number;
    banknotesRechargeExcessWithoutNewPickup?: number;
    banknotesRechargeExcessWithNewPickup?: number;
    chargeForEachCoinLimitExcess?: number;
  };
  changeService?: {
    changeWithPickupFee?: number;
    changeWithoutPickupFee?: number;
  };
  guarantee?: {
    type?: CashContractGuaranteeType | DropDownOption<CashContractGuaranteeType>;
    amount?: number;
  };
  clientInsurance?: {
    insuranceNumber?: string;
    insuranceCompany?: string;
    insuredAmount?: number;
  };
  instantCredit: {
    banknotesInstantCredit?: boolean;
    coinsInstantCredit?: boolean;
  };
  loomisOperationsBank?: LoomisBankDetails | DropDownOption<LoomisBankDetails>;
  fees: {
    minimumMonthlyFee?: number;
    monthlyFeeType?: CashContractMonthlyFeeType | DropDownOption<CashContractMonthlyFeeType>;
    fixedMonthlyFee?: number;
    percentageMonthlyFee?: number;
    upfrontPayment?: number;
    finalPayment?: number;
    paymentMethodChosen?: CashContractPaymentMethods | DropDownOption<CashContractPaymentMethods>;
  };
};

export type CashServicesDataSectionsES = keyof CashServicesFormFieldsES;
