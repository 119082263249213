import { Heading } from '@loomispay/vault';
import { DatePicker } from 'common/components/forms/DatePicker';
import { TextInput } from 'common/components/forms/TextInput/TextInput';
import { getStores } from 'features/onboardingV2/store/sales/selectors';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { CashServicesGridWrapper } from '../CashServices.styles';
import { CashServicesSectionTypes } from '../types';

export default function CashServicesDates({ storeIndex, control, errors, setValue }: CashServicesSectionTypes) {
  const { t } = useTranslation();
  const locations = useSelector(getStores);

  const cashDataContractDates = locations[storeIndex].cashData?.es?.contractDates;

  return (
    <section>
      <Heading size="s">{t('cashServices.dates')}</Heading>
      <CashServicesGridWrapper>
        <div>
          <DatePicker
            name={`store-${storeIndex}.contractDates.startDate`}
            label={t('cashServices.form.contractStartDate')}
            control={control}
            errors={errors}
            rules={{ required: t('common.form.validationMessages.required') }}
            defaultValue={cashDataContractDates?.startDate || new Date().toLocaleDateString('sv-SE')}
            onChange={(date: Date) => {
              setValue && setValue(`store-${storeIndex}.contractDates.startDate`, date.toLocaleDateString('sv-SE'));
            }}
            minDate={new Date()}
            testId={`store-${storeIndex}.contractDates.startDate`}
            useCustomMonthYearPicker
          />
        </div>
        <TextInput
          name={`store-${storeIndex}.contractDates.durationInMonths`}
          label={t('cashServices.form.contractDuration')}
          control={control}
          errors={errors}
          rules={{ required: t('common.form.validationMessages.required') }}
          defaultValue={cashDataContractDates?.durationInMonths?.toString() || ''}
          suffix={t('common.months')}
          type="number"
          inputProps={{
            min: 1,
            max: 120,
            step: 1,
          }}
        />
      </CashServicesGridWrapper>
    </section>
  );
}
