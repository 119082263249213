import { useState } from 'react';
import { Control, DeepMap, FieldError } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import ITransactionFeesForm from './ITransactionFeesForm';
import { Toggle } from '../../../../common/components/ToggleWrapper/Toggle';
import { useSelector } from 'react-redux';
import { getContractTerms, getOfferConfiguration } from '../../store/sales/selectors';
import Hr from 'common/components/Hr';
import TransactionFeeElement from './TransactionFeeElement';
import { AmexTransactionFee, AmexTransactionFeeConfigRule } from 'features/common/types';
import { spacing } from '../../../../constants';

const AmexFeeElement = (transactionFeeData: TransactionFeeElementProps) => {
  const contractTerms = useSelector(getContractTerms);
  const offerConfiguration = useSelector(getOfferConfiguration);
  const { t } = useTranslation();

  const previouslyEnteredAmexFee: AmexTransactionFee | undefined = contractTerms.transactionFees?.amexFee;

  const [toggle, setToggle] = useState(true);

  const configuration: AmexTransactionFeeConfigRule | undefined = offerConfiguration?.transactionFeeConfig?.amexRule;
  const isInputFieldVisible = toggle && configuration;

  return (
    <>
      <Hr margin={`${spacing.s1} 0`} />
      <section>
        <Toggle
          defaultValue={toggle.toString()}
          errors={transactionFeeData.errors}
          control={transactionFeeData.control}
          testId="amex-toggle"
          name="amex-toggle"
          label={t('onboarding.v2.offer.contractTerms.amex.title')}
          onChange={() => setToggle(!toggle)}
        >
          {toggle ? t('common.yes') : t('common.no')}
        </Toggle>
      </section>
      {isInputFieldVisible && (
        <TransactionFeeElement
          minFee={configuration.transactionFeeRange.minPercentage}
          maxFee={configuration.transactionFeeRange.maxPercentage}
          name={`amexFee`}
          testId={`transaction-fee-amex`}
          label={t('common.amex')}
          defaultValue={previouslyEnteredAmexFee?.percentage.toString() || ''}
          errors={transactionFeeData.errors}
          control={transactionFeeData.control}
        />
      )}
    </>
  );
};

export default AmexFeeElement;

export type TransactionFeeElementProps = {
  minFee: number;
  maxFee: number;
  name: string;
  defaultValue: string;
  ruleRef: string;
  control: Control;
  errors: DeepMap<ITransactionFeesForm, FieldError>;
};
