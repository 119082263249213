import { ReactNode } from 'react';
import { Grid, GridItem } from './Grid';
import styled from 'styled-components/macro';

export const SimpleGridLayout = ({ children }: { children: ReactNode }) => (
  <GridWrapper>
    <Grid>{children}</Grid>
  </GridWrapper>
);

export const SimpleGridItem = ({ children }: { children: ReactNode }) => (
  <GridItem s={4} m={8} l={6} lOffset={3}>
    {children}
  </GridItem>
);

export const SimpleSmallLeftGridItem = ({ children }: { children: ReactNode }) => (
  <GridItem s={4} m={4} l={3} lOffset={3}>
    {children}
  </GridItem>
);

export const SimpleSmallRightGridItem = ({ children }: { children: ReactNode }) => (
  <GridItem s={4} m={4} l={3}>
    {children}
  </GridItem>
);

export const CallToActionGridItem = ({
  children,
  isMobile,
  centered,
}: {
  children: ReactNode;
  isMobile?: boolean;
  centered?: boolean;
}) => (
  <GridItem
    s={4}
    m={8}
    l={10}
    lOffset={1}
    justifyContent={centered ? 'center' : 'space-between'}
    flexDirection={isMobile ? 'column' : 'row'}
    alignItems="center"
  >
    {children}
  </GridItem>
);

const GridWrapper = styled.div`
  padding: ${({ theme }) => theme.spacings['1']} 0;
`;
