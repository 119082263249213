import { Display, Icon, Paragraph, useMedia } from '@loomispay/vault';
import { useDocumentTitle } from 'hooks';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';
import { Grid, GridItem } from 'common/components/Grid';
import { useEffect } from 'react';
import useCognitoUser from '../../../loginPasswordless/useCognitoUser';

const Wrapper = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

const IconWrapper = styled.div`
  height: 88px;
  display: flex;
  align-items: center;
`;

export const DoneScreen = () => {
  const { t } = useTranslation();
  const isDesktop = useMedia('desktop');
  useDocumentTitle(t('onboarding.v2.done.heading'));
  const { signOut } = useCognitoUser();

  useEffect(() => {
    signOut();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Wrapper>
      <Grid>
        {isDesktop && (
          <GridItem s={4} m={8} l={1} lOffset={2}>
            <IconWrapper>
              <Icon name="successful" size="l" color="positive" />
            </IconWrapper>
          </GridItem>
        )}
        <GridItem s={4} m={8} l={6}>
          <Display size={isDesktop ? 'm' : 's'}>
            {!isDesktop && <Icon name="successful" size="l" color="positive" />}
            {t('onboarding.v2.done.heading')}
          </Display>
          <Paragraph size={isDesktop ? 'xl' : 'm'}>{t('onboarding.v2.done.body')}</Paragraph>
        </GridItem>
      </Grid>
    </Wrapper>
  );
};
