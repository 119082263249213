import { useState } from 'react';
import { FieldErrors } from 'react-hook-form';

const flatten = (object: FieldErrors, prefix = ''): FieldErrors => {
  return Object.keys(object).reduce((prev, element) => {
    return object[element] && typeof object[element] === 'object' && !Array.isArray(element)
      ? { ...prev, ...flatten(object[element], `${prefix}${element}.`) }
      : { ...prev, ...{ [`${prefix}${element}`]: object[element] } };
  }, {});
};

export default function useInputErrors(errors: FieldErrors, name: string) {
  const [errorMessage, setErrorMessage] = useState<string | undefined>();

  const getErrorMessage = () => {
    const namePlusKey = `${name}.message`;
    return setErrorMessage(flatten(errors)[namePlusKey]);
  };

  return { errorMessage, getErrorMessage };
}
