import { AnyAction, createReducer } from '@reduxjs/toolkit';
import {
  CLEAN_ACTION_PREFIX,
  FAIL_ACTION_SUFFIX,
  LIST_ACTION_SUFFIX,
  LIST_FAIL_ACTION_SUFFIX,
  LIST_SUCCESS_ACTION_SUFFIX,
  SUCCESS_ACTION_SUFFIX,
} from 'store/store.types';

export type AppState = {
  pendingRequests: number;
  isLoading: boolean;
};

const initialState: AppState = {
  pendingRequests: 0,
  isLoading: false,
};

const loadingBlacklistTypes = [
  'UPDATE_ONBOARDING_DATA',
  'UPDATE_LAYOUT_CONTENT',
  'GET_PAYOUT_STATS',
  'GET_MERCHANT_DOCUMENTS',
  'DELETE_PRODUCT_FROM_PRODUCT_GROUP',
  'CREATE_PRODUCT_TO_PRODUCT_GROUP_LINK',
  'GET_LOCATIONS',
  'GET_BALANCE',
  'GET_IMPORT_PRODUCTS',
  'GET_PAYOUT_PAYFAC_DETAILS_TRANSACTIONS',
];

const loadingIndicatorBlacklist = (type: string) => [
  isCleanAction(type),
  isListAction(type),
  loadingBlacklistTypes.some(
    v => type === v || type === `${v}${SUCCESS_ACTION_SUFFIX}` || type === `${v}${FAIL_ACTION_SUFFIX}`
  ),
];

const isCleanAction = (type: string): boolean => type.startsWith(CLEAN_ACTION_PREFIX);
const isListAction = (type: string): boolean =>
  type.endsWith(LIST_ACTION_SUFFIX) ||
  type.endsWith(LIST_SUCCESS_ACTION_SUFFIX) ||
  type.endsWith(LIST_FAIL_ACTION_SUFFIX);

const containsHttpRequest = (action: AnyAction): boolean =>
  action.payload !== undefined &&
  action.payload.request !== undefined &&
  action.payload.status === undefined &&
  !(action.type.endsWith(SUCCESS_ACTION_SUFFIX) || action.type.endsWith(FAIL_ACTION_SUFFIX));

const containsHttpResponse = (action: AnyAction): boolean =>
  (action.type.endsWith(SUCCESS_ACTION_SUFFIX) &&
    action.payload !== undefined &&
    action.payload.status !== undefined) ||
  (action.type.endsWith(FAIL_ACTION_SUFFIX) && action.error !== undefined);

const isRequestAction = (action: AnyAction): action is AnyAction => {
  return containsHttpRequest(action) && !loadingIndicatorBlacklist(action.type).some(el => el);
};

const isRequestFinish = (action: AnyAction): action is AnyAction => {
  return containsHttpResponse(action) && !loadingIndicatorBlacklist(action.type).some(el => el);
};

export const appReducer = createReducer(initialState, builder => {
  builder
    .addMatcher(isRequestAction, state => ({
      ...state,
      pendingRequests: state.pendingRequests + 1,
      isLoading: true,
    }))
    .addMatcher(isRequestFinish, state => ({
      ...state,
      pendingRequests: state.pendingRequests - 1,
      isLoading: state.pendingRequests - 1 > 0,
    }))
    .addDefaultCase(state => ({
      ...state,
    }));
});
