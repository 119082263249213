import { Heading, Paragraph, useMedia } from '@loomispay/vault';
import { SimpleGridItem, SimpleGridLayout } from 'common/components/GridLayout';
import { useDocumentTitle } from 'hooks';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ShippingInformationV2 as ShippingInformationV2Type,
  ShippingInformationV2Address,
  ShippingInformationForm,
} from 'features/common/types';
import ShippingInformationFormFields from 'features/common/components/ShippingInformationForm/ShippingInformationFormFields';
import { Footer } from 'features/onboardingV2/components/OnboardingFooter';
import { useForm } from 'react-hook-form';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { BaseQueryParams } from 'features/onboardingV2/data/types';
import { useOffer, useOnboardingData } from 'features/onboardingV2/data/queries';
import { CountryCode } from 'constants/types';
import { onboardingRoutes } from 'features/onboardingV2/OnboardingRouter';
import { usePartialUpdate } from 'features/onboardingV2/data/mutations';

const ShippingInformationV2 = () => {
  const { countryCode, offerRef } = useParams<BaseQueryParams>();
  const { data: offerData } = useOffer({ countryCode, offerRef });
  const { data: onboardingData } = useOnboardingData({ countryCode, offerRef });
  const { t } = useTranslation();
  const navigate = useNavigate();

  const prevPage = () => generatePath(onboardingRoutes.bankInformation.path, { countryCode, offerRef });
  const nextPage = generatePath(onboardingRoutes.additionalInformation.path, { countryCode, offerRef });

  const { mutate: uploadShippingInformation, isSuccess: shippingInformationUpdated } = usePartialUpdate();
  useDocumentTitle(t('common.shippingInformation.title'));

  const isMobile = useMedia('mobile');

  const form = useForm<ShippingInformationForm>({
    mode: 'onChange',
  });

  const { handleSubmit } = form;

  const onSubmit = async (shippingInformationForm: ShippingInformationForm) => {
    const shippingOption = shippingInformationForm.shippingOption.value;
    const all2OneStoreRef = shippingInformationForm.shippingLocationOption?.value;
    const shippingAddresses: ShippingInformationV2Address[] = [];

    if (shippingOption === 'ALL2ONE') {
      shippingAddresses.push({
        ...shippingInformationForm.shippingLocationData,
        countryCode: shippingInformationForm.countryCode.code,
      });
    }
    if (shippingOption === 'ALL2THEIR') {
      shippingInformationForm.storesShippingData.forEach(storesShippingData => {
        const location = onboardingData?.stores.find(location => location.ref === storesShippingData.storeRef);
        shippingAddresses.push({
          ...storesShippingData,
          ...location?.address,
        });
      });
    }

    const shippingInformationV2: ShippingInformationV2Type = {
      shippingOption,
      all2OneStoreRef,
      shippingAddresses,
    };

    uploadShippingInformation({
      countryCode,
      merchantRef: offerRef,
      data: { ...onboardingData, shippingInformationV2: shippingInformationV2, page: 'shippingInformationV2' },
    });
  };

  useEffect(() => {
    if (shippingInformationUpdated) {
      navigate(nextPage);
    }
  }, [shippingInformationUpdated, navigate, nextPage]);

  return onboardingData && offerData ? (
    <div>
      <SimpleGridLayout>
        <SimpleGridItem>
          <Heading size="xl">{t('common.shippingInformation.title')}</Heading>
          <Paragraph color={'tertiary'}>{t('common.shippingInformation.description')}</Paragraph>
        </SimpleGridItem>
      </SimpleGridLayout>
      <form onSubmit={handleSubmit(onSubmit)}>
        <ShippingInformationFormFields
          companyName={offerData.companyData.companyName}
          contactPerson={offerData.contactPerson}
          stores={onboardingData.stores}
          shippingInformation={onboardingData.shippingInformationV2}
          countryCode={countryCode as CountryCode}
          form={form}
        />
        <Footer isMobile={isMobile} prevPage={prevPage} />
      </form>
    </div>
  ) : null;
};

export default ShippingInformationV2;
