import { Link } from '@loomispay/vault';
import { Trans } from 'react-i18next';
import styled from 'styled-components';

const PrivacyNoticeContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
  white-space: pre-wrap;
`;

type Props = {
  countryCode: string;
};

const getPrivacyNoteLink = (countryCode: string) => {
  switch (countryCode) {
    case 'SE':
      return 'https://loomispay.com/sv-se/legal/onboarding-privacy-policy';
    case 'DK':
      return 'https://loomispay.com/da-dk/legal/onboarding-privacy-policy';
    case 'ES':
      return 'https://loomispay.com/es-es/legal/onboarding-privacy-policy';
    case 'NO':
      return 'https://loomispay.com/en/legal/onboarding-privacy-policy';
    default:
      throw new Error(`Unsupported country code ${countryCode}.`);
  }
};

export const PrivacyNotice = ({ countryCode }: Props) => {
  return (
    <PrivacyNoticeContainer id="privacy-notice-container">
      <Trans i18nKey={'onboarding.v2.merchant.overview.privacy'}>
        <span>{'By continuing this onboarding, I confirm that I have read and agree to the '}</span>
        <Link href={getPrivacyNoteLink(countryCode)}>Onboarding privacy notice</Link>
      </Trans>
    </PrivacyNoticeContainer>
  );
};
