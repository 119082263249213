import { ErrorMessage } from '@hookform/error-message';
import { Button, Icon, Label, Paragraph, Text } from '@loomispay/vault';
import { Batch, BatchItem, FileLike } from '@rpldy/shared';
import { asUploadButton, ButtonProps } from '@rpldy/upload-button';
import UploadDropZone from '@rpldy/upload-drop-zone';
import Uploady, { UPLOADER_EVENTS } from '@rpldy/uploady';
import { notifications } from 'common/components/Notification/notifications';
import { MouseEvent, useCallback, useEffect, useMemo, useRef } from 'react';
import { FieldErrors } from 'react-hook-form';
import { UseFormMethods } from 'react-hook-form/dist/types';
import { useTranslation } from 'react-i18next';
import { Input } from './OnboardingFileInput';
import {
  UploadWrapper,
  Container,
  FileContainer,
  FileList,
  FileListItem,
  RemoveFile,
  UploadText,
} from './OnboardingFileUpload.styles';
import styled from 'styled-components/macro';

export type FileUploadProps = {
  /**
   * string[] with list af the accepted file formats
   * ie: ['.jpg', '.csv' 'image/*', '.pdf']
   */
  acceptedTypes?: string[];
  label: string;
  isMobile: boolean;
  onUpload: (file: FileLike) => Promise<boolean>;
  onRemove: (fileName?: string) => Promise<boolean>;
  fileName?: string;
  fileList?: string[];
  name: string;
  register: UseFormMethods['register'];
  setValue: UseFormMethods['setValue'];
  errors: FieldErrors;
  multiUpload?: boolean;
  maxFiles?: number;
  testId?: string;
};

export const FileUpload = ({
  acceptedTypes,
  label,
  onUpload,
  onRemove,
  fileName = '',
  fileList = [],
  isMobile = false,
  name,
  register,
  setValue,
  errors,
  multiUpload = false,
  maxFiles,
  testId,
}: FileUploadProps) => {
  const { t } = useTranslation();
  const inputRef = useRef<HTMLDivElement>(null);

  const UploadField = asUploadButton((props: ButtonProps) => {
    return <div {...props} className={`${checkErrors(name, errors) ? 'has-error' : ''}`} />;
  });

  const checkErrors = (name: string, errors: FieldErrors) => {
    return resolvePath(name, errors);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const resolvePath = (path: string | string[], obj: any, separator = '.') => {
    const properties = Array.isArray(path) ? path : path.split(separator);
    return properties.reduce((prev, curr) => prev && prev[curr], obj);
  };

  const isValidFormat = useCallback(
    (item: BatchItem) => {
      if (!acceptedTypes || acceptedTypes.length === 0) return true;
      const fileType = item.file.type;

      return acceptedTypes.includes(fileType);
    },
    [acceptedTypes]
  );

  const listeners = useMemo(
    () => ({
      [UPLOADER_EVENTS.BATCH_ADD]: (batch: Batch) => {
        const item = batch.items[0];
        if (isValidFormat(item)) {
          onUpload(item.file);
        } else {
          notifications.warn(t('onboarding.v2.notifications.incorrectFileType'));
        }
      },
    }),
    [isValidFormat, onUpload, t]
  );

  const handleRemove = async (e: MouseEvent, fileName?: string) => {
    e.stopPropagation();
    if (onRemove) {
      await onRemove(fileName);
    }
    if (!multiUpload) {
      setValue(name, '');
    } else {
      const updatedFileList = fileList.filter(i => i !== fileName);
      setValue(name, updatedFileList);
    }
  };

  useEffect(
    () => {
      if (!multiUpload) {
        setValue(name, fileName);
      } else {
        setValue(name, fileList);
      }
    },
    /* eslint-disable react-hooks/exhaustive-deps */
    []
  );

  useEffect(() => {
    const fieldError = resolvePath(name, errors);
    if (fieldError) {
      setFocus();
    }
  }, [name, errors]);

  const setFocus = () => {
    inputRef.current && inputRef.current.focus();
  };

  const handleDrop = (e: DragEvent) => {
    const file = e?.dataTransfer?.files || [];
    const fileName = file?.[0].name;
    handleAdd(fileName);
    return file;
  };

  const handleAdd = (fileName?: string) => {
    if (!multiUpload) {
      setValue(name, fileName);
    } else {
      const updatedFileList = [...fileList, fileName];
      setValue(name, updatedFileList);
    }
  };

  return (
    <UploadContainer data-testid={testId}>
      <UploadWrapper isDisabled={multiUpload && fileList?.length === maxFiles}>
        <Uploady multiple={false} autoUpload={false} listeners={listeners} customInput>
          <Input name={name} acceptedTypes={acceptedTypes} register={register} handleAdd={handleAdd} />
          <UploadDropZone onDragOverClassName="drag-over" dropHandler={handleDrop}>
            <UploadField>
              <Container fileName={fileName} multiUpload={multiUpload} isMobile={isMobile} ref={inputRef} tabIndex={-1}>
                {!multiUpload && fileName ? (
                  <FileContainer>
                    <Label size="s" color="disabled">
                      {fileName}
                    </Label>
                    <RemoveFile>
                      <Button variant="tertiary" size="s" icon="delete" iconPosition="left" onClick={handleRemove} />
                    </RemoveFile>
                  </FileContainer>
                ) : (
                  <>
                    <Icon name="upload" size="s" />
                    {multiUpload && maxFiles && (
                      <Text size="s">
                        {fileList.length}/{maxFiles}
                      </Text>
                    )}
                    <UploadText>
                      <Paragraph size="s" align="center" noGutter>
                        {label}
                      </Paragraph>
                      <Paragraph size="s" align="center">
                        {t('onboarding.v2.additionalInformation.identification.addFile.fileTypes')}
                      </Paragraph>
                    </UploadText>
                  </>
                )}
              </Container>
              <ErrorMessage
                errors={errors}
                name={name}
                render={({ message }) => (
                  <Paragraph size="s" color="negative" data-testid={`${testId}.error`}>
                    {message}
                  </Paragraph>
                )}
              />
            </UploadField>
          </UploadDropZone>
        </Uploady>
      </UploadWrapper>
      {multiUpload && fileList.length > 0 && (
        <>
          <Paragraph size="s" noGutter>
            {t('onboarding.v2.additionalInformation.identification.addFile.uploadedFiles')}:
          </Paragraph>
          <FileList>
            {fileList.map((name, index) => {
              return (
                <FileListItem key={`${index}_${name}`}>
                  {name}
                  <Button
                    variant="tertiary"
                    size="s"
                    icon="delete"
                    iconPosition="left"
                    onClick={e => handleRemove(e, name)}
                  />
                </FileListItem>
              );
            })}
          </FileList>
        </>
      )}
    </UploadContainer>
  );
};

const UploadContainer = styled.div`
  display: grid;
  gap: ${({ theme }) => theme.spacings['2']};
`;
