import { Display, Paragraph, useMedia } from '@loomispay/vault';
import Form from 'common/components/forms/Form';
import Container from 'common/components/MainLayout/Container';
import { Footer } from 'features/onboardingV2/components/OnboardingFooter';
import { PepForm } from 'features/onboardingV2/components/pepForm';
import { useDocumentTitle } from 'hooks';
import { Fragment, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { NotaryDetails as NotaryDetailsType } from '../../../../common/types';

import { Representative, Signer } from '../../../store/types';
import { checkIfCashSupportedForCountry } from '../../sales/CashServices/utils';
import { AdditionalDocuments } from './AdditionalDocuments';
import { AdditionalInformationContent } from './AdditionalInformationContent';
import NotaryDetails from './NotaryDetails';
import { signersAnomalyWhitelistedCompanyIDs } from 'app/whitelistedCompanyIDs';
import { parse, formatISO } from 'date-fns';
import { BaseQueryParams } from 'features/onboardingV2/data/types';
import { useOffer, useOnboardingData } from 'features/onboardingV2/data/queries';
import { onboardingRoutes } from 'features/onboardingV2/OnboardingRouter';
import { CountryCode } from 'constants/types';
import { useSubmitOnboarding } from 'features/onboardingV2/data/mutations';

type AdditionalInformationForm = {
  owners: Record<
    string,
    {
      personalId?: string;
      pepInfo: PepForm;
      files: string;
    }
  >;
  signers: Record<
    string,
    {
      email?: string;
      personalId?: string;
      pepInfo: PepForm;
      files: string;
      dateOfBirth?: string;
    }
  >;
  notaryDetails: NotaryDetailsType;
};

const AdditionalInformation = () => {
  const { countryCode, offerRef } = useParams<BaseQueryParams>();
  const { data: offer } = useOffer({ countryCode, offerRef });
  const { data: onboardingData } = useOnboardingData({ countryCode, offerRef });
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dateOfBirthFormat = 'dd/MM/yyyy';
  const isMobile = useMedia('mobile');
  useDocumentTitle(t('onboarding.v2.additionalInformation.title'));

  const prevPage = () =>
    generatePath(
      offer?.offerType === 'CASH' ? onboardingRoutes.bankInformation.path : onboardingRoutes.shippingInformationV2.path,
      { countryCode, offerRef }
    );

  const { mutate: submitOnboarding, data: submittedOnboardingResponse } = useSubmitOnboarding();

  const { handleSubmit, control, errors, register, setValue, watch } = useForm<AdditionalInformationForm>({
    mode: 'onChange',
  });

  useEffect(() => {
    if (submittedOnboardingResponse) {
      if (submittedOnboardingResponse.status === 'EDD_ANSWERS_REQUIRED') {
        navigate(generatePath(onboardingRoutes.additionalQuestions.path, { countryCode, offerRef }));
      } else if (['CONTRACT_PENDING', 'FORM_SUBMITTED'].includes(submittedOnboardingResponse.status)) {
        navigate(generatePath(onboardingRoutes.done.path, { countryCode, offerRef }));
      }
    }
  }, [submittedOnboardingResponse, navigate, countryCode, offerRef]);

  if (onboardingData && offer && countryCode) {
    const { owners, signatories: signers } = onboardingData;
    const isCashOnlyOffer = offer.offerType === 'CASH';
    const isCashServiceIncluded =
      isCashOnlyOffer ||
      (checkIfCashSupportedForCountry(offer.countryCode) && offer.stores?.some(store => store.isCashEnabled));
    const isPersonalIdDocumentNeeded = (representative: Representative): boolean => {
      switch (countryCode) {
        case 'DK':
        case 'ES':
          return true;
        case 'NO':
          return !representative.personalId;
        case 'SE':
          return !!representative.isManuallyAdded;
        default:
          throw new Error('Unsupported country');
      }
    };

    const isPersonalNumberNeeded = (representative: Representative): boolean => {
      if (signersAnomalyWhitelistedCompanyIDs.includes(offer.companyData.companyNumber)) {
        return true;
      }
      switch (countryCode) {
        case 'DK':
        case 'ES':
          return true;
        case 'NO':
          return !representative.personalId;
        case 'SE':
          return !!representative.isManuallyAdded && !representative.personalId;
        default:
          throw new Error('Unsupported country');
      }
    };

    const prepareOwners = (form: AdditionalInformationForm) =>
      owners.map(owner => {
        const formOwner = form.owners && form.owners[owner.ref as string];
        const formSigner = form.signers && form.signers[owner.ref as string];

        if (!formOwner && !formSigner) return owner;

        // if owner is also a signer, use their input data
        const pepInfo = formSigner ? formSigner.pepInfo : formOwner.pepInfo;
        const personalId =
          (formSigner?.personalId?.length && formSigner?.personalId) ||
          (formOwner?.personalId?.length && formOwner?.personalId) ||
          owner.personalId;

        return {
          ...owner,
          pepInfo: {
            ...owner.pepInfo,
            isPep: pepInfo.isPep === 'true',
            position: pepInfo.position?.value,
            relation: pepInfo.relation?.value,
          },
          personalId: personalId,
        };
      });

    const dateOfBirthToISO = (dob: string): string => {
      const dateOfBirth = parse(dob, dateOfBirthFormat, new Date());
      return formatISO(dateOfBirth, { representation: 'date' });
    };

    const prepareSigners = (form: AdditionalInformationForm) => {
      const updatedSigners: Signer[] = [];

      Object.entries(form.signers || {}).forEach(([ref, signer]) => {
        const oldSigner = signers.find(signer => signer.ref === ref);

        if (!oldSigner) return;

        const updatedSigner: Signer = {
          ...oldSigner,
          personalId: signer.personalId ? signer.personalId : oldSigner.personalId,
          pepInfo: {
            ...oldSigner.pepInfo,
            isPep: signer.pepInfo.isPep === 'true',
            position: signer.pepInfo?.position?.value,
            relation: signer.pepInfo?.relation?.value,
          },
          email: signer.email,
          ...(signer.dateOfBirth && { dateOfBirth: dateOfBirthToISO(signer.dateOfBirth) }),
        };
        updatedSigners.push(updatedSigner);
      });
      return updatedSigners;
    };

    const onSubmit = (form: AdditionalInformationForm) => {
      const representatives = {
        owners: prepareOwners(form),
        signers: prepareSigners(form),
      };

      submitOnboarding({
        countryCode,
        merchantRef: offerRef,
        data: {
          ...onboardingData,
          owners: representatives.owners,
          signatories: representatives.signers,
          notaryDetails: isCashServiceIncluded
            ? {
                notary: form.notaryDetails.notary,
                notaryProvince: form.notaryDetails.notaryProvince,
                notarizedDeedDate: form.notaryDetails.notarizedDeedDate,
                notarizedDeedNumber: form.notaryDetails.notarizedDeedNumber,
                mercantileRegistry: form.notaryDetails.mercantileRegistry,
              }
            : undefined,
          page: 'additionalInformation',
        },
        offerType: offer.offerType,
      });
    };

    return (
      <Container spacing="4">
        <header>
          <Display size="s">{t('onboarding.v2.additionalInformation.title')}</Display>
          <Paragraph color="tertiary">{t('onboarding.v2.additionalInformation.description')}</Paragraph>
        </header>

        <Form spacing="6" onSubmit={handleSubmit(onSubmit)}>
          {owners.map((owner, i) => {
            const signatory = signers.some(signer => signer.ref === owner.ref);
            return (
              <Fragment key={i}>
                <AdditionalInformationContent
                  fullName={owner.fullName}
                  personalId={owner.personalId}
                  pepInfo={owner.pepInfo}
                  identifier={owner.ref || ''}
                  ownerType={owner.type}
                  isSignatory={signatory}
                  isPersonalIdNeeded={signatory && isPersonalIdDocumentNeeded(owner)}
                  isPersonalNumberNeeded={signatory && isPersonalNumberNeeded(owner)}
                  isEmailNeeded={!isCashOnlyOffer}
                  isDateOfBirthCollected={!isCashOnlyOffer && signatory && countryCode === 'ES'}
                  dateOfBirthFormat={dateOfBirthFormat}
                  control={control}
                  errors={errors}
                  testId={i}
                  register={register}
                  setValue={setValue}
                  watch={watch}
                />
              </Fragment>
            );
          })}

          {signers.map((signer, i) => {
            const owner = owners.some(owner => owner.ref === signer.ref);
            return (
              !owner && (
                <Fragment key={i}>
                  <AdditionalInformationContent
                    fullName={signer.fullName}
                    personalId={signer.personalId}
                    pepInfo={signer.pepInfo}
                    email={signer.email}
                    identifier={signer.ref || ''}
                    signingRoles={signer.roles}
                    isSignatory={true}
                    isPersonalIdNeeded={isPersonalIdDocumentNeeded(signer)}
                    isPersonalNumberNeeded={isPersonalNumberNeeded(signer)}
                    isEmailNeeded={!isCashOnlyOffer}
                    isDateOfBirthCollected={!isCashOnlyOffer && countryCode === 'ES'}
                    dateOfBirthFormat={dateOfBirthFormat}
                    control={control}
                    errors={errors}
                    testId={i}
                    register={register}
                    setValue={setValue}
                    watch={watch}
                  />
                </Fragment>
              )
            );
          })}
          {(countryCode === 'ES' || [...owners, ...signers].some(o => o.isManuallyAdded)) && (
            <AdditionalDocuments
              offerRef={offer.ref}
              errors={errors}
              register={register}
              setValue={setValue}
              countryCode={countryCode as CountryCode}
            />
          )}

          {isCashServiceIncluded && <NotaryDetails errors={errors} control={control} setValue={setValue} />}
          <Footer isMobile={isMobile} prevPage={prevPage} nextButtonLabel={t('common.done')} />
        </Form>
      </Container>
    );
  } else {
    return null;
  }
};

export default AdditionalInformation;
