import { Address, CountryCode, CurrencyCode, DropDownOption, FormVersion } from 'constants/types';
import {
  CashServicesFormFieldsES,
  CashServicesSupportedCountryCodes,
} from 'features/onboardingV2/screens/sales/CashServices/types';
import {
  BankInformation,
  ExtendedBankInformation,
  InvoiceInformation,
  OnboardingDocument,
  Owner,
  Page,
  PayfacOnboardingStatus,
  SignatureProvider,
  Signer,
} from '../onboardingV2/store/types';
import { UseFormMethods } from 'react-hook-form';

type StoreCashData = {
  cashData?:
    | {
        [key in CashServicesSupportedCountryCodes]?: CashServicesFormFieldsES;
      }
    | null;
  isCashEnabled?: boolean;
};

export type Store = StoreBase &
  StoreCashData & {
    loomisStoreRef?: string;
    storeOfferItems?: StoreOfferItems;
  };

export type StoreBase = {
  name: string;
  address: Address;
};

export type StoreOfferItems = {
  terminals: OfferItem[];
  addons: OfferItem[];
};

export type OfferItem = {
  name: string;
  ref: OfferItemReference;
  posTerminalTypeReference?: string;
  loomisModelName?: string;
  quantity: number;
  prices: OfferItemPrice[];
  freeMonths?: number;
};

export type ContactPerson = {
  firstName?: string;
  lastName?: string;
  emailAddress: string;
  phoneNumber?: string;
};

export type Consents = {
  directDebit?: boolean;
  electronicInvoicing?: boolean;
  commercialCommunications?: boolean;
};

export type CommercialRepresentative = {
  fullName: string;
  idNumber: string;
};

export type OfferContactData = {
  phoneNumber: string;
  loomisContactPerson: ContactPerson;
};

export type OfferItemReference = string; // this will be a string union when its well defined

export type OfferItemPrice = Cost & { priceType: OfferItemPriceType };

export type OfferItemPriceType = 'upfront' | 'monthly';

export type Cost = Amount & {
  softwareFee?: number | null;
  name: string;
  summarySectionId: number;
};

export type Amount = {
  amount: number;
  currencyCode: CurrencyCode;
};

export type OfferOnboardingStore = StoreBase &
  StoreCashData & {
    storeProducts: StoreOfferItems;
    ref: string;
    loomisStoreRef?: string;
  };

export type CompanyData = {
  companyNumber?: string;
  companyName?: string;
  currencyCode?: CurrencyCode;
  industry?: string;
  companyForm?: string;
  address?: Address;
  registrationDate?: string;
  isManualFallbackPathAvailable?: boolean;
  isManuallyAdded?: boolean;
  isAllowed?: boolean;
};

export type CompanyDataForm = CompanyData & {
  companyFormOption?: CompanyFormOption;
};

export type CompanyFormOption = {
  label: string;
  value: string;
};

/** OfferConfiguration Types */

export type OfferConfiguration = {
  contractDurationOptions: number[];
  subscriptionMonthsNumber: number;
  transactionFeeOptions: number[];
  transactionFeeConfig: TransactionFeeConfig;
  terminalOptions: OfferConfigurationItem[];
  addonOptions: OfferConfigurationItem[];
  companyTypes?: Record<string, string>;
  signatureProviderConfig: SignatureProviderConfig;
  partnersConfiguration?: PartnersConfiguration;
};

export type PartnersConfiguration = {
  requiredForOnboarding: boolean;
  defaultPartnerId?: string;
  partnerConfigurations: PartnerConfiguration[];
};

export type PartnerConfiguration = {
  id: string;
  name: string;
};

export type SignatureProviderConfig = {
  defaultProvider: SignatureProvider;
  providers: SignatureProvider[];
};

export type TransactionFeeConfig = {
  countryCode: string;
  defaultFee: TransactionFeeConfigDefault;
  rules: TransactionFeeConfigRule[];
  amexRule?: AmexTransactionFeeConfigRule;
};

export type TransactionFeeConfigDefault = {
  ref: string;
  transactionFeeRange: TransactionFeeRange;
};

export const cardTypes = ['DEBIT', 'CREDIT'] as const;
export type CardType = typeof cardTypes[number];

export const regions = ['DOMESTIC', 'INTRAREGIONAL'] as const;
export type Region = typeof regions[number];

export const cardSchemes = ['VISA', 'MASTERCARD'] as const;
export type CardScheme = typeof cardSchemes[number];

export type TransactionFeeConfigRule = {
  ref: string;
  region: Region;
  cardType: CardType;
  cardCustomerType: string;
  cardScheme: CardScheme;
  transactionFeeRange: TransactionFeeRange;
};

export type AmexTransactionFeeConfigRule = {
  ref: string;
  transactionFeeRange: TransactionFeeRange;
};

export type AmexTransactionFee = {
  percentage: number;
  configRef: string;
};

export type TransactionFees = {
  defaultFee: TransactionFeeDefault;
  rules: TransactionFee[];
  amexFee?: AmexTransactionFee;
};

export type TransactionFeeDefault = {
  percentage: number;
  configRef: string;
};

export type TransactionFee = {
  percentage: number;
  configRef: string;
  cardScheme: CardScheme;
  region: Region;
  cardType: CardType;
  cardCustomerType: string;
};

export type ContractDurationAndTransactionFee = {
  durationInMonths?: number;
  // TODO: remove after change in backend
  transactionFee?: number;
  transactionFees?: TransactionFees;
  startDate?: string;
};

export type ContractTerms = ContractDurationAndTransactionFee & {
  terminalsFreeMonths?: number;
};

export type DeprecatedTransactionFeeRange = {
  min: number;
  max: number;
};

export type TransactionFeeRange = {
  defaultPercentage: number;
  minPercentage: number;
  maxPercentage: number;
};

export type OfferConfigurationItem = {
  code: string;
  name: string;
  posTerminalTypeReference?: string;
  loomisModelName?: string;
  monthlyCost: Cost | null;
  initialCost: Cost | null;
};

/** Shipping information */
export type ShippingOptionType = DropDownOption<ShippingOption>;
export type ShippingLocationOptionType = DropDownOption<string | undefined>;
export type CountryDropdownOptionType = { code?: CountryCode; name?: string };

export type ShippingLocationData = {
  recipient: string;
  attention: string;
  address?: string;
  city?: string;
  zipCode?: string;
  storeRef?: string;
};

export type StoreShippingData = {
  recipient: string;
  attention: string;
  storeRef: string;
};

export type ShippingInformationForm = {
  shippingOption: ShippingOptionType;
  shippingLocationOption: ShippingLocationOptionType;
  shippingLocationData: ShippingLocationData;
  storesShippingData: StoreShippingData[];
  countryCode: CountryDropdownOptionType;
};

export type ShippingOption = 'ALL2ONE' | 'ALL2THEIR';

export type ShippingInformationV2 = {
  shippingOption?: ShippingOption;
  all2OneStoreRef?: string;
  shippingAddresses: ShippingInformationV2Address[];
};

export type ShippingInformationV2Address = Address & {
  recipient?: string;
  attention?: string;
  storeRef?: string;
};

export type BankAccountScreenProps = {
  form: UseFormMethods<FormVersion<ExtendedBankInformation>>;
  bankInformation?: BankInformation;
  countryCode: CountryCode;
};

export type NotaryDetails = {
  notary: string;
  notaryProvince: string;
  notarizedDeedDate: string;
  notarizedDeedNumber: string;
  mercantileRegistry: string;
};

export type MerchantOnboardingData = {
  offerRef?: string;
  status: PayfacOnboardingStatus;
  bankInformation?: BankInformation;
  invoiceInformation?: InvoiceInformation;
  owners: Owner[];
  isOwnersModified: boolean;
  signatories: Signer[];
  offerOnboardingGlobalDocuments: OnboardingDocument[];
  shippingInformationV2?: ShippingInformationV2;
  stores: OfferOnboardingStore[];
  page?: Page;
  notaryDetails?: NotaryDetails;
};
