import { Heading } from '@loomispay/vault';
import { Toggle } from 'common/components/ToggleWrapper/Toggle';
import { getStores } from 'features/onboardingV2/store/sales/selectors';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { CashServicesGridWrapper } from '../CashServices.styles';
import { CashServicesSectionTypes } from '../types';

export default function CashServicesInstantCredit({ storeIndex, control, errors }: CashServicesSectionTypes) {
  const { t } = useTranslation();
  const locations = useSelector(getStores);

  const cashData = locations[storeIndex].cashData?.es?.instantCredit;
  return (
    <section>
      <Heading size="s">{t('cashServices.instantCredit')}</Heading>
      <CashServicesGridWrapper>
        <Toggle
          label={t('cashServices.form.banknotesInstantCredit')}
          control={control}
          errors={errors}
          name={`store-${storeIndex}.instantCredit.banknotesInstantCredit`}
          defaultValue={cashData?.banknotesInstantCredit?.toString()}
        />

        <Toggle
          label={t('cashServices.form.coinsInstantCredit')}
          control={control}
          errors={errors}
          name={`store-${storeIndex}.instantCredit.coinsInstantCredit`}
          defaultValue={cashData?.coinsInstantCredit?.toString()}
        />
      </CashServicesGridWrapper>
    </section>
  );
}
