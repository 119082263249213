import { useTranslation } from 'react-i18next';
import { TextInput } from 'common/components/forms/TextInput/TextInput';
import { SimpleSmallLeftGridItem, SimpleSmallRightGridItem } from 'common/components/GridLayout';
import styled from 'styled-components/macro';
import { isValidBic, isValidIban } from 'utils/validation';
import { BankAccountScreenProps } from 'features/common/types';

const InternationalBankAccountScreen = ({ form, bankInformation, countryCode }: BankAccountScreenProps) => {
  const { t } = useTranslation();

  return (
    <>
      <SimpleSmallLeftGridItem>
        <TextInput
          label={t('onboarding.v2.bankInformation.swiftOrBic')}
          name="bankCode"
          setValue={(value?: string) => form.setValue('bankCode', value)}
          normalizeValue={(value: string) => value.toUpperCase().trim()}
          rules={{
            required: t('common.form.validationMessages.required'),
            validate: (value: string) =>
              isValidBic(value, countryCode) || t('common.form.validationMessages.invalidBic'),
          }}
          defaultValue={bankInformation?.bankCode || ''}
          control={form.control}
          errors={form.errors}
        />
      </SimpleSmallLeftGridItem>
      <SimpleSmallRightGridItem>
        <TextInput
          label={t('onboarding.v2.bankInformation.iban')}
          name="accountNumber"
          setValue={(value?: string) => form.setValue('accountNumber', value)}
          normalizeValue={(value: string) => value.toUpperCase().trim()}
          rules={{
            required: t('common.form.validationMessages.required'),
            validate: (value: string) =>
              isValidIban(value, countryCode) || t('common.form.validationMessages.invalidIban'),
          }}
          defaultValue={bankInformation?.accountNumber || ''}
          control={form.control}
          errors={form.errors}
        />
      </SimpleSmallRightGridItem>

      <ChildrenWrapper hidden={true}>
        <TextInput
          name="bankInformationType"
          defaultValue="INTERNATIONAL"
          label=""
          control={form.control}
          errors={form.errors}
        />
      </ChildrenWrapper>
    </>
  );
};

const ChildrenWrapper = styled.div``;

export default InternationalBankAccountScreen;
