import { createAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { GAApplicationType, GAListResponse, GAStore } from 'constants/gaTypes';
import { VatRate } from './applicationReducer';

const GET_APPLICATION_CONFIGS = 'GET_APPLICATION_CONFIGS';
const GET_VAT_RATES_CONFIG = 'GET_VAT_RATES_CONFIG';
const COUNT_LOCATIONS = 'COUNT_LOCATIONS';

export const getApplicationConfigs = createAction(GET_APPLICATION_CONFIGS, (type: GAApplicationType) => {
  return {
    payload: {
      request: {
        method: 'GET',
        url: `/applications/${type}/configurations`,
      },
    },
  };
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getApplicationConfigsSuccess = createAction<AxiosResponse<GAListResponse<any>>>(
  GET_APPLICATION_CONFIGS + '_SUCCESS'
);
export const getApplicationConfigsFail = createAction(GET_APPLICATION_CONFIGS + '_FAIL');

export const getVatRatesConfig = createAction(GET_VAT_RATES_CONFIG, country => {
  return {
    payload: {
      request: {
        method: 'GET',
        url: `/vat-rates?filter[country]=${country}`,
      },
    },
  };
});

export const getVatRatesConfigSuccess = createAction<AxiosResponse<GAListResponse<VatRate>>>(
  GET_VAT_RATES_CONFIG + '_SUCCESS'
);
export const getVatRatesConfigFail = createAction(GET_VAT_RATES_CONFIG + '_FAIL');

export const getLocations = createAction(COUNT_LOCATIONS, () => {
  return {
    payload: {
      request: {
        method: 'GET',
        url: `/stores?page=0&page_size=100`,
      },
    },
  };
});

export const getLocationsSuccess = createAction<AxiosResponse<GAListResponse<GAStore>>>(COUNT_LOCATIONS + '_SUCCESS');
export const getLocationsFail = createAction<AxiosResponse<GAListResponse<GAStore>>>(COUNT_LOCATIONS + '_FAIL');

export const configureSales = createAction(`CONFIGURE_SALES`, () => {
  return {
    payload: {
      client: 'onboardingServicePublicClient',
      request: {
        method: 'PUT',
        url: `/sales/configure`,
        data: {},
      },
    },
  };
});

export const configureSalesSuccess = createAction('CONFIGURE_SALES_SUCCESS');
export const configureSalesFail = createAction('CONFIGURE_SALES_FAIL');
