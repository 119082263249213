import { Heading } from '@loomispay/vault';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getOffer } from '../../../../store/sales/selectors';
import { Control, FieldErrors } from 'react-hook-form';
import { Checkbox } from '../../../../../../common/components/forms/Checkbox/Checkbox';
import Container from '../../../../../../common/components/MainLayout/Container';

export default function CashConsents({ control, errors }: { control: Control; errors: FieldErrors }) {
  const { t } = useTranslation();
  const offer = useSelector(getOffer);

  const cashData = offer.consents;

  return (
    <section>
      <Heading size="xxs" sansSerif>
        {t('cashServices.consents')}
      </Heading>
      <Container spacing="2">
        <Checkbox
          name="consents.directDebit"
          label={t('cashServices.form.consents.directDebitConsent')}
          control={control}
          errors={errors}
          defaultChecked={cashData?.directDebit}
        />

        <Checkbox
          name="consents.electronicInvoicing"
          label={t('cashServices.form.consents.electronicInvoiceConsent')}
          control={control}
          errors={errors}
          defaultChecked={cashData?.electronicInvoicing}
        />

        <Checkbox
          name="consents.commercialCommunications"
          label={t('cashServices.form.consents.commercialCommunicationsConsent')}
          control={control}
          errors={errors}
          defaultChecked={cashData?.commercialCommunications}
        />
      </Container>
    </section>
  );
}
