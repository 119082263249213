export type Environments = 'development' | 'staging' | 'mirror' | 'production' | 'test';

interface Envs {
  readonly appVersion: string;
  readonly environment: Environments;
  readonly process: string;
  readonly isProd: boolean;
  readonly showTestTransactions: boolean;
  readonly sentryDSN: string;
  readonly datadogApplicationId: string;
  readonly datadogClientToken: string;
  readonly datadogSite: string;
  readonly datadogTracingURLS: string[];
}

function toBool(str?: string) {
  return str === 'true' || str === '1';
}

export const links = {
  merchantPortalUrl: process.env.REACT_APP_MERCHANT_PORTAL_URL as string,
  companyDataProviderUrl: process.env.REACT_APP_COMPANY_DATA_PROVIDER_URL as string,
};

export const env: Envs = {
  appVersion: process.env.REACT_APP_VERSION as string,
  environment: process.env.REACT_APP_ENV as Environments,
  process: process.env.REACT_APP_PROCESS as string,
  isProd: process.env.REACT_APP_ENV === 'production',
  showTestTransactions: toBool(process.env.REACT_APP_SHOW_TEST_TRANSACTIONS),
  sentryDSN: process.env.REACT_APP_SENTRY_DSN as string,
  datadogApplicationId: process.env.REACT_APP_DATADOG_APPLICATION_ID as string,
  datadogClientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN as string,
  datadogSite: process.env.REACT_APP_DATADOG_SITE as string,
  datadogTracingURLS: Object.values(links),
};
