import { ReactNode } from 'react';
import { Heading } from '@loomispay/vault';
import { ModalBodyStyled, ModalFooterStyled, ModalHeaderStyled } from './modal.styles';

interface ModalHeaderProps {
  children: ReactNode;
}

interface ModalBodyProps {
  children: ReactNode;
  id: string;
}

interface ModalFooterProps {
  children: ReactNode;
}

export const ModalHeader = ({ children }: ModalHeaderProps) => (
  <ModalHeaderStyled>
    <Heading size="l" id="heading">
      {children}
    </Heading>
    {
      // TODO: Refactor to use button instead of div!
    }
  </ModalHeaderStyled>
);

export const ModalBody = ({ children, id }: ModalBodyProps) => <ModalBodyStyled id={id}>{children}</ModalBodyStyled>;

export const ModalFooter = ({ children }: ModalFooterProps) => <ModalFooterStyled>{children}</ModalFooterStyled>;
