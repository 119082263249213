import { CognitoUserSession } from 'amazon-cognito-identity-js';
import jwtDecode from 'jwt-decode';

export default function parseOAuthUserData(sessionData: CognitoUserSession) {
  const token = sessionData.getAccessToken().getJwtToken();
  const idToken = sessionData.getIdToken().getJwtToken();
  const { sub, 'cognito:groups': roles }: { sub: string; 'cognito:groups': string[] } = jwtDecode(token);

  const {
    email,
    given_name: firstName,
    family_name: lastName,
  }: { email: string; given_name: string; family_name: string } = jwtDecode(idToken);

  return { email, firstName, lastName, sub, token, roles };
}
