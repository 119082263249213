import { Banner, Button, Display, Link } from '@loomispay/vault';
import { FlexSpaceBetween } from 'features/onboardingV2/components/FlexSpaceBetween';
import SingleColumn from 'layout/SingleColumn';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { TurnoverForm } from '../../../components/TurnoverForm';
import {
  getBusinessCategory,
  getBusinessSubCategory,
  getCompanyData,
  getCountryCode,
} from '../../../store/sales/selectors';
import { useSalesFlowNavigation } from '../salesFlowRoutingService';
import { BusinessType } from './BusinessType';
import { CompanyLookup } from './CompanyLookup';
import { useDocumentTitle } from 'hooks';
import { CompanyDataManualForm } from './CompanyDataManualForm';
import { clearSalesOnboarding, updateStore } from 'features/onboardingV2/store/sales/actions';
import { CompanyOfferType } from './CompanyOfferType';
import Container from 'common/components/MainLayout/Container';
import { useConfirmation } from '../../../../../common/components/Modal';
import PartnerSelection from './PartnerSelection';
import { companyInformationAnomalyWhitelistedCompanyIDs } from '../../../../../app/whitelistedCompanyIDs';
import { salesBasePath } from 'features/onboardingV2/SalesRouter';

const CompanyInformation = () => {
  const { nextPage } = useSalesFlowNavigation();
  const [isTurnoverDataFilled, setIsTurnoverDataFilled] = useState(false);
  const [isCompanyDataFilled, setIsCompanyDataFilled] = useState(false);
  const [isPartnerFormValid, setIsPartnerFormValid] = useState(false);

  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const countryCode = useSelector(getCountryCode);
  const companyData = useSelector(getCompanyData);
  const businessCategory = useSelector(getBusinessCategory);
  const businessSubCategory = useSelector(getBusinessSubCategory);
  useDocumentTitle(t('onboarding.v2.companyInfo.title'));

  const isManualFlowCompleted = isCompanyDataFilled && businessSubCategory;

  const onSubmit = () => {
    if (companyData.isManuallyAdded && companyData.companyName && companyData.address) {
      dispatch(
        updateStore(
          {
            name: companyData.companyName,
            address: companyData.address,
          },
          0
        )
      );
    }
    navigate(nextPage());
  };

  const { getConfirmation } = useConfirmation();

  const confirmStartOver = async () => {
    const startOver = await getConfirmation({
      title: t('offerTypeSelection.startOver.header'),
      description: t('offerTypeSelection.startOver.description'),
      acceptButtonLabel: t('offerTypeSelection.startOver.button'),
      acceptButtonVariant: 'destructive-teriary',
      cancelButtonLabel: t('common.cancel'),
      cancelButtonVariant: 'secondary',
    });
    if (!startOver) {
      return;
    }

    dispatch(clearSalesOnboarding());
    navigate(salesBasePath);
  };

  const duringManualFallbackFlow = companyData.isManualFallbackPathAvailable && companyData.isManuallyAdded;
  const duringRegularFlow = !companyData.isManualFallbackPathAvailable && companyData.companyName !== undefined;
  const duringAnyFlow = duringManualFallbackFlow || duringRegularFlow;

  // temporary company specific bypass to allow for offer creation - see MP-5015
  // TODO: remove after target functionality implemented
  const incompleteDataTempFix = companyInformationAnomalyWhitelistedCompanyIDs.includes(companyData.companyNumber);

  return (
    <SingleColumn
      footer={
        <FlexSpaceBetween>
          <Button
            form="turnover-figures"
            testId={'company-info-submit-button'}
            type="submit"
            disabled={
              !isTurnoverDataFilled || !isPartnerFormValid || (companyData.isManuallyAdded && !isManualFlowCompleted)
            }
            label={t('common.continueButton')}
          />
          <Button
            onClick={confirmStartOver}
            variant="destructive-teriary"
            label={t('offerTypeSelection.startOver.button')}
            icon="arrowLeft"
            iconPosition="left"
          />
        </FlexSpaceBetween>
      }
    >
      <Container spacing="5" fullWidth>
        <Display size={'s'} noGutter>
          {t('onboarding.v2.companyInfo.title')}
        </Display>

        <Banner type="warning">
          {t('onboarding.v2.companyInfo.salesToolsWeb')}{' '}
          <Link href={`${process.env.REACT_APP_SALES_TOOLS_URL + '/new-offer'}`}>Sales Tools</Link>.
        </Banner>

        <CompanyLookup />

        {(companyData.isAllowed || incompleteDataTempFix) && (
          <>
            {duringManualFallbackFlow && <CompanyDataManualForm setIsCompanyDataFilled={setIsCompanyDataFilled} />}
            {duringAnyFlow && <PartnerSelection setIsPartnerFormValid={setIsPartnerFormValid} />}
            {duringAnyFlow && countryCode === 'ES' && <CompanyOfferType />}
            {duringAnyFlow && <BusinessType />}
            {duringAnyFlow && businessCategory && businessSubCategory && (
              <TurnoverForm onSubmit={onSubmit} setIsTurnoverDataFilled={setIsTurnoverDataFilled} />
            )}
          </>
        )}
      </Container>
    </SingleColumn>
  );
};

export default CompanyInformation;
