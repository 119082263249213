import { UseFormMethods } from 'react-hook-form/dist/types';
import { useTranslation } from 'react-i18next';
import { TextInput } from 'common/components/forms/TextInput/TextInput';
import { Heading, Label, Paragraph } from '@loomispay/vault';
import { OfferOnboardingStore, ShippingInformationForm } from 'features/common/types';
import { Box } from 'common/components/Box';
import { Grid, GridItem } from 'common/components/Grid';

type Props = {
  location: OfferOnboardingStore;
  index: number;
  form: UseFormMethods<ShippingInformationForm>;
};

const ShippingInformationV2LocationBox = ({ location: { name, address, storeProducts, ref }, index, form }: Props) => {
  const { t } = useTranslation();

  return (
    <Box testId={`location.${index}.locationBox`}>
      <div hidden={true}>
        <TextInput
          label={''}
          name={`storesShippingData[${index}].storeRef`}
          setValue={(value?: string) => form.setValue(`storesShippingData[${index}].storeRef`, value)}
          defaultValue={ref}
          control={form.control}
          errors={form.errors}
        />
      </div>
      <Grid>
        <GridItem s={4} m={8} l={12}>
          <Heading size="m">{name}</Heading>
        </GridItem>
        <GridItem s={2} m={4} l={6}>
          <div>
            <Label size="s">{t('common.address.addressLine')}</Label>
            <Paragraph size="s">
              {`${address.address}`}
              <br />
              {`${address.zipCode}`}
              <br />
              {`${address.city} `}
            </Paragraph>
          </div>
        </GridItem>
        <GridItem s={2} m={4} l={6}>
          {storeProducts && (
            <div>
              <Label size="s">{t('common.hardware')}</Label>
              <Paragraph size="s">
                {storeProducts.terminals.map(terminal => (
                  <span key={terminal.ref}>
                    {`${terminal.quantity}x ${terminal.name}`}
                    <br />
                  </span>
                ))}
                {storeProducts.addons.map(addon => (
                  <span key={addon.ref}>
                    {`${addon.quantity}x ${addon.name}`}
                    <br />
                  </span>
                ))}
              </Paragraph>
            </div>
          )}
        </GridItem>
        <GridItem s={4} m={4} l={6}>
          <TextInput
            label={t('common.shippingInformation.recipient')}
            name={`storesShippingData[${index}].recipient`}
            setValue={(value?: string) => form.setValue(`storesShippingData[${index}].recipient`, value)}
            rules={{ required: t('common.form.validationMessages.required') }}
            defaultValue={''}
            control={form.control}
            errors={form.errors}
            trimOnBlur
          />
        </GridItem>
        <GridItem s={4} m={4} l={6}>
          <TextInput
            label={t('common.shippingInformation.attention')}
            name={`storesShippingData[${index}].attention`}
            setValue={(value?: string) => form.setValue(`storesShippingData[${index}].attention`, value)}
            defaultValue={''}
            control={form.control}
            errors={form.errors}
            trimOnBlur
          />
        </GridItem>
      </Grid>
    </Box>
  );
};

export default ShippingInformationV2LocationBox;
