import { ErrorMessage } from '@hookform/error-message';
import { Paragraph, Text } from '@loomispay/vault';
import { ReactComponent as Checkmark } from 'assets/img/checkmark.svg';
import { VisuallyHidden } from 'common/components/VisuallyHidden';
import { ReactElement } from 'react';
import { Control, Controller, FieldErrors } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { CheckboxLabelWrapper, CheckboxIcon, CheckboxInput, ErrorSpacing, Label } from './Checkbox.styles';

type Props = {
  name: string;
  label: string | ReactElement;
  value?: string;
  disabled?: boolean;
  noBottomMargin?: boolean;
  children?: never;
  required?: boolean;
  defaultChecked?: boolean;
  control?: Control;
  errors?: FieldErrors;
  testId?: string;
  onChange?: (name: string) => void;
  labelVisuallyHidden?: boolean;
};

export const Checkbox = ({
  name,
  label,
  value,
  disabled,
  required,
  defaultChecked = false,
  control,
  errors,
  testId = '',
  onChange,
  labelVisuallyHidden = false,
}: Props) => {
  const { t } = useTranslation();

  return (
    <div>
      <Label disabled={disabled}>
        <Controller
          name={name}
          control={control}
          defaultValue={defaultChecked}
          rules={{
            required: required && (t('common.form.validationMessages.required') as string),
          }}
          render={props => {
            const inputProps = { ...props };

            if (typeof value === 'undefined') {
              delete inputProps.value;
            } else {
              inputProps.value = value;
            }

            return (
              <CheckboxInput
                data-testid={testId ? testId : name}
                type="checkbox"
                defaultChecked={defaultChecked}
                disabled={disabled}
                required={required}
                {...inputProps}
                // This makes the value propagate to when form is submitted
                onChange={e => {
                  inputProps.onChange(e.target.checked ? value || true : false);
                  // To fix bug with Radio buttons not triggering blur in Safari, which makes onBlur mode not working
                  // https://bugs.webkit.org/show_bug.cgi?id=13724
                  inputProps.onBlur();
                  onChange && onChange(name);
                }}
              />
            );
          }}
        />
        <>
          <CheckboxIcon>
            <Checkmark />
          </CheckboxIcon>
          {typeof label === 'string' ? (
            <CheckboxLabelWrapper labelVisuallyHidden={labelVisuallyHidden}>
              {labelVisuallyHidden ? (
                <VisuallyHidden>
                  <Text size="s" color={disabled ? 'disabled' : 'primary'}>
                    {label}
                  </Text>
                </VisuallyHidden>
              ) : (
                <Text size="s" color={disabled ? 'disabled' : 'primary'}>
                  {label}
                </Text>
              )}
            </CheckboxLabelWrapper>
          ) : (
            label
          )}
        </>
      </Label>
      <ErrorMessage
        errors={errors}
        name={name}
        render={({ message }) => (
          <ErrorSpacing>
            <Paragraph size="s" color="negative">
              {message}
            </Paragraph>
          </ErrorSpacing>
        )}
      />
    </div>
  );
};
