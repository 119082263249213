import { ReactNode } from 'react';
import { Button, Heading, Paragraph, useMedia } from '@loomispay/vault';
import styled from 'styled-components/macro';
import { Grid, GridItem } from 'common/components/Grid';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const ImageWrapper = styled.div<{ isDesktop: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: ${({ theme }) => theme.spacings['1']};
  height: ${({ isDesktop }) => (isDesktop ? '100vh' : 'inherit')};
`;

const ButtonWrapper = styled.div<{ isMobile: boolean }>`
  display: flex;
  align-items: center;
  flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')};
  gap: ${({ theme }) => theme.spacings[1]};
`;

type Props = {
  image: ReactNode;
  title: string;
  description: string;
  button1Label?: string;
  button1Action?: () => void;
  button2Label?: string;
  button2Action?: () => void;
};

export const Confirmation = ({
  image,
  title,
  description,
  button1Label,
  button1Action,
  button2Label,
  button2Action,
}: Props) => {
  const isMobile = useMedia('mobile');
  const isDesktop = useMedia('desktop');

  return (
    <Wrapper>
      <Grid>
        <GridItem s={4} m={8} l={3} lOffset={2}>
          <ImageWrapper isDesktop={isDesktop}>{image}</ImageWrapper>
        </GridItem>
        <GridItem s={4} m={6} mOffset={1} l={5} flexDirection="column" justifyContent="center">
          <Heading size="xl">{title}</Heading>
          <Paragraph size="m">{description}</Paragraph>

          <ButtonWrapper isMobile={isMobile}>
            {button1Label && (
              <Button
                testId={'confirmation-button1'}
                label={button1Label}
                variant="secondary"
                onClick={button1Action}
                fullWidth={isMobile}
              />
            )}
            {button2Label && (
              <Button
                testId={'confirmation-button2'}
                label={button2Label}
                variant="tertiary"
                onClick={button2Action}
                fullWidth={isMobile}
              />
            )}
          </ButtonWrapper>
        </GridItem>
      </Grid>
    </Wrapper>
  );
};
