import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { reactLocalStorage } from 'reactjs-localstorage';

import LanguageDetector from 'i18next-browser-languagedetector';
import { env } from 'app/config';

import en from '../locales/en/translation.json';
import da from '../locales/da/translation.json';
import sv from '../locales/sv/translation.json';
import es from '../locales/es/translation.json';
import { daCommon, enCommon, esCommon, svCommon } from '@loomispay/vault';

i18n
  // detect user language: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  .use(initReactI18next)
  // init i18next: https://www.i18next.com/overview/configuration-options
  .init({
    fallbackLng:
      env.environment !== 'production' && new URL(window.location.href).searchParams.has('showkeys') ? undefined : 'en',
    resources: {
      en: {
        translation: { ...enCommon, ...en },
      },
      da: {
        translation: { ...daCommon, ...da },
      },
      sv: {
        translation: { ...svCommon, ...sv },
      },
      es: {
        translation: { ...esCommon, ...es },
      },
    },
    keySeparator: false,
    debug: false,
    interpolation: {
      escapeValue: false,
    },
    detection: {
      caches: false,
    },
    lng:
      env.environment !== 'production' && new URL(window.location.href).searchParams.has('showkeys')
        ? 'zh'
        : (reactLocalStorage.get('userLanguage') as string),
  });

export default i18n;
