import styled from 'styled-components/macro';
import { ReactComponent as X } from 'assets/img/x.svg';
import { color, spacing } from '../constants';
import { Link } from 'react-router-dom';
import { ErrorProps } from '../constants/types';
import { Display, Paragraph } from '@loomispay/vault';

const Page = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 50%;
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 128px;
  height: 128px;
  border: 1px solid ${color.black};
  border-radius: 50%;
  margin-bottom: ${spacing.s4};
`;

const LinkWrapper = styled.div`
  margin-top: ${spacing.s4};
`;

const ErrorCode = styled(Paragraph)`
  margin-bottom: ${spacing.s2};
`;

const renderLink = (linkUrl: string, linkText: string) => {
  return linkUrl.startsWith('/') ? <Link to={linkUrl}>{linkText}</Link> : <a href={linkUrl}>{linkText}</a>;
};

const FullscreenErrorLayout = ({ title, description, errorCode, email, emailLabel, phone, phoneLabel }: ErrorProps) => {
  return (
    <Page>
      <ContentWrapper>
        <IconWrapper>
          <X />
        </IconWrapper>
        <Display size="s">{title}</Display>
        {errorCode && <ErrorCode align="center">{errorCode}</ErrorCode>}
        <Paragraph align="center">{description}</Paragraph>
        <LinkWrapper>
          {email && emailLabel && (
            <Paragraph>
              {emailLabel}: {renderLink(`mailto:${email}`, email)}
            </Paragraph>
          )}
          {phone && phoneLabel && (
            <Paragraph>
              {phoneLabel}: {renderLink(`tel:${phone}`, phone)}
            </Paragraph>
          )}
        </LinkWrapper>
      </ContentWrapper>
    </Page>
  );
};

export default FullscreenErrorLayout;
