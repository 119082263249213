import { ReactNode } from 'react';
import { DefaultNotificationBarWrapper } from './NotificationBarWrapper';
import { MessageType } from './types';

export const notifications = {
  success: (msg: ReactNode, testId?: string): void => {
    DefaultNotificationBarWrapper.show({
      severity: 'success',
      content: msg,
      closable: false,
      life: 5000,
      testId,
    });
  },

  error: (msg: ReactNode, testId?: string): void => {
    DefaultNotificationBarWrapper.show({
      severity: 'error',
      content: msg,
      closable: false,
      life: 5000,
      testId,
    });
  },

  warn: (msg: ReactNode, testId?: string): void => {
    DefaultNotificationBarWrapper.show({
      severity: 'warn',
      content: msg,
      closable: false,
      life: 5000,
      testId,
    });
  },

  show: (message: MessageType | MessageType[]): void => {
    DefaultNotificationBarWrapper.show(message);
  },

  clear() {
    DefaultNotificationBarWrapper.clear();
  },
};
