import { createAction, PrepareAction } from '@reduxjs/toolkit';
import { FileLike } from '@rpldy/shared';
import { AxiosResponse } from 'axios';
import { CountryCode } from 'constants/types';
import {
  MerchantOnboardingData,
  NotaryDetails,
  OfferConfiguration,
  OfferContactData,
  ShippingInformationV2,
  StoreBase,
} from 'features/common/types';
import {
  AdditionalQuestion,
  AdditionalQuestionAnswer,
  BankInformation,
  InvoiceInformation,
  OfferResponse,
  OnboardingDocument,
  OnboardingDocumentCategory,
  Owner,
  OwnersResponse,
  Page,
  Representatives,
  Signer,
  SigningCombination,
  SigningCombinationsResponse,
  SubmitOfferOnboardingDataResponse,
} from '../types';
const prefix = 'offerOnboardingMerchant/';

/** Merchant Flow */
export const fetchOfferConfiguration = createAction(`${prefix}FETCH_OFFER_CONFIGURATION`, () => {
  return {
    payload: {
      client: 'onboardingV2',
      request: {
        method: 'GET',
        url: `/offerConfig`,
      },
    },
  };
});

export const setCountryCode = createAction<CountryCode | null>(`${prefix}SET_COUNTRY_CODE`);

export const fetchOfferConfigurationSuccess = createAction<AxiosResponse<OfferConfiguration>>(
  `${prefix}FETCH_OFFER_CONFIGURATION_SUCCESS`
);

export const fetchOfferConfigurationFail = createAction(`${prefix}FETCH_OFFER_CONFIGURATION_FAIL`);

export const fetchOffer = createAction(`${prefix}FETCH_OFFER`, (offerRef: string) => {
  return {
    payload: {
      client: 'onboardingV2',
      request: {
        method: 'GET',
        url: `/offer/${offerRef}`,
      },
    },
  };
});

export const fetchOfferSuccess = createAction<AxiosResponse<OfferResponse>>(`${prefix}FETCH_OFFER_SUCCESS`);

export const fetchOfferFail = createAction(`${prefix}FETCH_OFFER_FAIL`);

export const fetchOfferContactData = createAction(`${prefix}FETCH_OFFER_CONTACT_DATA`, (offerRef: string) => {
  return {
    payload: {
      client: 'onboardingV2',
      request: {
        method: 'GET',
        url: `/offer/${offerRef}/contactData`,
      },
    },
  };
});

export const fetchOfferContactDataSuccess = createAction<AxiosResponse<OfferContactData>>(
  `${prefix}FETCH_OFFER_CONTACT_DATA_SUCCESS`
);

export const fetchOfferContactDataFail = createAction(`${prefix}FETCH_OFFER_CONTACT_DATA_FAIL`);

/* eslint-disable @typescript-eslint/no-explicit-any */
export const fetchMerchantOffer = createAction<PrepareAction<any>>(`${prefix}FETCH_MERCHANT_OFFER`, () => {
  return {
    payload: {
      client: 'onboardingMerchantV2',
      request: {
        method: 'GET',
        url: `/offer`,
      },
    },
  };
});

export const fetchMerchantOfferSuccess = createAction<AxiosResponse<OfferResponse>>(
  `${prefix}FETCH_MERCHANT_OFFER_SUCCESS`
);

export const fetchMerchantOfferFail = createAction(`${prefix}FETCH_MERCHANT_OFFER_FAIL`);

export const createMerchantAndAssignToOffer = createAction(
  `${prefix}CREATE_MERCHANT_AND_ASSIGN_TO_OFFER`,
  (offerRef: string) => {
    return {
      payload: {
        client: 'onboardingV2',
        request: {
          method: 'POST',
          url: `/offer/${offerRef}/assignToMerchant`,
        },
      },
    };
  }
);

export const createMerchantAndAssignToOfferSuccess = createAction<AxiosResponse>(
  `${prefix}CREATE_MERCHANT_AND_ASSIGN_TO_OFFER_SUCCESS`
);

export const createMerchantAndAssignToOfferFail = createAction(`${prefix}CREATE_MERCHANT_AND_ASSIGN_TO_OFFER_FAIL`);
export const updateRepresentatives = createAction<Representatives>(`${prefix}UPDATE_REPRESENTATIVES`);
export const updateNotaryDetails = createAction<NotaryDetails>(`${prefix}UPDATE_NOTARY_DETAILS`);
export const updateBankInformation = createAction<BankInformation>(`${prefix}UPDATE_BANK_INFORMATION`);
export const updateInvoiceInformation = createAction<InvoiceInformation>(`${prefix}UPDATE_INVOICE_INFORMATION`);
export const updateShippingInformationV2 = createAction<ShippingInformationV2>(
  `${prefix}UPDATE_SHIPPING_INFORMATION_DATA`
);
export const updateLocation = createAction(`${prefix}UPDATE_LOCATION`, (store: StoreBase, index: number) => {
  return {
    payload: {
      store: store,
      index: index,
    },
  };
});

export const fetchOwners = createAction(`${prefix}FETCH_OWNERS`, (companyNumber: string, countryCode: CountryCode) => {
  const queryParams = new URLSearchParams({
    businessRegisterNumber: companyNumber,
    country: countryCode,
    licence: 'PAYFAC',
  });

  return {
    payload: {
      client: 'companyDataProviderV2',
      request: {
        method: 'GET',
        url: `/companyData/owners?${queryParams}`,
      },
    },
  };
});

export const fetchOwnersSuccess = createAction<AxiosResponse<OwnersResponse>>(`${prefix}FETCH_OWNERS_SUCCESS`);

export const fetchOwnersFail = createAction(`${prefix}FETCH_OWNERS_FAIL`);

export const upsertOwner = createAction<Owner>(`${prefix}UPSERT_OWNER`);

export const upsertOwnerV22 = createAction(`${prefix}UPSERT_OWNER_V2_2`, (owner: Owner, index: number) => ({
  payload: {
    owner,
    index,
  },
}));

export const removeOwner = createAction(`${prefix}REMOVE_OWNER`, (index: number) => ({
  payload: {
    index,
  },
}));

export const fetchSigningCombinations = createAction(
  `${prefix}FETCH_SIGNING_COMBINATIONS`,
  (companyNumber: string, countryCode: CountryCode) => {
    const queryParams = new URLSearchParams({
      businessRegisterNumber: companyNumber,
      country: countryCode,
      licence: 'PAYFAC',
    });

    return {
      payload: {
        client: 'companyDataProviderV2',
        request: {
          method: 'GET',
          url: `/companyData/signingCombination?${queryParams}`,
        },
      },
    };
  }
);

export const fetchSigningCombinationsSuccess = createAction<AxiosResponse<SigningCombinationsResponse>>(
  `${prefix}FETCH_SIGNING_COMBINATIONS_SUCCESS`
);

export const fetchSigningCombinationsFail = createAction(`${prefix}FETCH_SIGNING_COMBINATIONS_FAIL`);

export const setSigningCombinations = createAction<SigningCombination[]>(`${prefix}SET_SIGNING_COMBINATIONS`);

export const setSigners = createAction<Signer[]>(`${prefix}SET_SIGNERS`);

export const removeSignerByFullName = createAction<string>(`${prefix}REMOVE_SIGNER_BY_FULL_NAME`);

export const addManualSigner = createAction<Signer>(`${prefix}ADD_MANUAL_SIGNERS`);

export const editManualSignerName = createAction(
  `${prefix}EDIT_MANUAL_SIGNERS`,
  (oldFullName: string, newFullName) => ({
    payload: {
      oldFullName,
      newFullName,
    },
  })
);

export const setManualSigners = createAction<Signer[]>(`${prefix}SET_MANUAL_SIGNERS`);

export const fetchEddQuestions = createAction(`${prefix}FETCH_ADDITIONAL_QUESTIONS`, () => {
  return {
    payload: {
      client: 'onboardingMerchantV2',
      request: {
        method: 'GET',
        url: '/kyc/edd/questions',
      },
    },
  };
});

export const fetchEddQuestionsFail = createAction(`${prefix}FETCH_ADDITIONAL_QUESTIONS_FAIL`);
export const fetchEddQuestionsSuccess = createAction<AxiosResponse<AdditionalQuestion[]>>(
  `${prefix}FETCH_ADDITIONAL_QUESTIONS_SUCCESS`
);

export const postEddQuestions = createAction(
  `${prefix}POST_ADDITIONAL_QUESTIONS`,
  (data: AdditionalQuestionAnswer[]) => {
    return {
      payload: {
        client: 'onboardingMerchantV2',
        request: {
          method: 'POST',
          url: '/kyc/edd/questions/answers',
          data,
        },
      },
    };
  }
);

export const postEddQuestionsFail = createAction(`${prefix}POST_ADDITIONAL_QUESTIONS_FAIL`);
export const postEddQuestionsSuccess = createAction<AxiosResponse<SubmitOfferOnboardingDataResponse>>(
  `${prefix}POST_ADDITIONAL_QUESTIONS_SUCCESS`
);

export const submitOnboardingPayOfferData = createAction(
  `${prefix}SUBMIT_PAY_OFFER_ONBOARDING_DATA`,
  (data: MerchantOnboardingData) => ({
    payload: {
      client: 'onboardingMerchantV2',
      request: {
        method: 'POST',
        data,
        url: '/pay',
      },
    },
  })
);
export const submitOnboardingPayOfferDataSuccess = createAction<AxiosResponse<SubmitOfferOnboardingDataResponse>>(
  `${prefix}SUBMIT_PAY_OFFER_ONBOARDING_DATA_SUCCESS`
);
export const submitOnboardingPayOfferDataFail = createAction(`${prefix}SUBMIT_PAY_OFFER_ONBOARDING_DATA_FAIL`);

export const submitOnboardingCashOfferData = createAction(
  `${prefix}SUBMIT_CASH_OFFER_ONBOARDING_DATA`,
  (data: MerchantOnboardingData) => ({
    payload: {
      client: 'onboardingMerchantV2',
      request: {
        method: 'POST',
        data,
        url: '/cash',
      },
    },
  })
);
export const submitOnboardingCashOfferDataSuccess = createAction<AxiosResponse<SubmitOfferOnboardingDataResponse>>(
  `${prefix}SUBMIT_CASH_OFFER_ONBOARDING_DATA_SUCCESS`
);
export const submitOnboardingCashOfferDataFail = createAction(`${prefix}SUBMIT_CASH_OFFER_ONBOARDING_DATA_FAIL`);

export const submitOnboardingPayAndCashOfferData = createAction(
  `${prefix}SUBMIT_PAY_AND_CASH_OFFER_ONBOARDING_DATA`,
  (data: MerchantOnboardingData) => ({
    payload: {
      client: 'onboardingMerchantV2',
      request: {
        method: 'POST',
        data,
        url: '/pay-and-cash',
      },
    },
  })
);
export const submitOnboardingPayAndCashOfferDataSuccess = createAction<
  AxiosResponse<SubmitOfferOnboardingDataResponse>
>(`${prefix}SUBMIT_PAY_AND_CASH_OFFER_ONBOARDING_DATA_SUCCESS`);
export const submitOnboardingPayAndCashOfferDataFail = createAction(
  `${prefix}SUBMIT_PAY_AND_CASH_OFFER_ONBOARDING_DATA_FAIL`
);

export const setOfferRef = createAction<string>(`${prefix}SET_OFFER_REF`);

export const clearOfferOnboardingData = createAction(`${prefix}CLEAR_OFFER_ONBOARDING_DATA`);

export const clearOnboardingState = createAction(`${prefix}CLEAR_ONBOARDING_STATE`);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getOfferOnboardingData = createAction<PrepareAction<any>>(`${prefix}GET_OFFER_ONBOARDING_DATA`, () => {
  return {
    payload: {
      client: 'onboardingMerchantV2',
      request: {
        method: 'GET',
      },
    },
  };
});

export const getOfferOnboardingDataSuccess = createAction<AxiosResponse<MerchantOnboardingData>>(
  `${prefix}GET_OFFER_ONBOARDING_DATA_SUCCESS`
);

export const getOfferOnboardingDataFail = createAction(`${prefix}GET_OFFER_ONBOARDING_DATA_FAIL`);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const savePartial = createAction<PrepareAction<any>>(`${prefix}SAVE_PARTIAL`, (data: MerchantOnboardingData) => {
  return {
    payload: {
      client: 'onboardingMerchantV2',
      request: {
        method: 'PUT',
        url: `/partial`,
        data,
      },
    },
  };
});

export const savePartialSuccess = createAction<AxiosResponse<MerchantOnboardingData>>(`${prefix}SAVE_PARTIAL_SUCCESS`);

export const savePartialFail = createAction(`${prefix}SAVE_PARTIAL_FAIL`);

export const setPage = createAction<Page>(`${prefix}SET_PAGE`);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const postDocument = createAction<PrepareAction<any>>(
  `${prefix}POST_DOCUMENT`,
  (file: FileLike, representativeRef: string) => {
    const formData = new FormData();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    formData.append('file', file as any);
    return {
      payload: {
        client: 'onboardingMerchantV2',
        representativeRef,
        request: {
          method: 'POST',
          url: `/representative/${representativeRef}/idDocument`,
          data: formData,
          headers: {
            'content-type': 'multipart/form-data',
          },
        },
      },
    };
  }
);

export const postDocumentSuccess = createAction<AxiosResponse<OnboardingDocument | null>>(
  `${prefix}POST_DOCUMENT_SUCCESS`
);
export const postDocumentFail = createAction(`${prefix}POST_DOCUMENT_FAIL`);

export const deleteDocument = createAction(
  `${prefix}DELETE_DOCUMENT`,
  (representativeRef: string, documentRef: string) => {
    return {
      payload: {
        client: 'onboardingMerchantV2',
        representativeRef,
        request: {
          method: 'DELETE',
          url: `/representative/${representativeRef}/idDocument/${documentRef}`,
        },
      },
    };
  }
);

export const deleteDocumentSuccess = createAction<AxiosResponse>(`${prefix}DELETE_DOCUMENT_SUCCESS`);
export const deleteDocumentFail = createAction(`${prefix}DELETE_DOCUMENT_FAIL`);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const postGlobalDocument = createAction<PrepareAction<any>>(
  `${prefix}POST_GLOBAL_DOCUMENT`,
  (file: FileLike, category: OnboardingDocumentCategory) => {
    const formData = new FormData();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    formData.append('file', file as any);
    formData.append('category', category);
    return {
      payload: {
        client: 'onboardingMerchantV2',
        request: {
          method: 'POST',
          url: `/document`,
          data: formData,
          headers: {
            'content-type': 'multipart/form-data',
          },
        },
      },
    };
  }
);

export const postGlobalDocumentSuccess = createAction<AxiosResponse<OnboardingDocument | null>>(
  `${prefix}POST_GLOBAL_DOCUMENT_SUCCESS`
);
export const postGlobalDocumentFail = createAction(`${prefix}POST_GLOBAL_DOCUMENT_FAIL`);

export const deleteGlobalDocument = createAction(`${prefix}DELETE_GLOBAL_DOCUMENT`, (documentRef: string) => {
  return {
    payload: {
      client: 'onboardingMerchantV2',
      documentRef,
      request: {
        method: 'DELETE',
        url: `/document/${documentRef}`,
      },
    },
  };
});

export const deleteGlobalDocumentSuccess = createAction<AxiosResponse>(`${prefix}DELETE_GLOBAL_DOCUMENT_SUCCESS`);
export const deleteGlobalDocumentFail = createAction(`${prefix}DELETE_GLOBAL_DOCUMENT_FAIL`);
