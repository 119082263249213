import { spacings } from '@loomispay/vault';
import { ReactNode } from 'react';
import styled from 'styled-components/macro';

const FooterContainer = styled.footer<{ isMobile?: boolean; isHigh?: boolean }>`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  justify-content: flex-end;
  height: ${({ isHigh }) => (isHigh ? '215px' : '80px')};
  border-top: 1px solid ${({ theme }) => theme.color.border.disabledBorder};
  background-color: ${({ theme }) => theme.color.background.primaryBg};
`;

const FooterContent = styled.div<{ isMobile?: boolean; isV22?: boolean }>`
  height: 100%;
  max-width: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 0 ${({ isMobile, isV22 }) => (isMobile && isV22 ? 0 : spacings['4'])};
`;

type Props = {
  footer?: ReactNode;
  isMobile?: boolean;
  isV22?: boolean;
  isHigh?: boolean;
};

export const Wrapper = styled.div`
  position: relative;
  padding-bottom: 80px;
`;

export const Footer = ({ footer, isMobile = false, isV22 = false, isHigh = false }: Props) => {
  return (
    <div>
      <FooterContainer isMobile={isMobile} isHigh={isHigh}>
        <FooterContent isMobile={isMobile} isV22={isV22}>
          {footer}
        </FooterContent>
      </FooterContainer>
    </div>
  );
};
