import { BoxWrapper } from 'common/components/Box';
import styled from 'styled-components/macro';

export const OfferSummarySectionBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings[1]};
  border: 1px solid ${({ theme }) => theme.color.border.disabledBorder};
  border-radius: 8px;
  padding: ${({ theme }) => theme.spacings[2]};

  span:first-letter {
    text-transform: capitalize;
  }
`;

export const OfferSummaryRow = styled.div`
  display: flex;
  justify-content: space-between;

  & > *:last-child {
    text-align: right;
  }
`;

export const OfferSummaryHr = styled.hr`
  border-top: 1px solid ${({ theme }) => theme.color.border.disabledBorder};
  margin: ${({ theme }) => `${theme.spacings[2]} -${theme.spacings[2]}`};
`;

export const OfferBorderedBoxRow = styled.div`
  display: flex;
  align-items: flex-start;

  & > * {
    flex-grow: 2;
    flex-basis: 0;
  }

  & > *:first-child {
    flex-grow: 2;
    margin-right: ${({ theme }) => theme.spacings['2']};
  }

  & > *:last-child {
    text-align: end;
  }
`;
export const BorderedBoxSummary = styled.div`
  border-top: 1px solid ${({ theme }) => theme.color.border.disabledBorder};
  padding: ${({ theme }) => theme.spacings['2']} ${({ theme }) => theme.spacings['2']} 0
    ${({ theme }) => theme.spacings['2']};
  margin: ${({ theme }) => theme.spacings['2']} -${({ theme }) => theme.spacings['1']};
`;

export const OfferMonthlyCost = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  &::first-letter {
    text-transform: uppercase;
  }
`;

export const OfferStyledBoxWrapper = styled.div`
  padding-top: ${({ theme }) => theme.spacings[3]};
`;

export const OfferStyledBox = styled(BoxWrapper)`
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: ${({ theme }) => theme.spacings[1]};
`;

export const ContractStartDateContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
