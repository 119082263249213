import { Heading } from '@loomispay/vault';
import { Dropdown } from 'common/components/forms/Dropdown/Dropdown';
import { TextInput } from 'common/components/forms/TextInput/TextInput';
import { VisuallyHidden } from 'common/components/VisuallyHidden';
import { DropDownOption } from 'constants/types';
import { getStores } from 'features/onboardingV2/store/sales/selectors';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { CashServicesGridWrapper } from '../CashServices.styles';
import { cashContractMonthlyFeeTypes } from '../fixtures';
import { CashContractMonthlyFeeType, CashServicesSectionTypes } from '../types';
import useCashServices from '../useCashServices';
import { maxValue } from 'utils/format';

export default function CashServicesFees({
  storeIndex,
  control,
  errors,
  monthlyFeeType = cashContractMonthlyFeeTypes[0],
}: CashServicesSectionTypes & { monthlyFeeType?: CashContractMonthlyFeeType }) {
  const { t } = useTranslation();
  const { cashContractPaymentMethodOptions } = useCashServices();
  const locations = useSelector(getStores);

  const cashDataFees = locations[storeIndex].cashData?.es?.fees;

  const monthlyFeeTypeOptions: DropDownOption<CashContractMonthlyFeeType>[] = cashContractMonthlyFeeTypes.map(type => {
    return {
      value: type,
      label: t(`cashServices.form.monthlyFeeType.${type}`),
    };
  });

  return (
    <section>
      <Heading size="s">{t('cashServices.fees')}</Heading>
      <CashServicesGridWrapper>
        <Dropdown
          name={`store-${storeIndex}.fees.monthlyFeeType`}
          label={t('cashServices.form.monthlyFeeType')}
          control={control}
          errors={errors}
          options={monthlyFeeTypeOptions}
          defaultValue={
            monthlyFeeTypeOptions.find(option => option.value === cashDataFees?.monthlyFeeType?.toString()) ||
            monthlyFeeTypeOptions[0]
          }
        />
        <VisuallyHidden isHidden={monthlyFeeType !== 'percentage'}>
          <TextInput
            label={t('cashServices.form.finalMonthlyFee')}
            name={`store-${storeIndex}.fees.percentageMonthlyFee`}
            defaultValue={cashDataFees?.percentageMonthlyFee?.toString() || ''}
            control={control}
            errors={errors}
            type="number"
            suffix="%"
            inputProps={{ step: 0.01, min: 0, max: 100 }}
            rules={
              monthlyFeeType === 'percentage'
                ? {
                    required: t('common.form.validationMessages.required'),
                  }
                : undefined
            }
          />
        </VisuallyHidden>
        <VisuallyHidden isHidden={monthlyFeeType !== 'fixed'}>
          <TextInput
            label={t('cashServices.form.finalMonthlyFee')}
            name={`store-${storeIndex}.fees.fixedMonthlyFee`}
            defaultValue={cashDataFees?.fixedMonthlyFee?.toString() || ''}
            control={control}
            errors={errors}
            type="number"
            currency="EUR"
            rules={
              monthlyFeeType === 'fixed'
                ? {
                    required: t('common.form.validationMessages.required'),
                  }
                : undefined
            }
            inputProps={{
              min: 0,
              max: maxValue(7, 2),
              step: 1,
            }}
          />
        </VisuallyHidden>
        <VisuallyHidden isHidden={monthlyFeeType !== 'percentage'}>
          <TextInput
            label={t('cashServices.form.minimumMonthlyFee')}
            name={`store-${storeIndex}.fees.minimumMonthlyFee`}
            defaultValue={cashDataFees?.minimumMonthlyFee?.toString() || ''}
            control={control}
            errors={errors}
            type="number"
            currency="EUR"
            inputProps={{
              min: 0,
              max: maxValue(7, 2),
              step: 1,
            }}
            rules={
              monthlyFeeType === 'percentage'
                ? {
                    required: t('common.form.validationMessages.required'),
                  }
                : undefined
            }
          />
        </VisuallyHidden>
        <TextInput
          label={t('cashServices.form.upfrontPayment')}
          name={`store-${storeIndex}.fees.upfrontPayment`}
          defaultValue={cashDataFees?.upfrontPayment?.toString() || ''}
          control={control}
          errors={errors}
          type="number"
          currency="EUR"
          inputProps={{
            min: 0,
            max: maxValue(7, 2),
            step: 1,
          }}
        />
        <TextInput
          label={t('cashServices.form.finalPayment')}
          name={`store-${storeIndex}.fees.finalPayment`}
          defaultValue={cashDataFees?.finalPayment?.toString() || ''}
          control={control}
          errors={errors}
          type="number"
          currency="EUR"
          inputProps={{
            min: 0,
            max: maxValue(7, 2),
            step: 1,
          }}
        />
        <Dropdown
          name={`store-${storeIndex}.fees.paymentMethodChosen`}
          label={t('cashServices.form.paymentMethodChosen')}
          control={control}
          errors={errors}
          options={cashContractPaymentMethodOptions}
          defaultValue={
            cashContractPaymentMethodOptions.find(
              method => method.value === cashDataFees?.paymentMethodChosen?.toString()
            ) || ''
          }
          rules={{ required: t('common.form.validationMessages.required') }}
        />
      </CashServicesGridWrapper>
    </section>
  );
}
