import { CountryCode, CurrencyCode } from '../constants/types';

type CountryCurrencyMap = Record<CountryCode, CurrencyCode>;

export const currencyForCountry: CountryCurrencyMap = {
  SE: 'SEK',
  DK: 'DKK',
  ES: 'EUR',
  NO: 'NOK',
};

export const defaultCurrency: CurrencyCode = currencyForCountry['SE'];

type CountryFlagMap = Record<CountryCode, string>;

export const flagForCountry: CountryFlagMap = {
  SE: '🇸🇪',
  DK: '🇩🇰',
  ES: '🇪🇸',
  NO: '🇳🇴',
};
