import { Fragment, useEffect } from 'react';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { Button, Heading, Paragraph, useMedia } from '@loomispay/vault';
import { useForm } from 'react-hook-form';
import { CircledNumber } from 'features/onboardingV2/components/CircledNumber';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import { TextInput } from 'common/components/forms/TextInput/TextInput';
import { notifications } from 'common/components/Notification/notifications';
import { AdditionalQuestionAnswer } from '../../store/types';
import { SimpleGridLayout, SimpleGridItem, CallToActionGridItem } from 'common/components/GridLayout';
import { useDocumentTitle } from 'hooks';
import { onboardingRoutes } from '../../OnboardingRouter';
import { Grid } from 'common/components/Grid';
import { convertTextAreaInput } from '../../../../utils/convertTextAreaInput';
import { BaseQueryParams } from '../../data/types';
import { useAdditionalQuestions } from '../../data/queries';
import { useSendAdditionalQuestionsAnswersMutation } from '../../data/mutations';

interface QuestionWrapperProps {
  isMobile: boolean;
}
const QuestionWrapper = styled.div<QuestionWrapperProps>`
  display: flex;
  flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')};
  margin-bottom: ${({ theme }) => theme.spacings[4]};
`;

const TextWrapper = styled.div`
  flex: 1;
  padding-left: ${({ theme }) => theme.spacings['1']};
`;

type QuestionsForm = Record<string, string>;

export const AdditionalQuestions = () => {
  const { countryCode, offerRef } = useParams<BaseQueryParams>();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isMobile = useMedia('mobile');
  useDocumentTitle(t('onboarding.v2.additional.questions.title'));

  const { data: additionalQuestions } = useAdditionalQuestions({ countryCode, offerRef });
  const {
    mutate: sendAnswers,
    isSuccess: isSuccessSendAnswers,
    isError: isErrorSendAnswers,
  } = useSendAdditionalQuestionsAnswersMutation();

  useEffect(() => {
    if (isSuccessSendAnswers) navigate(generatePath(onboardingRoutes.done.path, { countryCode, offerRef }));
  }, [isSuccessSendAnswers, countryCode, offerRef, navigate]);

  useEffect(() => {
    if (isErrorSendAnswers) notifications.error(t('common.serverError'));
  }, [isErrorSendAnswers, t]);
  const questionsForm = useForm({
    mode: 'onChange',
  });

  const onSubmit = (data: QuestionsForm) => {
    const result: AdditionalQuestionAnswer[] = Object.entries(data).map(item => ({
      questionRef: item[0],
      answer: convertTextAreaInput(item[1]),
    }));

    sendAnswers({ countryCode, merchantRef: offerRef, data: result });
  };

  if (!additionalQuestions) return null;

  // sort questions by order
  const questionsData = [...additionalQuestions].sort((a, b) => a.order - b.order);

  return (
    <div>
      <SimpleGridLayout>
        <SimpleGridItem>
          <Heading size="xl">{t('onboarding.v2.additional.questions.title')}</Heading>
          <Paragraph color={'tertiary'}>{t('onboarding.v2.additional.questions.description')}</Paragraph>
        </SimpleGridItem>
      </SimpleGridLayout>

      <form onSubmit={questionsForm.handleSubmit(onSubmit)}>
        <Grid>
          {questionsData.map(question => (
            <Fragment key={question.ref}>
              <SimpleGridItem>
                <QuestionWrapper isMobile={isMobile}>
                  <CircledNumber value={question.order + 1} color="primary" />
                  <TextWrapper>
                    <Paragraph noGutter>
                      {t(`onboarding.v2.additional.questions.${question.translationKey}` as const)}
                    </Paragraph>
                    <Paragraph size="s" color="tertiary">
                      {t(`onboarding.v2.additional.questions.${question.translationKey}-description` as const)}
                    </Paragraph>
                    <TextInput
                      label={t('onboarding.v2.additional.questions.inputLabel')}
                      textArea
                      name={question.ref}
                      defaultValue=""
                      rules={{ required: t('common.form.validationMessages.required') }}
                      control={questionsForm.control}
                      errors={questionsForm.errors}
                    />
                  </TextWrapper>
                </QuestionWrapper>
              </SimpleGridItem>
            </Fragment>
          ))}
          <CallToActionGridItem isMobile={isMobile} centered>
            <Button
              variant="secondary"
              label={t('onboarding.v2.additional.questions.submitButton')}
              type="submit"
              disabled={!questionsForm.formState.isValid}
              fullWidth={isMobile}
            />
          </CallToActionGridItem>
        </Grid>
      </form>
    </div>
  );
};
