const BASE_SQUARE = 8;

function multi(times) {
  return `${BASE_SQUARE * times}px`;
}

export const spacing = {
  exception12: '12px',
  s0: multi(0.5),
  s1: multi(1),
  s2: multi(2),
  s3: multi(3),
  s4: multi(4),
  s5: multi(5),
  s6: multi(6),
  s7: multi(7),
  s8: multi(8),
  s10: multi(10),
  s12: multi(12),
  s16: multi(16),
  s28: multi(28),
  s32: multi(32),
  s36: multi(36),
  s64: multi(64),
};
