import { Display, Paragraph, useMedia } from '@loomispay/vault';
import { SimpleGridItem, SimpleGridLayout } from 'common/components/GridLayout';
import { Footer } from 'features/onboardingV2/components/OnboardingFooter';
import { useDocumentTitle } from 'hooks';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { LocationBox } from 'features/common/components/Locations/LocationBox';
import { onboardingRoutes } from '../../../OnboardingRouter';
import { Box } from 'common/components/Box';
import { Grid } from 'common/components/Grid';
import styled from 'styled-components/macro';
import { useOnboardingData } from '../../../data/queries';
import { generatePath, useNavigate, useParams } from 'react-router-dom';

const LocationsMerchant = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isMobile = useMedia('mobile');
  useDocumentTitle(t('onboarding.v2.locations.merchant.title'));

  const { countryCode, offerRef } = useParams();

  const prevPage = () => generatePath(onboardingRoutes.welcomeScreen.path, { countryCode, offerRef });
  const nextPage = () => navigate(generatePath(onboardingRoutes.ownersV2.path, { countryCode, offerRef }));

  const { data: onboardingData, isLoading: isLoadingOnboardingData } = useOnboardingData({ countryCode, offerRef });

  if (isLoadingOnboardingData || !onboardingData) return null;

  return (
    <>
      <SimpleGridLayout>
        <SimpleGridItem>
          <Display size="s">{t('onboarding.v2.locations.merchant.title')}</Display>
          <Paragraph size="m" color={'tertiary'}>
            {t('onboarding.v2.locations.merchant.description')}
          </Paragraph>
        </SimpleGridItem>
      </SimpleGridLayout>

      <LocationsWrapper>
        {onboardingData.stores.map((location, i) => {
          return (
            <Fragment key={location.name}>
              <Grid>
                <SimpleGridItem>
                  <Box testId={`location.${i}.locationBox`}>
                    <LocationBox
                      location={location}
                      items={location.storeProducts}
                      index={i}
                      editUrl={generatePath(onboardingRoutes.editLocationMerchant.path, {
                        countryCode,
                        offerRef,
                        locationId: i,
                      })}
                      editButtonPosition={'BottomLeft'}
                      breakAddressLine={true}
                    />
                  </Box>
                </SimpleGridItem>
              </Grid>
            </Fragment>
          );
        })}
        <Footer prevPage={prevPage} nextPage={nextPage} isMobile={isMobile} />
      </LocationsWrapper>
    </>
  );
};

const LocationsWrapper = styled.form`
  display: grid;
  gap: ${({ theme }) => theme.spacings['4']};
`;

export default LocationsMerchant;
