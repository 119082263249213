import styled from 'styled-components/macro';
import { spacing } from 'constants/spacings';

export const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const Wrapper = styled.div`
  position: relative;
`;

export const Spacer = styled.div`
  margin-top: ${spacing.s4};
`;

export const Header = styled.div<{ isDesktop: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding: ${spacing.s3} ${({ isDesktop }) => (isDesktop ? spacing.s4 : spacing.s2)} 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ButtonWrapper = styled.div<{ isMobile: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: ${({ isMobile }) => (isMobile ? 'column-reverse' : 'row')};
`;

export const ImageWrapper = styled.div`
  background-color: ${({ theme }) => theme.color.base.grey.black};
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: ${({ theme }) => -theme.spacings[1]};
`;

export const ContentWrapper = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-left: ${({ theme }) => theme.spacings[1]};
  margin-right: ${({ theme }) => theme.spacings[1]};
`;

export const SignUpMobileWrapper = styled.div`
  position: absolute;
  bottom: ${({ theme }) => theme.spacings[3]};
`;
