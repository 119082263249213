import { AnyAction, createReducer } from '@reduxjs/toolkit';
import { OfferConfiguration } from 'features/common/types';
import { CountryCode } from '../../../../constants/types';
import { Offer, SalesOfferState } from '../types';
import {
  addStore,
  clearCompanyInformation,
  clearSalesOnboarding,
  clearStores,
  fetchCompanyInformation,
  fetchCompanyInformationFail,
  fetchCompanyInformationSuccess,
  fetchOfferConfiguration,
  fetchOfferConfigurationFail,
  fetchOfferConfigurationSuccess,
  fetchCashOfferConfiguration,
  fetchCashOfferConfigurationFail,
  fetchCashOfferConfigurationSuccess,
  postPayOffer,
  postPayOfferFail,
  postPayOfferSuccess,
  postPayAndCashOffer,
  postPayAndCashOfferFail,
  postPayAndCashOfferSuccess,
  removeAddon,
  removeStore,
  removeTerminal,
  setContractStartDate,
  setContractTerms,
  setContractTransactionFees,
  setCountryCode,
  updateBusinessCategory,
  updateBusinessSubCategory,
  updateCommercialRepresentative,
  updateCompanyInfoManually,
  updateCompanyDataIsManuallyAdded,
  updateContactPersons,
  updateMultipleStores,
  updateSignatureProvider,
  updateStore,
  updateTurnoverInformation,
  upsertAddon,
  upsertAvailableCountry,
  upsertTerminal,
  updateOfferType,
  updateSalesSupervisedFlow,
  updateOfferRef,
  updateCompanyDataIsManualFallbackPathAvailable,
  updatePartnerConfiguration,
  updateCommentForLogistics,
} from './actions';

export const emptyOffer: Offer = {
  companyData: {
    address: {},
  },
  turnoverData: {},
  stores: [],
  contractTerms: {
    durationInMonths: 0,
    terminalsFreeMonths: 0,
  },
  signatureProvider: 'PENNEO',
  commentForLogistics: undefined,
};

export const emptyOfferConfiguration: OfferConfiguration = {
  contractDurationOptions: [],
  subscriptionMonthsNumber: 0,
  transactionFeeOptions: [],
  transactionFeeConfig: {
    countryCode: '',
    defaultFee: {
      ref: '',
      transactionFeeRange: {
        minPercentage: 0,
        maxPercentage: 0,
        defaultPercentage: 0,
      },
    },
    rules: [],
  },
  terminalOptions: [],
  addonOptions: [],
  signatureProviderConfig: {
    defaultProvider: undefined,
    providers: [],
  },
};

export const initialState: SalesOfferState = {
  offer: emptyOffer,
  offerConfiguration: emptyOfferConfiguration,
  availableCountries: [],
  availableFlows: [],
  isLoading: false,
  error: null,
};

export const onboardingV2SalesReducer = createReducer(initialState, builder =>
  builder
    .addCase(clearSalesOnboarding, () => {
      return initialState;
    })
    .addCase(setCountryCode, (state, action) => {
      state.offer.countryCode = action.payload;
      return state;
    })
    .addCase(fetchCompanyInformation, (state, action) => {
      state.error = null;
      state.isLoading = true;

      state.offer.companyData = {
        ...emptyOffer.companyData,
        companyNumber: action.payload.companyNumber,
      };
      return state;
    })
    .addCase(fetchCompanyInformationSuccess, (state, action) => {
      state.isLoading = false;
      const { company, onboardingAllowed: isAllowed } = action.payload.data;
      state.offer.companyData = {
        ...(company || { companyNumber: state.offer.companyData.companyNumber }),
        isAllowed,
      };
      state.availableFlows = action.payload.data.flows;
      return state;
    })
    .addCase(fetchCompanyInformationFail, (state, action: AnyAction) => {
      state.isLoading = false;
      state.error = action?.error?.response;
      return state;
    })
    .addCase(fetchOfferConfiguration, state => {
      state.isLoading = true;
      state.error = null;
      return state;
    })
    .addCase(fetchOfferConfigurationSuccess, (state, action) => {
      state.isLoading = false;
      state.error = null;
      state.offerConfiguration = action.payload.data;
      return state;
    })
    .addCase(fetchOfferConfigurationFail, (state, action: AnyAction) => {
      state.isLoading = false;
      state.error = action?.error?.response;
    })
    .addCase(fetchCashOfferConfiguration, state => {
      state.isLoading = true;
      state.error = null;
      return state;
    })
    .addCase(fetchCashOfferConfigurationSuccess, (state, action) => {
      state.isLoading = false;
      state.error = null;
      state.cashOfferConfiguration = action.payload.data;
      return state;
    })
    .addCase(fetchCashOfferConfigurationFail, (state, action: AnyAction) => {
      state.isLoading = false;
      state.error = action?.error?.response;
    })
    .addCase(clearCompanyInformation, state => {
      state.offer.companyData = { ...emptyOffer.companyData };
      return state;
    })
    .addCase(updateCompanyDataIsManuallyAdded, (state, action) => {
      state.offer.companyData = {
        ...state.offer.companyData,
        isManuallyAdded: action.payload,
      };
      return state;
    })
    .addCase(updatePartnerConfiguration, (state, action) => {
      state.offer.partnerData = action.payload.partnerId ? { partnerId: action.payload.partnerId } : undefined;
      return state;
    })
    .addCase(updateCompanyDataIsManualFallbackPathAvailable, (state, action) => {
      state.offer.companyData = {
        ...state.offer.companyData,
        isManualFallbackPathAvailable: action.payload,
      };
      return state;
    })
    .addCase(updateCompanyInfoManually, (state, action) => {
      const companyData = action.payload;
      state.offer.companyData = {
        ...state.offer.companyData,
        companyName: companyData.companyName,
        address: companyData.address,
        registrationDate: companyData.registrationDate,
        companyForm: companyData.companyForm,
      };
      return state;
    })
    .addCase(updateContactPersons, (state, action) => {
      state.offer.contactPerson = action.payload.contactPerson;
      state.offer.loomisContactPerson = action.payload.loomisContactPerson;
      return state;
    })
    .addCase(updateCommercialRepresentative, (state, action) => {
      state.offer.loomisCommercialRepresentative = action.payload;
      return state;
    })
    .addCase(updateSalesSupervisedFlow, (state, action) => {
      state.offer.salesSupervisedFlow = action.payload;
      return state;
    })
    .addCase(updateCommentForLogistics, (state, action) => {
      state.offer.commentForLogistics = action.payload;
      return state;
    })
    .addCase(upsertTerminal, (state, action) => {
      const { locationIdx } = action.payload;
      const storeOfferItems = state.offer.stores[locationIdx].storeOfferItems;
      if (storeOfferItems) {
        const updatedTerminals = storeOfferItems.terminals.filter(t => t.ref !== action.payload.offerItem.ref);
        updatedTerminals.push(action.payload.offerItem);

        state.offer.stores[locationIdx].storeOfferItems = {
          ...storeOfferItems,
          terminals: updatedTerminals,
        };
      }
      return state;
    })
    .addCase(removeTerminal, (state, action) => {
      const { locationIdx } = action.payload;
      const storeOfferItems = state.offer.stores[locationIdx].storeOfferItems;
      if (storeOfferItems) {
        const updatedTerminals = storeOfferItems.terminals.filter(t => t.ref !== action.payload.terminalRef);

        state.offer.stores[locationIdx].storeOfferItems = {
          ...storeOfferItems,
          terminals: updatedTerminals,
        };
      }

      return state;
    })
    .addCase(upsertAddon, (state, action) => {
      const { locationIdx } = action.payload;
      const storeOfferItems = state.offer.stores[locationIdx].storeOfferItems;
      if (storeOfferItems) {
        const updatedAddons = storeOfferItems.addons.filter(t => t.ref !== action.payload.offerItem.ref);
        updatedAddons.push(action.payload.offerItem);

        state.offer.stores[locationIdx].storeOfferItems = {
          ...storeOfferItems,
          addons: updatedAddons,
        };
      }

      return state;
    })
    .addCase(removeAddon, (state, action) => {
      const { locationIdx } = action.payload;
      const storeOfferItems = state.offer.stores[locationIdx].storeOfferItems;
      if (storeOfferItems) {
        const updatedAddons = storeOfferItems.addons.filter(t => t.ref !== action.payload.addonRef);

        state.offer.stores[locationIdx].storeOfferItems = {
          ...storeOfferItems,
          addons: updatedAddons,
        };
      }
      return state;
    })
    .addCase(postPayOffer, state => ({
      ...state,
      isLoading: true,
      error: null,
    }))
    .addCase(postPayOfferSuccess, state => ({
      ...state,
      isLoading: false,
      error: null,
    }))
    .addCase(postPayOfferFail, (state, action: AnyAction) => ({
      ...state,
      isLoading: false,
      error: action.error.response,
    }))
    .addCase(postPayAndCashOffer, state => ({
      ...state,
      isLoading: true,
      error: null,
    }))
    .addCase(postPayAndCashOfferSuccess, state => ({
      ...state,
      isLoading: false,
      error: null,
    }))
    .addCase(postPayAndCashOfferFail, (state, action: AnyAction) => ({
      ...state,
      isLoading: false,
      error: action.error.response,
    }))
    .addCase(setContractTerms, (state, action) => {
      state.offer.contractTerms.durationInMonths = action.payload.durationInMonths;
      state.offer.contractTerms.terminalsFreeMonths = action.payload.terminalsFreeMonths;
      if (
        state.offer.contractTerms.durationInMonths &&
        state.offer.contractTerms.terminalsFreeMonths &&
        state.offer.contractTerms.durationInMonths < state.offer.contractTerms.terminalsFreeMonths
      ) {
        state.offer.contractTerms.terminalsFreeMonths = state.offer.contractTerms.durationInMonths;
      }

      // legacy
      state.offer.subscriptionMonthsNumber = action.payload.durationInMonths;
      if (action.payload.durationInMonths) {
        state.offer.subscriptionMonthsNumber = action.payload.durationInMonths;
      }
      return state;
    })
    .addCase(setContractStartDate, (state, action) => {
      if (state.offer.contractTerms) {
        state.offer.contractTerms.startDate = action.payload;
      }
      return state;
    })
    .addCase(setContractTransactionFees, (state, action) => {
      if (state.offer.contractTerms) {
        state.offer.contractTerms.transactionFees = action.payload;
        // TODO: remove after change in backend
        state.offer.contractTerms.transactionFee = action.payload.defaultFee?.percentage;
      }
      return state;
    })
    .addCase(updateOfferType, (state, action) => {
      state.offer.offerType = action.payload;
      return state;
    })
    .addCase(updateOfferRef, (state, action) => {
      state.offer.ref = action.payload;
      return state;
    })
    .addCase(updateBusinessCategory, (state, action) => {
      state.offer.businessCategory = action.payload;
      return state;
    })
    .addCase(updateBusinessSubCategory, (state, action) => {
      state.offer.businessSubCategory = action.payload;
      return state;
    })
    .addCase(updateTurnoverInformation, (state, action) => {
      state.offer.turnoverData = action.payload;
      return state;
    })
    .addCase(addStore, (state, action) => {
      state.offer.stores.push({
        ...action.payload,
        storeOfferItems: {
          terminals: [],
          addons: [],
        },
      });
      return state;
    })
    .addCase(updateStore, (state, action) => {
      const updatedStores = state.offer.stores.map((s, i) => {
        if (i !== action.payload.index) {
          return s;
        } else {
          return { ...action.payload.store, storeOfferItems: s.storeOfferItems };
        }
      });
      state.offer.stores = updatedStores;
      return state;
    })
    .addCase(removeStore, (state, action) => {
      state.offer.stores = state.offer.stores.filter((s, i) => i !== action.payload);
      return state;
    })
    .addCase(clearStores, state => {
      state.offer.stores = [];
      return state;
    })
    .addCase(updateMultipleStores, (state, action) => {
      state.offer.stores = action.payload;
      return state;
    })
    .addCase(updateSignatureProvider, (state, action) => {
      state.offer.signatureProvider = action.payload;
      return state;
    })
    .addCase(upsertAvailableCountry, (state, action) => {
      const availableCountries = state.availableCountries.filter((country: CountryCode) => country !== action.payload);
      availableCountries.push(action.payload);
      state.availableCountries = availableCountries;
      return state;
    })
);
