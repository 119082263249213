import { SpacingType } from 'common/types';
import styled from 'styled-components/macro';

const Form = styled.form<{ spacing?: SpacingType; cols?: number; colWidth?: string }>`
  display: ${({ cols }) => (cols ? 'grid' : 'flex')};
  grid-template-columns: ${({ cols, colWidth }) => `repeat(${cols}, ${colWidth || '1fr'})`};
  flex-direction: column;
  gap: ${({ spacing }) => `${Number(spacing || 0) * 8}px`};
  width: 100%;
`;

export default Form;
