import Container from 'common/components/MainLayout/Container';
import { Dropdown } from 'common/components/forms/Dropdown/Dropdown';
import Form from 'common/components/forms/Form';
import { DropDownOption } from 'constants/types';
import { PartnerConfiguration } from 'features/common/types';
import { getOfferConfiguration, getPartnerId } from 'features/onboardingV2/store/sales/selectors';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { updatePartnerConfiguration } from '../../../store/sales/actions';
import { DropdownOption } from '../../../components/pepForm';

type PartnerData = {
  partnerId?: DropDownOption<string>;
};

type Props = {
  setIsPartnerFormValid: (screenCompleted: boolean) => void;
};

/**
 * This component is part of the company information page. As the page does not have a form that we could integrate into
 * we need a workaround. Since this section requires no validation, the simplest way would be to trigger the update when
 * dropdown value changes. However, if there are more fields that are partner related, we might need a form anyway, so
 * I decided to keep it.
 * The partner ID update is handled within the 'useEffect' with a dependency to the dropdown value. If there is a validation
 * it might be good idea to use react-hook-form's 'formState' (and its 'isValid' property).
 */

const PartnerSelection = ({ setIsPartnerFormValid }: Props) => {
  const { partnersConfiguration } = useSelector(getOfferConfiguration);
  const { defaultPartnerId, partnerConfigurations, requiredForOnboarding } = partnersConfiguration || {};

  const { control, errors, formState, setValue } = useForm<PartnerData>({
    mode: 'onChange',
  });

  const partnerId = useSelector(getPartnerId);

  const isFormValid = formState.isValid;

  const { t } = useTranslation();

  const dispatch = useDispatch();

  useEffect(() => {
    if (!partnerId) {
      dispatch(updatePartnerConfiguration({ partnerId: defaultPartnerId })); // update the offer's state
    }
  }, [dispatch, defaultPartnerId, partnerId]);

  useEffect(() => {
    setIsPartnerFormValid(isFormValid);
  }, [setIsPartnerFormValid, isFormValid]);

  const getPartnerOptions = () =>
    partnerConfigurations?.map(
      (partner: PartnerConfiguration): DropDownOption<string> => ({
        value: partner.id,
        label: partner.name,
      })
    ) || [];

  const onPartnerChange = (partnerId?: string) => {
    dispatch(updatePartnerConfiguration({ partnerId: partnerId })); // update the offer's state
  };

  return partnersConfiguration ? (
    <Container spacing="2" fullWidth>
      <article title="partner-configuration-section">
        <Form>
          <Dropdown
            name="partnerId"
            size="sm"
            options={getPartnerOptions()}
            control={control}
            rules={
              requiredForOnboarding
                ? {
                    required: t('common.form.validationMessages.required'),
                  }
                : undefined
            }
            errors={errors}
            defaultValue={getPartnerOptions().find(opt => {
              return opt.value === defaultPartnerId || opt.value === partnerId;
            })}
            isClearable={!requiredForOnboarding}
            isMulti={false}
            label={t('onboarding.v2.companyInfo.partnerConfiguration')}
            setValue={(value?: string) => setValue('partnerId', value)}
            onChange={option => {
              const optionTyped = option as DropdownOption<string>;
              onPartnerChange(optionTyped ? optionTyped.value : undefined);
            }}
            testId="partner-configuration-dropdown"
          />
        </Form>
      </article>
    </Container>
  ) : (
    <></>
  );
};

export default PartnerSelection;
