import styled from 'styled-components/macro';

type RowProps = {
  noMarginBottom?: boolean;
};

type ColumnProps = {
  flex?: string;
};

export const Row = styled.div<RowProps>`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: ${({ noMarginBottom, theme }) => (noMarginBottom ? 0 : theme.spacings[3])};
`;

export const Column = styled.div<ColumnProps>`
  display: flex;
  flex: ${({ flex }) => (flex ? flex : '1')};
  flex-direction: column;
  margin-right: ${({ theme }) => theme.spacings[4]};

  &:last-child {
    margin-right: 0;
  }
`;
