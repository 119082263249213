import { Button, Heading, Paragraph } from '@loomispay/vault';
import { TextInput } from 'common/components/forms/TextInput/TextInput';
import { useFormWithSchema } from 'hooks/useForm';
import { useTranslation } from 'react-i18next';
import { PasswordlessForm, PasswordlessFormButtons } from 'features/loginPasswordless/LoginPasswordless.styles';
import * as Yup from 'yup';
import useCognitoUser, { SignedInUser } from 'features/loginPasswordless/useCognitoUser';
import { useEffect, useState } from 'react';
import { env } from 'app/config';
import { notifications } from 'common/components/Notification/notifications';

const RESEND_CODE_TIMOUT = 35;

export default function LoginPasswordlessStepTwo({ phoneNumber, username }: { phoneNumber: string; username: string }) {
  const { t } = useTranslation();
  const formSchema = Yup.object({
    code: Yup.string().required(t('passwordlessLogin.missingCode')).max(6, t('passwordlessLogin.codeTooLong')),
  });
  const { register, handleSubmit, errors, control, setError, clearErrors } = useFormWithSchema(formSchema);
  const { signIn, answerCustomChallenge } = useCognitoUser();

  const [resendSMScountdown, setResendSMScountdown] = useState(0);
  const [cognitoUser, setCognitoUser] = useState<SignedInUser>();
  const [codeProvidingAttempts, setCodeProvidingAttempts] = useState(0);

  const tooManyIncorrectAttempts = codeProvidingAttempts >= 3;

  useEffect(() => {
    sendVerificationCode();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let interval: NodeJS.Timeout;

    if (resendSMScountdown > 0) {
      interval = setInterval(() => {
        if (resendSMScountdown > 0) {
          setResendSMScountdown(resendSMScountdown - 1);
        }
      }, 1000);
    }

    return () => clearInterval(interval);
  }, [resendSMScountdown]);

  async function sendVerificationCode() {
    setResendSMScountdown(RESEND_CODE_TIMOUT);
    setCodeProvidingAttempts(0);

    try {
      const cognitoUserData = await signIn(username);
      if (cognitoUserData) {
        if (!env.isProd && cognitoUserData.challengeParam.secretCode) {
          const msg = `Secret code: ${cognitoUserData.challengeParam.secretCode}`;
          notifications.success(msg);
          console.info(msg);
        }
        setCognitoUser(cognitoUserData);
      }
    } catch {
      notifications.error(t('common.serverError'));
    }
  }

  const verifyCode = async ({ code }: { code: string }) => {
    const handleError = () => {
      const currentAttempts = codeProvidingAttempts + 1;
      setCodeProvidingAttempts(currentAttempts);

      if (currentAttempts >= 3) {
        clearErrors('code');
        setResendSMScountdown(0);
      } else {
        setError('code', {
          type: 'wrongCode',
          message: t('passwordlessLogin.incorrectCode'),
        });
      }
    };

    try {
      if (!cognitoUser) {
        return;
      }
      const verification = await answerCustomChallenge(cognitoUser, code);
      if (!verification) {
        handleError();
      }
    } catch {
      handleError();
    }
  };

  return (
    <>
      <Heading size="s">{t('passwordlessLogin.heading')}</Heading>
      <Paragraph>
        {t('passwordlessLogin.instructionsPartOne')} <strong>{phoneNumber}</strong>.{' '}
        {t('passwordlessLogin.instructionsPartTwo')}
      </Paragraph>

      <PasswordlessForm onSubmit={handleSubmit(verifyCode)}>
        <TextInput
          control={control}
          name="code"
          label={t('passwordlessLogin.inputLabel')}
          placeholder="123456"
          type="text"
          inputProps={{ pattern: '[0-9]{6}', register, autocomplete: 'off', autoFocus: true }}
          errors={errors}
          disabled={tooManyIncorrectAttempts}
          defaultValue=""
        />
        {tooManyIncorrectAttempts && (
          <>
            <Paragraph color="negative">{t('passwordlessLogin.incorrectCodeTooManyAttempts')}</Paragraph>
            <Paragraph color="negative">{t('passwordlessLogin.incorrectCodeTooManyAttemptsPersist')}</Paragraph>
          </>
        )}
        <PasswordlessFormButtons>
          <Button variant="tertiary" onClick={sendVerificationCode} disabled={resendSMScountdown > 0}>
            {t('passwordlessLogin.resendCode')}{' '}
            {resendSMScountdown > 0 ? `0:${resendSMScountdown < 10 ? '0' : ''}${resendSMScountdown}` : ''}
          </Button>
          <Button type="submit" disabled={tooManyIncorrectAttempts || !cognitoUser}>
            {t('passwordlessLogin.verify')}
          </Button>
        </PasswordlessFormButtons>
      </PasswordlessForm>
    </>
  );
}
