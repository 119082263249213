import { useEffect, useState } from 'react';

import { Heading } from '@loomispay/vault';
import { useTranslation } from 'react-i18next';
import LogInForm from './components/LogInForm';
import { useDocumentTitle } from 'hooks';
import { useNavigate } from 'react-router-dom';
import useCognitoUser from '../loginPasswordless/useCognitoUser';

const LogInScreen = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { signOut } = useCognitoUser();

  useDocumentTitle(t('login.title'), false);
  const [sessionCleared, setSessionCleared] = useState(false);

  useEffect(() => {
    const clearSession = async () => {
      await signOut(); // Log out any existing session
      setSessionCleared(true);
    };

    clearSession();
  }, [signOut]);

  return sessionCleared ? (
    <LogInForm
      loginHeader={<Heading size={'m'}>{t('login.title')}</Heading>}
      onLoginCompleted={() => {
        navigate('/');
      }}
    />
  ) : null;
};

export default LogInScreen;
