import { SimpleGridItem, SimpleSmallLeftGridItem, SimpleSmallRightGridItem } from 'common/components/GridLayout';
import { Dropdown } from 'common/components/forms/Dropdown/Dropdown';
import {
  ContactPerson,
  OfferOnboardingStore,
  ShippingInformationV2,
  ShippingLocationOptionType,
  ShippingOptionType,
} from 'features/common/types';
import Hr from 'common/components/Hr';
import { TextInput } from 'common/components/forms/TextInput/TextInput';
import { CountryDropdown } from 'features/onboardingV2/components/CountryDropdown';
import ShippingInformationV2LocationBox from 'features/common/components/ShippingInformationForm/ShippingInformationV2LocationBox';
import {
  getShippingLocationOptions,
  getShippingOptions,
  shippingLocationOptionChanged,
  shippingOptionChanged,
} from 'features/common/service/ShippingInformationService';
import { useTranslation } from 'react-i18next';
import { ShippingInformationForm } from 'features/common/types';
import { CountryCode } from 'constants/types';
import { useEffect } from 'react';
import { UseFormMethods } from 'react-hook-form';
import { Grid } from 'common/components/Grid';

type Props = {
  stores: OfferOnboardingStore[];
  shippingInformation?: ShippingInformationV2;
  countryCode?: CountryCode;
  companyName?: string;
  contactPerson?: ContactPerson;
  form: UseFormMethods<ShippingInformationForm>;
};

const ShippingInformationFormFields = ({
  companyName,
  contactPerson,
  countryCode,
  shippingInformation,
  stores,
  form,
}: Props) => {
  const { t } = useTranslation();

  const { control, errors, setValue, watch } = form;

  const shippingOptions = getShippingOptions(t);
  const defaultShippingOption = shippingOptions.find(
    option => option.value === (shippingInformation?.shippingOption || 'ALL2ONE')
  );
  const formShippingOption = watch('shippingOption', defaultShippingOption);

  useEffect(() => {
    shippingOptionChanged(stores, form, formShippingOption.value, shippingInformation);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formShippingOption]);

  const shippingLocationOptions = getShippingLocationOptions(t, stores);
  const defaultShippingLocationOption = shippingLocationOptions.find(
    option => option.value === shippingInformation?.all2OneStoreRef
  );
  const formShippingLocationOption = watch('shippingLocationOption', defaultShippingLocationOption);
  const shippingLocation = stores.find(store => store.ref === formShippingLocationOption?.value);
  useEffect(() => {
    shippingLocationOptionChanged(
      stores,
      form,
      companyName,
      contactPerson,
      formShippingLocationOption?.value,
      shippingInformation
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formShippingLocationOption]);

  return (
    <Grid vGap="3">
      <SimpleSmallLeftGridItem>
        <Dropdown
          name={`shippingOption`}
          label={t('common.shippingInformation.options')}
          rules={{ required: t('common.form.validationMessages.required') }}
          options={shippingOptions}
          getOptionLabel={(option: ShippingOptionType) => option.label}
          getOptionValue={(option: ShippingOptionType) => option.value}
          defaultValue={defaultShippingOption}
          control={control}
          errors={errors}
        />
      </SimpleSmallLeftGridItem>
      <SimpleSmallRightGridItem>
        {formShippingOption?.value === 'ALL2ONE' && (
          <Dropdown
            name={`shippingLocationOption`}
            label={t('common.shippingInformation.locationOptions')}
            rules={{ required: t('common.form.validationMessages.required') }}
            options={shippingLocationOptions}
            getOptionLabel={(option: ShippingLocationOptionType) => option.label}
            getOptionValue={(option: ShippingLocationOptionType) => option.value}
            defaultValue={defaultShippingLocationOption}
            control={control}
            errors={errors}
          />
        )}
      </SimpleSmallRightGridItem>
      <SimpleGridItem>
        <Hr margin="0" />
      </SimpleGridItem>
      {formShippingOption?.value === 'ALL2ONE' && (
        <>
          <SimpleSmallLeftGridItem>
            <TextInput
              label={t('common.shippingInformation.recipient')}
              name="shippingLocationData.recipient"
              setValue={(value?: string) => setValue('shippingLocationData.recipient', value)}
              rules={{ required: t('common.form.validationMessages.required') }}
              defaultValue={''}
              control={control}
              errors={errors}
              trimOnBlur
            />
          </SimpleSmallLeftGridItem>
          <SimpleSmallRightGridItem>
            <TextInput
              label={t('common.shippingInformation.attention')}
              name="shippingLocationData.attention"
              setValue={(value?: string) => setValue('shippingLocationData.attention', value)}
              defaultValue={''}
              control={control}
              errors={errors}
              trimOnBlur
            />
          </SimpleSmallRightGridItem>
          <SimpleSmallLeftGridItem>
            <TextInput
              label={t('common.address.streetName')}
              name="shippingLocationData.address"
              setValue={(value?: string) => setValue('address', value)}
              rules={!shippingLocation ? { required: t('common.form.validationMessages.required') } : undefined}
              defaultValue={''}
              disabled={!!shippingLocation}
              control={control}
              errors={errors}
              trimOnBlur
            />
          </SimpleSmallLeftGridItem>
          <SimpleSmallRightGridItem>
            <TextInput
              label={t('common.address.zipCode')}
              name="shippingLocationData.zipCode"
              setValue={(value?: string) => setValue('zipCode', value)}
              rules={!shippingLocation ? { required: t('common.form.validationMessages.required') } : undefined}
              defaultValue={
                shippingInformation?.shippingAddresses[0]?.zipCode ||
                (shippingLocation ? shippingLocation?.address.zipCode : '')
              }
              disabled={!!shippingLocation}
              control={control}
              errors={errors}
              trimOnBlur
            />
          </SimpleSmallRightGridItem>
          <SimpleSmallLeftGridItem>
            <TextInput
              label={t('common.address.city')}
              name="shippingLocationData.city"
              setValue={(value?: string) => setValue('city', value)}
              rules={!shippingLocation ? { required: t('common.form.validationMessages.required') } : undefined}
              defaultValue={
                shippingInformation?.shippingAddresses[0]?.city ||
                (shippingLocation ? shippingLocation?.address.city : '')
              }
              disabled={!!shippingLocation}
              control={control}
              errors={errors}
              trimOnBlur
            />
          </SimpleSmallLeftGridItem>
          <SimpleSmallRightGridItem>
            <CountryDropdown control={control} errors={errors} countryCodes={[countryCode || 'SE']} />
          </SimpleSmallRightGridItem>
        </>
      )}

      {formShippingOption?.value === 'ALL2THEIR' && (
        <SimpleGridItem>
          {stores.map((store, i) => (
            <div key={store.ref}>
              <ShippingInformationV2LocationBox location={store} index={i} form={form} />
            </div>
          ))}
        </SimpleGridItem>
      )}
    </Grid>
  );
};

export default ShippingInformationFormFields;
