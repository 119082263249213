import { createContext, ReactNode, useContext } from 'react';
import { Control, Controller, FieldErrors } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';

import { Fieldset, Label, Legend, RadioIcon, RadioInput, RadioWrapper } from './Radio.styles';

import { useTranslation } from 'react-i18next';
import { Paragraph, Text } from '@loomispay/vault';

type InputValue = string;

type RadioProps = {
  value: InputValue;
  disabled?: boolean;
  verticalAlign?: 'top' | 'center';
  testId?: string;
} & ({ children?: never; label?: string } | { children: ReactNode; label?: never });

type RadioGroupProps = {
  children: ReactNode;
  label?: string;
  name: string;
  size?: 'sm' | 'md';
  required?: boolean;
  control: Control;
  errors: FieldErrors;
  defaultValue?: InputValue;
  radioWrapperStyles?: string;
};

type IRadioGroupContext = {
  name: string;
  required?: boolean;
  control: Control;
  defaultValue?: InputValue;
  size?: 'sm' | 'md';
};

const RadioGroupContext = createContext<IRadioGroupContext | null>(null);

export const Radio = ({ value, label, children, disabled, testId, verticalAlign = 'center' }: RadioProps) => {
  const { t } = useTranslation();
  const context = useContext(RadioGroupContext);

  if (!context) {
    throw new Error('Radio button should be used within a RadioGroup');
  }

  const isDefault = context.defaultValue === value;

  return (
    <Label disabled={disabled} size={context.size} verticalAlign={verticalAlign}>
      <Controller
        name={context.name}
        control={context.control}
        defaultValue={context.defaultValue || ''}
        rules={{
          required: context.required && (t('common.form.validationMessages.required') as string),
        }}
        render={props => (
          <RadioInput
            type="radio"
            defaultChecked={isDefault}
            disabled={disabled}
            required={context.required}
            {...props}
            // default uses value from chosen input, which is wrong. We override react-hook-form:
            value={value}
            onChange={e => {
              props.onChange(e.target.checked ? value || true : false);
              // To fix bug with Radio buttons not triggering blur in Safari, which makes onBlur mode not working
              // https://bugs.webkit.org/show_bug.cgi?id=13724
              props.onBlur();
            }}
            data-testid={testId ? testId : context.name}
          />
        )}
      />
      <RadioIcon />
      {children ? (
        <div>{children}</div>
      ) : (
        <Text size="s" color={disabled ? 'disabled' : 'primary'}>
          {label}
        </Text>
      )}
    </Label>
  );
};

export const RadioGroup = ({
  label,
  name,
  size = 'md',
  defaultValue,
  required,
  control,
  errors,
  children,
  radioWrapperStyles,
}: RadioGroupProps) => {
  return (
    <RadioGroupContext.Provider value={{ name, size, defaultValue, required, control }}>
      <Fieldset>
        {label && (
          <Legend>
            <Text size="s" weight="bold">
              {label}
            </Text>
          </Legend>
        )}
        <RadioWrapper size={size} styles={radioWrapperStyles}>
          {children}
        </RadioWrapper>
        {/* TODO: Error messages are not displayed. react-hook-form bug? */}
        <ErrorMessage
          errors={errors}
          name={name}
          render={({ message }) => (
            <Paragraph size="s" color="negative">
              {message}
            </Paragraph>
          )}
        />
      </Fieldset>
    </RadioGroupContext.Provider>
  );
};
