import React, { Fragment } from 'react';
import { Button, Heading, Link, Paragraph } from '@loomispay/vault';
import { useTranslation } from 'react-i18next';
import { ContactPerson } from 'features/common/types';

export default function LoginPasswordlessStepOne({
  setNextStep,
  userPhoneNumber,
  contactPerson,
  header,
  description,
}: {
  setNextStep: () => void;
  userPhoneNumber: string;
  contactPerson: ContactPerson;
  header?: string;
  description?: string;
}) {
  const { t } = useTranslation();

  const contactPersonFullName = [contactPerson.firstName, contactPerson.lastName].join(' ');
  const contactPersonPhone = contactPerson.phoneNumber || '';
  const contactPersonEmail = contactPerson.emailAddress || '';

  const contactPersonParagraph = t('passwordlessLogin.instructionThree', {
    contactPersonFullName,
    contactPersonPhone,
    contactPersonEmail,
  }) as string;

  const contactPersonParagraphDecomposed = contactPersonParagraph.split(' ');
  const contactPersonPhoneIndex = contactPersonParagraphDecomposed.findIndex(word =>
    word.startsWith(contactPersonPhone)
  );
  const contactPersonEmailIndex = contactPersonParagraphDecomposed.findIndex(word =>
    word.startsWith(contactPersonEmail)
  );

  const contactPersonParagraphFormatted = contactPersonParagraphDecomposed.map((word, index) => {
    const isLastWord = index === contactPersonParagraphDecomposed.length - 1;

    return (
      <Fragment key={`${word}-${index}`}>
        {index === contactPersonPhoneIndex ? (
          <Link href={`tel: ${contactPersonPhone}`}>{contactPersonPhone}</Link>
        ) : index === contactPersonEmailIndex ? (
          <Link href={`mailto: ${contactPersonEmail}`}>{contactPersonEmail}</Link>
        ) : (
          <>{word}</>
        )}
        {isLastWord ? '.' : ' '}
      </Fragment>
    );
  });

  return (
    <div>
      <Heading size="m">{header ? header : t('passwordlessLogin.verifyIdentity')}</Heading>
      <Paragraph>{description ? description : t('passwordlessLogin.instructionOne')}</Paragraph>
      <Paragraph>
        {t('passwordlessLogin.instructionTwo')} <strong>{userPhoneNumber}</strong>
      </Paragraph>
      <Paragraph>{contactPersonParagraphFormatted}</Paragraph>
      <Button onClick={() => setNextStep()}>{t('passwordlessLogin.sendVerificationCode')}</Button>
    </div>
  );
}
