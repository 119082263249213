import { Accordion, Heading, Text } from '@loomispay/vault';
import { CountryCode, supportedCountryCodes } from 'constants/types';
import { Store } from 'features/common/types';
import { CashServicesSupportedCountryCodes } from 'features/onboardingV2/screens/sales/CashServices/types';
import useCashServices from 'features/onboardingV2/screens/sales/CashServices/useCashServices';
import { useTranslation } from 'react-i18next';
import getCurrencyForCountryCode from 'utils/currencyHelper';
import { OfferSummaryRow, OfferSummarySectionBox } from '../../OfferOverview.styles';
import { formatNormalizedAmount } from 'utils/moneyHelper';

export default function OfferOverviewCashLocationBreakdown({
  store,
  countryCode,
}: {
  store: Store;
  countryCode?: CountryCode;
}) {
  const { t } = useTranslation();
  const cashDataKey = supportedCountryCodes
    .find(supportedCode => supportedCode === countryCode)
    ?.toLowerCase() as CashServicesSupportedCountryCodes;
  const cashData = store.cashData ? store.cashData[cashDataKey] : null;
  const currency = getCurrencyForCountryCode(countryCode);
  const { cashContractTypesOptions } = useCashServices();

  if (!cashData) {
    return null;
  }

  return (
    <section>
      <Heading size="xxs" sansSerif>
        {store.name}
      </Heading>
      <OfferSummarySectionBox>
        <OfferSummaryRow>
          <Text size="s">{t('cashServices.form.solutionChosen')}</Text>
          <Text size="s">
            {cashData?.generalInfo?.solutionTypeDescription ||
              cashContractTypesOptions.find(type => type.value === cashData?.generalInfo?.solutionType)?.label ||
              cashData?.generalInfo?.solutionType}
          </Text>
        </OfferSummaryRow>

        <OfferSummaryRow>
          <Text size="s">{t('cashServices.form.basicSafePointIncluded')}</Text>
          <Text size="s">
            {cashData.generalInfo.basicSafePointIncluded ? t('common.form.booleanTrue') : t('common.form.booleanFalse')}
          </Text>
        </OfferSummaryRow>

        <OfferSummaryRow>
          <Text size="s">{t('cashServices.form.isLoomisBeneficiary')}</Text>
          <Text size="s">
            {cashData.generalInfo.isLoomisBeneficiary ? t('common.form.booleanTrue') : t('common.form.booleanFalse')}
          </Text>
        </OfferSummaryRow>

        <OfferSummaryRow>
          <Text size="s">{t('cashServices.form.clientWantsInsurance')}</Text>
          <Text size="s">
            {cashData.generalInfo.insuranceIncluded ? t('common.form.booleanTrue') : t('common.form.booleanFalse')}
          </Text>
        </OfferSummaryRow>

        <OfferSummaryRow>
          <Text size="s">{t('cashServices.form.clientWantsChange')}</Text>
          <Text size="s">
            {cashData.generalInfo.changeServiceIncluded ? t('common.form.booleanTrue') : t('common.form.booleanFalse')}
          </Text>
        </OfferSummaryRow>

        <OfferSummaryRow>
          <Text size="s">{t('cashServices.form.contractStartDate')}</Text>
          <Text size="s">{cashData.contractDates.startDate}</Text>
        </OfferSummaryRow>

        <OfferSummaryRow>
          <Text size="s">{t('cashServices.form.contractDuration')}</Text>
          <Text size="s">
            {cashData.contractDates.durationInMonths} {t('common.months')}
          </Text>
        </OfferSummaryRow>

        <Accordion title={t('cashServices.detailedInformation')}>
          <OfferSummaryRow>
            <Text size="s">{t('cashServices.form.banknotesInstantCredit')}</Text>
            <Text size="s">
              {cashData.instantCredit.banknotesInstantCredit
                ? t('common.form.booleanTrue')
                : t('common.form.booleanFalse')}
            </Text>
          </OfferSummaryRow>
          <OfferSummaryRow>
            <Text size="s">{t('cashServices.form.coinsInstantCredit')}</Text>
            <Text size="s">
              {cashData.instantCredit.coinsInstantCredit ? t('common.form.booleanTrue') : t('common.form.booleanFalse')}
            </Text>
          </OfferSummaryRow>

          {/* DETAILS SECTION */}

          <OfferSummaryRow>
            <Text size="s">{t('cashServices.form.pickupsPerMonth')}</Text>
            <Text size="s">{cashData.details.pickupsPerMonth}</Text>
          </OfferSummaryRow>
          <OfferSummaryRow>
            <Text size="s">{t('cashServices.form.banknotesLimitCountPerMonth')}</Text>
            <Text size="s">{formatNormalizedAmount(cashData.details.banknotesLimitValuePerMonth || 0, currency)}</Text>
          </OfferSummaryRow>
          <OfferSummaryRow>
            <Text size="s">{t('cashServices.form.coinsLimitCountPerMonth')}</Text>
            <Text size="s">{(formatNormalizedAmount(cashData.details.coinsLimitValuePerMonth || 0), currency)}</Text>
          </OfferSummaryRow>
          <OfferSummaryRow>
            <Text size="s">{t('cashServices.form.banknotesRechargeExcessWithoutNewPickup')}</Text>
            <Text size="s">
              {formatNormalizedAmount(cashData.details.banknotesRechargeExcessWithoutNewPickup || 0, currency)}
            </Text>
          </OfferSummaryRow>
          <OfferSummaryRow>
            <Text size="s">{t('cashServices.form.banknotesRechargeExcessWithNewPickup')}</Text>
            <Text size="s">
              {formatNormalizedAmount(cashData.details.banknotesRechargeExcessWithNewPickup || 0, currency)}
            </Text>
          </OfferSummaryRow>
          <OfferSummaryRow>
            <Text size="s">{t('cashServices.form.forEachCoinValueLimitExcess')}</Text>
            <Text size="s">{formatNormalizedAmount(cashData.details.chargeForEachCoinLimitExcess || 0, currency)}</Text>
          </OfferSummaryRow>

          {/* CHANGE SERVICE SECTION */}

          {cashData.changeService && (
            <>
              <OfferSummaryRow>
                <Text size="s">{t('cashServices.form.changeWithPickupFee')}</Text>
                <Text size="s">
                  {formatNormalizedAmount(cashData.changeService.changeWithPickupFee || 0, currency)}
                </Text>
              </OfferSummaryRow>
              <OfferSummaryRow>
                <Text size="s">{t('cashServices.form.changeWithoutPickupFee')}</Text>
                <Text size="s">
                  {formatNormalizedAmount(cashData.changeService.changeWithoutPickupFee || 0, currency)}
                </Text>
              </OfferSummaryRow>
            </>
          )}
        </Accordion>
      </OfferSummarySectionBox>
    </section>
  );
}
