import { ChangeEvent, useCallback, useEffect } from 'react';
import { Controller } from 'react-hook-form';
import { InputComponent } from '../TextInput/TextInput.styles';
import { DropdownAsyncProps, DropdownCreatableProps, DropdownProps, SelectWrapperProps } from './dropdownTypes';

import i18n from 'i18next';
import { Select, SelectAsync, SelectCreatable } from '@loomispay/vault';
import { useTranslation } from 'react-i18next';
import useInputErrors from '../TextInput/useInputErrors';

const SelectWrapper = ({ selectType, selectProps }: SelectWrapperProps) => {
  const {
    label,
    name,
    errors = {},
    required,
    loadOptions,
    minValueLength = 3,
    control,
    defaultValue,
    testId = '',
    loadOptionsUseCallback = true,
    onChange: externalOnChange,
    onBlur: externalOnBlur,
    hidden = false,
    ...restSelectProps
  } = selectProps;
  const error = errors && errors[name];

  const asyncOptions = useCallback(
    async (value: string) => {
      if (value.length >= minValueLength) {
        return loadOptions(value);
      }
    },
    [loadOptions, minValueLength]
  );

  const { t } = useTranslation();
  const { errorMessage, getErrorMessage } = useInputErrors(errors, name);

  useEffect(() => {
    getErrorMessage();
  }, [errors, getErrorMessage]);

  return (
    <InputComponent hidden={hidden} data-testid={testId ? testId : name} className={error ? 'has-error' : ''}>
      <Controller
        control={control}
        error={error}
        name={name}
        defaultValue={defaultValue}
        {...restSelectProps}
        render={({ onChange, onBlur, ...controllerProps }) => {
          const onChangeCombined = (value: ChangeEvent) => {
            externalOnChange && externalOnChange(value);
            onChange(value);
          };

          const onBlurCombined = (value: ChangeEvent) => {
            externalOnBlur && externalOnBlur(value);
            onBlur();
          };

          const standardPropsCombined = {
            label,
            ...selectProps,
            ...controllerProps,
            required: required ? t('common.form.required') : undefined,
            onChange: onChangeCombined,
            onBlur: onBlurCombined,
            error: errorMessage,
          };

          return (
            <>
              {selectType === 'select' && <Select {...standardPropsCombined} />}
              {selectType === 'selectAsync' && (
                <SelectAsync
                  {...standardPropsCombined}
                  loadOptions={loadOptionsUseCallback ? asyncOptions : loadOptions}
                />
              )}
              {selectType === 'selectCreatable' && <SelectCreatable {...standardPropsCombined} />}
            </>
          );
        }}
      />
    </InputComponent>
  );
};

export const Dropdown = (props: DropdownProps) => <SelectWrapper selectType="select" selectProps={props} />;
export const DropdownAsync = ({ placeholder = i18n.t('common.search'), ...props }: DropdownAsyncProps) => (
  <SelectWrapper selectType="selectAsync" selectProps={{ ...props, placeholder }} />
);
export const DropdownCreatable = ({ ...props }: DropdownCreatableProps) => {
  return (
    <SelectWrapper
      selectType="selectCreatable"
      selectProps={{
        ...props,
      }}
    />
  );
};
