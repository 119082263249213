import { CountryCode } from 'constants/types';
import { useSelector } from 'react-redux';
import { getCountryCode, getOfferType } from '../../store/sales/selectors';
import { getSalesOnboardingFlow } from './salesFlows';
import { findRoute, STEP_BACK, STEP_FORWARD } from 'services/flowRoutingService';
import { RouteConfig } from 'common/route/createFeatureRouter';
import { useLocation, useNavigate } from 'react-router-dom';
import { userService } from 'services/userService';
import { useEffect } from 'react';

const nextPageForCountry = (flow: RouteConfig[], path: string, countryCode?: CountryCode): string => {
  if (!countryCode) return '';
  const route = findRoute(path, flow, STEP_FORWARD);
  return route.path ? route.path : '';
};

const prevPageForCountry = (flow: RouteConfig[], path: string, countryCode?: CountryCode): string => {
  if (!countryCode) return '';
  const route = findRoute(path, flow, STEP_BACK);
  return route.path ? route.path : '';
};

export const firstPageForCountry = (flow: RouteConfig[], countryCode?: CountryCode): string => {
  if (!countryCode) return '';
  return flow[0]?.path || '';
};

export function useSalesFlowNavigation() {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const countryCode = useSelector(getCountryCode);
  const offerType = useSelector(getOfferType);
  const cashEnabled = countryCode === 'ES';
  const flow = getSalesOnboardingFlow({ cashEnabled, offerType });

  useEffect(() => {
    // guard for sales flow if user in not logged in
    async function checkIfAuthorized() {
      if (!(await userService.isSalesRepLoggedIn())) {
        navigate('/logout');
      }
    }
    checkIfAuthorized();
  }, [navigate]);

  const nextPage = (): string => nextPageForCountry(flow, pathname, countryCode);
  const prevPage = (): string => prevPageForCountry(flow, pathname, countryCode);
  const firstPage = (): string => firstPageForCountry(flow, countryCode);

  return {
    nextPage,
    prevPage,
    firstPage,
  };
}
