import { ReactComponent as GetYourPayoutFast } from 'assets/img/get_your_payout_fast.svg';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getContactPersonEmail, getOfferRef } from '../../store/sales/selectors';
import { clearSalesOnboarding } from '../../store/sales/actions';
import { Confirmation } from '../../components/Confirmation';
import { useDocumentTitle } from 'hooks';
import { useNavigate } from 'react-router-dom';
import useCognitoUser from 'features/loginPasswordless/useCognitoUser';

export const OfferSent = () => {
  const contactEmail = useSelector(getContactPersonEmail);
  const offerRef = useSelector(getOfferRef);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { t } = useTranslation();
  const { signOut } = useCognitoUser();
  useDocumentTitle(t('onboarding.v2.offerSent.title'));

  return (
    <Confirmation
      image={<GetYourPayoutFast width="100%" />}
      title={t('onboarding.v2.offerSent.title')}
      description={t('onboarding.v2.offerSent.description', { email: contactEmail })}
      button1Label={t('onboarding.v2.offerSent.createNewOffer.button')}
      button1Action={() => {
        dispatch(clearSalesOnboarding());
        navigate('/');
      }}
      button2Label={
        offerRef
          ? t('onboarding.v2.offerSent.continueToApplication.button')
          : t('onboarding.v2.offerSent.doneForNow.button')
      }
      button2Action={() => {
        if (offerRef) {
          signOut();
          navigate(`/onboarding/v2/${offerRef}`);
        } else {
          navigate('/logout');
        }
      }}
    />
  );
};
