import { Heading, Paragraph, useMedia } from '@loomispay/vault';
import { TextInput } from 'common/components/forms/TextInput/TextInput';
import { SimpleGridItem, SimpleGridLayout, SimpleSmallLeftGridItem } from 'common/components/GridLayout';
import Hr from 'common/components/Hr';
import { CountryCode, FormVersion } from 'constants/types';
import { Footer } from 'features/onboardingV2/components/OnboardingFooter';
import { useDocumentTitle } from 'hooks';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { patterns } from 'utils/validation';
import { spacing } from '../../../../constants';
import { bankAccountForm } from '../../onboardingV2Config';
import { BankInformation, BankInformationType, ExtendedBankInformation, InvoiceInformation } from '../../store/types';
import { MerchantOnboardingData } from '../../../common/types';
import { Grid, GridItem } from 'common/components/Grid';
import styled from 'styled-components/macro';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { BaseQueryParams } from 'features/onboardingV2/data/types';
import { useOffer, useOnboardingData } from 'features/onboardingV2/data/queries';
import { onboardingRoutes } from 'features/onboardingV2/OnboardingRouter';
import { usePartialUpdate } from 'features/onboardingV2/data/mutations';
import { useEffect } from 'react';

type BankInformationForm = FormVersion<ExtendedBankInformation>;

const BankInformationScreen = () => {
  const { countryCode, offerRef } = useParams<BaseQueryParams>();
  const { data: onboardingData } = useOnboardingData({ countryCode, offerRef });
  const { data: offer } = useOffer({ countryCode, offerRef });
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isMobile = useMedia('mobile');
  useDocumentTitle(t('onboarding.v2.bankInformation.title'));

  const prevPage = () => generatePath(onboardingRoutes.signingCombinations.path, { countryCode, offerRef });
  const nextPage = generatePath(
    offer?.offerType === 'CASH'
      ? onboardingRoutes.additionalInformation.path
      : onboardingRoutes.shippingInformationV2.path,
    { countryCode, offerRef }
  );

  const { mutate: uploadBankInformation, isSuccess: bankInformationUpdated } = usePartialUpdate();

  const bankForm = useForm<BankInformationForm>({
    mode: 'onChange',
  });

  const isScreenCompleted = bankForm.formState.isValid;

  const getMerchantOnboardingData = (bankInformation: BankInformation, invoiceInformation: InvoiceInformation) => {
    return {
      ...onboardingData,
      bankInformation: bankInformation,
      invoiceInformation: invoiceInformation,
      page: 'bankInformation',
    } as MerchantOnboardingData;
  };

  const onSubmit = async (bankInformationForm: BankInformationForm) => {
    const bankInformation: BankInformation = {
      accountOwner: bankInformationForm.accountOwner,
      accountNumber: bankInformationForm.accountNumber,
      bankCode: bankInformationForm.bankCode,
      bankInformationType: bankInformationForm.bankInformationType as BankInformationType,
    };
    const invoiceInformation: InvoiceInformation = { email: bankInformationForm.email };
    const merchantOnboardingData: MerchantOnboardingData = getMerchantOnboardingData(
      bankInformation,
      invoiceInformation
    );
    uploadBankInformation({
      countryCode,
      merchantRef: offerRef,
      data: merchantOnboardingData,
    });
  };

  useEffect(() => {
    if (bankInformationUpdated) {
      navigate(nextPage);
    }
  }, [bankInformationUpdated, navigate, nextPage]);

  const resolveBankAccountScreen = () => {
    switch (countryCode) {
      case 'SE':
      case 'NO':
        return bankAccountForm[countryCode].bankInfoScreen;
      default:
        return bankAccountForm['INTERNATIONAL'].bankInfoScreen;
    }
  };

  const BankAccountScreen = resolveBankAccountScreen();

  return onboardingData ? (
    <div>
      <SimpleGridLayout>
        <SimpleGridItem>
          <Heading size="xl">{t('onboarding.v2.bankInformation.title')}</Heading>
          <Paragraph color={'tertiary'}>{t('onboarding.v2.bankInformation.description')}</Paragraph>
        </SimpleGridItem>
      </SimpleGridLayout>
      <BankForm onSubmit={bankForm.handleSubmit(onSubmit)}>
        <Grid vGap="2">
          <SimpleSmallLeftGridItem>
            <TextInput
              label={t('onboarding.v2.bankInformation.accountOwner')}
              name="accountOwner"
              setValue={(value?: string) => bankForm.setValue('accountOwner', value)}
              rules={{
                required: t('common.form.validationMessages.required'),
                pattern: {
                  value: patterns.fullName,
                  message: t('common.form.validationMessages.fullName'),
                },
              }}
              defaultValue={onboardingData.bankInformation?.accountOwner || ''}
              control={bankForm.control}
              errors={bankForm.errors}
              trimOnBlur
            />
          </SimpleSmallLeftGridItem>
          <GridItem s={0} m={0} l={4} />
          <BankAccountScreen
            form={bankForm}
            bankInformation={onboardingData.bankInformation}
            countryCode={countryCode as CountryCode}
          />
          <SimpleGridItem>
            <Hr margin={`${spacing.s2} 0`} />
          </SimpleGridItem>
        </Grid>
        <SimpleGridLayout>
          <SimpleGridItem>
            <Heading size="xl">{t('onboarding.v2.invoiceInformation.title')}</Heading>
            <Paragraph color={'tertiary'}>{t('onboarding.v2.invoiceInformation.description')}</Paragraph>
          </SimpleGridItem>
        </SimpleGridLayout>
        <Grid>
          <SimpleSmallLeftGridItem>
            <TextInput
              label={t('onboarding.v2.invoiceInformation.invoiceEmail')}
              name="email"
              type="email"
              setValue={(value?: string) => bankForm.setValue('email', value)}
              rules={{
                required: t('common.form.validationMessages.required'),
                pattern: {
                  value: patterns.email,
                  message: t('common.form.validationMessages.invalidEmail'),
                },
              }}
              defaultValue={onboardingData.invoiceInformation?.email || ''}
              control={bankForm.control}
              errors={bankForm.errors}
              trimOnBlur
            />
          </SimpleSmallLeftGridItem>
        </Grid>
        <Footer isMobile={isMobile} prevPage={prevPage} isScreenCompleted={isScreenCompleted} />
      </BankForm>
    </div>
  ) : null;
};

const BankForm = styled.form`
  padding-bottom: ${({ theme }) => theme.spacings['4']};
`;

export default BankInformationScreen;
