import { useEffect } from 'react';
import { configureAmplify } from '../login/SalesToolsAmplifyConfigurer';
import OnboardingMainWrapper from '../../common/components/MainLayout/OnboardingMainWrapper';
import { Outlet } from 'react-router-dom';
import { RouteConfig } from '../../common/route/createFeatureRouter';
import SalesDispatcher from './screens/sales/SalesDispatcher';
import CompanyInformation from './screens/sales/search/CompanyInformation';
import LocationsSales from './screens/sales/locations/LocationsSales';
import SalesLocationEdit from './screens/sales/locations/SalesLocationEdit';
import CashServices from './screens/sales/CashServices/CashServices';
import TransactionFees from './screens/sales/TransactionFees';
import OnboardingPricing from './screens/sales/OnboardingPricing';
import OnboardingProducts from './screens/sales/Products/OnboardingProducts';
import OverviewSales from './screens/sales/OverviewSales/OverviewSales';
import { OfferSent } from './screens/sales/OfferSent';

export const salesBasePath = `/onboarding/v2/sales`;

export const salesRoutes: Record<string, RouteConfig> = {
  salesDispatcher: {
    path: salesBasePath,
    element: SalesDispatcher,
    allowedRoles: ['sales'],
  },
  companyInformation: {
    path: `${salesBasePath}/company-information`,
    element: CompanyInformation,
    allowedRoles: ['sales'],
  },
  salesLocations: {
    path: `${salesBasePath}/locations`,
    element: LocationsSales,
    allowedRoles: ['sales'],
  },
  editLocationSales: {
    path: `${salesBasePath}/locations/:locationId`,
    element: SalesLocationEdit,
    allowedRoles: ['sales'],
  },
  addLocation: {
    path: `${salesBasePath}/locations/new`,
    element: SalesLocationEdit,
    allowedRoles: ['sales'],
  },
  cashServices: {
    path: `${salesBasePath}/cashServices`,
    element: CashServices,
    allowedRoles: ['sales'],
  },
  transactionFees: {
    path: `${salesBasePath}/transactionFees`,
    element: TransactionFees,
    allowedRoles: ['sales'],
  },
  pricing: {
    path: `${salesBasePath}/pricing`,
    element: OnboardingPricing,
    allowedRoles: ['sales'],
  },
  products: {
    path: `${salesBasePath}/products`,
    element: OnboardingProducts,
    allowedRoles: ['sales'],
  },
  overview: {
    path: `${salesBasePath}/overview`,
    element: OverviewSales,
    allowedRoles: ['sales'],
  },
  offerSent: {
    path: `${salesBasePath}/offer-sent`,
    element: OfferSent,
    allowedRoles: ['sales'],
    hideNavigation: true,
  },
};

const SalesRouter = () => {
  useEffect(() => {
    configureAmplify();
  }, []);

  return (
    <OnboardingMainWrapper>
      <Outlet />
    </OnboardingMainWrapper>
  );
};

export default SalesRouter;
