import FullscreenErrorLayout from '../../layout/FullscreenErrorLayout';
import { ErrorProps, ErrorRouteParams } from '../../constants/types';
import { useMerchantContext } from '../../services/MerchantContext';
import { TFuncKey, useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

const getLanguageFromCountryCode = (countryCode?: string) => {
  let emailKey: TFuncKey = 'errors.email.en';
  let phoneKey: TFuncKey = 'errors.phone.en';
  if (countryCode) {
    switch (countryCode) {
      case 'SE':
        emailKey = 'errors.email.sv';
        phoneKey = 'errors.phone.sv';
        break;
      case 'DK':
        emailKey = 'errors.email.da';
        phoneKey = 'errors.phone.da';
        break;
      case 'ES':
        emailKey = 'errors.email.es';
        phoneKey = 'errors.phone.es';
        break;
      case 'NO':
        // FIXME: MP-4467 intentionally falling back to defaults
        break;
    }
  }
  return { emailKey, phoneKey };
};

export const NotFoundErrorScreen = () => {
  const { t } = useTranslation();
  const merchantContext = useMerchantContext();
  const { errorCode } = useParams<ErrorRouteParams>();

  const { emailKey, phoneKey } = getLanguageFromCountryCode(merchantContext.merchantContext.merchant?.countryCode);

  const renderProps: ErrorProps = {
    title: t('errors.commonTitle'),
    description: t('errors.commonDescription'),
    email: t(emailKey),
    emailLabel: t('errors.emailLabel'),
    phone: t(phoneKey),
    phoneLabel: t('errors.phoneLabel'),
  };
  if (errorCode) {
    renderProps.errorCode = `${t('errors.errorCode')}: ${errorCode}`;
  }
  return <FullscreenErrorLayout {...renderProps} />;
};

export const ServiceUnavailableErrorScreen = () => {
  const { t } = useTranslation();
  const merchantContext = useMerchantContext();
  const { errorCode } = useParams<ErrorRouteParams>();

  const { emailKey, phoneKey } = getLanguageFromCountryCode(merchantContext.merchantContext.merchant?.countryCode);

  const renderProps: ErrorProps = {
    title: t('errors.serviceUnavailableTitle'),
    description: t('errors.commonDescription'),
    email: t(emailKey),
    emailLabel: t('errors.emailLabel'),
    phone: t(phoneKey),
    phoneLabel: t('errors.phoneLabel'),
  };
  if (errorCode) {
    renderProps.errorCode = `${t('errors.errorCode')}: ${errorCode}`;
  }
  return <FullscreenErrorLayout {...renderProps} />;
};
