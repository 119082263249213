import { Heading, Text } from '@loomispay/vault';
import { CurrencyCode } from 'constants/types';
import { Store } from 'features/common/types';
import { useTranslation } from 'react-i18next';
import { OfferSummaryHr, OfferSummaryRow, OfferSummarySectionBox } from '../../OfferOverview.styles';
import { formatNormalizedAmount } from 'utils/moneyHelper';

export default function OfferOverviewCashCosts({ stores, currency }: { stores: Store[]; currency: CurrencyCode }) {
  const { t } = useTranslation();

  return (
    <section>
      <Heading size="xxs" sansSerif>
        {t('cashServices.cashServicesCosts')}
      </Heading>
      <OfferSummarySectionBox>
        <OfferSummaryRow>
          <Text size="xs" weight="semiBold">
            {t('cashServices.monthlyFees')}
          </Text>
          <div />
        </OfferSummaryRow>
        {stores.map(store => (
          <OfferSummaryRow key={store.name}>
            <Text size="s">{store.name}</Text>
            <Text size="s">
              {store.cashData?.es?.fees.percentageMonthlyFee
                ? `${store.cashData?.es?.fees.percentageMonthlyFee}%`
                : `${(formatNormalizedAmount(store.cashData?.es?.fees.fixedMonthlyFee || 0), currency)}`}
            </Text>
          </OfferSummaryRow>
        ))}

        {stores.filter(store => store.cashData?.es?.fees.percentageMonthlyFee).length === 0 && (
          <OfferSummaryRow>
            <Text size="l" weight="semiBold">
              {t('cashServices.monthlyFeesForCashService')}
            </Text>
            <Text size="l" weight="semiBold">
              {`${formatNormalizedAmount(
                stores.reduce((acc: number, next) => {
                  const monthlyFee = next.cashData?.es?.fees.fixedMonthlyFee || 0;
                  return acc + +monthlyFee;
                }, 0),
                currency
              )}/${t('common.month')}`}
            </Text>
          </OfferSummaryRow>
        )}

        <OfferSummaryHr />

        <OfferSummaryRow>
          <Text size="xs" weight="semiBold">
            {t('cashServices.oneTimeCosts')}
          </Text>
          <div />
        </OfferSummaryRow>
        {stores.map(store => (
          <OfferSummaryRow key={store.name}>
            <Text size="s">{store.name}</Text>
            <Text size="s">{formatNormalizedAmount(store.cashData?.es?.fees.upfrontPayment || 0, currency)}</Text>
          </OfferSummaryRow>
        ))}
        <OfferSummaryRow>
          <Text size="l" weight="semiBold">
            {t('cashServices.oneTimeCostsForCashService')}
          </Text>
          <Text size="l" weight="semiBold">
            {formatNormalizedAmount(
              stores.reduce((acc: number, next) => {
                const monthlyFee = next.cashData?.es?.fees.upfrontPayment || 0;
                return acc + +monthlyFee;
              }, 0),
              currency
            )}
          </Text>
        </OfferSummaryRow>

        <OfferSummaryHr />

        <OfferSummaryRow>
          <Text size="l" weight="semiBold">
            {t('cashServices.finalPayment')}
          </Text>
          <Text size="l" weight="semiBold">
            {formatNormalizedAmount(
              stores.reduce((acc: number, next) => {
                const monthlyFee = next.cashData?.es?.fees.finalPayment || 0;
                return acc + +monthlyFee;
              }, 0),
              currency
            )}
          </Text>
        </OfferSummaryRow>
      </OfferSummarySectionBox>
    </section>
  );
}
