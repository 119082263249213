import { useLocation, useNavigate } from 'react-router-dom';
import { ScreenMode } from 'constants/types';
import { useDispatch, useSelector } from 'react-redux';
import { addStore, updateStore } from 'features/onboardingV2/store/sales/actions';
import { getCountryCode, getStores } from 'features/onboardingV2/store/sales/selectors';
import { useParams } from 'react-router-dom';
import LocationEditForm, { LocationForm } from 'features/common/components/Locations/LocationEditForm';
import { Store } from 'features/common/types';
import { salesRoutes } from '../../../SalesRouter';

type RouteParameters = {
  locationId: string;
};

const SalesLocationEdit = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const dispatch = useDispatch();

  const mode = pathname.includes('/new') ? ScreenMode.add : ScreenMode.edit;

  const stores = useSelector(getStores);
  const countryCode = useSelector(getCountryCode);
  const { locationId } = useParams<RouteParameters>() as RouteParameters;
  const locationIndex = parseInt(locationId);

  const location = mode === ScreenMode.edit ? stores.find((_, i) => i === locationIndex) : null;
  const locationForm: LocationForm = {
    name: location?.name,
    address: location?.address.address,
    zipCode: location?.address.zipCode,
    city: location?.address.city,
    country: countryCode,
  };

  const onSubmit = (locationForm: LocationForm) => {
    const location: Store = {
      name: locationForm.name || '',
      address: {
        address: locationForm.address,
        zipCode: locationForm.zipCode,
        city: locationForm.city,
        countryCode: countryCode,
      },
    };

    if (mode === ScreenMode.edit) {
      dispatch(updateStore(location, locationIndex));
    } else {
      dispatch(addStore(location));
    }
    salesRoutes.salesLocations.path && navigate(salesRoutes.salesLocations.path);
  };

  return (
    <LocationEditForm
      location={locationForm}
      closeUrl={salesRoutes.salesLocations.path as string}
      mode={mode}
      onSubmit={onSubmit}
      countryCode={countryCode}
    />
  );
};

export default SalesLocationEdit;
