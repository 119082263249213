import OnboardingMainWrapper from '../../common/components/MainLayout/OnboardingMainWrapper';
import { Outlet } from 'react-router-dom';
import { RouteConfig } from '../../common/route/createFeatureRouter';
import LocationsMerchant from './screens/merchant/locations/LocationsMerchant';
import MerchantLocationEdit from './screens/merchant/locations/MerchantLocationEdit';
import OwnersV2 from './screens/merchant/owners/OwnersV2';
import OwnerEdit from './screens/merchant/owners/OwnerEdit';
import SigningCombinations from './screens/merchant/signingCombinations/SigningCombinations';
import ShippingInformationV2 from './screens/merchant/ShippingInformationV2';
import AdditionalInformation from './screens/merchant/additionalInformation/AdditionalInformation';
import { AdditionalQuestions } from './screens/merchant/AdditionalQuestions';
import { DoneScreen } from './screens/merchant/DoneScreen';
import OfferLoginScreen from './screens/merchant/login/OfferLoginScreen';
import WelcomeScreen from './screens/merchant/WelcomeScreen';
import OverviewMerchant from './screens/merchant/overview/OverviewMerchant';
import BankInformation from './screens/merchant/BankInformation';
import SignerFormV2 from './screens/merchant/signingCombinations/SignerFormV2';
import OnboardingDispatcher from './screens/merchant/OnboardingDispatcher';

export const onboardingBasePathWithParams = '/onboarding/v2/:countryCode/:offerRef';
export const onboardingBasePath = '/onboarding/v2';

export const getOfferLoginRoute = (offerRef: string) => {
  return `${onboardingBasePath}/${offerRef}/signin`;
};

export const onboardingRoutes: Record<string, RouteConfig> = {
  locationsMerchant: {
    path: `${onboardingBasePathWithParams}/locations`,
    element: LocationsMerchant,
    allowedRoles: ['onboarding'],
  },
  editLocationMerchant: {
    path: `${onboardingBasePathWithParams}/locations/:locationId`,
    element: MerchantLocationEdit,
    allowedRoles: ['onboarding'],
  },
  ownersV2: {
    path: `${onboardingBasePathWithParams}/owners-v2`,
    element: OwnersV2,
    allowedRoles: ['onboarding'],
  },
  addOwner: {
    path: `${onboardingBasePathWithParams}/owners-v2/add`,
    element: OwnerEdit,
    allowedRoles: ['onboarding'],
  },
  editOwner: {
    path: `${onboardingBasePathWithParams}/owners-v2/:ownerId`,
    element: OwnerEdit,
    allowedRoles: ['onboarding'],
  },
  signingCombinations: {
    path: `${onboardingBasePathWithParams}/signing-combinations`,
    element: SigningCombinations,
    allowedRoles: ['onboarding'],
  },
  signerFormV2Edit: {
    path: `${onboardingBasePathWithParams}/signer-form-v2/:signerRef`,
    element: SignerFormV2,
    allowedRoles: ['merchant', 'onboarding'],
  },
  signerFormV2New: {
    path: `${onboardingBasePathWithParams}/signer-form-v2`,
    element: SignerFormV2,
    allowedRoles: ['merchant', 'onboarding'],
  },
  bankInformation: {
    path: `${onboardingBasePathWithParams}/bank-information`,
    element: BankInformation,
    allowedRoles: ['onboarding'],
  },
  shippingInformationV2: {
    path: `${onboardingBasePathWithParams}/shipping-information`,
    element: ShippingInformationV2,
    allowedRoles: ['onboarding'],
  },
  additionalInformation: {
    path: `${onboardingBasePathWithParams}/additional-information`,
    element: AdditionalInformation,
    allowedRoles: ['onboarding'],
  },
  additionalQuestions: {
    path: `${onboardingBasePathWithParams}/additional-questions`,
    element: AdditionalQuestions,
    allowedRoles: ['onboarding'],
  },
  done: {
    path: `${onboardingBasePath}/done`,
    element: DoneScreen,
    isPublic: true,
    hideNavigation: true,
  },
  login: {
    path: `${onboardingBasePath}/:offerRef/signin`,
    element: OfferLoginScreen,
    isPublic: true,
  },
  welcomeScreen: {
    path: `${onboardingBasePathWithParams}/welcome`,
    element: WelcomeScreen,
    isPublic: true,
    hideNavigation: true,
  },
  // Keep last since wildcard match
  merchantOfferOverview: {
    path: `${onboardingBasePath}/:offerRef`,
    element: OverviewMerchant,
    isPublic: true,
  },
  onboardingDispatcher: {
    path: `${onboardingBasePathWithParams}`,
    element: OnboardingDispatcher,
    allowedRoles: ['onboarding'],
  },
};

const OnboardingRouter = () => {
  return (
    <OnboardingMainWrapper>
      <Outlet />
    </OnboardingMainWrapper>
  );
};

export default OnboardingRouter;
