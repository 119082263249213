import { Store } from 'features/common/types';
import LocationCard from 'features/common/components/Locations/LocationCard';
import { Fragment } from 'react';

type Props = {
  locations: Store[];
  selectedStoreIndex: number;
  onLocationClick: (i: number) => void;
  showAddons?: boolean;
};

export const ProductsLocations = ({ locations, selectedStoreIndex, onLocationClick, showAddons = true }: Props) => {
  const sumUpTerminals = (location: Store) => {
    return (
      location.storeOfferItems?.terminals.map(terminal => terminal.quantity).reduce((acc, curr) => acc + curr, 0) || 0
    );
  };

  const sumUpAddons = (location: Store) => {
    return location.storeOfferItems?.addons.map(addon => addon.quantity).reduce((acc, curr) => acc + curr, 0) || 0;
  };

  return (
    <>
      {locations.length > 1 &&
        locations.map((location, i) => {
          return (
            <Fragment key={location.name}>
              <LocationCard
                selected={selectedStoreIndex === i}
                onClick={() => onLocationClick(i)}
                location={location}
                selectedTerminalsNumber={sumUpTerminals(location)}
                selectedAddonsNumber={sumUpAddons(location)}
                testId={`location.${i}.card`}
                showAddons={showAddons}
                showOrderedItems
              />
            </Fragment>
          );
        })}
    </>
  );
};
