import { MerchantState } from './merchantTypes';
import { AnyAction, createReducer } from '@reduxjs/toolkit';
import {
  cleanMerchants,
  getMerchants,
  getMerchantsFail,
  getMerchantsSuccess,
  setCurrentMerchantReference,
} from './merchantActions';

export const initialState: MerchantState = {
  loading: false,
  error: null,
  data: {
    defaultMerchantReference: '',
    merchants: [],
  },
  currentMerchantReference: undefined,
};

export const merchantsReducer = createReducer(initialState, builder =>
  builder
    .addCase(getMerchants, state => ({
      ...state,
      loading: true,
      error: null,
    }))
    .addCase(getMerchantsSuccess, (state, action: AnyAction) => ({
      ...state,
      loading: false,
      error: null,
      data: action.payload.data,
    }))
    .addCase(getMerchantsFail, (state, action: AnyAction) => ({
      ...state,
      loading: false,
      error: action.error.response,
      data: initialState.data,
      currentMerchantReference: initialState.currentMerchantReference,
    }))
    .addCase(cleanMerchants, () => initialState)
    .addCase(setCurrentMerchantReference, (state, action) => ({
      ...state,
      currentMerchantReference: action.payload,
    }))
);
