import { createContext, useState, ReactNode } from 'react';
import { Confirmation } from './Confirmation';
import { ConfirmationContextType, ConfirmationType } from './confirmationTypes';

import i18n from 'i18next';
import { OfferSentModal } from '../../../../features/onboardingV2/screens/sales/OfferSentModal';

interface Props {
  children: ReactNode;
}

const defaultConfig: ConfirmationContextType = {
  title: '',
  description: '',
  cancelButtonLabel: i18n.t('common.no'),
  cancelButtonVariant: 'secondary',
  acceptButtonLabel: i18n.t('common.yes'),
  acceptButtonVariant: 'primary',
  actionCallback: isOpen => isOpen,
  type: 'DEFAULT',
  content: <></>,
};

type ContextType = undefined | ((config: ConfirmationContextType) => void);

export const ConfirmationContext = createContext<ContextType>(undefined);

export const ConfirmationProvider = ({ children }: Props) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [config, setConfig] = useState<ConfirmationContextType>(defaultConfig);

  const openConfirmationDialog = (config: ConfirmationContextType) => {
    setConfig(config);
    setIsOpen(true);
  };

  const reset = () => {
    setIsOpen(false);
    setConfig(defaultConfig);
  };

  const onConfirm = () => {
    reset();
    config.actionCallback(true);
  };

  const onDismiss = () => {
    reset();
    config.actionCallback(false);
  };

  const renderSwitch = (type?: ConfirmationType | null, children?: ReactNode) => {
    switch (type) {
      case 'OFFER_SENT':
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        return <OfferSentModal isOpen={isOpen} acceptCallback={() => {}} cancelCallback={() => {}} />;
      default:
        return (
          <Confirmation
            isOpen={isOpen}
            title={config.title}
            description={config.description}
            acceptButtonLabel={config.acceptButtonLabel}
            acceptButtonVariant={config.acceptButtonVariant}
            cancelButtonLabel={config.cancelButtonLabel}
            cancelButtonVariant={config.cancelButtonVariant}
            cancelButtonPresent={config.cancelButtonPresent}
            acceptCallback={onConfirm}
            cancelCallback={onDismiss}
            content={children}
          />
        );
    }
  };

  return (
    <ConfirmationContext.Provider value={openConfirmationDialog}>
      {children}
      {renderSwitch(config.type, config.content)}
    </ConfirmationContext.Provider>
  );
};
