import { Heading, Text } from '@loomispay/vault';
import { DatePicker } from 'common/components/forms/DatePicker';
import { Radio, RadioGroup } from 'common/components/forms/Radio/Radio';
import { setContractStartDate } from 'features/onboardingV2/store/sales/actions';
import { Offer } from 'features/onboardingV2/store/types';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import {
  OfferBorderedBoxRow,
  OfferSummarySectionBox,
  OfferStyledBox,
  OfferStyledBoxWrapper,
} from '../../OfferOverview.styles';

type ContractStartDateForm = {
  startDateType: ContractStartDateOption;
  startDate: string;
};

export const offerStartDateOptions = {
  FIXED_DATE: 'FIXED_DATE',
  ASAP: 'ASAP',
};

type ContractStartDateOption = keyof typeof offerStartDateOptions;

export default function OfferOverviewStartDateForm({ offer, startDate }: { offer: Offer; startDate?: string }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { watch, control, errors, setValue } = useForm<ContractStartDateForm>({
    mode: 'onChange',
  });
  const selectedStartDateType = watch('startDateType') as ContractStartDateOption;

  useEffect(() => {
    if (selectedStartDateType === 'ASAP') {
      dispatch(setContractStartDate(''));
    }
  }, [selectedStartDateType, dispatch]);

  return (
    <section>
      <Heading size="xxs" sansSerif>
        {t('onboarding.v2.overview.contractTerms.startDate')}
      </Heading>
      <OfferSummarySectionBox>
        {offer.ref ? (
          <OfferBorderedBoxRow>
            <Text size="s">{t('onboarding.v2.overview.contractTerms.startDate')}</Text>
            <Text size="s">
              {startDate ? startDate : t('onboarding.v2.overview.contractTerms.startDate.whenContractIsSigned')}
            </Text>
          </OfferBorderedBoxRow>
        ) : (
          <RadioGroup
            name="startDateType"
            required
            defaultValue={startDate ? offerStartDateOptions.FIXED_DATE : offerStartDateOptions.ASAP}
            control={control}
            errors={errors}
          >
            <OfferStyledBox>
              <Radio value={offerStartDateOptions.ASAP} testId={offerStartDateOptions.ASAP} />
              <Heading noGutter size="s">
                {t('onboarding.v2.overview.contractTerms.startDate.asSoonAsPossible')}
              </Heading>
            </OfferStyledBox>
            <OfferStyledBox>
              <Radio value={offerStartDateOptions.FIXED_DATE} testId={offerStartDateOptions.FIXED_DATE} />
              <div>
                <Heading noGutter size="s">
                  {t('onboarding.v2.overview.contractTerms.startDate.specificDate')}
                </Heading>
                {selectedStartDateType === 'FIXED_DATE' && (
                  <OfferStyledBoxWrapper>
                    <DatePicker
                      name="startDate"
                      format="yyyy-MM-dd"
                      label={t('onboarding.v2.overview.contractTerms.startDate.label')}
                      size="sm"
                      minDate={new Date()}
                      selected={startDate}
                      control={control}
                      errors={errors}
                      required
                      onChange={(date: Date) => {
                        setValue('startDate', date.toLocaleDateString('sv-SE'));
                        dispatch(setContractStartDate(date.toLocaleDateString('sv-SE')));
                      }}
                      testId="startDate"
                    />
                  </OfferStyledBoxWrapper>
                )}
              </div>
            </OfferStyledBox>
          </RadioGroup>
        )}
      </OfferSummarySectionBox>
    </section>
  );
}
