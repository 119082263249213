import { ReactNode } from 'react';
import styled from 'styled-components/macro';

type ChildProps = {
  children: ReactNode;
  testId?: string;
  disabled?: boolean;
};

type ColumnProps = ChildProps & {
  justify?: string;
};

const StyledBox = styled.div<{ disabled?: boolean }>`
  border: 1px solid ${({ theme }) => theme.color.border.disabledBorder};
  border-radius: 8px;
  padding: ${({ theme }) => theme.spacings[2]};
  ${({ disabled, theme }) => disabled && `background: ${theme.color.base.opacityBlack[2]}; opacity: .3;`};
`;

const StyledRow = styled.div`
  display: flex;
  justify-content: space-between;

  &:not(:last-child) {
    margin-bottom: ${({ theme }) => theme.spacings[0.5]};
  }
`;

const StyledColumn = styled.div<{ justify?: string }>`
  flex: 0 1 50%;

  display: flex;
  justify-content: ${({ justify }) => justify || 'space-between'};

  ${({ justify }) => {
    if (justify === 'flex-end') {
      return `
        text-align: right;
      `;
    }
  }}
`;

export const BorderedBox = ({ children, testId, disabled }: ChildProps) => (
  <StyledBox data-testid={testId} disabled={disabled}>
    {children}
  </StyledBox>
);
export const BorderedBoxRow = ({ children }: ChildProps) => <StyledRow>{children}</StyledRow>;
export const BorderedBoxColumn = ({ children, justify }: ColumnProps) => (
  <StyledColumn justify={justify}>{children}</StyledColumn>
);

BorderedBox.Row = BorderedBoxRow;
BorderedBox.Column = BorderedBoxColumn;
