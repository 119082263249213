import { RouteConfig } from 'common/route/createFeatureRouter';
import { OfferType } from 'features/onboardingV2/store/types';
import { onboardingRoutes } from '../../OnboardingRouter';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
//TODO: why no single param?
export const merchantOnboardingV2_2Flow = ({ offerType }: { offerType?: OfferType }): RouteConfig[] => [
  onboardingRoutes.merchantOfferOverview,
  onboardingRoutes.welcomeScreen,
  onboardingRoutes.locationsMerchant,
  onboardingRoutes.ownersV2,
  onboardingRoutes.signingCombinations,
  onboardingRoutes.bankInformation,
  ...(offerType !== 'CASH' ? [onboardingRoutes.shippingInformationV2] : []),
  onboardingRoutes.additionalInformation,
  ...(offerType !== 'CASH' ? [onboardingRoutes.additionalQuestions] : []),
];

//TODO: use it in the future instead of pagesMapping and path comparing

// type FlowEntityType = {
//   step: number;
//   route: RouteConfig;
//   disabled?: boolean;
// };
//
// const StepKeyName = [
//   'overview',
//   'welcome',
//   'locations',
//   'owners',
//   'signingCombinations',
//   'bankInformation',
//   'shippingInformationV2',
//   'additionalInformation',
//   'additionalQuestions',
// ] as const;
// export const merchantOnboardingV2_2FlowUpdated = (
//   offerType?: OfferType
// ): { [key in typeof StepKeyName[number]]: FlowEntityType } => ({
//   overview: {
//     step: 1,
//     route: onboardingRoutes.merchantOfferOverview,
//   },
//   welcome: {
//     step: 2,
//     route: onboardingRoutes.welcomeScreen,
//   },
//   locations: {
//     step: 3,
//     route: onboardingRoutes.locationsMerchant,
//   },
//   owners: {
//     step: 4,
//     route: onboardingRoutes.ownersV2,
//   },
//   signingCombinations: {
//     step: 5,
//     route: onboardingRoutes.signingCombinations,
//   },
//   bankInformation: {
//     step: 6,
//     route: onboardingRoutes.bankInformation,
//   },
//   shippingInformationV2: { step: 7, route: onboardingRoutes.shippingInformationV2, disabled: offerType === 'CASH' },
//   additionalInformation: { step: 8, route: onboardingRoutes.additionalInformation },
//   additionalQuestions: { step: 9, route: onboardingRoutes.additionalQuestions, disabled: offerType === 'CASH' },
// });
