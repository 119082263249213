import { useState, useEffect, useCallback } from 'react';
import { useFormContext } from 'react-hook-form';

export function useFormFieldWarning({
  name,
  validate,
}: {
  name: string;
  validate?(value: string): string | undefined;
}): {
  warning: string | undefined;
  triggerWarning(): void;
} {
  const [warning, setWarning] = useState<string>();

  const formContext = useFormContext();
  const { watch, getValues } = formContext || {};

  const triggerWarning = useCallback((): void => {
    const value = getValues && getValues(name);
    const warning = validate ? validate(value) : undefined;
    setWarning(warning);
  }, [getValues, name, validate]);

  const value = watch && watch(name);
  useEffect((): void => {
    setWarning(undefined);
    triggerWarning();
  }, [value, triggerWarning]);

  return {
    warning,
    triggerWarning,
  };
}
