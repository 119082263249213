import { createAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { CountryCode } from 'constants/types';
import {
  CommercialRepresentative,
  CompanyData,
  ContractTerms,
  OfferConfiguration,
  OfferItem,
  Store,
  TransactionFees,
} from 'features/common/types';
import { CashOfferConfiguration } from 'features/onboardingV2/screens/sales/CashServices/types';
import { createPayloadAction } from '../../../../common/store/actions';
import {
  BusinessCategory,
  CompanyDataSearchResult,
  CompanyKeywordOption,
  ContactPersons,
  Offer,
  OfferPartnerData,
  OfferType,
  SignatureProvider,
  TurnoverData,
} from '../types';

const prefix = 'offerOnboardingSales/';

/** Sales Flow */

export const clearSalesOnboarding = createAction(`${prefix}CLEAR_SALES_ONBOARDING`);

export const setCountryCode = createAction<CountryCode | undefined>(`${prefix}SET_COUNTRY_CODE`);

export const fetchCompanyInformation = createAction(
  `${prefix}FETCH_COMPANY_INFORMATION`,
  (companyNumber: string, countryCode: CountryCode) => {
    return {
      payload: {
        client: 'onboardingV2',
        companyNumber,
        countryCode,
        request: {
          method: 'GET',
          url: `/offer/company/${companyNumber}`,
        },
      },
    };
  }
);

export const fetchCompanyInformationSuccess = createAction<AxiosResponse<CompanyDataSearchResult>>(
  `${prefix}FETCH_COMPANY_INFORMATION_SUCCESS`
);

export const fetchCompanyInformationFail = createAction(`${prefix}FETCH_COMPANY_INFORMATION_FAIL`);

export const clearCompanyInformation = createAction(`${prefix}CLEAR_COMPANY_INFORMATION`);

export const fetchOfferConfiguration = createAction(`${prefix}FETCH_OFFER_CONFIGURATION`, () => {
  return {
    payload: {
      client: 'onboardingV2',
      request: {
        method: 'GET',
        url: `/offerConfig`,
      },
    },
  };
});

export const fetchOfferConfigurationSuccess = createAction<AxiosResponse<OfferConfiguration>>(
  `${prefix}FETCH_OFFER_CONFIGURATION_SUCCESS`
);

export const fetchOfferConfigurationFail = createAction(`${prefix}FETCH_OFFER_CONFIGURATION_FAIL`);

export const fetchCashOfferConfiguration = createAction(`${prefix}FETCH_CASH_OFFER_CONFIGURATION`, () => {
  return {
    payload: {
      client: 'onboardingV2',
      request: {
        method: 'GET',
        url: `/offerConfig/cash`,
      },
    },
  };
});

export const fetchCashOfferConfigurationSuccess = createAction<AxiosResponse<CashOfferConfiguration>>(
  `${prefix}FETCH_CASH_OFFER_CONFIGURATION_SUCCESS`
);

export const fetchCashOfferConfigurationFail = createAction(`${prefix}FETCH_CASH_OFFER_CONFIGURATION_FAIL`);

export const updateOfferType = createAction<OfferType | undefined>(`${prefix}UPDATE_OFFER_TYPE`);

export const updateOfferRef = createAction<Offer['ref'] | undefined>(`${prefix}UPDATE_OFFER_REF`);

export const updateBusinessCategory = createAction<BusinessCategory | undefined>(`${prefix}UPDATE_BUSINESS_TYPE`);

export const updateBusinessSubCategory = createAction<CompanyKeywordOption | undefined>(
  `${prefix}UPDATE_BUSINESS_CATEGORY`
);

export const updateTurnoverInformation = createAction<TurnoverData>(`${prefix}UPDATE_TURNOVER_INFORMATION`);

export const upsertTerminal = createPayloadAction<{ offerItem: OfferItem; locationIdx: number }>(
  `${prefix}UPSERT_TERMINAL`
);
export const removeTerminal = createAction<{ terminalRef: string; locationIdx: number }>(`${prefix}REMOVE_TERMINAL`);

export const upsertAddon = createPayloadAction<{ offerItem: OfferItem; locationIdx: number }>(`${prefix}UPSERT_ADDON`);
export const removeAddon = createAction<{ addonRef: string; locationIdx: number }>(`${prefix}REMOVE_ADDON`);

export const postPayOffer = createAction(`${prefix}POST_PAY_OFFER`, (data: Offer) => {
  return {
    payload: {
      client: 'onboardingV2',
      request: {
        method: 'POST',
        url: '/offer/pay',
        data,
      },
    },
  };
});
export const postPayOfferSuccess = createAction<AxiosResponse>(`${prefix}POST_PAY_OFFER_SUCCESS`);
export const postPayOfferFail = createAction(`${prefix}POST_PAY_OFFER_FAIL`);

export const postCashOffer = createAction(`${prefix}POST_CASH_OFFER`, (data: Offer) => {
  return {
    payload: {
      client: 'onboardingV2',
      request: {
        method: 'POST',
        url: '/offer/cash',
        data,
      },
    },
  };
});
export const postCashOfferSuccess = createAction<AxiosResponse>(`${prefix}POST_CASH_OFFER_SUCCESS`);
export const postCashOfferFail = createAction(`${prefix}POST_CASH_OFFER_FAIL`);

export const postPayAndCashOffer = createAction(`${prefix}POST_PAY_AND_CASH_OFFER`, (data: Offer) => {
  return {
    payload: {
      client: 'onboardingV2',
      request: {
        method: 'POST',
        url: '/offer/pay-and-cash',
        data,
      },
    },
  };
});
export const postPayAndCashOfferSuccess = createAction<AxiosResponse>(`${prefix}POST_PAY_AND_CASH_OFFER_SUCCESS`);
export const postPayAndCashOfferFail = createAction(`${prefix}POST_PAY_AND_CASH_OFFER_FAIL`);

export const updateCompanyInfoManually = createAction<CompanyData>(`${prefix}UPDATE_COMPANY_INFORMATION_MANUALLY`);

export const updateCompanyDataIsManuallyAdded = createAction<boolean>(`${prefix}UPDATE_COMPANY_DATA_IS_MANUALLY_ADDED`);
export const updateCompanyDataIsManualFallbackPathAvailable = createAction<boolean>(
  `${prefix}UPDATE_COMPANY_DATA_IS_MANUAL_FALLBACK_PATH_AVAILABLE`
);

export const updatePartnerConfiguration = createPayloadAction<OfferPartnerData>(`${prefix}UPDATE_OFFER_PARTNER`);

export const updateContactPersons = createPayloadAction<ContactPersons>(`${prefix}UPDATE_CONTACT_PERSONS`);

export const updateCommercialRepresentative = createPayloadAction<CommercialRepresentative>(
  `${prefix}UPDATE_COMMERCIAL_REPRESENTATIVE`
);

export const updateSalesSupervisedFlow = createPayloadAction<boolean>(`${prefix}UPDATE_SALES_SUPERVISED_FLOW`);

export const updateSignatureProvider = createAction<SignatureProvider | undefined>(
  `${prefix}UPDATE_SIGNATURE_PROVIDER`
);

export const setContractTerms = createAction<ContractTerms>(`${prefix}SET_CONTRACT_TERMS`);

export const setContractStartDate = createAction<string | undefined>(`${prefix}SET_CONTRACT_START_DATE`);

export const setContractTransactionFees = createAction<TransactionFees>(`${prefix}SET_CONTRACT_TRANSACTION_FEES`);

export const addStore = createAction<Omit<Store, 'storeOfferItems'>>(`${prefix}ADD_STORE`);

export const updateStore = createAction(`${prefix}UPDATE_STORE`, (store: Store, index: number) => {
  return {
    payload: {
      store: store,
      index: index,
    },
  };
});

export const updateMultipleStores = createAction<Store[]>(`${prefix}UPDATE_MULTIPLE_STORES`);

export const removeStore = createAction<number>(`${prefix}REMOVE_STORE`);
export const clearStores = createAction(`${prefix}CLEAR_STORES`);

export const upsertAvailableCountry = createAction<CountryCode>(`${prefix}UPSERT_AVAILABLE_COUNTRY`);

export const updateCommentForLogistics = createPayloadAction<string>(`${prefix}UPDATE_COMMENT_FOR_LOGISTICS`);
