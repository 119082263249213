import { Component } from 'react';
import { MessageType, NotificationBarProps } from './types';
import { NotificationBar } from './NotificationBar';

export class NotificationBarWrapper extends Component<NotificationBarProps> {
  notificationBar: NotificationBar | null;

  constructor(props: NotificationBarProps) {
    super(props);
    this.notificationBar = null;
  }

  show(message: MessageType | MessageType[]) {
    if (this.notificationBar) {
      this.notificationBar.show(message);
    }
  }

  clear() {
    if (this.notificationBar) {
      this.notificationBar.clear();
    }
  }

  render() {
    return <NotificationBar ref={el => (this.notificationBar = el)} {...this.props} />;
  }
}

export const DefaultNotificationBarWrapper = new NotificationBarWrapper({ id: 'default-notification-bar' });
