import { useTranslation } from 'react-i18next';
import { Controller } from 'react-hook-form';
import { useFormFieldWarning } from '../common/useFormFieldWarning';
import { isValidSingleLineText, isValidMultiLineText } from 'utils/validation';
import { TextInputProps } from './types';
import { TextInput as TextInputVault } from '@loomispay/vault';
import useInputErrors from './useInputErrors';
import { useEffect } from 'react';

type VaultInputProps = React.ComponentProps<typeof TextInputVault>;

export const TextInput = ({
  type = 'text',
  placeholder = '',
  disabled = false,
  hintText = '',
  label,
  required = false,
  name,
  defaultValue,
  normalizeValue,
  fieldWarningValidate,
  setValue,
  trimOnBlur,
  errors,
  inputProps = {},
  testId = '',
  textArea = false,
  currency,
  suffix,
  skipSafeTextValidation = false,
  control,
  maskConfig,
  locale,
  localeFormatted,
  onBlur,
  onFocus,
  ...props
}: TextInputProps & Omit<VaultInputProps, 'required' | 'id'>) => {
  const { t } = useTranslation();
  const { warning, triggerWarning } = useFormFieldWarning({
    name,
    validate: fieldWarningValidate,
  });
  const { errorMessage, getErrorMessage } = useInputErrors(errors, name);

  useEffect(() => {
    getErrorMessage();
  }, [errors, getErrorMessage]);

  return (
    <Controller
      name={name}
      defaultValue={defaultValue}
      {...{
        ...props,
        ...(type === 'text' &&
          !skipSafeTextValidation && {
            rules: {
              validate: value => {
                if (textArea && !isValidMultiLineText(value)) {
                  return t('common.form.validationMessages.unsafeText');
                }
                if (!textArea && !isValidSingleLineText(value)) {
                  return t('common.form.validationMessages.unsafeText');
                }
                if (props.rules?.validate instanceof Function) {
                  return props.rules?.validate(value);
                }
              },
              ...props.rules,
            },
          }),
      }}
      control={control}
      render={({ onChange, onBlur: _onBlur, ..._props }) => {
        return (
          <>
            <TextInputVault
              id={name}
              onChange={onChange}
              onBlur={e => {
                if (fieldWarningValidate) {
                  triggerWarning();
                }
                onBlur && onBlur(e);
                _onBlur();
              }}
              onFocus={onFocus}
              normalizeValue={normalizeValue}
              setValue={setValue}
              placeholder={placeholder}
              disabled={disabled}
              hintText={hintText}
              errorText={errorMessage}
              label={label}
              required={required ? t('common.form.required') : undefined}
              testId={testId || name}
              currency={currency}
              suffix={suffix}
              trimOnBlur={trimOnBlur}
              warningText={warning}
              inputProps={inputProps}
              textArea={textArea}
              type={type}
              maskConfig={maskConfig}
              locale={locale}
              localeFormatted={localeFormatted}
              {..._props}
              ref={_props.ref}
            />
          </>
        );
      }}
    />
  );
};
