import { AxiosResponse } from 'axios';

/**
 *
 * Global Utility Types
 *
 * Built in types: https://www.typescriptlang.org/docs/handbook/utility-types.html
 *
 * */

/** TypeScript primitives */
// eslint-disable-next-line @typescript-eslint/ban-types
export type Primitive = undefined | null | boolean | string | number | bigint | symbol | Function;

/** Remove types from T that are assignable to U (a set minus) */
export type Diff<T, U> = T extends U ? never : T;

/** Remove types from T that are not assignable to U (a set intersection) */
export type Filter<T, U> = T extends U ? T : never;

/** A recursive version of `Partial<T>` */
export type DeepPartial<T> = T extends Primitive ? T : { [P in keyof T]?: DeepPartial<T[P]> };

/** A recursive version of `Required<T>` */
export type DeepRequired<T> = T extends Primitive
  ? NonNullable<T>
  : {
      [P in keyof T]-?: DeepRequired<T[P]>;
    };

/**
 * Casts object values to `string`.
 * `react-hook-form` takes values from browser DOM, which are strings.
 * Working with string types gets easier.
 * */
export type FormVersion<T> = {
  [P in keyof Required<T>]: string;
};

/**
 *
 * Global MerchantPortal Types
 *
 * */

export type Variant = 'primary' | 'secondary' | 'tertiary' | 'inverted' | 'disabled';
export type Size = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl' | 'xxxl';

export type DropDownOption<T> = {
  label: string;
  value: T;
};

export enum PriceType {
  Fixed = 'FIXED',
  Variable = 'VARIABLE',
  Weight = 'WEIGHT',
  Other = 'OTHER',
}

export interface PortalError {
  code: string;
  message: string;
}

export type MerchantPortalError = AxiosResponse<PortalError>;

export enum ScreenMode {
  edit = 'edit',
  add = 'add',
}

export type ErrorProps = {
  title: string;
  description: string;
  errorCode?: string;
  email?: string;
  emailLabel?: string;
  phone?: string;
  phoneLabel?: string;
};

export type ErrorRouteParams = {
  errorCode: string;
};

export interface Pagination {
  pageNumber: number;
  nextPageNumber: number;
  pageSize: number;
  totalPages: number;
  totalElements: number;
  numberOfElements: number;
  first: boolean;
  last: boolean;
  empty: boolean;
}

export interface Sort {
  sorted: boolean;
  unsorted: boolean;
  empty: boolean;
}

export interface Pageable {
  sort: Sort;
  pageSize: number;
  pageNumber: number;
  offset: number;
  paged: boolean;
  unpaged: boolean;
}

export interface PageableListResponse<D> {
  content: D;
  pageable: Pageable;
  last: boolean;
  totalPages: number;
  totalElements: number;
  sort: Sort;
  first: boolean;
  number: number;
  numberOfElements: number;
  size: number;
  empty: false;
}

export const supportedCountryCodes = ['DK', 'SE', 'ES', 'NO'] as const;
export type CountryCode = typeof supportedCountryCodes[number];

export type FlowName = 'onboarding' | 'sales' | null;

export type Address = {
  address?: string;
  zipCode?: string;
  city?: string;
  countryCode?: CountryCode;
};

export type CurrencyCode = 'DKK' | 'SEK' | 'EUR' | 'NOK';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const mapToPagination = (response: PageableListResponse<any>) => ({
  pageNumber: response.number,
  nextPageNumber: !response.last ? response.number + 1 : -1,
  pageSize: response.size,
  totalPages: response.totalPages,
  totalElements: response.totalElements,
  numberOfElements: response.numberOfElements,
  first: response.first,
  last: response.last,
  empty: response.empty,
});
