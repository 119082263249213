import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { userService } from 'services/userService';
import { useDispatch } from 'react-redux';
import { salesBasePath } from '../features/onboardingV2/SalesRouter';

const RootRouter = () => {
  const dispatch = useDispatch();
  const [redirectTo, setRedirectTo] = useState<string>();
  const navigate = useNavigate();

  useEffect(() => {
    async function assignRedirectRoute() {
      if (await userService.isSalesRepLoggedIn()) {
        setRedirectTo(salesBasePath);
      } else {
        // User was authenticated, but doesn't have any roles. Either misconfiguration in Cognito or Amplify.
        console.error(`User '${userService.getUserData()?.email}' has no corresponding role assigned`);
        setRedirectTo('/logout');
      }
    }

    assignRedirectRoute();
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [dispatch]);

  if (redirectTo) navigate(redirectTo);

  return <></>;
};
export default RootRouter;
