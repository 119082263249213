import { Button, Display, Heading } from '@loomispay/vault';
import { TextInput } from 'common/components/forms/TextInput/TextInput';
import { useDocumentTitle } from 'hooks';
import SingleColumn from 'layout/SingleColumn';
import { Control, FieldErrors, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components/macro';
import { formatPhoneNumber } from 'utils/format';
import { isPersonalId, isValidPhoneNumber, patterns } from 'utils/validation';
import Hr from '../../../../../common/components/Hr';
import OfferOverviewLoomisPay from '../../../components/OfferOverview/LoomisPay/OfferOverviewLoomisPay';
import {
  postPayOffer,
  postPayAndCashOffer,
  updateCommercialRepresentative,
  updateContactPersons,
  updateSalesSupervisedFlow,
  postCashOffer,
  postCashOfferSuccess,
  postPayAndCashOfferSuccess,
  postPayOfferSuccess,
  updateOfferRef,
  updateCommentForLogistics,
} from '../../../store/sales/actions';
import { getCountryCode, getOffer } from '../../../store/sales/selectors';
import { ContactPersons, SignatureProvider } from '../../../store/types';
import { ContactPersonRow } from './OverviewSales.styles';
import { useSalesFlowNavigation } from '../salesFlowRoutingService';
import OfferOverviewCash from 'features/onboardingV2/components/OfferOverview/CashServices/OfferOverviewCash';
import { CommercialRepresentative, Consents } from 'features/common/types';
import { CountryCode } from 'constants/types';
import { useNavigate } from 'react-router-dom';
import useCashServices from '../CashServices/useCashServices';
import CashConsents from '../../../components/OfferOverview/CashServices/consents/CashConsents';
import OfferOverviewCommon from '../../../components/OfferOverview/Common/OfferOverviewCommon';
import FlowSupervisionSelection from '../../../components/OfferOverview/Common/sections/FlowSupervisionSelection';
import { useConfirmation } from '../../../../../common/components/Modal';
import ContactDetailsVerification from './ContactDetailsVerification';
import { notifications } from '../../../../../common/components/Notification/notifications';
import { userService } from '../../../../../services/userService';
import SignatureProviderSelection from '../../../components/OfferOverview/Common/sections/SignatureProviderSelection';
import CommentForLogistics from '../../../components/OfferOverview/Common/sections/CommentForLogistics';
import Container from '../../../../../common/components/MainLayout/Container';

const ErrorText = styled.div`
  margin-bottom: ${({ theme }) => theme.spacings[2]};
`;

type OfferOverviewFormFields = {
  consents: Consents;
  loomisCommercialRepresentative: CommercialRepresentative;
  salesSupervisedFlow: boolean;
  signatureProvider: SignatureProvider;
  commentForLogistics: string;
} & ContactPersons;

const OverviewSales = () => {
  const { nextPage, prevPage } = useSalesFlowNavigation();
  const country: CountryCode | undefined = useSelector(getCountryCode);

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useDocumentTitle(t('onboarding.v2.overview.title'));
  const { cashOfferConfig } = useCashServices();

  const { handleSubmit, watch, setValue, control, errors } = useForm<OfferOverviewFormFields>({
    mode: 'onBlur',
  });

  const offer = useSelector(getOffer);
  const { contactPerson, loomisContactPerson, loomisCommercialRepresentative } = offer;

  const offerContainsCashServices = !!offer.stores.find(store => store.isCashEnabled);
  const isCashOnlyOffer = offer.offerType === 'CASH';

  const checkIfSalesSupervisedFlow = () => {
    return watch(`salesSupervisedFlow`, isCashOnlyOffer);
  };

  const { getConfirmation } = useConfirmation();

  const confirmContactDetails = async (formData: OfferOverviewFormFields) => {
    const salesContactData = checkIfSalesSupervisedFlow();
    const contactPerson = salesContactData ? formData.loomisContactPerson : formData.contactPerson;

    const title = salesContactData
      ? t('onboarding.v2.overview.confirmation.salesContactDetailsTitle')
      : t('onboarding.v2.overview.confirmation.merchantContactDetailsTitle');

    const description = salesContactData
      ? t('onboarding.v2.overview.confirmation.salesContactDetailsDescription')
      : t('onboarding.v2.overview.confirmation.merchantContactDetailsDescription');

    const acceptButtonLabel = t('onboarding.v2.overview.confirmation.sendOfferButton');
    const cancelButtonLabel = t('common.edit');
    const name = salesContactData ? 'loomisContactPerson' : 'contactPersons';

    return await getConfirmation({
      title: title,
      description: description,
      acceptButtonLabel: acceptButtonLabel,
      cancelButtonLabel: cancelButtonLabel,
      content: (
        <ContactDetailsVerification
          phoneNumber={contactPerson?.phoneNumber}
          email={contactPerson?.emailAddress}
          name={name}
          control={control}
          errors={errors}
        />
      ),
    });
  };

  const onSubmit = async (formData: OfferOverviewFormFields) => {
    const confirm = await confirmContactDetails(formData);
    if (!confirm) {
      return;
    }

    const normalizedData = {
      ...formData,
      loomisContactPerson: {
        ...formData.loomisContactPerson,
        phoneNumber: formatPhoneNumber(formData.loomisContactPerson.phoneNumber || ''),
      },
      contactPerson: {
        ...formData.contactPerson,
        phoneNumber: formatPhoneNumber(formData.contactPerson.phoneNumber || ''),
      },
      commentForLogistics:
        formData.commentForLogistics && formData.commentForLogistics.length > 0
          ? formData.commentForLogistics
          : undefined,
    };
    dispatch(
      updateContactPersons({
        contactPerson: normalizedData.contactPerson,
        loomisContactPerson: normalizedData.loomisContactPerson,
      })
    );
    const signatureProvider = formData.signatureProvider;

    let postDataDispatched;

    switch (offer.offerType) {
      case 'CASH':
        postDataDispatched = (dispatch(
          postCashOffer({
            ...offer,
            ...normalizedData,
            signatureProvider,
          })
        ) as unknown) as Promise<ReturnType<typeof postCashOfferSuccess>>;
        break;
      case 'PAY_AND_CASH':
        if (offerContainsCashServices) {
          postDataDispatched = (dispatch(
            postPayAndCashOffer({
              ...offer,
              ...normalizedData,
              signatureProvider,
            })
          ) as unknown) as Promise<ReturnType<typeof postPayAndCashOfferSuccess>>;
        } else {
          postDataDispatched = (dispatch(
            postPayOffer({
              ...offer,
              ...normalizedData,
              signatureProvider,
              // override PAY_AND_CASH offerType if sales rep didn't activate cash services
              offerType: 'PAY',
            })
          ) as unknown) as Promise<ReturnType<typeof postPayOfferSuccess>>;
        }
        break;
      case 'PAY':
      default:
        postDataDispatched = (dispatch(
          postPayOffer({
            ...offer,
            ...normalizedData,
            signatureProvider,
          })
        ) as unknown) as Promise<ReturnType<typeof postPayOfferSuccess>>;
    }

    postDataDispatched
      .then(({ payload: { data } }) => {
        if (data.ref) {
          dispatch(updateOfferRef(data.ref));
        }
        navigate(nextPage());
      })
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .catch((err: any) => {
        const errorResponse = err?.error?.response;
        // TODO: Map error codes through translations
        let messages: string[] = [t('common.serverError')];
        if (errorResponse?.status === 400) {
          const validationErrors: Record<string, string[]> = errorResponse?.data?.errors || [];
          const generalValidationErrors: string[] = errorResponse?.data?.generalErrors || [];
          messages = Object.values(validationErrors).reduce((prev: string[], curr: string[]) => {
            return prev.concat(curr);
          }, []);
          messages = [...messages, ...generalValidationErrors];
        }
        notifications.error(
          <>
            {messages.map((message, i) => (
              <ErrorText key={i}>{message}</ErrorText>
            ))}
          </>
        );
      });

    return;
  };

  const onBackButton = () => {
    const formData: OfferOverviewFormFields = control.getValues();
    const normalizedData = {
      ...formData,
      loomisContactPerson: {
        ...formData.loomisContactPerson,
      },
      contactPerson: {
        ...formData.contactPerson,
      },
    };
    dispatch(
      updateContactPersons({
        contactPerson: normalizedData.contactPerson,
        loomisContactPerson: normalizedData.loomisContactPerson,
      })
    );
    dispatch(updateCommercialRepresentative(formData.loomisCommercialRepresentative));
    dispatch(updateSalesSupervisedFlow(formData.salesSupervisedFlow));
    dispatch(updateCommentForLogistics(formData.commentForLogistics));
    navigate(prevPage());
  };

  return (
    <SingleColumn
      footer={
        <>
          <Button
            onClick={onBackButton}
            variant="tertiary"
            label={t('common.backButton')}
            icon="arrowLeft"
            iconPosition="left"
          />
          <Button
            form="overview-form"
            type="submit"
            label={t('onboarding.v2.overview.sendOfferButton')}
            testId="overviewSales_submitButton"
          />
        </>
      }
    >
      <Display size="s">{t('onboarding.v2.overview.title')}</Display>

      <OfferOverviewCommon offer={offer} />

      {!isCashOnlyOffer && (
        <OfferOverviewWrapper>
          <OfferOverviewLoomisPay offer={offer} />
        </OfferOverviewWrapper>
      )}

      <Hr />
      <FlowSupervisionSelection control={control} errors={errors} />
      <SignatureProviderSelection control={control} errors={errors} />
      <Hr />
      <CommentForLogisticsDecorator control={control} errors={errors} />
      <Hr />

      <form id="overview-form" onSubmit={handleSubmit(onSubmit)}>
        <section>
          <Heading sansSerif size="xxs">
            {t('onboarding.v2.overview.contactPersonMerchant')}
          </Heading>
          <ContactPersonRow>
            <TextInput
              testId="merchant-contact-person-first-name"
              label={t('onboarding.v2.overview.contactPerson.firstName')}
              name="contactPerson.firstName"
              setValue={(value?: string) => setValue('contactPerson.firstName', value)}
              rules={{ required: t('common.form.validationMessages.required') }}
              defaultValue={contactPerson?.firstName || ''}
              control={control}
              errors={errors}
              trimOnBlur
            />
            <TextInput
              testId="merchant-contact-person-last-name"
              label={t('onboarding.v2.overview.contactPerson.lastName')}
              name="contactPerson.lastName"
              setValue={(value?: string) => setValue('contactPerson.lastName', value)}
              rules={{ required: t('common.form.validationMessages.required') }}
              defaultValue={contactPerson?.lastName || ''}
              control={control}
              errors={errors}
              trimOnBlur
            />
          </ContactPersonRow>
          <ContactPersonRow>
            <TextInput
              testId="merchant-contact-person-email"
              label={t('onboarding.v2.overview.contactPerson.email')}
              name="contactPerson.emailAddress"
              type="email"
              setValue={(value?: string) => setValue('contactPerson.emailAddress', value)}
              rules={{
                required: t('common.form.validationMessages.required'),
                pattern: {
                  value: patterns.email,
                  message: t('common.form.validationMessages.invalidEmail'),
                },
              }}
              defaultValue={contactPerson?.emailAddress || ''}
              control={control}
              errors={errors}
              hintText={!checkIfSalesSupervisedFlow() ? t('onboarding.v2.overview.sentLinkDisclaimer') : ''}
              trimOnBlur
            />
            <TextInput
              testId="merchant-contact-person-phone"
              label={t('onboarding.v2.overview.contactPerson.phone')}
              name="contactPerson.phoneNumber"
              setValue={(value?: string) => setValue('contactPerson.phoneNumber', value, { shouldDirty: true })}
              rules={{
                required: t('common.form.validationMessages.required'),
                validate: (value: string) =>
                  isValidPhoneNumber(value) || t('common.form.validationMessages.invalidPhoneNumber'),
              }}
              defaultValue={contactPerson?.phoneNumber || ''}
              hintText={!checkIfSalesSupervisedFlow() ? t('onboarding.v2.overview.sentSMSDisclaimer') : ''}
              control={control}
              errors={errors}
              trimOnBlur
            />
          </ContactPersonRow>
        </section>

        <Hr />

        <section>
          <Heading sansSerif size="xxs">
            {t('onboarding.v2.overview.contactPersonLoomis')}
          </Heading>
          <ContactPersonRow>
            <TextInput
              testId="loomis-contact-person-first-name"
              label={t('onboarding.v2.overview.contactPerson.firstName')}
              name="loomisContactPerson.firstName"
              setValue={(value?: string) => setValue('loomisContactPerson.firstName', value)}
              rules={{ required: t('common.form.validationMessages.required') }}
              defaultValue={loomisContactPerson?.firstName || userService.getUserData()?.firstName || ''}
              control={control}
              errors={errors}
              trimOnBlur
            />
            <TextInput
              testId="loomis-contact-person-last-name"
              label={t('onboarding.v2.overview.contactPerson.lastName')}
              name="loomisContactPerson.lastName"
              setValue={(value?: string) => setValue('loomisContactPerson.lastName', value)}
              rules={{ required: t('common.form.validationMessages.required') }}
              defaultValue={loomisContactPerson?.lastName || userService.getUserData()?.lastName || ''}
              control={control}
              errors={errors}
              trimOnBlur
            />
          </ContactPersonRow>
          <ContactPersonRow>
            <TextInput
              testId="loomis-contact-person-email"
              label={t('onboarding.v2.overview.contactPerson.email')}
              name="loomisContactPerson.emailAddress"
              type="email"
              setValue={(value?: string) => setValue('loomisContactPerson.emailAddress', value)}
              rules={{
                required: t('common.form.validationMessages.required'),
                pattern: {
                  value: patterns.email,
                  message: t('common.form.validationMessages.invalidEmail'),
                },
              }}
              defaultValue={loomisContactPerson?.emailAddress || userService.getUserData()?.email || ''}
              hintText={
                checkIfSalesSupervisedFlow() ? t('onboarding.v2.overview.loomisContactPerson.sentLinkDisclaimer') : ''
              }
              control={control}
              errors={errors}
              trimOnBlur
            />
            <TextInput
              testId="loomis-contact-person-phone"
              label={t('onboarding.v2.overview.contactPerson.phone')}
              name="loomisContactPerson.phoneNumber"
              setValue={(value?: string) => setValue('loomisContactPerson.phoneNumber', value)}
              rules={{
                required: t('common.form.validationMessages.required'),
                validate: (value: string) =>
                  isValidPhoneNumber(value) || t('common.form.validationMessages.invalidPhoneNumber'),
              }}
              defaultValue={loomisContactPerson?.phoneNumber || ''}
              hintText={
                checkIfSalesSupervisedFlow() ? t('onboarding.v2.overview.loomisContactPerson.sentSMSDisclaimer') : ''
              }
              control={control}
              errors={errors}
              trimOnBlur
            />
          </ContactPersonRow>
        </section>

        {offerContainsCashServices && (
          <>
            <Hr />
            <section>
              <OfferOverviewCash offer={offer} />

              <Hr />
              <CashConsents control={control} errors={errors} />
              <Hr />

              <Heading size="xxs" sansSerif>
                {t('cashServices.form.pointOfContactLoomis')}
              </Heading>

              <Grid>
                <TextInput
                  testId="loomis-commercial-representative-fullname"
                  label={t('cashServices.form.commercialRepresentative')}
                  name="loomisCommercialRepresentative.fullName"
                  rules={{ required: t('common.form.validationMessages.required') }}
                  defaultValue={
                    loomisCommercialRepresentative?.fullName ||
                    cashOfferConfig?.loomisCommercialRepresentative?.fullName ||
                    ''
                  }
                  control={control}
                  errors={errors}
                  trimOnBlur
                />
                <TextInput
                  testId="loomis-commercial-representative-id"
                  label={t('cashServices.form.commercialRepresentativeIdNumber')}
                  name="loomisCommercialRepresentative.idNumber"
                  rules={{
                    required: t('common.form.validationMessages.required'),
                    validate: (value: string) =>
                      isPersonalId(country, value) || t('common.form.validationMessages.invalidPersonalId'),
                  }}
                  defaultValue={
                    loomisCommercialRepresentative?.idNumber ||
                    cashOfferConfig?.loomisCommercialRepresentative?.idNumber ||
                    ''
                  }
                  control={control}
                  errors={errors}
                  trimOnBlur
                />
              </Grid>
            </section>
          </>
        )}
      </form>
    </SingleColumn>
  );
};

const Grid = styled.div`
  display: flex;
  grid-template-columns: 1fr 1fr;
  gap: ${({ theme }) => theme.spacings[2]};
`;

const OfferOverviewWrapper = styled.div`
  padding-top: ${({ theme }) => theme.spacings['3']};
`;

const CommentForLogisticsDecorator = ({ control, errors }: { control: Control; errors: FieldErrors }) => {
  return (
    <Container spacing="2">
      <CommentForLogistics control={control} errors={errors} />
    </Container>
  );
};

export default OverviewSales;
