import { AnyAction, createReducer } from '@reduxjs/toolkit';
import deepmerge from 'deepmerge';
import { MerchantOnboardingData, OfferConfiguration, OfferContactData } from 'features/common/types';
import { Offer, OnboardingState } from '../types';
import {
  addManualSigner,
  clearOfferOnboardingData,
  clearOnboardingState,
  deleteDocument,
  deleteDocumentFail,
  deleteDocumentSuccess,
  deleteGlobalDocument,
  deleteGlobalDocumentFail,
  deleteGlobalDocumentSuccess,
  editManualSignerName,
  fetchEddQuestions,
  fetchEddQuestionsFail,
  fetchEddQuestionsSuccess,
  fetchMerchantOffer,
  fetchMerchantOfferFail,
  fetchMerchantOfferSuccess,
  fetchOffer,
  fetchOfferConfiguration,
  fetchOfferConfigurationFail,
  fetchOfferConfigurationSuccess,
  fetchOfferContactData,
  fetchOfferContactDataFail,
  fetchOfferContactDataSuccess,
  fetchOfferFail,
  fetchOfferSuccess,
  fetchOwners,
  fetchOwnersFail,
  fetchOwnersSuccess,
  fetchSigningCombinations,
  fetchSigningCombinationsFail,
  fetchSigningCombinationsSuccess,
  getOfferOnboardingData,
  getOfferOnboardingDataFail,
  getOfferOnboardingDataSuccess,
  postDocument,
  postDocumentFail,
  postDocumentSuccess,
  postEddQuestions,
  postEddQuestionsFail,
  postEddQuestionsSuccess,
  postGlobalDocument,
  postGlobalDocumentFail,
  postGlobalDocumentSuccess,
  removeOwner,
  removeSignerByFullName,
  savePartial,
  savePartialFail,
  savePartialSuccess,
  setCountryCode,
  setManualSigners,
  setOfferRef,
  setPage,
  setSigners,
  setSigningCombinations,
  submitOnboardingPayAndCashOfferData,
  submitOnboardingPayAndCashOfferDataFail,
  submitOnboardingPayAndCashOfferDataSuccess,
  submitOnboardingPayOfferData,
  submitOnboardingPayOfferDataFail,
  submitOnboardingPayOfferDataSuccess,
  updateBankInformation,
  updateInvoiceInformation,
  updateLocation,
  updateNotaryDetails,
  updateRepresentatives,
  updateShippingInformationV2,
  upsertOwner,
  upsertOwnerV22,
} from './actions';

export const emptyOffer: Offer = {
  companyData: {
    address: {},
  },
  turnoverData: {},
  stores: [],
  contractTerms: {
    durationInMonths: 0,
    terminalsFreeMonths: 0,
  },
};

export const emptyOfferContactData: OfferContactData = {
  phoneNumber: '',
  loomisContactPerson: {
    emailAddress: '',
  },
};

export const emptyOfferConfiguration: OfferConfiguration = {
  contractDurationOptions: [],
  subscriptionMonthsNumber: 0,
  transactionFeeOptions: [],
  transactionFeeConfig: {
    countryCode: '',
    defaultFee: {
      ref: '',
      transactionFeeRange: {
        defaultPercentage: 0,
        minPercentage: 0,
        maxPercentage: 0,
      },
    },
    rules: [],
  },
  terminalOptions: [],
  addonOptions: [],
  signatureProviderConfig: {
    defaultProvider: undefined,
    providers: [],
  },
};

export const emptyOnboardingData: MerchantOnboardingData = {
  bankInformation: {},
  status: 'NOT_STARTED',
  invoiceInformation: { email: '' },
  shippingInformationV2: { shippingAddresses: [] },
  owners: [],
  isOwnersModified: false,
  signatories: [],
  offerOnboardingGlobalDocuments: [],
  stores: [],
};

export const initialState: OnboardingState = {
  offer: emptyOffer,
  offerConfiguration: emptyOfferConfiguration,
  signingCombinations: {
    isLoading: true,
    error: null,
    signers: [],
  },
  manualSigners: [],
  onboardingData: emptyOnboardingData,
  additionalQuestions: [],
  onboardingStatus: 'NOT_STARTED',
  verificationStatus: null,
  countryCode: null,
  isLoading: false,
  error: null,
  offerContactData: emptyOfferContactData,
};

const unknownError = {
  message: 'unknown error',
  error: 'UNKNOWN_ERROR',
};

export const onboardingV2MerchantReducer = createReducer(initialState, builder =>
  builder
    .addCase(setCountryCode, (state, action) => {
      state.countryCode = action.payload;
      return state;
    })
    .addCase(fetchOfferConfiguration, state => {
      state.isLoading = true;
      state.error = null;
      return state;
    })
    .addCase(fetchOfferConfigurationSuccess, (state, action) => {
      state.isLoading = false;
      state.error = null;
      state.offerConfiguration = action.payload.data;
      return state;
    })
    .addCase(fetchOfferConfigurationFail, (state, action: AnyAction) => {
      state.isLoading = false;
      state.error = action?.error?.response?.data || unknownError;
    })
    .addCase(fetchOffer, state => {
      state.isLoading = true;
      state.error = null;
      return state;
    })
    .addCase(fetchOfferSuccess, (state, action) => {
      state.offer = action.payload.data;
      state.isLoading = false;
      state.error = null;
      return state;
    })
    .addCase(fetchOfferFail, (state, action: AnyAction) => {
      state.offer = emptyOffer;
      state.isLoading = false;
      state.error = action?.error?.response?.data || unknownError;
    })
    .addCase(fetchOfferContactData, state => {
      state.isLoading = true;
      state.error = null;
      return state;
    })
    .addCase(fetchOfferContactDataSuccess, (state, action) => {
      state.offerContactData = action.payload.data;
      state.isLoading = false;
      state.error = null;
      return state;
    })
    .addCase(fetchOfferContactDataFail, (state, action: AnyAction) => {
      state.offer = emptyOffer;
      state.isLoading = false;
      state.error = action?.error?.response?.data || unknownError;
    })
    .addCase(fetchMerchantOffer, state => {
      state.offer = emptyOffer;
      state.isLoading = true;
      state.error = null;
      return state;
    })
    .addCase(fetchMerchantOfferSuccess, (state, action) => {
      state.offer = action.payload.data;
      state.isLoading = false;
      state.error = null;
      return state;
    })
    .addCase(fetchMerchantOfferFail, (state, action: AnyAction) => {
      state.offer = emptyOffer;
      state.isLoading = false;
      state.error = action?.error?.response?.data || unknownError;
    })
    .addCase(updateRepresentatives, (state, action) => {
      state.onboardingData.owners = action.payload.owners;
      state.onboardingData.signatories = action.payload.signers;
      return state;
    })
    .addCase(updateNotaryDetails, (state, action) => {
      state.onboardingData.notaryDetails = action.payload;
      return state;
    })
    .addCase(updateBankInformation, (state, action) => {
      state.onboardingData.bankInformation = action.payload;
      return state;
    })
    .addCase(updateInvoiceInformation, (state, action) => {
      state.onboardingData.invoiceInformation = action.payload;
      return state;
    })
    .addCase(updateLocation, (state, action) => {
      const updatedLocations = state.onboardingData.stores.map((s, i) => {
        if (i !== action.payload.index) {
          return s;
        } else {
          return {
            ...s,
            ...action.payload.store,
          };
        }
      });
      state.onboardingData.stores = updatedLocations;
      return state;
    })
    .addCase(updateShippingInformationV2, (state, action) => {
      state.onboardingData.shippingInformationV2 = action.payload;
      return state;
    })
    .addCase(fetchOwners, state => {
      state.isLoading = true;
      state.error = null;
      return state;
    })
    .addCase(fetchOwnersSuccess, (state, action) => {
      state.isLoading = false;
      state.onboardingData.owners = action.payload.data.map(owner => ({
        ...owner,
        minSharesPercentage:
          owner.minSharesPercentage !== null && owner.minSharesPercentage !== undefined
            ? owner.minSharesPercentage
            : owner.sharesPercentage,
        maxSharesPercentage:
          owner.maxSharesPercentage !== null && owner.maxSharesPercentage !== undefined
            ? owner.maxSharesPercentage
            : owner.sharesPercentage,
      }));
      state.onboardingData.isOwnersModified = false;
      return state;
    })
    .addCase(fetchOwnersFail, (state, action: AnyAction) => {
      state.isLoading = false;
      state.error = action?.error?.response?.data || unknownError;
      return state;
    })
    .addCase(upsertOwner, (state, action) => {
      const existingOwner = state.onboardingData.owners.find(owner => owner.personalId === action.payload.personalId);
      state.onboardingData.owners = state.onboardingData.owners.filter(
        owner => owner.personalId !== action.payload.personalId
      );
      state.onboardingData.owners.push(deepmerge(existingOwner || {}, action.payload, {}));

      if (!existingOwner) {
        state.onboardingData.isOwnersModified = true;
      }
      return state;
    })
    .addCase(upsertOwnerV22, (state, action) => {
      const existingOwner = state.onboardingData.owners.find((owner, i) => i === action.payload.index);
      state.onboardingData.owners = state.onboardingData.owners.filter(
        (owner, index) => index !== action.payload.index
      );

      state.onboardingData.owners.push(deepmerge(existingOwner || {}, action.payload.owner, {}));
      if (!existingOwner) {
        state.onboardingData.isOwnersModified = true;
      }
      return state;
    })
    .addCase(removeOwner, (state, action) => {
      state.onboardingData.owners = state.onboardingData.owners.filter(
        (owner, index) => index !== action.payload.index
      );

      state.onboardingData.isOwnersModified = true;
      return state;
    })
    .addCase(fetchSigningCombinations, state => {
      state.signingCombinations.isLoading = true;
      state.signingCombinations.error = null;
      return state;
    })
    .addCase(fetchSigningCombinationsSuccess, (state, action) => {
      state.signingCombinations.isLoading = false;
      state.signingCombinations.signers = action.payload.data;
      return state;
    })
    .addCase(fetchSigningCombinationsFail, (state, action: AnyAction) => {
      state.signingCombinations.isLoading = false;
      state.signingCombinations.error = action?.error?.response?.data || unknownError;
      return state;
    })
    .addCase(setSigningCombinations, (state, action) => {
      state.signingCombinations.signers = action.payload;
      return state;
    })
    .addCase(setSigners, (state, action) => {
      state.onboardingData.signatories = action.payload;
      return state;
    })
    .addCase(removeSignerByFullName, (state, action) => {
      state.onboardingData.signatories = state.onboardingData.signatories.filter(
        signer => signer.fullName !== action.payload
      );
      return state;
    })
    .addCase(addManualSigner, (state, action) => {
      state.onboardingData.signatories = [
        ...state.onboardingData.signatories,
        { ...action.payload, isManuallyAdded: true },
      ];
      return state;
    })
    .addCase(editManualSignerName, (state, action) => {
      const signer = state.onboardingData.signatories.find(signer => signer.fullName === action.payload.oldFullName);
      if (signer) {
        signer.fullName = action.payload.newFullName;
      }
      return state;
    })
    .addCase(setManualSigners, (state, action) => {
      state.manualSigners = action.payload;
      return state;
    })
    .addCase(fetchEddQuestions, state => {
      state.isLoading = true;
      state.error = null;
      return state;
    })
    .addCase(fetchEddQuestionsFail, (state, action: AnyAction) => {
      state.isLoading = false;
      state.error = action?.error?.response?.data || unknownError;
      return state;
    })
    .addCase(fetchEddQuestionsSuccess, (state, action) => {
      state.isLoading = false;
      state.additionalQuestions = action.payload.data;
      return state;
    })
    .addCase(postEddQuestions, state => {
      state.isLoading = true;
      state.error = null;
      return state;
    })
    .addCase(postEddQuestionsFail, (state, action: AnyAction) => {
      state.isLoading = false;
      state.error = action?.error?.response?.data || unknownError;
      return state;
    })
    .addCase(postEddQuestionsSuccess, state => {
      state.isLoading = false;
      return state;
    })
    .addCase(submitOnboardingPayOfferData, state => ({
      ...state,
      isLoading: true,
      error: null,
    }))
    .addCase(submitOnboardingPayOfferDataSuccess, (state, action) => ({
      ...state,
      isLoading: false,
      error: null,
      onboardingStatus: action.payload.data.status,
      verificationStatus: action.payload.data.verificationStatus,
    }))
    .addCase(submitOnboardingPayOfferDataFail, (state, action: AnyAction) => ({
      ...state,
      isLoading: false,
      error: action?.error?.response?.data || unknownError,
    }))
    .addCase(submitOnboardingPayAndCashOfferData, state => ({
      ...state,
      isLoading: true,
      error: null,
    }))
    .addCase(submitOnboardingPayAndCashOfferDataSuccess, (state, action) => ({
      ...state,
      isLoading: false,
      error: null,
      onboardingStatus: action.payload.data.status,
      verificationStatus: action.payload.data.verificationStatus,
    }))
    .addCase(submitOnboardingPayAndCashOfferDataFail, (state, action: AnyAction) => ({
      ...state,
      isLoading: false,
      error: action?.error?.response?.data || unknownError,
    }))
    .addCase(clearOfferOnboardingData, state => {
      state.onboardingData = initialState.onboardingData;
      state.signingCombinations.signers = [];
      return state;
    })
    .addCase(setOfferRef, (state, action) => {
      state.onboardingData.offerRef = action.payload;
      return state;
    })
    .addCase(getOfferOnboardingData, state => {
      state.isLoading = true;
      state.error = null;
      return state;
    })
    .addCase(getOfferOnboardingDataSuccess, (state, action) => ({
      ...state,
      isLoading: false,
      error: null,
      onboardingData: action.payload.data,
      onboardingStatus: action.payload.data.status,
      manualSigners: action.payload.data.signatories.filter(signer => signer.isManuallyAdded),
    }))
    .addCase(getOfferOnboardingDataFail, (state, action: AnyAction) => {
      state.isLoading = false;
      state.error = action?.error?.response?.data || unknownError;
      return state;
    })
    .addCase(savePartial, state => {
      state.isLoading = true;
      state.error = null;
      return state;
    })
    .addCase(savePartialSuccess, (state, action) => {
      state.onboardingData = action.payload.data;
      state.isLoading = false;
      state.error = null;
      return state;
    })
    .addCase(savePartialFail, (state, action: AnyAction) => {
      state.isLoading = false;
      state.error = action?.error?.response?.data || unknownError;
      return state;
    })
    .addCase(setPage, (state, action) => {
      state.onboardingData.page = action.payload;
      return state;
    })
    .addCase(postDocument, state => {
      state.isLoading = true;
      state.error = null;
      return state;
    })
    .addCase(postDocumentFail, (state, action: AnyAction) => ({
      ...state,
      isLoading: false,
      error: action?.error?.response?.data || unknownError,
    }))
    .addCase(postDocumentSuccess, (state, action: AnyAction) => {
      state.isLoading = false;
      state.error = null;

      const representativeRef = action.meta.previousAction.payload.representativeRef;
      state.onboardingData.owners = state.onboardingData.owners.map(o => {
        if (o.ref !== representativeRef) {
          return o;
        } else {
          return {
            ...o,
            idDocument: action.payload.data,
          };
        }
      });

      state.onboardingData.signatories = state.onboardingData.signatories.map(s => {
        if (s.ref !== representativeRef) {
          return s;
        } else {
          return {
            ...s,
            idDocument: action.payload.data,
          };
        }
      });

      return state;
    })
    .addCase(deleteDocument, state => {
      state.isLoading = true;
      return state;
    })
    .addCase(deleteDocumentFail, (state, action: AnyAction) => ({
      ...state,
      isLoading: false,
      error: action?.error?.response?.data || unknownError,
    }))
    .addCase(deleteDocumentSuccess, (state, action: AnyAction) => {
      state.isLoading = false;
      state.error = null;

      const representativeRef = action.meta.previousAction.payload.representativeRef;
      state.onboardingData.owners = state.onboardingData.owners.map(o => {
        if (o.ref !== representativeRef) {
          return o;
        } else {
          return {
            ...o,
            idDocument: null,
          };
        }
      });

      state.onboardingData.signatories = state.onboardingData.signatories.map(s => {
        if (s.ref !== representativeRef) {
          return s;
        } else {
          return {
            ...s,
            idDocument: null,
          };
        }
      });

      return state;
    })
    .addCase(postGlobalDocument, state => {
      state.isLoading = true;
      state.error = null;
      return state;
    })
    .addCase(postGlobalDocumentFail, (state, action: AnyAction) => ({
      ...state,
      isLoading: false,
      error: action?.error?.response?.data || unknownError,
    }))
    .addCase(postGlobalDocumentSuccess, (state, action) => {
      state.isLoading = false;
      state.error = null;
      const document = action.payload.data;
      const existingDocuments = state.onboardingData.offerOnboardingGlobalDocuments || [];
      if (document) {
        state.onboardingData = {
          ...state.onboardingData,
          offerOnboardingGlobalDocuments: [...existingDocuments, document],
        };
      }
      return state;
    })
    .addCase(deleteGlobalDocument, state => {
      state.isLoading = true;
      return state;
    })
    .addCase(deleteGlobalDocumentFail, (state, action: AnyAction) => ({
      ...state,
      isLoading: false,
      error: action?.error?.response?.data || unknownError,
    }))
    .addCase(deleteGlobalDocumentSuccess, (state, action: AnyAction) => {
      const documentRef = action.meta.previousAction.payload.documentRef;
      if (state.onboardingData.offerOnboardingGlobalDocuments) {
        state.onboardingData.offerOnboardingGlobalDocuments = state.onboardingData.offerOnboardingGlobalDocuments.filter(
          d => d.ref !== documentRef
        );
      }
      state.isLoading = false;
      state.error = null;
      return state;
    })
    .addCase(clearOnboardingState, () => {
      return initialState;
    })
);
