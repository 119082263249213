import { Heading, Text, toLocaleBasedString } from '@loomispay/vault';
import { TurnoverData } from 'features/onboardingV2/store/types';
import { useTranslation } from 'react-i18next';
import { OfferSummarySectionBox, OfferSummaryRow } from '../../OfferOverview.styles';
import { CurrencyCode } from 'constants/types';

export default function OfferOverviewTurnover({
  turnoverData,
  currencyCode,
}: {
  turnoverData?: TurnoverData;
  currencyCode?: CurrencyCode;
}) {
  const { t } = useTranslation();

  return (
    <section>
      <Heading size="xxs" sansSerif>
        {t('onboarding.v2.overview.turnoverFigures')}
      </Heading>
      <OfferSummarySectionBox>
        <OfferSummaryRow>
          <Text size="s">{t('onboarding.v2.overview.turnoverFigures.averageOrderValue')}</Text>
          <Text size="s">{`${toLocaleBasedString(turnoverData?.averageOrderValue || '')} ${currencyCode}`}</Text>
        </OfferSummaryRow>
        <OfferSummaryRow>
          <Text size="s">{t('onboarding.v2.overview.turnoverFigures.yearlyTurnover')}</Text>
          <Text size="s">{`${toLocaleBasedString(turnoverData?.yearlyTurnover || '')} ${currencyCode}`}</Text>
        </OfferSummaryRow>
        <OfferSummaryRow>
          <Text size="s">{t('onboarding.v2.overview.turnoverFigures.cardCashTurnover')}</Text>
          <Text size="s">
            {`${turnoverData?.cardTransactionsPercentage}% / ${turnoverData?.cashTransactionsPercentage}%`}
          </Text>
        </OfferSummaryRow>
      </OfferSummarySectionBox>
    </section>
  );
}
