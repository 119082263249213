import { Button, Display } from '@loomispay/vault';
import { useConfirmation } from 'common/components/Modal';
import { Cost, OfferConfigurationItem, OfferItemPrice } from 'features/common/types';
import { useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ProductType } from '../../../../common/components/Products/ProductCard/ProductCard';
import { removeAddon, removeTerminal, upsertAddon, upsertTerminal } from '../../../store/sales/actions';
import {
  getOfferConfiguration,
  getSelectedAddonsByStoreId,
  getSelectedTerminalsByStoreId,
  getStores,
} from '../../../store/sales/selectors';
import { useSalesFlowNavigation } from '../salesFlowRoutingService';
import { ProductsLocations } from '../../../../common/components/Products/ProductsLocations';
import { getQuantity, ProductsOptions } from '../../../../common/components/Products/ProductsOptions';
import { useDocumentTitle } from 'hooks';
import Container from 'common/components/MainLayout/Container';
import LocationsSidebar from 'features/common/components/Locations/LocationsSidebar';
import styled from 'styled-components/macro';
import SingleColumn from 'layout/SingleColumn';

export default function OnboardingProducts() {
  const { nextPage, prevPage } = useSalesFlowNavigation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [selectedStoreIndex, setSelectedStoreIndex] = useState<number>(0);
  useDocumentTitle(t('onboarding.v2.products.add.title'));

  const selectedTerminals = useSelector(getSelectedTerminalsByStoreId(selectedStoreIndex));
  const selectedAddons = useSelector(getSelectedAddonsByStoreId(selectedStoreIndex));

  const form = useForm({
    mode: 'onChange',
  });
  const locations = useSelector(getStores);

  const isFirstPage = selectedStoreIndex === 0;
  const isLastPage = selectedStoreIndex === locations.length - 1;

  const onSubmit = () => {
    if (isLastPage && locations.length > 1 && !isScreenCompleted) {
      warnScreenIncomplete();
      return;
    }

    navigateForward();
  };

  const submitButtonLabel = isLastPage ? t('common.continueButton') : t('onboarding.v2.products.nextLocation');

  const isScreenCompleted =
    locations.every(location => {
      return location.storeOfferItems && location.storeOfferItems?.terminals.length > 0;
    }) && !Object.keys(form.errors).length;

  const { getConfirmation } = useConfirmation();

  const warnScreenIncomplete = async () => {
    await getConfirmation({
      title: t('onboarding.v2.products.missingTerminalsTitle'),
      description: t('onboarding.v2.products.missingTerminalsDescription'),
      acceptButtonLabel: t('onboarding.v2.products.missingTerminalsAcceptLabel'),
      cancelButtonPresent: false,
    });
  };

  const navigateForward = () => {
    if (isLastPage) {
      navigate(nextPage());
    } else {
      setSelectedStoreIndex(selectedStoreIndex + 1);
    }
  };

  const navigateBackward = () => {
    if (isFirstPage) {
      navigate(prevPage());
    } else {
      setSelectedStoreIndex(selectedStoreIndex - 1);
    }
  };

  const offerConfiguration = useSelector(getOfferConfiguration);
  const terminalOptions = offerConfiguration?.terminalOptions;
  const addonOptions = offerConfiguration?.addonOptions;

  const terminals = useMemo(
    () =>
      terminalOptions.map(terminal => {
        const selectedPricing = selectedTerminals.find(({ ref }) => terminal.code === ref)?.prices;
        return {
          ...terminal,
          ...(selectedPricing?.length
            ? { monthlyCost: { ...terminal.monthlyCost, amount: selectedPricing[0].amount } }
            : {}),
        } as OfferConfigurationItem;
      }),
    [terminalOptions, selectedTerminals]
  );

  const selectionCallback = (
    name: string,
    code: string,
    productType: ProductType,
    increment: number,
    posTerminalTypeReference?: string,
    loomisModelName?: string,
    initialCost?: Cost | null,
    monthlyCost?: Cost | null
  ) => {
    let currentQuantity = 0;
    if (productType === 'TERMINAL') {
      currentQuantity = getQuantity(selectedTerminals, code);
    } else if ((productType = 'ADDON')) {
      currentQuantity = getQuantity(selectedAddons, code);
    }
    const newQuantity = currentQuantity + increment;

    if (newQuantity <= 0) {
      removeOfferItem(code, productType, newQuantity, selectedStoreIndex);
    } else {
      upsertOfferItem(
        name,
        code,
        productType,
        newQuantity,
        selectedStoreIndex,
        posTerminalTypeReference,
        loomisModelName,
        initialCost,
        monthlyCost
      );
    }

    return;
  };

  const upsertOfferItem = (
    name: string,
    ref: string,
    productType: ProductType,
    quantity: number,
    locationIdx: number,
    posTerminalTypeReference?: string,
    loomisModelName?: string,
    initialCost?: Cost | null,
    monthlyCost?: Cost | null
  ) => {
    const prices: OfferItemPrice[] = [];
    if (initialCost) {
      prices.push({ priceType: 'upfront', ...initialCost });
    }
    if (monthlyCost) {
      prices.push({ priceType: 'monthly', ...monthlyCost });
    }

    switch (productType) {
      case 'TERMINAL':
        dispatch(
          upsertTerminal({
            offerItem: { name, ref, posTerminalTypeReference, loomisModelName, quantity, prices },
            locationIdx: locationIdx,
          })
        );
        return;
      case 'ADDON':
        dispatch(
          upsertAddon({
            offerItem: { name, ref, posTerminalTypeReference, quantity, prices },
            locationIdx: locationIdx,
          })
        );
        return;
      default:
        return 0;
    }
  };

  const removeOfferItem = (code: string, productType: ProductType, quantity: number, locationIdx: number) => {
    switch (productType) {
      case 'TERMINAL':
        dispatch(removeTerminal({ terminalRef: code, locationIdx: locationIdx }));
        return;
      case 'ADDON':
        dispatch(removeAddon({ addonRef: code, locationIdx: locationIdx }));
        return;
      default:
        return 0;
    }
  };

  const onLocationClick = (index: number) => {
    setSelectedStoreIndex(index);
  };

  return (
    <SingleColumn
      footer={
        <>
          <Button
            onClick={navigateBackward}
            variant="tertiary"
            label={t('common.backButton')}
            fullWidth={false}
            icon="arrowLeft"
            iconPosition="left"
          />
          <Button
            form="onboarding-products"
            disabled={locations.length === 1 && !isScreenCompleted}
            type="submit"
            label={submitButtonLabel}
            testId="onboardingProducts_submitButton"
            fullWidth={false}
          />
        </>
      }
    >
      <Container spacing="4">
        <header>
          <Display size="s">{t('onboarding.v2.products.add.title')}</Display>
        </header>

        <LocationsSidebar>
          <ProductsLocations
            locations={locations}
            selectedStoreIndex={selectedStoreIndex}
            onLocationClick={onLocationClick}
          />
        </LocationsSidebar>

        <FormProvider {...form}>
          <form id="onboarding-products" onSubmit={form.handleSubmit(onSubmit)}>
            <ProductsWrapper>
              <ProductsOptions
                storeId={selectedStoreIndex}
                terminalOptions={terminals}
                addonOptions={addonOptions}
                selectedTerminals={selectedTerminals}
                selectedAddons={selectedAddons}
                selectionCallback={selectionCallback}
              />
            </ProductsWrapper>
          </form>
        </FormProvider>
      </Container>
    </SingleColumn>
  );
}

const ProductsWrapper = styled.div`
  display: flex;
`;
