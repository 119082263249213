import { Button, Heading, Paragraph, useMedia } from '@loomispay/vault';
import Container from 'common/components/MainLayout/Container';
import { Footer } from 'features/common/components/Footer/Footer';
import LocationCard from 'features/common/components/Locations/LocationCard';
import LocationsSidebar from 'features/common/components/Locations/LocationsSidebar';
import { getOfferType, getStores } from '../../../store/sales/selectors';
import { Fragment, ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useSalesFlowNavigation } from '../salesFlowRoutingService';
import { useForm } from 'react-hook-form';
import Form from 'common/components/forms/Form';
import { DropdownOption } from 'features/onboardingV2/components/pepForm';
import { useNavigate } from 'react-router-dom';
import { CallToActionGridItem } from 'common/components/GridLayout';
import { CashServicesFormWrapper, CashServicesGridWrapper, CashServicesSettingsEnabler } from './CashServices.styles';
import CashServicesGeneralInfo from './sections/CashServicesGeneralInfo';
import { CashServicesFormFieldsES } from './types';
import CashServicesDates from './sections/CashServicesDates';
import CashServicesDetails from './sections/CashServicesDetails';
import CashServicesFees from './sections/CashServicesFees';
import { Store } from 'features/common/types';
import { notifications } from 'common/components/Notification/notifications';
import { Dropdown } from 'common/components/forms/Dropdown/Dropdown';
import { Toggle } from 'common/components/ToggleWrapper/Toggle';
import CashServicesChangeService from './sections/CashServicesChangeService';
import CashServicesInstantCredit from './sections/CashServicesInstantCredit';
import useCashServices from './useCashServices';
import { fetchCashOfferConfiguration, updateStore } from 'features/onboardingV2/store/sales/actions';
import CashServicesClientInsurance from './sections/CashServicesClientInsurance';
import { VisuallyHidden } from 'common/components/VisuallyHidden';
import { useDocumentTitle } from 'hooks';
import CashServicesGuarantee from './sections/CashServicesGuarantee';
import { CashServicesLoomisOperationsBank } from './sections/CashServicesLoomisOperationsBank';
import { cashServicesDataESTemplate } from './fixtures';

const ConditionalVisibilityWrapper = ({ isHidden, children }: { isHidden: boolean; children: ReactElement }) =>
  isHidden ? <VisuallyHidden>{children}</VisuallyHidden> : <>{children}</>;

export default function CashServices() {
  const { t } = useTranslation();
  const { nextPage, prevPage } = useSalesFlowNavigation();
  const isMobile = useMedia('mobile');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [selectedLocationIndex, setSelectedLocationIndex] = useState(0);
  const locations = useSelector(getStores);
  const offerType = useSelector(getOfferType);
  const [storesWithCashEnabled, setStoresWithCashEnabled] = useState<number[]>([]);
  const { handleSubmit, control, errors, getValues, setValue, watch } = useForm();
  const storeDataPrefix = 'store-';
  const { cashOfferConfig, updateStoresDataInRedux } = useCashServices();
  useDocumentTitle(t('onboarding.v2.offer.cashServices.title'));

  const toggleCashServicesForStore = (index: number) => {
    let storesWithCashEnabledCopy = [...storesWithCashEnabled];
    if (storesWithCashEnabledCopy.indexOf(index) >= 0)
      storesWithCashEnabledCopy = storesWithCashEnabledCopy.filter(storeIndex => storeIndex !== index);
    else storesWithCashEnabledCopy.push(index);
    setStoresWithCashEnabled(storesWithCashEnabledCopy);
  };

  const getCashOfferConfiguration = useCallback(() => {
    if (!cashOfferConfig) {
      dispatch(fetchCashOfferConfiguration());
    }
  }, [cashOfferConfig, dispatch]);

  const getLocationIdentifier = (location: Store) => {
    return `${location.name}-${location.address.address}`;
  };

  const chooseSelectedLocation = (index: number) => {
    updateStoresDataInRedux(getValues());
    setSelectedLocationIndex(index);
  };

  function updateForm(sourceStoreIndex: number) {
    const cashServicesSchema = (cashServicesDataESTemplate as unknown) as Record<string, Record<string, unknown>>;
    const sections = Object.keys(cashServicesDataESTemplate);
    const formState = getValues();

    sections.forEach(sectionKey => {
      const schemaSection = cashServicesSchema[sectionKey];
      if (schemaSection !== undefined) {
        Object.keys(schemaSection).forEach(fieldKey => {
          const section = formState[`store-${sourceStoreIndex}`][sectionKey];
          if (section) {
            setValue(`store-${selectedLocationIndex}.${sectionKey}.${fieldKey}`, section[fieldKey]);
          }
        });
      } else {
        setValue(`store-${selectedLocationIndex}.${sectionKey}`, formState[`store-${sourceStoreIndex}`][sectionKey]);
      }
    });
  }

  function updateReduxState(sourceStoreIndex: number) {
    const sourceStore: Store = locations[sourceStoreIndex];
    let targetStore: Store = locations[selectedLocationIndex];
    targetStore = {
      ...targetStore,
      cashData: sourceStore.cashData,
      isCashEnabled: sourceStore.isCashEnabled,
    };

    dispatch(updateStore(targetStore, selectedLocationIndex));
  }

  const copyLocationCashServicesSettings = (locationIdentifier: string) => {
    const sourceStoreIndex = locations.findIndex(location => getLocationIdentifier(location) === locationIdentifier);
    // Below we do the update for:
    // 1. the form - it's necessary to update view state without manual re-rendering triggered
    // 2. redux state - it's necessary to copy all cash data including conditionally rendered sections
    updateForm(sourceStoreIndex);
    updateReduxState(sourceStoreIndex);
    notifications.success(t('cashServices.form.copySettingsSuccessMessage'));
  };

  const checkIfStoreHasCashServicesEnabled = useCallback(
    (index: number) => Boolean(getValues(`store-${index}.isCashEnabled`)) === true,
    [getValues]
  );

  const checkIfChangeServiceEnabled = (index: number) => {
    const formState = getValues();
    if (formState[`store-${index}`].generalInfo) {
      return formState[`store-${index}`].generalInfo.changeServiceIncluded;
    }
    return (
      watch(`store-${index}.generalInfo.changeServiceIncluded`) === true ||
      locations[index].cashData?.es?.generalInfo.changeServiceIncluded
    );
  };

  const checkGuaranteeType = (index: number) => {
    const formState = getValues();
    if (formState[`store-${index}`].guarantee) {
      return formState[`store-${index}`].guarantee.type.value;
    }
    return watch(`store-${index}.guarantee.type`) || locations[index].cashData?.es?.guarantee?.type;
  };

  const checkIfLoomisInsuranceEnabled = (index: number) => {
    const formState = getValues();
    if (formState[`store-${index}`].generalInfo) {
      return !formState[`store-${index}`].generalInfo.insuranceIncluded;
    }
    return (
      watch(`store-${index}.generalInfo.insuranceIncluded`) === false ||
      !locations[index].cashData?.es?.generalInfo.insuranceIncluded
    );
  };

  const checkIfClientInsuranceRequired = (index: number) => {
    const formState = getValues();
    if (formState[`store-${index}`].generalInfo) {
      return (
        !formState[`store-${index}`].generalInfo.insuranceIncluded &&
        formState[`store-${index}`].generalInfo.isLoomisBeneficiary
      );
    }
    return (
      watch(`store-${index}.generalInfo.insuranceIncluded`) === false ||
      watch(`store-${index}.generalInfo.isLoomisBeneficiary`) === true ||
      (!locations[index].cashData?.es?.generalInfo.insuranceIncluded &&
        locations[index].cashData?.es?.generalInfo.isLoomisBeneficiary)
    );
  };

  const checkMonthlyFeeType = (index: number) => {
    const formState = getValues();
    if (formState[`store-${index}`].fees) {
      return formState[`store-${index}`].fees.monthlyFeeType.value;
    }
    return watch(`store-${index}.fees.monthlyFeeType`) || locations[index].cashData?.es?.fees.monthlyFeeType;
  };

  const checkSolutionType = (index: number) => {
    const formState = getValues();
    if (formState[`store-${index}`].generalInfo) {
      return formState[`store-${index}`].generalInfo.solutionType.value;
    }
    return watch(`store-${index}.generalInfo.solutionType`) || locations[index].cashData?.es?.generalInfo.solutionType;
  };

  const submitCashServicesForms = (data: Record<string, CashServicesFormFieldsES>) => {
    updateStoresDataInRedux(data);
    navigate(nextPage());
  };

  // Apply persisted data from redux store to local storage in order to reflect it on the view
  useEffect(() => {
    const storesIndexesWithCashServicesEnabled = locations.filter(location => location.isCashEnabled).map((_, i) => i);

    if (offerType === 'CASH' && storesIndexesWithCashServicesEnabled.length < locations.length) {
      getCashOfferConfiguration();
      // enable cash services for all locations that had them disabled
      locations.map((_, i) => setValue(`store-${i}.isCashEnabled`, true));
      setStoresWithCashEnabled([...Array(locations.length).keys()]);
    } else {
      setStoresWithCashEnabled(storesIndexesWithCashServicesEnabled);
    }
  }, [getCashOfferConfiguration, locations, offerType, setValue]);

  const locationsAsDropdownOptions = useMemo(() => {
    const locationsWithCashEnabled = locations.filter((_, index) => checkIfStoreHasCashServicesEnabled(index));
    const locationsWithoutCurrentlySelected = locationsWithCashEnabled.filter(
      location =>
        location.name !== locations[selectedLocationIndex].name ||
        location.address.address !== locations[selectedLocationIndex].address.address
    );
    return locationsWithoutCurrentlySelected.map(location => ({
      label: location.name,
      value: getLocationIdentifier(location),
    }));
  }, [locations, selectedLocationIndex, checkIfStoreHasCashServicesEnabled]);

  const checkIfLocationHasErrors = (index: number): boolean =>
    errors[`store-${index}`] && Object.keys(errors[`store-${index}`]).length > 0;

  return (
    <Container spacing="4">
      <header>
        <Heading level="h1" size="xl">
          {t('cashServices.heading')}
        </Heading>

        {offerType !== 'CASH' && <Paragraph color="secondary">{t('cashServices.headerParagraph')}</Paragraph>}
      </header>

      <LocationsSidebar>
        {locations.map((location, i) => {
          return (
            <Fragment key={location.name}>
              <LocationCard
                selected={selectedLocationIndex === i}
                onClick={() => chooseSelectedLocation(i)}
                location={location}
                testId={`location.${i}.card`}
                cashServicesEnabled={checkIfStoreHasCashServicesEnabled(i)}
                showErrorLabel={checkIfLocationHasErrors(i)}
              />
            </Fragment>
          );
        })}
      </LocationsSidebar>

      <Form spacing="8" id="cashServices" onSubmit={handleSubmit(submitCashServicesForms)}>
        {locations.map((location, i) => {
          return (
            <CashServicesFormWrapper key={location.name} isVisible={selectedLocationIndex === i}>
              <CashServicesSettingsEnabler>
                <CashServicesGridWrapper>
                  <ConditionalVisibilityWrapper isHidden={offerType === 'CASH'}>
                    <Toggle
                      label={t('cashServices.form.isEnabled')}
                      name={`${storeDataPrefix}${i}.isCashEnabled`}
                      onChange={() => {
                        getCashOfferConfiguration();
                        toggleCashServicesForStore(i);
                      }}
                      control={control}
                      errors={errors}
                      defaultValue={locations[i].isCashEnabled?.toString()}
                    />
                  </ConditionalVisibilityWrapper>
                  {locationsAsDropdownOptions.length > 0 && checkIfStoreHasCashServicesEnabled(i) && (
                    <Container fullWidth spacing="1">
                      <Dropdown
                        name={`cashServices-${i}.copySettings`}
                        label={t('cashServices.form.copySettings')}
                        control={control}
                        errors={errors}
                        options={locationsAsDropdownOptions}
                        onChange={option => {
                          const optionTyped = option as DropdownOption<string>;
                          copyLocationCashServicesSettings(optionTyped.value);
                        }}
                        defaultValue={{ label: '', value: '' }}
                      />
                      <Paragraph size="s" color="secondary">
                        {t('cashServices.form.copySettingsDescription')}
                      </Paragraph>
                    </Container>
                  )}
                </CashServicesGridWrapper>
              </CashServicesSettingsEnabler>

              {checkIfStoreHasCashServicesEnabled(i) && (
                <>
                  <CashServicesGeneralInfo
                    storeIndex={i}
                    control={control}
                    errors={errors}
                    checkSolutionType={checkSolutionType(i)}
                  />
                  <CashServicesDates storeIndex={i} control={control} errors={errors} setValue={setValue} />
                  <CashServicesDetails storeIndex={i} control={control} errors={errors} />
                  <CashServicesChangeService
                    storeIndex={i}
                    control={control}
                    errors={errors}
                    changeServiceEnabled={checkIfChangeServiceEnabled(i)}
                  />
                  <CashServicesGuarantee
                    storeIndex={i}
                    control={control}
                    errors={errors}
                    checkSolutionType={checkSolutionType(i)}
                    guaranteeType={checkGuaranteeType(i)}
                  />
                  <CashServicesClientInsurance
                    storeIndex={i}
                    control={control}
                    errors={errors}
                    loomisInsuranceEnabled={checkIfLoomisInsuranceEnabled(i)}
                    clientInsuranceEnabled={checkIfClientInsuranceRequired(i)}
                  />
                  <CashServicesInstantCredit storeIndex={i} control={control} errors={errors} />
                  <CashServicesLoomisOperationsBank
                    storeIndex={i}
                    control={control}
                    errors={errors}
                    checkSolutionType={checkSolutionType(i)}
                  />
                  <CashServicesFees
                    storeIndex={i}
                    control={control}
                    errors={errors}
                    monthlyFeeType={checkMonthlyFeeType(i)}
                  />
                </>
              )}
            </CashServicesFormWrapper>
          );
        })}
      </Form>

      <Footer
        footer={
          <CallToActionGridItem isMobile={isMobile}>
            <Button
              onClick={() => {
                navigate(prevPage());
              }}
              variant="tertiary"
              label={t('common.backButton')}
              fullWidth={false}
              icon="arrowLeft"
              iconPosition="left"
            />
            <Button
              type="submit"
              form="cashServices"
              label={t('common.continueButton')}
              testId="onboardingProducts_submitButton"
              fullWidth={false}
            />
          </CallToActionGridItem>
        }
      />
    </Container>
  );
}
