import { RuleTextInput } from './TransactionFees.styles';
import { Control, DeepMap, FieldError } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import ITransactionFeesForm from './ITransactionFeesForm';
import { TextInput } from 'common/components/forms/TextInput/TextInput';
import { toFixedFractionalDigits } from '@loomispay/vault';

const TransactionFeeElement = (transactionFeeData: TransactionFeeElementProps) => {
  const { t } = useTranslation();

  return (
    <>
      <RuleTextInput>
        <TextInput
          testId={transactionFeeData.testId}
          type="number"
          inputProps={{ step: 0.01, min: transactionFeeData.minFee, max: transactionFeeData.maxFee }}
          label={transactionFeeData.label}
          name={transactionFeeData.name}
          suffix="%"
          rules={{
            min: {
              value: transactionFeeData.minFee,
              message: t('common.form.validationMessages.numberBetween', {
                min: transactionFeeData.minFee,
                max: transactionFeeData.maxFee,
              }),
            },
            max: {
              value: transactionFeeData.maxFee,
              message: t('common.form.validationMessages.numberBetween', {
                min: transactionFeeData.minFee,
                max: transactionFeeData.maxFee,
              }),
            },
            required: t('common.form.validationMessages.required'),
          }}
          defaultValue={transactionFeeData.defaultValue}
          control={transactionFeeData.control}
          errors={transactionFeeData.errors}
          placeholder={t('onboarding.v2.offer.contractTerms.transactionFees.hint', {
            min: toFixedFractionalDigits(transactionFeeData.minFee, 2),
            max: toFixedFractionalDigits(transactionFeeData.maxFee, 2),
          })}
        />
      </RuleTextInput>
    </>
  );
};

export default TransactionFeeElement;

export type TransactionFeeElementProps = {
  minFee: number;
  maxFee: number;
  name: string;
  testId: string;
  label: string;
  defaultValue: string;
  control: Control;
  errors: DeepMap<ITransactionFeesForm, FieldError>;
};
