import styled from 'styled-components/macro';
import { spacings } from '@loomispay/vault';

export const ContactPersonRow = styled.div`
  display: flex;
  margin: ${spacings['3']} 0;
  justify-content: space-between;

  & > * {
    flex: 1;
    margin-right: ${spacings['4']};

    &:last-child {
      margin-right: 0;
    }
  }
`;
