import { MouseEvent, useCallback, useEffect } from 'react';
import styled from 'styled-components/macro';
import { spacing } from 'constants/spacings';
import { MessageProps, Severity } from './types';
import { Banner, MessageType } from '@loomispay/vault';

const MessageContainer = styled.div`
  margin: ${spacing.s3} ${spacing.s4};
`;

const bannerTypes = new Map<Severity, MessageType>([
  ['success', 'positive'],
  ['info', 'neutral'],
  ['warn', 'warning'],
  ['error', 'negative'],
]);

export const Message = ({ message, onClose, onClick }: MessageProps) => {
  const handleClose = useCallback(
    (event?: MouseEvent) => {
      if (onClose) {
        onClose(message);
      }

      if (event) {
        event.preventDefault();
        event.stopPropagation();
      }
    },
    [message, onClose]
  );

  const handleClick = () => {
    if (onClick) {
      onClick(message);
    }
  };

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (!message.sticky) {
      timer = setTimeout(() => {
        handleClose();
      }, message.life || 3000);
    }
    return () => clearTimeout(timer);
  }, [handleClose, message]);

  return (
    <MessageContainer data-testid={message.testId} onClick={handleClick}>
      <Banner type={bannerTypes.get(message.severity)} onClose={message.closable ? handleClose : undefined}>
        {message.content}
      </Banner>
    </MessageContainer>
  );
};
