import { Button, Heading, Text, toLocaleBasedString } from '@loomispay/vault';
import { Cost } from 'features/common/types';
import { useTranslation } from 'react-i18next';
import { denormalizeAmount, formatAmount, normalizeAmount } from '../../../../../utils/moneyHelper';
import { Card, CardControlWrapper, CardPriceWrapper, QuantityWrapper } from './ProductCard.styles';
import { TextInput } from 'common/components/forms/TextInput/TextInput';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { selectIsFlexiblePricingEnabled } from 'features/featureToggles/featureToggles';
import { useRef } from 'react';

type Props = {
  newQuantity: number;
  currentQuantity?: number;
  code: string;
  name: string;
  posTerminalTypeReference?: string;
  loomisModelName?: string;
  terminalVersion?: string;
  selectionCallback: (
    name: string,
    code: string,
    productType: ProductType,
    increment: number,
    posTerminalTypeReference?: string,
    loomisModelName?: string,
    initialCost?: Cost | null,
    monthlyCost?: Cost | null
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ) => any;
  initialCost?: Cost | null;
  monthlyCost?: Cost | null;
  productType: ProductType;
};

export type ProductType = 'TERMINAL' | 'ADDON';

const ProductCard = ({
  newQuantity = 0,
  currentQuantity,
  code,
  selectionCallback,
  initialCost,
  monthlyCost,
  productType,
  name,
  terminalVersion,
  posTerminalTypeReference,
  loomisModelName,
}: Props) => {
  const { t } = useTranslation();
  const { control, errors, getValues } = useFormContext();

  // decided to leave it here instead of HOC as it will be easier to remove feature flag from one component in the future
  const isFlexiblePricingEnabled = useSelector(selectIsFlexiblePricingEnabled);
  const isPriceFieldValid = !errors[code];
  const initialMonthlyCost = useRef(monthlyCost?.amount);

  const updateSelection = (step: number, newMonthlyAmount?: number) =>
    selectionCallback(
      name,
      code,
      productType,
      step,
      posTerminalTypeReference,
      loomisModelName,
      initialCost,
      monthlyCost
        ? ({
            ...monthlyCost,
            amount:
              isFlexiblePricingEnabled && monthlyCost.softwareFee
                ? normalizeAmount(newMonthlyAmount || getValues(code))
                : monthlyCost.amount,
          } as Cost)
        : null
    );

  const toMinAmount = (softwareFee: number | null): number | null => {
    return softwareFee ? softwareFee + 1 : null;
  };

  return (
    <Card selected={newQuantity > 0} withError={!isPriceFieldValid} key={code}>
      <Text size="s" color="invertedTertiary">
        {productType === 'TERMINAL' && terminalVersion}
        {productType === 'ADDON' && initialCost && t('onboarding.v2.products.initialCost')}
        {productType === 'ADDON' && monthlyCost && t('onboarding.v2.products.monthlyCost')}
      </Text>

      <Heading size="s">{name}</Heading>

      <CardPriceWrapper>
        {!!initialCost && (
          <Text>{toLocaleBasedString(formatAmount(initialCost.amount, initialCost.currencyCode))}</Text>
        )}
        {!!monthlyCost && productType === 'TERMINAL' && (
          <>
            {isFlexiblePricingEnabled && !!monthlyCost.softwareFee ? (
              <TextInput
                type="number"
                label=""
                name={code}
                inputProps={{ step: 0.01 }}
                rules={{
                  min: {
                    value: newQuantity > 0 ? denormalizeAmount(toMinAmount(monthlyCost.softwareFee)) : 0,
                    message: `${t('common.min')} ${formatAmount(
                      toMinAmount(monthlyCost.softwareFee),
                      monthlyCost.currencyCode
                    )} `,
                  },
                  required: t('common.form.validationMessages.required'),
                }}
                defaultValue={`${formatAmount(monthlyCost.amount)}`}
                onBlur={e => isPriceFieldValid && !!newQuantity && updateSelection(0, +e.target.value)}
                disabled={monthlyCost.softwareFee === null || monthlyCost.amount === 0}
                control={control}
                errors={errors}
                currency={monthlyCost.currencyCode}
                fieldWarningValidate={() => {
                  const highTierPrice = initialMonthlyCost.current && initialMonthlyCost.current / 10;
                  if (isPriceFieldValid && highTierPrice && +getValues(code) > highTierPrice) {
                    return `${t('common.over')} ${formatAmount(
                      normalizeAmount(highTierPrice),
                      monthlyCost.currencyCode,
                      0
                    )}`;
                  }
                }}
                hintText={`${t('common.min')} ${formatAmount(
                  toMinAmount(monthlyCost.softwareFee),
                  monthlyCost.currencyCode
                )}`}
              />
            ) : (
              <Text>{toLocaleBasedString(formatAmount(monthlyCost.amount, monthlyCost.currencyCode))}</Text>
            )}
            <Text>{`/ ${t('common.month')}`}</Text>
          </>
        )}
      </CardPriceWrapper>

      {!!currentQuantity && (
        <Text color="linkPress" weight="semiBold">
          {`${t('onboarding.common.current')}: ${currentQuantity}`}
        </Text>
      )}

      <CardControlWrapper>
        <Button
          variant="secondary"
          size="s"
          icon="minus"
          onClick={() => updateSelection(-1)}
          disabled={newQuantity <= 0}
          testId={`minus-${code}`}
        />
        <Text size="s">
          <QuantityWrapper>{newQuantity}</QuantityWrapper>
        </Text>
        <Button variant="secondary" size="s" icon="plus" onClick={() => updateSelection(1)} testId={`plus-${code}`} />
      </CardControlWrapper>
    </Card>
  );
};

export default ProductCard;
