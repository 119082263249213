import { TFunction } from 'react-i18next';
import {
  ContactPerson,
  ShippingInformationForm,
  ShippingLocationOptionType,
  ShippingOption,
  ShippingOptionType,
  ShippingInformationV2 as ShippingInformationV2Type,
  OfferOnboardingStore,
} from 'features/common/types';
import { UseFormMethods } from 'react-hook-form/dist/types';

export function getShippingOptions(t: TFunction): ShippingOptionType[] {
  return [
    { value: 'ALL2ONE', label: t('common.shippingInformation.options.ALL2ONE') },
    { value: 'ALL2THEIR', label: t('common.shippingInformation.options.ALL2THEIR') },
  ];
}

export function getShippingLocationOptions(
  t: TFunction,
  locations: OfferOnboardingStore[]
): ShippingLocationOptionType[] {
  return [
    { value: undefined, label: t('common.shippingInformation.locationOptions.specifyAddress') },
    ...locations.map(location => ({ value: location.ref, label: location.name })),
  ];
}

export function getReceipientFromContactPerson(contactPerson?: ContactPerson) {
  return `${contactPerson?.firstName ? contactPerson?.firstName : ''}${
    contactPerson?.firstName && contactPerson?.lastName ? ' ' : ''
  }${contactPerson?.lastName ? contactPerson?.lastName : ''}`;
}

export function shippingOptionChanged(
  locations: OfferOnboardingStore[],
  // companyData: CompanyData,
  form: UseFormMethods<ShippingInformationForm>,
  shippingOption: ShippingOption,
  // contactPerson?: ContactPerson,
  shippingInformationV2?: ShippingInformationV2Type
) {
  if (shippingOption === 'ALL2THEIR') {
    locations.forEach((location, index) => {
      const shippingAddress =
        shippingInformationV2?.shippingOption === 'ALL2THEIR' &&
        shippingInformationV2?.shippingAddresses.find(shippingAddress => shippingAddress.storeRef === location.ref);

      form.setValue(`storesShippingData[${index}].storeRef`, location.ref);
      form.setValue(
        `storesShippingData[${index}].recipient`,
        shippingAddress ? shippingAddress.recipient : location.name
      );
      form.setValue(`storesShippingData[${index}].attention`, shippingAddress ? shippingAddress.attention : '');
    });
  }
}

export function shippingLocationOptionChanged(
  locations: OfferOnboardingStore[],
  form: UseFormMethods<ShippingInformationForm>,
  companyName?: string,
  contactPerson?: ContactPerson,
  shippingLocationOption?: string,
  shippingInformationV2?: ShippingInformationV2Type
) {
  const shippingLocation = locations.find(location => location.ref === shippingLocationOption);
  if (shippingLocation) {
    form.setValue(
      'shippingLocationData.recipient',
      shippingInformationV2?.shippingOption === 'ALL2ONE' &&
        shippingInformationV2?.all2OneStoreRef === shippingLocation.ref
        ? shippingInformationV2?.shippingAddresses[0]?.recipient
        : shippingLocation.name
    );
    form.setValue(
      'shippingLocationData.attention',
      shippingInformationV2?.shippingOption === 'ALL2ONE' &&
        shippingInformationV2?.all2OneStoreRef === shippingLocation.ref
        ? shippingInformationV2?.shippingAddresses[0]?.attention
        : ''
    );

    form.setValue('shippingLocationData.address', shippingLocation.address.address);
    form.setValue('shippingLocationData.zipCode', shippingLocation.address.zipCode);
    form.setValue('shippingLocationData.city', shippingLocation.address.city);
    // revalidate form
    form.trigger();
  } else {
    form.setValue(
      'shippingLocationData.recipient',
      shippingInformationV2?.shippingOption === 'ALL2ONE' && !shippingInformationV2?.all2OneStoreRef
        ? shippingInformationV2?.shippingAddresses[0]?.recipient
        : companyName
    );
    form.setValue(
      'shippingLocationData.attention',
      shippingInformationV2?.shippingOption === 'ALL2ONE' && !shippingInformationV2?.all2OneStoreRef
        ? shippingInformationV2?.shippingAddresses[0]?.attention
        : getReceipientFromContactPerson(contactPerson)
    );
    form.setValue(
      'shippingLocationData.address',
      shippingInformationV2?.shippingOption === 'ALL2ONE' && !shippingInformationV2?.all2OneStoreRef
        ? shippingInformationV2?.shippingAddresses[0]?.address
        : ''
    );
    form.setValue(
      'shippingLocationData.zipCode',
      shippingInformationV2?.shippingOption === 'ALL2ONE' && !shippingInformationV2?.all2OneStoreRef
        ? shippingInformationV2?.shippingAddresses[0]?.zipCode
        : ''
    );
    form.setValue(
      'shippingLocationData.city',
      shippingInformationV2?.shippingOption === 'ALL2ONE' && !shippingInformationV2?.all2OneStoreRef
        ? shippingInformationV2?.shippingAddresses[0]?.city
        : ''
    );
  }
}
