import { Heading, Text } from '@loomispay/vault';
import { Store } from 'features/common/types';
import { useTranslation } from 'react-i18next';
import { OfferSummaryRow, OfferSummarySectionBox } from '../../OfferOverview.styles';

export default function OfferOverviewCashDisabledLocations({ stores }: { stores: Store[] }) {
  const { t } = useTranslation();

  return (
    <section>
      <Heading size="xxs" sansSerif>
        {t('cashServices.noCashServices')}
      </Heading>
      <OfferSummarySectionBox>
        <OfferSummaryRow>
          <Text size="s">{t('locations.title')}</Text>
          <Text size="s">{stores.map(store => store.name).join(', ')}</Text>
        </OfferSummaryRow>
      </OfferSummarySectionBox>
    </section>
  );
}
