import { Country } from '@loomispay/vault/build/components/Flag/Flag';
import { CountryCode } from 'constants/types';

export const countryToFlagMapping: Record<CountryCode, Country> = {
  DK: 'denmark',
  ES: 'spain',
  SE: 'sweden',
  NO: 'norway',
};

export const getFlagByCountry = (language: CountryCode): Country => {
  return countryToFlagMapping[language];
};

export const supportedCountries = Object.keys(countryToFlagMapping);
