import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm, FormProvider } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Heading, toLocaleBasedString } from '@loomispay/vault';
import { TextInput } from 'common/components/forms/TextInput/TextInput';
import { getCountryCode, getTurnoverData } from '../store/sales/selectors';
import { updateTurnoverInformation } from '../store/sales/actions';
import { useDidUpdateEffect } from 'hooks';
import { Column, Row } from 'layout/flexLayouts';
import { currencyForCountry } from 'services/configService';
import Container from 'common/components/MainLayout/Container';
import Form from 'common/components/forms/Form';

const validationRules = {
  averageOrderValue: {
    min: 10,
    max: 5000,
  },
  yearlyTurnover: {
    min: 10000,
    max: 25000000,
  },
};

const notInRange = ({ min, max }: { min: number; max: number }, value?: string) => {
  const numericValue = Number(value);
  return numericValue > 0 && (numericValue < min || numericValue > max);
};

type TurnoverData = {
  averageOrderValue: string;
  yearlyTurnover: string;
  cardTransactionsPercentage: string;
  cashTransactionsPercentage: string;
  otherTransactionsPercentage: string;
};

type Props = {
  onSubmit: () => void;
  setIsTurnoverDataFilled: (screenCompleted: boolean) => void;
};

export const TurnoverForm = ({ onSubmit, setIsTurnoverDataFilled }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const countryCode = useSelector(getCountryCode);
  const currencyCode = countryCode ? currencyForCountry[countryCode] : '';

  const turnoverData = useSelector(getTurnoverData);

  const turnoverDataForm = useForm<TurnoverData>({
    mode: 'onChange',
  });

  const { formState, handleSubmit, watch, setValue, control, errors } = turnoverDataForm;

  const formCardPercentage = watch('cardTransactionsPercentage');
  const formCashPercentage = watch('cashTransactionsPercentage');

  const isFormCompleted = formState.isValid;

  useEffect(() => {
    if (formCardPercentage && formCashPercentage && formCashPercentage.length && formCardPercentage.length) {
      setIsTurnoverDataFilled(isFormCompleted);
    }
  }, [isFormCompleted, setIsTurnoverDataFilled, formCardPercentage, formCashPercentage]);

  useDidUpdateEffect(() => {
    if (formCardPercentage && formCashPercentage) {
      const remainder = 100 - parseInt(formCardPercentage, 10) - parseInt(formCashPercentage, 10);

      setValue('otherTransactionsPercentage', remainder.toString(), { shouldValidate: true });
    }
  }, [formCardPercentage, formCashPercentage]);

  const onHandleSubmit = (formData: TurnoverData) => {
    dispatch(
      updateTurnoverInformation({
        averageOrderValue: parseInt(formData.averageOrderValue, 10),
        yearlyTurnover: parseInt(formData.yearlyTurnover, 10),
        cardTransactionsPercentage: parseInt(formData.cardTransactionsPercentage, 10),
        cashTransactionsPercentage: parseInt(formData.cashTransactionsPercentage, 10),
        otherTransactionsPercentage: parseInt(formData.otherTransactionsPercentage, 10),
      })
    );
    onSubmit();
  };

  return (
    <Container spacing="2" fullWidth>
      <Heading size="xxs" noGutter sansSerif>
        {t('onboarding.v2.companyInfo.turnoverHeading')}
      </Heading>
      <FormProvider {...turnoverDataForm}>
        <Form spacing="3" onSubmit={handleSubmit(onHandleSubmit)} id="turnover-figures">
          <Row noMarginBottom>
            <Column>
              <TextInput
                testId={'average-order-value'}
                type="number"
                label={t('onboarding.v2.companyInfo.averageOrderValue', { currencyCode })}
                name="averageOrderValue"
                setValue={(value?: string) => setValue('averageOrderValue', value)}
                inputProps={{ step: 1 }}
                rules={{
                  min: {
                    value: 1,
                    message: t('common.form.validationMessages.numberTooLow'),
                  },
                  required: t('common.form.validationMessages.required'),
                }}
                defaultValue={turnoverData.averageOrderValue?.toString() || ''}
                control={control}
                errors={errors}
                fieldWarningValidate={(value: string) => {
                  if (notInRange(validationRules.averageOrderValue, value)) {
                    return t('onboarding.v2.companyInfo.averageOrderValue.hint', {
                      min: toLocaleBasedString(validationRules.averageOrderValue.min),
                      max: toLocaleBasedString(validationRules.averageOrderValue.max),
                    });
                  }
                }}
              />
            </Column>
            <Column>
              <TextInput
                testId={'yearly-turnover'}
                type="number"
                label={t('onboarding.v2.companyInfo.yearlyTurnover', { currencyCode })}
                name="yearlyTurnover"
                inputProps={{ step: 1 }}
                setValue={(value?: string) => setValue('yearlyTurnover', value)}
                rules={{
                  min: {
                    value: 1,
                    message: t('common.form.validationMessages.numberTooLow'),
                  },
                  required: t('common.form.validationMessages.required'),
                }}
                defaultValue={turnoverData.yearlyTurnover?.toString() || ''}
                control={control}
                errors={errors}
                fieldWarningValidate={(value: string) => {
                  if (notInRange(validationRules.yearlyTurnover, value)) {
                    return t('onboarding.v2.companyInfo.averageOrderValue.hint', {
                      min: toLocaleBasedString(validationRules.yearlyTurnover.min),
                      max: toLocaleBasedString(validationRules.yearlyTurnover.max),
                    });
                  }
                }}
              />
            </Column>
          </Row>
          <Row noMarginBottom>
            <Column>
              <Row>
                <Column>
                  <TextInput
                    testId={'card-transactions-percentage'}
                    type="number"
                    inputProps={{ min: 0, max: 100, step: 1 }}
                    label={t('onboarding.v2.companyInfo.cardTransactionsPercentage')}
                    name="cardTransactionsPercentage"
                    suffix="%"
                    rules={{
                      min: {
                        value: 0,
                        message: t('common.form.validationMessages.numberTooLow'),
                      },
                      max: {
                        value: 100,
                        message: t('common.form.validationMessages.numberTooHigh'),
                      },
                      required: t('common.form.validationMessages.required'),
                    }}
                    defaultValue={turnoverData.cardTransactionsPercentage?.toString() || ''}
                    control={control}
                    errors={errors}
                  />
                </Column>
                <Column>
                  <TextInput
                    testId={'cash-transactions-percentage'}
                    type="number"
                    inputProps={{ min: 0, max: 100, step: 1 }}
                    label={t('onboarding.v2.companyInfo.cashTransactionsPercentage')}
                    name="cashTransactionsPercentage"
                    suffix="%"
                    rules={{
                      min: {
                        value: 0,
                        message: t('common.form.validationMessages.numberTooLow'),
                      },
                      max: {
                        value: 100,
                        message: t('common.form.validationMessages.numberTooHigh'),
                      },
                      required: t('common.form.validationMessages.required'),
                    }}
                    defaultValue={turnoverData.cashTransactionsPercentage?.toString() || ''}
                    control={control}
                    errors={errors}
                  />
                </Column>
                <Column>
                  <TextInput
                    testId={'other-transactions-percentage'}
                    type={turnoverData.otherTransactionsPercentage ? 'number' : 'text'}
                    label={t('onboarding.v2.companyInfo.otherTransactionsPercentage')}
                    name="otherTransactionsPercentage"
                    suffix="%"
                    disabled
                    rules={{
                      min: {
                        value: 0,
                        message: t('common.form.validationMessages.numberTooLow'),
                      },
                      max: {
                        value: 100,
                        message: t('common.form.validationMessages.numberTooHigh'),
                      },
                      required: t('common.form.validationMessages.required'),
                    }}
                    defaultValue={turnoverData.otherTransactionsPercentage?.toString() || ''}
                    control={control}
                    errors={errors}
                  />
                </Column>
              </Row>
            </Column>
            <Column></Column>
          </Row>
        </Form>
      </FormProvider>
    </Container>
  );
};
