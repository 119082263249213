import { useDocumentTitle } from 'hooks';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { useMerchantContext } from 'services/MerchantContext';
import LoginPasswordless from 'features/loginPasswordless/LoginPasswordless';
import { useOffer, useOfferContactData } from '../../../data/queries';
import { useAssignOfferToMerchantMutation } from '../../../data/mutations';
import { onboardingRoutes } from '../../../OnboardingRouter';
import { reactLocalStorage } from 'reactjs-localstorage';
import axios from 'axios';

export default function PasswordlessLoginScreen() {
  const { t } = useTranslation();
  const { mutate: assignOfferToMerchant, isSuccess: isSuccessAssigned } = useAssignOfferToMerchantMutation();
  const navigate = useNavigate();
  useDocumentTitle(t('login.title'), false);

  const [isAuth, setIsAuth] = useState<boolean>(false);
  const [loginHeader, setLoginHeader] = useState<string>();
  const [loginDescription, setLoginDescription] = useState<string>();

  const { offerRef } = useParams<{ offerRef: string }>() as { offerRef: string };

  // TODO: changes this null countryCode in the future, need BE changes?!
  const { data: dataOfferContact, error: errorOfferContactData } = useOfferContactData({ offerRef });
  const { data: dataOffer, error: errorOffer } = useOffer(isAuth ? { offerRef } : {});

  const { loadMerchants } = useMerchantContext();

  const setLoginCopyVariant = useCallback(
    (copyVariant: 'WELCOME_BACK' | 'VERIFY_IDENTITY') => {
      switch (copyVariant) {
        case 'WELCOME_BACK':
          setLoginHeader(t('passwordlessLogin.welcomeBack'));
          setLoginDescription(t('passwordlessLogin.instructionOneWelcomeBack'));
          break;
        default:
          setLoginHeader(t('passwordlessLogin.verifyIdentity'));
          setLoginDescription(t('passwordlessLogin.instructionOne'));
          break;
      }
    },
    [t]
  );

  const onSuccessAuth = async () => {
    // TODO: changes this null countryCode in the future, need BE changes?!
    assignOfferToMerchant({ countryCode: null, offerRef });
  };

  useEffect(() => {
    if (isSuccessAssigned) {
      setIsAuth(true);
    }
  }, [isSuccessAssigned]);

  useEffect(() => {
    if (dataOffer && isAuth) {
      setLoginCopyVariant('VERIFY_IDENTITY');
      const { offerType } = dataOffer;
      if (offerType === 'CASH') {
        reactLocalStorage.set('merchantRef', offerRef);
      } else {
        //TODO: talk about loadMerchants with Maciek
        // update: it's old implementation probably to talk about and refactor with help od BE
        loadMerchants();
      }
      navigate(
        generatePath(onboardingRoutes.onboardingDispatcher.path, { countryCode: dataOffer.countryCode, offerRef })
      );
    }
  }, [dataOffer, loadMerchants, navigate, setLoginCopyVariant, offerRef, isAuth]);

  useEffect(() => {
    if (errorOfferContactData && axios.isAxiosError(errorOfferContactData)) {
      if (errorOfferContactData.response?.status === 404) {
        navigate('/offer-not-found');
      }
    }
  }, [errorOfferContactData, navigate]);

  useEffect(() => {
    if (errorOffer && axios.isAxiosError(errorOffer)) {
      if (errorOffer.response?.status === 401) {
        setLoginCopyVariant('WELCOME_BACK');
      }
    }
  }, [errorOffer, setLoginCopyVariant]);

  if (!dataOfferContact) return null;

  return (
    <LoginPasswordless
      stepOneHeader={loginHeader}
      stepOneDescription={loginDescription}
      onAuthSuccess={onSuccessAuth}
      contactPerson={dataOfferContact.loomisContactPerson}
      username={offerRef}
      userPhoneNumber={dataOfferContact?.phoneNumber}
    />
  );
}
