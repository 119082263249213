import { Button, Display, Paragraph } from '@loomispay/vault';
import { AmexTransactionFee, OfferConfiguration, TransactionFee } from 'features/common/types';
import SingleColumn from 'layout/SingleColumn';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Column } from '../../../../layout/flexLayouts';
import { setContractTransactionFees } from '../../store/sales/actions';
import { getContractTerms, getOfferConfiguration } from '../../store/sales/selectors';
import { PricingRow } from './OnboardingPricing.styles';
import { useSalesFlowNavigation } from './salesFlowRoutingService';
import { useDocumentTitle } from 'hooks';
import { transactionFeeConfigRulesSort } from '../../../../utils/sort';
import ITransactionFeesForm from './ITransactionFeesForm';
import TransactionFeeElement from './TransactionFeeElement';
import AmexFeeElement from './AmexFeeElement';
import styled from 'styled-components/macro';

const TransactionFees = () => {
  const { nextPage, prevPage } = useSalesFlowNavigation();
  const { t } = useTranslation();
  useDocumentTitle(t('onboarding.v2.offer.contractTerms.transactionFees.title'));
  const { handleSubmit, control, errors, formState, watch } = useForm<ITransactionFeesForm>({
    mode: 'onChange',
  });

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const contractTerms = useSelector(getContractTerms);
  const offerConfiguration = useSelector(getOfferConfiguration);

  const defaultTransactionFee = (offerConfiguration: OfferConfiguration) => {
    const defaultRange = offerConfiguration?.transactionFeeConfig.defaultFee.transactionFeeRange;
    return { min: defaultRange.minPercentage, max: defaultRange.maxPercentage };
  };

  const { min: defaultMinFee, max: defaultMaxFee } = defaultTransactionFee(offerConfiguration);

  const transactionFeeRules = offerConfiguration?.transactionFeeConfig?.rules
    ?.slice()
    .sort(transactionFeeConfigRulesSort);
  const transactionFeeRulesExists = transactionFeeRules.length > 0;

  const { transactionFees } = contractTerms;
  const formTransactionFee = watch('transactionFeeDefault', transactionFees?.defaultFee?.percentage);

  const screenCompleted = formState.isValid && formTransactionFee && formTransactionFee !== 0;

  const amexTransactionFeeConfigRule = offerConfiguration?.transactionFeeConfig?.amexRule;

  const onSubmit = (data: ITransactionFeesForm) => {
    if (data.transactionFeeRules === undefined) {
      data.transactionFeeRules = {};
    }

    const rules: TransactionFee[] = Object.entries(data.transactionFeeRules)
      .map(([configRef, percentage]) => {
        const rule = transactionFeeRules.find(rule => rule.ref === configRef);

        return (
          rule && {
            configRef: configRef,
            percentage: percentage,
            region: rule.region,
            cardType: rule.cardType,
            cardCustomerType: rule.cardCustomerType,
            cardScheme: rule.cardScheme,
          }
        );
      })
      .filter((rule): rule is TransactionFee => !!rule);

    const amexFee: AmexTransactionFee | undefined =
      data.amexFee && amexTransactionFeeConfigRule?.ref
        ? {
            configRef: amexTransactionFeeConfigRule.ref,
            percentage: data.amexFee,
          }
        : undefined;

    const transactionFees = {
      defaultFee: {
        configRef: offerConfiguration?.transactionFeeConfig?.defaultFee?.ref,
        percentage: data.transactionFeeDefault,
      },
      rules,
      amexFee,
    };
    dispatch(setContractTransactionFees(transactionFees));

    navigate(nextPage());
  };

  return (
    <SingleColumn
      footer={
        <>
          <Button
            onClick={() => {
              navigate(prevPage());
            }}
            variant="tertiary"
            label={t('common.backButton')}
            icon="arrowLeft"
            iconPosition="left"
          />
          <Button
            form="transaction-fees"
            type="submit"
            label={t('common.continueButton')}
            disabled={!screenCompleted}
            testId="transactionFees_submitButton"
          />
        </>
      }
    >
      <form id="transaction-fees" onSubmit={handleSubmit(onSubmit)}>
        <>
          <section>
            <header>
              <Display size="s">{t('onboarding.v2.offer.contractTerms.transactionFees.title')}</Display>
            </header>
            <Paragraph size="s" color="tertiary">
              {transactionFeeRulesExists
                ? t('onboarding.v2.offer.contractTerms.transactionFees.info')
                : t('onboarding.v2.offer.contractTerms.transactionFee.info', {
                    min: defaultMinFee,
                    max: defaultMaxFee,
                  })}
            </Paragraph>
          </section>
          <PricingRow>
            <Column>
              <PricingRow>
                <Column>
                  <FeesListing>
                    {transactionFeeRules.map((rule, i) => {
                      return (
                        <TransactionFeeElement
                          key={`transactionFeeRules[${rule.ref}]`}
                          minFee={rule.transactionFeeRange.minPercentage}
                          maxFee={rule.transactionFeeRange.maxPercentage}
                          name={`transactionFeeRules.${rule.ref}`}
                          testId={`transaction-fee-${i}`}
                          label={`${rule.cardScheme} ${rule.region} ${rule.cardType} ${rule.cardCustomerType}`.toLowerCase()}
                          defaultValue={
                            contractTerms?.transactionFees?.rules?.[
                              transactionFeeRules.indexOf(rule)
                            ]?.percentage.toString() || ''
                          }
                          errors={errors}
                          control={control}
                        />
                      );
                    })}
                    <TransactionFeeElement
                      minFee={defaultMinFee}
                      maxFee={defaultMaxFee}
                      name={'transactionFeeDefault'}
                      testId={`transaction-fee-default`}
                      label={
                        transactionFeeRulesExists
                          ? t('onboarding.v2.offer.contractTerms.transactionFees.allOtherCards')
                          : t('onboarding.v2.offer.contractTerms.transactionFee.label')
                      }
                      defaultValue={contractTerms?.transactionFees?.defaultFee?.percentage.toString() || ''}
                      errors={errors}
                      control={control}
                    />
                    {amexTransactionFeeConfigRule && (
                      <AmexFeeElement
                        minFee={amexTransactionFeeConfigRule.transactionFeeRange.minPercentage}
                        maxFee={amexTransactionFeeConfigRule.transactionFeeRange.maxPercentage}
                        name={`transactionFeeRules.${amexTransactionFeeConfigRule.ref}`}
                        defaultValue={
                          amexTransactionFeeConfigRule.transactionFeeRange.defaultPercentage.toString() || ''
                        }
                        ruleRef={`transactionFeeRules.${amexTransactionFeeConfigRule.ref}`}
                        errors={errors}
                        control={control}
                      />
                    )}
                  </FeesListing>
                </Column>
              </PricingRow>
            </Column>
          </PricingRow>
        </>
      </form>
    </SingleColumn>
  );
};

const FeesListing = styled.div`
  display: grid;
  gap: ${({ theme }) => theme.spacings['3']};
  padding-top: ${({ theme }) => theme.spacings['3']};
`;

export default TransactionFees;
