import { useRef, useEffect, DependencyList, EffectCallback } from 'react';

export const useDidUpdateEffect = (fn: EffectCallback, inputs: DependencyList): void => {
  const hasMountedRef = useRef(false);

  useEffect(() => {
    if (hasMountedRef.current) {
      fn();
    } else {
      hasMountedRef.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, inputs);
};
