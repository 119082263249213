import styled from 'styled-components/macro';
import { spacing } from 'constants/spacings';
import { color } from 'constants/colors';

export const Fieldset = styled.fieldset`
  border: 0;
  margin: 0;
  padding: 0;
  width: 100%;
`;

export const Legend = styled.legend`
  padding: 0;
  margin-bottom: ${({ theme }) => `${theme.spacings['1.5']}`};
  line-height: initial;
  font-size: inherit;
`;

export const RadioWrapper = styled.div<{ size: 'sm' | 'md'; styles?: string }>`
  min-height: ${({ size }) => (size === 'md' ? '56px' : '38px')};
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  ${({ styles }) => styles};
`;

export const RadioIcon = styled.span`
  position: absolute;
  left: 0;
  height: 24px;
  width: 24px;
  margin: 4px 0;
  border-radius: 100%;
  border: 1px solid ${color.ashGray400};

  &::after {
    content: '';
    position: absolute;
    display: none;
  }
`;

export const Label = styled.label<{
  size?: 'sm' | 'md';
  disabled?: boolean;
  verticalAlign?: string;
}>`
  position: relative;
  min-height: ${({ size }) => (size === 'md' ? '56px' : '38px')};
  display: inline-flex;
  align-items: ${({ verticalAlign }) => (verticalAlign === 'top' ? 'flex-start' : 'center')};
  cursor: ${({ disabled }) => (disabled ? 'normal' : 'pointer')};
  padding-left: 36px;
  margin-bottom: 0;

  &:not(:last-child) {
    margin-right: ${spacing.s3};
  }

  ${({ disabled }) => {
    if (!disabled) {
      return `
        &:hover {
          ${RadioIcon} {
            background-color: ${color.ashGray100};
            border: 1px solid ${color.ashGray400Hover};
          }
        }
      `;
    }
  }}
`;

type RadioInputProps = {
  testId?: string;
};

export const RadioInput = styled.input<RadioInputProps>`
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;

  &:checked ~ ${RadioIcon} {
    background-color: ${({ disabled }) => (disabled ? color.ashGray100 : color.primaryBlack)};

    &::after {
      display: block;
      width: 8px;
      height: 8px;
      border-radius: 100%;
      background-color: ${({ disabled }) => (disabled ? color.ashGray400 : color.primaryWhite)};
      top: 50%;
      left: 50%;
      transform: translate3d(-50%, -50%, 0);
    }
  }

  &:focus ~ ${RadioIcon} {
    border: none;
    box-shadow: 0 0 0 2px ${color.primaryBlack};
  }

  &:disabled ~ ${RadioIcon} {
    border: 1px solid ${color.ashGray200};
  }
`;
