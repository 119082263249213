import { Heading } from '@loomispay/vault';
import Form from 'common/components/forms/Form';
import { TextInput } from 'common/components/forms/TextInput/TextInput';
import { CompanyDataForm } from 'features/common/types';
import { getCompanyData, getCountryCode, getOfferConfiguration } from 'features/onboardingV2/store/sales/selectors';
import { fetchOfferConfiguration, updateCompanyInfoManually } from 'features/onboardingV2/store/sales/actions';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components/macro';
import { DatePicker } from 'common/components/forms/DatePicker';
import { DropDownOption } from 'constants/types';
import { Dropdown } from 'common/components/forms/Dropdown/Dropdown';

type Props = {
  setIsCompanyDataFilled: (formCompleted: boolean) => void;
};

export const CompanyDataManualForm = ({ setIsCompanyDataFilled }: Props) => {
  const { t } = useTranslation();
  const { formState, watch, handleSubmit, setValue, control, errors } = useForm<CompanyDataForm>({
    mode: 'all',
  });
  const dispatch = useDispatch();
  const countryCode = useSelector(getCountryCode);
  const companyData = useSelector(getCompanyData);
  const offerConfiguration = useSelector(getOfferConfiguration);
  const [companyRegisterDate, setCompanyRegisterDate] = useState<string | undefined>(companyData.registrationDate);
  const formCompanyData = watch();
  const isFormCompleted = formState.isValid;
  const companyTypes = offerConfiguration?.companyTypes || {};
  const companyTypesDropdownOptions: DropDownOption<string>[] = Object.values(companyTypes)
    .map(val => ({
      value: val,
      label: countryCode === 'NO' ? t(`onboarding.v2.companyInfo.companyForms.no.${val}`) : val,
    }))
    .sort((a, b) => a.label.localeCompare(b.label));

  useEffect(() => {
    if (!offerConfiguration.companyTypes) {
      dispatch(fetchOfferConfiguration());
    }
  }, [dispatch, offerConfiguration]);

  const submitCompanyDataForm = () => {
    setIsCompanyDataFilled(isFormCompleted);
    if (Object.keys(formCompanyData).length > 0 && isFormCompleted) {
      const { address } = formCompanyData;
      dispatch(
        updateCompanyInfoManually({
          ...formCompanyData,
          companyForm:
            formCompanyData.companyFormOption && Object.keys(formCompanyData.companyFormOption).length > 0
              ? formCompanyData.companyFormOption?.value
              : formCompanyData.companyForm,
          address: { ...address, countryCode: countryCode },
          registrationDate: companyRegisterDate,
        })
      );
    }
  };

  useEffect(() => {
    submitCompanyDataForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFormCompleted, JSON.stringify(formCompanyData)]);

  return (
    <Form spacing="2" id="manualCompanyData" onSubmit={handleSubmit(submitCompanyDataForm)}>
      <CompanyDataGridWrapper>
        <TextInput
          label={t('onboarding.v2.companyInfo.companyName')}
          name="companyName"
          control={control}
          errors={errors}
          setValue={(value?: string) => setValue('companyName', value)}
          defaultValue={companyData.companyName || ''}
          rules={{
            required: t('common.form.validationMessages.required'),
          }}
          trimOnBlur
        />
        {Object.keys(companyTypes).length ? (
          <Dropdown
            label={t('onboarding.v2.companyInfo.companyForm')}
            name="companyFormOption"
            control={control}
            options={companyTypesDropdownOptions}
            setValue={(option: Record<string, string>) => setValue('companyFormOption', option.value)}
            defaultValue={companyTypesDropdownOptions.find(option => companyData.companyForm === option.value)}
            rules={{
              required: t('common.form.validationMessages.required'),
            }}
            testId="companyFormOption"
          />
        ) : (
          <TextInput
            label={t('onboarding.v2.companyInfo.companyForm')}
            name="companyForm"
            control={control}
            errors={errors}
            setValue={(value?: string) => setValue('companyForm', value)}
            defaultValue={companyData.companyForm || ''}
            rules={{
              required: t('common.form.validationMessages.required'),
            }}
            trimOnBlur
          />
        )}
      </CompanyDataGridWrapper>
      <CompanyDataGridWrapper>
        <DatePicker
          name="registrationDate"
          format="yyyy-MM-dd"
          label={t('onboarding.v2.companyInfo.registrationDate')}
          size="sm"
          control={control}
          errors={errors}
          maxDate={new Date()}
          selected={companyData.registrationDate ? new Date(Date.parse(companyData.registrationDate)) : null}
          defaultValue={companyData.registrationDate}
          setValue={(value?: string) => setValue('registrationDate', value)}
          onChange={(date: Date) => {
            setValue('registrationDate', date.toLocaleDateString('sv-SE'));
            if (date) {
              setCompanyRegisterDate(date.toLocaleDateString('sv-SE'));
              submitCompanyDataForm();
            }
          }}
          testId="registrationDate"
          useCustomMonthYearPicker
        />
      </CompanyDataGridWrapper>
      <Heading size="xxs" noGutter sansSerif>
        {t('onboarding.v2.companyInfo.address')}
      </Heading>
      <CompanyDataGridWrapper>
        <TextInput
          label={t('onboarding.v2.companyInfo.streetName')}
          name="address.address"
          control={control}
          errors={errors}
          setValue={(value?: string) => setValue('address.address', value)}
          defaultValue={companyData.address?.address || ''}
          rules={{
            required: t('common.form.validationMessages.required'),
          }}
          trimOnBlur
        />
        <CompanyDataGridWrapper>
          <TextInput
            label={t('onboarding.v2.companyInfo.zipCode')}
            name="address.zipCode"
            control={control}
            errors={errors}
            setValue={(value?: string) => setValue('address.zipCode', value)}
            defaultValue={companyData.address?.zipCode || ''}
            rules={{
              required: t('common.form.validationMessages.required'),
            }}
            trimOnBlur
          />
          <TextInput
            label={t('onboarding.v2.companyInfo.city')}
            name="address.city"
            control={control}
            errors={errors}
            setValue={(value?: string) => setValue('address.city', value)}
            defaultValue={companyData.address?.city || ''}
            rules={{
              required: t('common.form.validationMessages.required'),
            }}
            trimOnBlur
          />
        </CompanyDataGridWrapper>
      </CompanyDataGridWrapper>
    </Form>
  );
};

export const CompanyDataGridWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: ${({ theme }) => theme.spacings[3]};
`;

export const CompanyDataSubmitButton = styled.div`
  display: flex;
  justify-content: end;
`;
