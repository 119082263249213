import { Amount, OfferItem, OfferItemPriceType, Store } from 'features/common/types';

export function getOrderItemTotalPrice(priceType: OfferItemPriceType, item: OfferItem): Amount {
  const costs = item.prices.filter(price => price.priceType === priceType);
  return costs.reduce<Amount>(
    (acc, offerItemPrice) => {
      return {
        amount: acc.amount + item.quantity * offerItemPrice.amount,
        currencyCode: offerItemPrice.currencyCode,
      };
    },
    { amount: 0, currencyCode: 'SEK' }
  );
}

function getMultipleOrderItemsTotalPrice(priceType: OfferItemPriceType, items?: OfferItem[]): Amount | undefined {
  if (!items || items.length === 0) {
    return undefined;
  }

  return items.reduce<Amount>(
    (acc, offerItem) => {
      const singleItemCost = getOrderItemTotalPrice(priceType, offerItem);
      return {
        amount: acc.amount + singleItemCost.amount,
        currencyCode: singleItemCost.currencyCode,
      };
    },
    { amount: 0, currencyCode: 'SEK' }
  );
}

export function getTotalMonthlyFee(priceType: OfferItemPriceType, stores: Store[]): Amount {
  return stores.reduce<Amount>(
    (acc, store) => {
      const storeTerminalsCost = getMultipleOrderItemsTotalPrice(priceType, store.storeOfferItems?.terminals);
      return {
        amount: acc.amount + (storeTerminalsCost?.amount || 0),
        currencyCode: storeTerminalsCost?.currencyCode || acc.currencyCode,
      };
    },
    { amount: 0, currencyCode: 'SEK' }
  );
}

export function getAddonsTotalCost(priceType: OfferItemPriceType, stores: Store[]): Amount {
  return stores.reduce<Amount>(
    (acc, store) => {
      const storeTerminalsCost = getMultipleOrderItemsTotalPrice(priceType, store.storeOfferItems?.addons);
      return {
        amount: acc.amount + (storeTerminalsCost?.amount || 0),
        currencyCode: storeTerminalsCost?.currencyCode || acc.currencyCode,
      };
    },
    { amount: 0, currencyCode: 'SEK' }
  );
}
