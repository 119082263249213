import { Heading, Icon, Label, MediumIcons, Paragraph, spacings, Text, useMedia, useTheme } from '@loomispay/vault';
import { FileLike } from '@rpldy/shared';
import { TextInput } from 'common/components/forms/TextInput/TextInput';
import Hr from 'common/components/Hr';
import { notifications } from 'common/components/Notification/notifications';
import { useCallback, useEffect } from 'react';
import { Control, FieldErrors } from 'react-hook-form';
import { UseFormMethods } from 'react-hook-form/dist/types';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';
import { isPersonalId, patterns } from 'utils/validation';
import { FileUpload } from '../../../components/upload/OnboardingFileUpload';
import { OwnerType, PoliticallyExposedInfo, Representative, SigningRole } from '../../../store/types';
import { PepInfoWrapper } from './PepInfoWrapper';
import { parse, isWithinInterval, subYears } from 'date-fns';
import { BoxWrapper } from 'common/components/Box';
import { Grid, GridItem } from 'common/components/Grid';
import { useParams } from 'react-router-dom';
import { BaseQueryParams } from 'features/onboardingV2/data/types';
import { useOnboardingData } from 'features/onboardingV2/data/queries';
import { CountryCode } from 'constants/types';
import { useDeletePersonalDocument, useUploadPersonalDocument } from 'features/onboardingV2/data/mutations';

export interface LabelWithIconProps {
  icon: MediumIcons;
  text: string;
}

const LabelWithIcon = ({ icon, text }: LabelWithIconProps) => {
  return (
    <IconLabelContent>
      <Icon size="m" name={icon} />
      <Label size="l">{text}</Label>
    </IconLabelContent>
  );
};

type Props = {
  fullName: string;
  personalId?: string;
  pepInfo?: PoliticallyExposedInfo;
  email?: string;
  identifier: string;
  ownerType?: OwnerType;
  signingRoles?: SigningRole[];
  isSignatory: boolean;
  isPersonalIdNeeded?: boolean;
  isPersonalNumberNeeded?: boolean;
  isEmailNeeded?: boolean;
  isDateOfBirthCollected?: boolean;
  dateOfBirthFormat: string;
  control: Control;
  errors: FieldErrors;
  testId?: number;
  watch: UseFormMethods['watch'];
  register: UseFormMethods['register'];
  setValue: UseFormMethods['setValue'];
};

export const AdditionalInformationContent = ({
  fullName,
  personalId,
  pepInfo,
  email,
  identifier,
  ownerType,
  signingRoles,
  isSignatory,
  isPersonalIdNeeded,
  isPersonalNumberNeeded,
  isEmailNeeded,
  isDateOfBirthCollected,
  dateOfBirthFormat,
  control,
  errors,
  testId,
  watch,
  register,
  setValue,
}: Props) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const isMobile = useMedia('mobile');
  const { countryCode, offerRef } = useParams<BaseQueryParams>();
  const { data: onboardingData, refetch } = useOnboardingData({ countryCode, offerRef });
  const representative: Representative =
    (onboardingData?.owners.find(o => o.ref === identifier) as Representative) ||
    (onboardingData?.signatories.find(s => s.ref === identifier) as Representative);

  const representativeGroup = isSignatory ? 'signers' : 'owners';
  const representativeType = ownerType || signingRoles?.find(role => role === 'LEGAL_REPRESENTATIVE') || 'OTHER_OWNER';

  const document = representative?.idDocument;
  const documentRef = document?.ref;

  const { mutate: uploadPersonalDocument, isSuccess: personalDocumentUploaded } = useUploadPersonalDocument();
  const { mutate: deletePersonalDocument, isSuccess: personalDocumentDeleted } = useDeletePersonalDocument();

  const acceptableFileTypes = ['image/png', 'image/jpeg', 'application/pdf'];

  const handleRemoval = useCallback(async () => {
    try {
      if (documentRef) {
        deletePersonalDocument({
          countryCode,
          merchantRef: offerRef,
          representativeRef: identifier,
          documentRef,
        });
      }
    } catch (e) {
      notifications.error(t('common.serverError'));
      return false;
    }
    return true;
  }, [countryCode, deletePersonalDocument, documentRef, identifier, offerRef, t]);

  const handleUpload = async (file: FileLike) => {
    try {
      uploadPersonalDocument({
        countryCode,
        merchantRef: offerRef,
        representativeRef: identifier,
        file,
      });
      notifications.success(t('onboarding.v2.notifications.fileUploadSuccess'));
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      if (
        e?.error?.response?.status === 400 &&
        e?.error?.response?.data?.generalErrors?.includes('file_size_exceeded')
      ) {
        notifications.error(t('errorCodes.file_size_exceeded'));
      } else if (
        e?.error?.response?.status === 400 &&
        e?.error?.response?.data?.generalErrors?.includes('file_is_corrupted')
      ) {
        notifications.error(t('errorCodes.file_is_corrupted'));
      } else {
        notifications.error(t('common.serverError'));
      }
      return false;
    }
    return true;
  };

  const validateDoB = (value: string) => {
    const now = new Date();
    const givenDate = parse(value, dateOfBirthFormat, now);
    return isWithinInterval(givenDate, {
      start: subYears(now, 120),
      end: subYears(now, 18),
    });
  };

  useEffect(() => {
    // refetch onboarding data when uploaded documents changed
    refetch();
  }, [personalDocumentUploaded, personalDocumentDeleted, refetch]);

  return onboardingData ? (
    <>
      <StyledBox data-testid={`${representativeGroup}.${testId}.box`}>
        <NameWrapper>
          <Heading size="m" noGutter>
            {fullName}
          </Heading>
          <Paragraph size="m" color="secondary" noGutter>
            {t(`onboarding.v2.owners.type.${representativeType}` as const)}
          </Paragraph>
          <Hr color={theme.color.border.disabledBorder} margin={`${spacings['1']} 0`} />
        </NameWrapper>
        <Grid gutter={8} vGap="2">
          {isSignatory && isEmailNeeded && (
            <GridItem s={4} m={4} l={6}>
              <SignatoryDataWrapper>
                <TextInput
                  label={t('common.email')}
                  name={`${representativeGroup}.${identifier}.email`}
                  type="email"
                  placeholder={t('onboarding.v2.additionalInformation.emailPlaceholder')}
                  rules={{
                    pattern: {
                      value: patterns.email,
                      message: t('common.form.validationMessages.invalidEmail'),
                    },
                    required: t('common.form.validationMessages.required'),
                  }}
                  defaultValue={email || ''}
                  control={control}
                  errors={errors}
                  testId={`${representativeGroup}.${testId}.email`}
                />
                {isDateOfBirthCollected && (
                  <TextInput
                    label={t('common.dateOfBirth')}
                    name={`${representativeGroup}.${identifier}.dateOfBirth`}
                    type="text"
                    placeholder={dateOfBirthFormat.toUpperCase()}
                    rules={{
                      validate: (value: string) =>
                        validateDoB(value) || t('common.form.validationMessages.invalidDate'),
                      required: t('common.form.validationMessages.required'),
                    }}
                    defaultValue={''}
                    control={control}
                    errors={errors}
                    testId={`${representativeGroup}.${testId}.dateOfBirth`}
                    setValue={(value?: string) =>
                      value && value.length && setValue(`${representativeGroup}.${identifier}.dateOfBirth`, value)
                    }
                    trimOnBlur
                  />
                )}
              </SignatoryDataWrapper>
            </GridItem>
          )}
          <PepInfoWrapper
            representativeGroup={representativeGroup}
            identifier={identifier}
            isSignatory={isSignatory}
            pepInfo={pepInfo}
            control={control}
            errors={errors}
            watch={watch}
            testId={testId}
            isMobile={isMobile}
          />
          {isPersonalIdNeeded && identifier && (
            <>
              <GridItem s={4} m={4} l={10}>
                <IdentificationWrapper>
                  <LabelWithIcon icon="passport" text={t('onboarding.v2.additionalInformation.identification.label')} />
                  <Text size="s" color="secondary">
                    {t('onboarding.v2.additionalInformation.identification.text')}
                    <br />
                    <List>
                      <ListItem> {t('onboarding.v2.additionalInformation.identification.bullet.1')}</ListItem>
                      <ListItem> {t('onboarding.v2.additionalInformation.identification.bullet.2')}</ListItem>
                    </List>
                  </Text>
                </IdentificationWrapper>
              </GridItem>
              <GridItem s={4} m={4} l={6}>
                <IdentificationWrapper>
                  <Label size="s">{t('onboarding.v2.additionalInformation.identification.document')}</Label>
                  <FileUpload
                    label={t('onboarding.v2.additionalInformation.identification.addFile')}
                    acceptedTypes={acceptableFileTypes}
                    onUpload={handleUpload}
                    onRemove={handleRemoval}
                    isMobile={isMobile}
                    name={`${representativeGroup}.${identifier}.files`}
                    fileName={document?.originalFileName}
                    testId={`${representativeGroup}.${testId}.fileUpload`}
                    register={register}
                    setValue={setValue}
                    errors={errors}
                  />
                </IdentificationWrapper>
              </GridItem>
            </>
          )}
          {isPersonalNumberNeeded && (
            <GridItem s={4} m={4} l={6}>
              <TextInput
                label={t('common.personalNumber')}
                name={`${representativeGroup}.${identifier}.personalId`}
                placeholder={t('onboarding.v2.additionalInformation.personalNumberPlaceholder')}
                rules={{
                  required: t('common.form.validationMessages.required'),
                  validate: (value: string) =>
                    isPersonalId(countryCode as CountryCode, value) ||
                    t('common.form.validationMessages.invalidPersonalId'),
                }}
                defaultValue={personalId || ''}
                control={control}
                maskConfig={countryCode === 'DK' ? { mask: '999999-9999', maskChar: '-' } : undefined}
                errors={errors}
                testId={`${representativeGroup}.${testId}.personalId`}
                setValue={(value?: string) =>
                  value && value.length && setValue(`${representativeGroup}.${identifier}.personalId`, value)
                }
                trimOnBlur
              />
            </GridItem>
          )}
        </Grid>
      </StyledBox>
    </>
  ) : null;
};

const IconLabelContent = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const StyledBox = styled(BoxWrapper)`
  overflow: inherit;
`;

const NameWrapper = styled.div`
  display: grid;
  gap: ${({ theme }) => theme.spacings['1']};
  padding: ${spacings[1]};
`;

const List = styled.ul`
  padding-left: ${({ theme }) => theme.spacings[2]};
  margin: 0;
  list-style: none;
`;

const ListItem = styled.li`
  &:before {
    content: '•';
    display: inline-block;
    width: 0.6em;
    margin-left: -0.5em;
  }
`;

const IdentificationWrapper = styled.div`
  display: grid;
  gap: ${({ theme }) => theme.spacings['1']};
`;

const SignatoryDataWrapper = styled.div`
  display: grid;
  gap: ${({ theme }) => theme.spacings['2']};
`;
