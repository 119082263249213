import { ReactNode } from 'react';
import styled from 'styled-components/macro';
import { Button, spacings, Text, useMedia } from '@loomispay/vault';
import { CallToActionGridItem, SimpleGridItem } from '../common/components/GridLayout';
import { useTranslation } from 'react-i18next';
import Hr from '../common/components/Hr';
import { useNavigate } from 'react-router-dom';
import { Grid } from 'common/components/Grid';

type Props = {
  title: string;
  closeUrl: string;
  label: string;
  isScreenCompleted: boolean;
  formId: string;
  children: ReactNode;
  gridGutter?: number;
  noGutter?: boolean;
};

const ModalHeader = styled.div<{ isMobile: boolean }>`
  display: flex;
  align-items: center;
  padding: ${({ isMobile, theme }) => (isMobile ? `${theme.spacings[2]} 0` : `${theme.spacings[3]} 0`)};
`;

const ModalWrapper = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-content: center;
  width: 100%;
  min-height: 100vh;
  background-color: ${({ theme }) => theme.color.base.grey.white};
`;

const CloseButtonWrapper = styled.div<{ isDesktop: boolean }>`
  position: absolute;
  right: ${({ isDesktop, theme }) => (!isDesktop ? `${theme.spacings['0.5']}` : `${theme.spacings[3]}`)};
`;

const OnboardingModalLayout = ({
  title,
  closeUrl,
  label,
  isScreenCompleted,
  formId,
  children,
  gridGutter = 16,
  noGutter = false,
}: Props) => {
  const { t } = useTranslation();
  const isMobile = useMedia('mobile');
  const isTablet = useMedia('tablet');
  const isDesktop = useMedia('desktop');
  const navigate = useNavigate();

  return (
    <ModalWrapper>
      <Grid gutter={gridGutter}>
        <SimpleGridItem>
          <ModalHeader isMobile={isMobile}>
            <Text size="l" weight="semiBold">
              {title}
            </Text>
            <CloseButtonWrapper isDesktop={isDesktop}>
              <Button
                icon="close"
                iconPosition="left"
                variant="tertiary"
                onClick={() => {
                  navigate(closeUrl);
                }}
                size="s"
                label={!isMobile ? t('common.closeButton') : ''}
              />
            </CloseButtonWrapper>
          </ModalHeader>
        </SimpleGridItem>
        <Hr margin={`0 0 ${spacings['6']} 0`} />
      </Grid>

      <Grid noGutter={noGutter}>
        <SimpleGridItem>{children}</SimpleGridItem>
      </Grid>

      <Grid gutter={gridGutter}>
        {isDesktop && (
          <SimpleGridItem>
            <Button type="submit" label={label} testId={'submitButton'} disabled={!isScreenCompleted} form={formId} />
          </SimpleGridItem>
        )}

        {(isMobile || isTablet) && (
          <CallToActionGridItem isMobile={isMobile}>
            <Button
              type="submit"
              disabled={!isScreenCompleted}
              variant="primary"
              label={label}
              testId="submitButton"
              fullWidth={isMobile || isTablet}
              form={formId}
            />
          </CallToActionGridItem>
        )}
      </Grid>
    </ModalWrapper>
  );
};

export default OnboardingModalLayout;
