import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Dispatch } from 'redux';
import store from '../../../../store/store';
import { MerchantOnboardingData } from '../../../common/types';
import {
  clearOnboardingState,
  fetchMerchantOffer,
  getOfferOnboardingData,
  savePartial,
  setCountryCode,
  setOfferRef,
} from '../../store/merchant/actions';
import { merchantOnboardingV2_2Flow } from './merchantFlows';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { getOffer, getOnboardingData } from 'features/onboardingV2/store/merchant/selectors';
import { reactLocalStorage } from 'reactjs-localstorage';
import { findRoute, STEP_BACK, STEP_FORWARD } from 'services/flowRoutingService';
import { RouteConfig } from 'common/route/createFeatureRouter';
import { OfferType } from 'features/onboardingV2/store/types';
import useCognitoUser from 'features/loginPasswordless/useCognitoUser';
import { onboardingRoutes } from '../../OnboardingRouter';
import { useMerchantContext } from '../../../../services/MerchantContext';
import { BaseQueryParams } from '../../data/types';

export function useMerchantFlow() {
  const { pathname } = useLocation();
  const { isAuthenticated } = useCognitoUser();
  const { loadMerchants } = useMerchantContext();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { countryCode: urlCountryCode, offerRef: urlOfferRef } = useParams<BaseQueryParams>();
  const onboardingData = useSelector(getOnboardingData);
  const offer = useSelector(getOffer);

  const { countryCode, salesRef, offerType, ref } = offer;
  const flow = merchantOnboardingV2_2Flow({ offerType });

  const offerRef = onboardingData.offerRef || offer.ref;

  useEffect(() => {
    // guard for merchant flow if user in not authenticated using passwordless auth
    if (!isAuthenticated()) {
      // TODO: we are loosing all history with those redirects. Why not navigate?
      if (offerRef) {
        window.location.href = `/onboarding/v2/${offerRef}`;
      } else {
        window.location.href = '/logout';
      }
    }
    if (!offerRef) {
      dispatch(clearOnboardingState());
      loadMerchants().then(() => {
        dispatch(getOfferOnboardingData());
        dispatch(fetchMerchantOffer());
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, isAuthenticated, navigate, offerRef]);

  const pagesMapping = (page: string, offerType?: OfferType) => {
    const merchantPaySpecificRoutes: Record<string, RouteConfig> = {
      shippingInformationV2: onboardingRoutes.shippingInformationV2,
      additionalQuestions: onboardingRoutes.additionalQuestions,
    };

    const merchantOnboardingFlow: Record<string, RouteConfig> = {
      locations: onboardingRoutes.locationsMerchant,
      ownersV2: onboardingRoutes.ownersV2,
      signingCombinations: onboardingRoutes.signingCombinations,
      bankInformation: onboardingRoutes.bankInformation,
      additionalInformation: onboardingRoutes.additionalInformation,
      ...(!['CASH'].includes(offerType || '') && merchantPaySpecificRoutes),
    };

    return new Map(Object.entries(merchantOnboardingFlow)).get(page)?.path || '';
  };

  const getOfferRef = (): string => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const state = store.getState() as any;
    return state.onboardingV2.merchant.offer.ref || urlOfferRef;
  };

  const getCountryCode = (): string => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const state = store.getState() as any;
    return state.onboardingV2.merchant.offer.countryCode || urlCountryCode;
  };

  const applyOfferRef = (path: string): string =>
    path.includes(':offerRef') ? path.replace(':offerRef', getOfferRef()) : path;

  const applyCountryCode = (path: string): string =>
    path.includes(':countryCode') ? path.replace(':countryCode', getCountryCode()) : path;
  const applyParams = (path: string): string => applyOfferRef(applyCountryCode(path));

  const getFirstPageAfterOverview = (flow: RouteConfig[]): string => {
    const firstPageRoute = flow[1];

    return firstPageRoute.path ? applyParams(firstPageRoute.path) : '';
  };

  const getNextPage = (flow: RouteConfig[], path: string): string => {
    const route = findRoute(applyParams(path), flow, STEP_FORWARD, getCountryCode(), getOfferRef());
    return route.path ? applyParams(route.path) : '';
  };

  const getPrevPage = (flow: RouteConfig[], path: string): string => {
    const route = findRoute(applyParams(path), flow, STEP_BACK, getCountryCode(), getOfferRef());
    return route.path ? applyParams(route.path) : '';
  };

  const nextPage = (): string => getNextPage(flow, pathname);
  const prevPage = (): string => getPrevPage(flow, pathname);
  const firstPageAfterOverview = (): string => getFirstPageAfterOverview(flow);

  /* eslint-disable @typescript-eslint/no-explicit-any */
  const goToNextPage = async (dispatch: Dispatch<any>, merchantOnboardingData: MerchantOnboardingData) => {
    await dispatch(savePartial(merchantOnboardingData));
    navigate(nextPage());
  };

  const getContinuationPage = (): string => {
    if (!onboardingData.offerRef || !offer.ref) {
      throw new Error('Invalid state. Missing onboarding data or the offer');
    }
    const { page, status } = onboardingData;
    if (['NOT_STARTED', 'IN_PROGRESS'].includes(status)) {
      if (countryCode) {
        dispatch(setCountryCode(countryCode));
      }
      //TODO: not sure if it's needed
      if (ref) {
        dispatch(setOfferRef(ref));
      }
      if (salesRef) {
        // the purpose of toggleContext was to pass the context of the given offer so in the cases of sales it is his userId
        // and in the case of merchant, it is sales ref. For instance, if we want to create an offer in the sales flow using
        // production Roaring data and then in the onboarding flow we still want to know that we should use production Roaring data.
        // We do not enable manually a toggle for each newly created merchant that is why feature is evaluated for sales user.
        reactLocalStorage.set('toggleContext', salesRef);
      }
      return page
        ? applyParams(getNextPage(flow, pagesMapping(page, offerType)))
        : applyParams(getFirstPageAfterOverview(flow));
    } else if (status === 'EDD_ANSWERS_REQUIRED') {
      return applyParams(onboardingRoutes.additionalQuestions.path);
    } else if (['FORM_SUBMITTED', 'CONTRACT_PENDING', 'CONTRACT_SIGNED', 'KYC_REJECTED'].includes(status)) {
      return onboardingRoutes.done.path;
    } else {
      throw new Error(`Unsupported status: ${status}`);
    }
  };

  return {
    offer,
    onboardingData,
    isLoading: !onboardingData.offerRef || !offer.ref,
    nextPage,
    prevPage,
    firstPageAfterOverview,
    goToNextPage,
    getContinuationPage,
  };
}
