/* eslint-disable @typescript-eslint/no-empty-interface */
import { rootReducer } from './store';
import { AnyAction } from '@reduxjs/toolkit';
import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';

export type RootState = ReturnType<typeof rootReducer>;

declare module 'react-redux' {
  interface DefaultRootState extends RootState {}
}

export interface AnyActionWithPayload<P> extends AnyAction {
  payload: P;
}

/**
 * Types for the redux actions using Axios middleware
 */
export interface ActionAsyncRequestConfig<D> extends AxiosRequestConfig {
  data: D;
}

export interface ActionAsync<D> extends AnyAction {
  payload: {
    request: ActionAsyncRequestConfig<D>;
  };
}

export interface ActionAsyncSuccess<D> extends AnyActionWithPayload<AxiosResponse<D>> {
  meta: {
    previousAction: AnyActionWithPayload<unknown>;
  };
}

export interface ActionAsyncFail<E> extends AnyActionWithPayload<AxiosError<E>> {
  meta: {
    previousAction: AnyActionWithPayload<unknown>;
  };
}

export const SUCCESS_ACTION_SUFFIX = '_SUCCESS';
export const FAIL_ACTION_SUFFIX = '_FAIL';
export const CLEAN_ACTION_PREFIX = 'CLEAN_';

export const LIST_ACTION_SUFFIX = '_LIST';
export const LIST_SUCCESS_ACTION_SUFFIX = '_LIST' + SUCCESS_ACTION_SUFFIX;
export const LIST_FAIL_ACTION_SUFFIX = '_LIST' + FAIL_ACTION_SUFFIX;

export enum ActionType {
  REQUEST,
  SUCCESS,
  FAIL,
  CLEAN,
}

export const buildActionName = (name: string, actionType: ActionType = ActionType.REQUEST, isListAction = false) => {
  const actionName = isListAction ? name + LIST_ACTION_SUFFIX : name;
  switch (actionType) {
    case ActionType.REQUEST:
      return actionName;
    case ActionType.CLEAN:
      return CLEAN_ACTION_PREFIX + actionName;
    case ActionType.SUCCESS:
      return actionName + SUCCESS_ACTION_SUFFIX;
    case ActionType.FAIL:
      return actionName + FAIL_ACTION_SUFFIX;
    default:
      return actionName;
  }
};
