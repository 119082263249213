import { RouteConfig } from 'common/route/createFeatureRouter';
import { applyParams } from '../features/onboardingV2/data/utils';

export type FLOW_STEP = -1 | 1;
export const STEP_FORWARD = 1 as FLOW_STEP;
export const STEP_BACK = -1 as FLOW_STEP;

export const getCurrentRoute = (
  flow: RouteConfig[],
  path: string,
  countryCode?: string,
  offerRef?: string
): RouteConfig => {
  const currentRoute = flow.find(route => applyParams(route.path, countryCode, offerRef) === path);

  if (!currentRoute) {
    throw new Error(`Unsupported route in merchant flow: ${path}`);
  }

  return currentRoute;
};

export const findRoute = (
  path: string,
  currentFlow: RouteConfig[],
  step: FLOW_STEP,
  countryCode?: string,
  offerRef?: string
): RouteConfig => {
  const route = getCurrentRoute(currentFlow, path, countryCode, offerRef);
  const pageIndex = currentFlow.indexOf(route);
  const newPageIndex = pageIndex + step;

  if (newPageIndex >= currentFlow.length) {
    console.error(`Attempt to proceed from final page to next page in onboarding flow on page: ${path}`);
    return currentFlow[currentFlow.length - 1];
  }

  if (newPageIndex < 0) {
    console.error(`Attempt to proceed from first page to prev page in onboarding flow on page: ${path}`);
    return currentFlow[0];
  }

  return currentFlow[newPageIndex];
};
