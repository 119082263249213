import { usePartialUpdate } from 'features/onboardingV2/data/mutations';
import { useOffer, useOnboardingData, useOwners, useSignees } from 'features/onboardingV2/data/queries';
import { BaseQueryParams } from 'features/onboardingV2/data/types';
import { Owner, SigningCombination } from 'features/onboardingV2/store/types';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

export default function useOwnersView() {
  const { countryCode, offerRef } = useParams<BaseQueryParams>();
  const shouldFetchSignees = countryCode === 'ES';
  const [shouldFetchOwners, setShouldFetchOwners] = useState(false);
  const [hasFetchedOwners, setHasFetchedOwners] = useState(false);
  const [hasFetchedSignees, setHasFetchedSignees] = useState(!shouldFetchSignees);
  const [addingMoreOwnersDisabled, setAddingMoreOwnersDisabled] = useState(false);

  const { data: onboardingData, isSuccess: onboardingDataFetched } = useOnboardingData({ countryCode, offerRef });
  const { data: offerData } = useOffer({ countryCode, offerRef });
  const { mutate: uploadOwnersData, isSuccess: hasUpdatedOwners } = usePartialUpdate();

  const [owners, setOwners] = useState<Owner[]>([]);
  const [signees, setSignees] = useState<SigningCombination[]>([]);
  const companyNumber = offerData?.companyData.companyNumber;

  const { data: ownersData, isSuccess: ownersDataFetched } = useOwners({
    countryCode,
    companyNumber,
    shouldFetchOwners,
  });
  const { data: signeesData } = useSignees({ countryCode, companyNumber, shouldFetchSignees });

  const upsertOwner = (ownerData: Owner, ownerIndex?: number) => {
    const updatedOwners =
      ownerIndex !== undefined
        ? owners.map((owner, index) => (index === ownerIndex ? ownerData : owner))
        : [...owners, ownerData];
    updateOwners(updatedOwners, true);
  };

  const deleteOwner = (ownerIndex: number) => {
    const updatedOwners = owners.filter((_owner, index) => index !== ownerIndex);
    updateOwners(updatedOwners, true);
  };

  const updateOwners = useCallback(
    (owners: Owner[], isOwnersModified: boolean) => {
      if (onboardingData) {
        uploadOwnersData({
          countryCode,
          merchantRef: offerRef,
          data: { ...onboardingData, owners, isOwnersModified },
        });
      }
    },
    [countryCode, offerRef, onboardingData, uploadOwnersData]
  );

  useEffect(() => {
    if (onboardingDataFetched) {
      setOwners(onboardingData?.owners);
      if (!onboardingData?.owners.length && !onboardingData?.isOwnersModified) {
        setShouldFetchOwners(true);
      } else {
        setHasFetchedOwners(true);
      }
    }
  }, [
    onboardingDataFetched,
    onboardingData?.owners,
    onboardingData?.owners.length,
    onboardingData?.isOwnersModified,
    hasFetchedOwners,
  ]);

  useEffect(() => {
    if (ownersDataFetched && shouldFetchOwners && !hasFetchedOwners) {
      ownersData.length && updateOwners(ownersData, false);
      setShouldFetchOwners(false);
      setHasFetchedOwners(true);
    }
  }, [ownersDataFetched, ownersData, hasFetchedOwners, shouldFetchOwners, updateOwners]);

  useEffect(() => {
    if (signeesData?.length) {
      setSignees(signeesData);
      setHasFetchedSignees(true);
    }
  }, [countryCode, offerRef, onboardingData, signeesData, uploadOwnersData]);

  useEffect(() => {
    if (hasFetchedOwners) {
      setAddingMoreOwnersDisabled(
        owners.filter(owner => owner.type === 'BENEFICIARY_OWNER').length >= 3 ||
          owners.filter(owner => owner.type === 'ALTERNATIVE_BENEFICIARY_OWNER').length >= 1
      );
    }
  }, [owners, hasFetchedOwners]);

  return {
    owners,
    signees,
    addingMoreOwnersDisabled,
    hasFetchedOwners,
    hasFetchedSignees,
    hasUpdatedOwners,
    upsertOwner,
    deleteOwner,
  };
}
