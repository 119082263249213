import { Styles } from 'react-modal';
import { useTranslation } from 'react-i18next';

import { Modal } from '../Modal';
import { Button, Text } from '@loomispay/vault';
import { ConfirmationProps } from './confirmationTypes';
import { ModalBody, ModalFooter, ModalHeader } from '../modalCommons/ModalComponents';

export const customModalStyles: Styles = {
  content: {
    maxWidth: '400px',
    borderRadius: '16px',
    padding: '24px',
  },
};

export const Confirmation = ({
  isOpen,
  title,
  acceptButtonLabel,
  acceptButtonVariant,
  cancelButtonLabel,
  cancelButtonVariant,
  description,
  content,
  acceptCallback,
  cancelCallback,
  cancelButtonPresent = true,
}: ConfirmationProps) => {
  const { t } = useTranslation();
  return (
    <Modal
      style={customModalStyles}
      isOpen={isOpen}
      aria={{
        labelledby: 'heading',
        describedby: 'full_description',
      }}
    >
      <>
        <ModalHeader>{title}</ModalHeader>

        <ModalBody id="full_description">
          <Text size="m">{description}</Text>
          {content}
        </ModalBody>

        <ModalFooter>
          {cancelButtonPresent && (
            <Button
              testId={'confirmation-modal-cancel-btn'}
              onClick={cancelCallback}
              size="m"
              variant={cancelButtonVariant}
              type="button"
              label={cancelButtonLabel || t('common.no')}
            />
          )}
          <Button
            testId={'confirmation-modal-accept-btn'}
            onClick={acceptCallback}
            size="m"
            variant={acceptButtonVariant}
            type="button"
            label={acceptButtonLabel || t('common.yes')}
          />
        </ModalFooter>
      </>
    </Modal>
  );
};
