import { Amplify } from 'aws-amplify';

export function configureAmplify() {
  const cognitoOAuthConfig = {
    Auth: {
      region: process.env.REACT_APP_COGNITO_OAUTH_REGION,
      userPoolId: process.env.REACT_APP_COGNITO_OAUTH_USER_POOL_ID,
      userPoolWebClientId: process.env.REACT_APP_COGNITO_OAUTH_USER_POOL_WEBCLIENT_ID,
      oauth: {
        domain: process.env.REACT_APP_COGNITO_OAUTH_DOMAIN,
        scope: ['email', 'openid', 'aws.cognito.signin.user.admin', 'profile'],
        redirectSignIn: process.env.REACT_APP_COGNITO_OAUTH_REDIRECT_SIGN_IN,
        redirectSignOut: process.env.REACT_APP_COGNITO_OAUTH_REDIRECT_SIGN_OUT,
        responseType: 'code',
      },
    },
  };

  Amplify.configure(cognitoOAuthConfig);
}
