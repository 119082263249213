import { Heading } from '@loomispay/vault';
import { DatePicker } from 'common/components/forms/DatePicker';
import { TextInput } from 'common/components/forms/TextInput/TextInput';
import Grid from 'common/components/MainLayout/GridWrapper';
import { useOnboardingData } from 'features/onboardingV2/data/queries';
import { BaseQueryParams } from 'features/onboardingV2/data/types';
import { Control, FieldErrors, UseFormMethods } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

type Props = {
  control: Control;
  errors: FieldErrors;
  setValue?: UseFormMethods['setValue'];
};

export default function NotaryDetails({ control, errors, setValue }: Props) {
  const { t } = useTranslation();
  const { countryCode, offerRef } = useParams<BaseQueryParams>();
  const { data: onboardingData } = useOnboardingData({ countryCode, offerRef });
  const notaryDetails = onboardingData?.notaryDetails;

  return (
    <section>
      <Heading size="xxs" sansSerif>
        {t('cashServices.notary.heading')}
      </Heading>
      <Grid cols={2} spacing="3">
        <TextInput
          name="notaryDetails.notary"
          label={t('cashServices.notary.fullName')}
          control={control}
          errors={errors}
          rules={{ required: t('common.form.validationMessages.required') }}
          defaultValue={notaryDetails?.notary || ''}
          trimOnBlur
        />

        <TextInput
          name="notaryDetails.notaryProvince"
          label={t('cashServices.notary.notaryProvince')}
          control={control}
          errors={errors}
          rules={{ required: t('common.form.validationMessages.required') }}
          defaultValue={notaryDetails?.notary || ''}
          trimOnBlur
        />

        <div>
          <DatePicker
            name="notaryDetails.notarizedDeedDate"
            label={t('cashServices.notary.notarizedDeedDate')}
            control={control}
            errors={errors}
            rules={{ required: t('common.form.validationMessages.required') }}
            defaultValue={notaryDetails?.notarizedDeedDate || ''}
            onChange={(date: Date) => {
              setValue && setValue(`notaryDetails.notarizedDeedDate`, date.toLocaleDateString('sv-SE'));
            }}
            maxDate={new Date()}
            testId="notaryDetails.notarizedDeedDate"
            useCustomMonthYearPicker
          />
        </div>

        <TextInput
          name="notaryDetails.notarizedDeedNumber"
          label={t('cashServices.notary.notarizedDeedNumber')}
          control={control}
          errors={errors}
          rules={{ required: t('common.form.validationMessages.required') }}
          defaultValue={notaryDetails?.notarizedDeedNumber || ''}
          trimOnBlur
        />

        <TextInput
          name="notaryDetails.mercantileRegistry"
          label={t('cashServices.notary.mercantileRegistry')}
          control={control}
          errors={errors}
          rules={{ required: t('common.form.validationMessages.required') }}
          defaultValue={notaryDetails?.mercantileRegistry || ''}
          trimOnBlur
        />
      </Grid>
    </section>
  );
}
