import { PepPosition, PepRelation } from '../store/types';

export type DropdownOption<T> = { value: T };
export type PepForm = {
  isPep: string;
  position?: DropdownOption<PepPosition>;
  relation?: DropdownOption<PepRelation>;
};

export const pepPositionsOptions: DropdownOption<PepPosition>[] = [
  { value: 'HEAD_OF_STATE' },
  { value: 'MEMBER_OF_PARLIAMENT' },
  { value: 'POLITICAL_PARTY_LEADER' },
  { value: 'JUDGE' },
  { value: 'AUDITOR' },
  { value: 'AMBASSADOR_OR_MILITARY' },
  { value: 'STATE_OWNED_BUSINESS_MGMNT' },
  { value: 'INT_ORGANIZATION_LEADER' },
];

export const pepRelationsOptions: DropdownOption<PepRelation>[] = [
  { value: 'ME' },
  { value: 'SPOUSE' },
  { value: 'CHILD' },
  { value: 'CHILDS_SPUSE' },
  { value: 'PARENT' },
  { value: 'COWORKER' },
];
