import { Heading } from '@loomispay/vault';
import { TextInput } from 'common/components/forms/TextInput/TextInput';
import { Toggle } from 'common/components/ToggleWrapper/Toggle';
import { getStores } from 'features/onboardingV2/store/sales/selectors';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { CashServicesGridWrapper } from '../CashServices.styles';
import { CashServicesSectionTypes } from '../types';
import { maxValue } from 'utils/format';

export default function CashServicesClientInsurance({
  storeIndex,
  control,
  errors,
  clientInsuranceEnabled = false,
  loomisInsuranceEnabled = false,
}: CashServicesSectionTypes & { clientInsuranceEnabled?: boolean; loomisInsuranceEnabled?: boolean }) {
  const { t } = useTranslation();
  const locations = useSelector(getStores);

  const cashDataGeneralInfo = locations[storeIndex].cashData?.es?.generalInfo;
  const cashDataClientInsurance = locations[storeIndex].cashData?.es?.clientInsurance;

  return (
    <section>
      <Heading size="s">{t('cashServices.insurance')}</Heading>
      <CashServicesGridWrapper>
        <Toggle
          name={`store-${storeIndex}.generalInfo.insuranceIncluded`}
          label={t('cashServices.form.clientWantsInsurance')}
          control={control}
          errors={errors}
          defaultValue={cashDataGeneralInfo?.insuranceIncluded?.toString() || ''}
        />

        {loomisInsuranceEnabled && (
          <Toggle
            name={`store-${storeIndex}.generalInfo.isLoomisBeneficiary`}
            label={t('cashServices.form.isLoomisBeneficiary')}
            control={control}
            errors={errors}
            defaultValue={cashDataGeneralInfo?.isLoomisBeneficiary?.toString() || ''}
          />
        )}

        {clientInsuranceEnabled && (
          <>
            <TextInput
              label={t('cashServices.form.insuranceNumber')}
              name={`store-${storeIndex}.clientInsurance.insuranceNumber`}
              defaultValue={cashDataClientInsurance?.insuranceNumber || ''}
              control={control}
              errors={errors}
              rules={
                clientInsuranceEnabled
                  ? {
                      required: t('common.form.validationMessages.required'),
                    }
                  : undefined
              }
              trimOnBlur
            />
            <TextInput
              label={t('cashServices.form.insuranceCompany')}
              name={`store-${storeIndex}.clientInsurance.insuranceCompany`}
              defaultValue={cashDataClientInsurance?.insuranceCompany || ''}
              control={control}
              errors={errors}
              rules={
                clientInsuranceEnabled
                  ? {
                      required: t('common.form.validationMessages.required'),
                    }
                  : undefined
              }
              trimOnBlur
            />
            <TextInput
              label={t('cashServices.form.insuredAmount')}
              name={`store-${storeIndex}.clientInsurance.insuredAmount`}
              defaultValue={cashDataClientInsurance?.insuredAmount?.toString() || ''}
              control={control}
              errors={errors}
              rules={
                clientInsuranceEnabled
                  ? {
                      required: t('common.form.validationMessages.required'),
                    }
                  : undefined
              }
              type="number"
              currency="EUR"
              inputProps={{
                min: 0,
                max: maxValue(9, 2),
                step: 1,
              }}
            />
          </>
        )}
      </CashServicesGridWrapper>
    </section>
  );
}
