import { ReactNode } from 'react';
import styled, { css } from 'styled-components';
import { SpacingType } from 'common/types';

type JustifyContent = 'center' | 'flex-start' | 'flex-end' | 'space-between' | 'space-evenly' | 'space-around';
type AlignItems = 'flex-start' | 'flex-end' | 'center' | 'baseline' | 'stretch';
type FlexDirection = 'row' | 'row-reverse' | 'column' | 'column-reverse';

interface GridItemStyledProps {
  l: number;
  m: number;
  s: number;
  lOffset?: number;
  mOffset?: number;
  sOffset?: number;
  justifyContent?: JustifyContent;
  alignItems?: AlignItems;
  flexDirection?: FlexDirection;
}

interface GridItemProps extends GridItemStyledProps {
  children?: ReactNode;
}

interface RowStyledProps {
  gutter?: number;
  noGutter?: boolean;
  hGap?: SpacingType;
  vGap?: SpacingType;
}

interface RowProps extends RowStyledProps {
  children: ReactNode;
}

const breakpoints = {
  mobile: 480,
  tablet: 768,
  desktop: 1024,
  wideScreen: 1366,
};

const mediaQueries = {
  mobile: `(max-width: ${breakpoints.mobile}px)`,
  tablet: `(min-width: ${breakpoints.mobile + 1}px) and (max-width: ${breakpoints.desktop}px)`,
  desktop: `(min-width: ${breakpoints.desktop + 1}px)`,
  wideScreen: `(min-width: ${breakpoints.wideScreen + 1}px)`,
};

const GridUnit = (base: number, input: number) => {
  const value = (input / base) * 100;

  return css`
    flex-basis: ${value}%;
  `;
};

const GridOffset = (base: number, offset?: number) => {
  if (offset !== undefined) {
    return css`
      margin-left: ${(offset / base) * 100}%;
    `;
  }
};

const ApplyFlex = (justifyContent?: JustifyContent, flexDirection?: FlexDirection, alignItems?: AlignItems) => {
  if (justifyContent || flexDirection || alignItems) {
    return css`
      display: flex;
      flex-direction: ${flexDirection ? flexDirection : 'row'};
      justify-content: ${justifyContent ? justifyContent : 'flex-start'};
      align-items: ${alignItems ? alignItems : 'stretch'};
    `;
  }
};

const GridItemStyled = styled.div<GridItemStyledProps>`
  flex: 0 0 auto;
  box-sizing: border-box;

  ${props => ApplyFlex(props.justifyContent, props.flexDirection, props.alignItems)}

  @media ${mediaQueries.desktop} {
    ${props => GridUnit(12, props.l)};
    ${props => GridOffset(12, props.lOffset)};
  }

  @media ${mediaQueries.tablet} {
    ${props => GridUnit(8, props.m)};
    ${props => GridOffset(8, props.mOffset)};
  }

  @media ${mediaQueries.mobile} {
    ${props => GridUnit(4, props.s)};
    ${props => GridOffset(4, props.sOffset)};
  }
`;

const ApplyPaddings = (props: RowStyledProps, spacing: SpacingType) => {
  if (props.noGutter) {
    return css`
      ${GridItemStyled} {
      }
    `;
  }

  return css`
    ${GridItemStyled} {
      padding-right: ${props.gutter !== undefined ? `${props.gutter}px` : ({ theme }) => theme.spacings[spacing]};
      padding-left: ${props.gutter !== undefined ? `${props.gutter}px` : ({ theme }) => theme.spacings[spacing]};
    }
  `;
};

const ApplyGaps = (hGap?: SpacingType, vGap?: SpacingType) => {
  if (hGap || vGap) {
    return css`
      gap: ${({ theme }) => `${vGap ? theme.spacings[vGap] : '0'} ${hGap ? theme.spacings[hGap] : '0'}`};
    `;
  }
};

const RowStyled = styled.div.attrs((props: RowStyledProps) => props)`
  box-sizing: border-box;
  display: flex;
  flex: 0 1 auto;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;

  ${props => ApplyGaps(props.hGap, props.vGap)}

  @media ${mediaQueries.desktop} {
    ${props => ApplyPaddings(props, '1')}
  }

  @media ${mediaQueries.tablet} {
    ${props => ApplyPaddings(props, '3')}
  }

  @media ${mediaQueries.mobile} {
    ${props => ApplyPaddings(props, '2')}
  }
`;

/**
 * The Grid component is a wrapper that will need Grid Items as children. The grid Item is where
 * the magic happens and here we calculate the the width of each item. On different screen sizes
 * we have defined different amount of columns, for desktop we decided 12 cols, 8 on tablet
 * and 4 cols for mobile.
 * */
export const Grid = ({ children, hGap, vGap, gutter, noGutter = false }: RowProps) => (
  <RowStyled hGap={hGap} vGap={vGap} gutter={gutter} noGutter={noGutter}>
    {children}
  </RowStyled>
);

export const GridItem = ({
  children,
  s,
  m,
  l,
  sOffset,
  mOffset,
  lOffset,
  justifyContent,
  alignItems,
  flexDirection,
}: GridItemProps) => (
  <GridItemStyled
    s={s}
    m={m}
    l={l}
    sOffset={sOffset}
    mOffset={mOffset}
    lOffset={lOffset}
    justifyContent={justifyContent}
    alignItems={alignItems}
    flexDirection={flexDirection}
  >
    {children}
  </GridItemStyled>
);
