import { Heading, Link, Paragraph, useMedia } from '@loomispay/vault';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';
import { useDocumentTitle } from 'hooks';

export default function OfferNotFound() {
  const { t } = useTranslation();
  const isDesktop = useMedia('desktop');
  useDocumentTitle(t('offer404.heading'));

  return (
    <OfferNotFoundWrapper isDesktop={isDesktop}>
      <Heading size={isDesktop ? 'xl' : 'l'}>{t('offer404.heading')}</Heading>
      <Paragraph size={isDesktop ? 'xl' : 'l'}>
        {t('offer404.paragraph')}{' '}
        <Link size={isDesktop ? 'xl' : 'l'} href={`mailto: ${t('offer404.contactMail')}`}>
          {t('offer404.contactMail')}.
        </Link>
      </Paragraph>
    </OfferNotFoundWrapper>
  );
}

const OfferNotFoundWrapper = styled.section<{ isDesktop: boolean }>`
  width: ${({ isDesktop }) => (isDesktop ? '600px' : '92%')};
  height: 100vh;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
