import { Heading, Text } from '@loomispay/vault';
import { OfferPartnerData } from 'features/onboardingV2/store/types';
import { useTranslation } from 'react-i18next';
import { OfferSummarySectionBox, OfferSummaryRow } from '../../OfferOverview.styles';
import { getOfferConfiguration } from 'features/onboardingV2/store/sales/selectors';
import { useSelector } from 'react-redux';

export default function OfferOverviewPartner({ partnerData }: { partnerData?: OfferPartnerData }) {
  const { t } = useTranslation();
  const { partnersConfiguration } = useSelector(getOfferConfiguration);

  return partnerData?.partnerId ? (
    <section>
      <Heading size="xxs" sansSerif>
        {t('onboarding.v2.overview.partner')}
      </Heading>
      <OfferSummarySectionBox>
        {
          <OfferSummaryRow>
            <Text size="s">{t('onboarding.v2.overview.companyInformation.partner')}</Text>
            <Text size="s">
              {partnersConfiguration?.partnerConfigurations.find(
                partnerConfig => partnerConfig.id === partnerData.partnerId
              )?.name || partnerData.partnerId}
            </Text>
          </OfferSummaryRow>
        }
      </OfferSummarySectionBox>
    </section>
  ) : (
    <></>
  );
}
