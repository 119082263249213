import { UseMutationResult, UseQueryResult } from 'react-query';
import { useLoadingContext } from '../../../index';
import { useEffect } from 'react';
import axios from 'axios';
import { generatePath, useParams } from 'react-router-dom';
import { onboardingRoutes } from '../OnboardingRouter';
import { BaseQueryParams } from './types';
import useCognitoUser from '../../loginPasswordless/useCognitoUser';
import { notifications } from '../../../common/components/Notification/notifications';
import { useTranslation } from 'react-i18next';
import useFlowName from '../../../hooks/useFlowName';

const useQueryWrapper = <T, E>(query: UseQueryResult<T, E>): UseQueryResult<T, E> => {
  const { t } = useTranslation();
  const { offerRef } = useParams<BaseQueryParams>();
  const { setIsLoading } = useLoadingContext();
  const { isLoading, error } = query;
  const { signOut } = useCognitoUser();
  const flowName = useFlowName();

  useEffect(() => {
    setIsLoading(isLoading);
  }, [isLoading, setIsLoading]);

  useEffect(() => {
    // TODO: remove this condition, after upsell refactor.
    if (error && flowName === 'onboarding') {
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 401) {
          if (offerRef && generatePath(onboardingRoutes.login.path, { offerRef }) !== window.location.pathname) {
            signOut();
          }
        } else {
          notifications.error(t('common.serverError'));
        }
      }
    }
  }, [error, offerRef, signOut, t, flowName]);

  return query;
};

const useMutationWrapper = <T>(mutation: UseMutationResult<T>): UseMutationResult<T> => {
  const { t } = useTranslation();
  const { offerRef } = useParams<BaseQueryParams>();
  const { setIsLoading } = useLoadingContext();
  const { signOut } = useCognitoUser();
  const { isLoading, error } = mutation;
  const flowName = useFlowName();

  useEffect(() => {
    setIsLoading(isLoading);
  }, [isLoading, setIsLoading]);

  useEffect(() => {
    if (error && flowName === 'onboarding') {
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 401) {
          if (offerRef && generatePath(onboardingRoutes.login.path, { offerRef }) !== window.location.pathname) {
            signOut();
          }
        } else {
          notifications.error(t('common.serverError'));
        }
      }
    }
  }, [error, offerRef, signOut, t, flowName]);

  return mutation;
};

const applyParams = (path: string, countryCode?: string, offerRef?: string): string => {
  const fixedPath = path.includes(':countryCode') && countryCode ? path.replace(':countryCode', countryCode) : path;

  return fixedPath.includes(':offerRef') && offerRef ? fixedPath.replace(':offerRef', offerRef) : fixedPath;
};

export { useQueryWrapper, useMutationWrapper, applyParams };
