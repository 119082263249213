import { Icon, Label, useTheme } from '@loomispay/vault';
import { Radio, RadioGroup } from '../../../../../common/components/forms/Radio/Radio';
import { HoverOverlay } from '../../../../../common/components/HoverOverlay';
import { Dropdown } from '../../../../../common/components/forms/Dropdown/Dropdown';
import { pepPositionsOptions, pepRelationsOptions } from '../../../components/pepForm';
import { PepPosition, PepRelation, PoliticallyExposedInfo } from '../../../store/types';
import styled from 'styled-components/macro';
import { Control, FieldErrors } from 'react-hook-form';
import { UseFormMethods } from 'react-hook-form/dist/types';
import { useTranslation } from 'react-i18next';
import { GridItem } from 'common/components/Grid';

const IsPepWrapper = styled.div`
  display: inline-flex;
  gap: 5px;
`;

const HelpIconWrapper = styled.div`
  position: relative;
  top: -2px;
  cursor: pointer;
`;

const Overlay = styled.div<{ isMobile?: boolean }>`
  position: absolute;
  top: 32px;
  left: -180px;
  z-index: 2000;

  min-width: ${({ isMobile }) => (isMobile ? '340px' : '420px')};
  min-height: 124px;
  padding: ${({ theme }) => theme.spacings[0.5]} ${({ theme }) => theme.spacings[1]};
  border-radius: 4px;

  background-color: rgba(18, 18, 18, 0.8);
  color: ${({ theme }) => theme.color.base.grey.white};
`;
type Props = {
  representativeGroup: string;
  identifier: string;
  pepInfo?: PoliticallyExposedInfo;
  isSignatory: boolean;
  control: Control;
  errors: FieldErrors;
  testId?: number;
  watch: UseFormMethods['watch'];
  isMobile: boolean;
};
export const PepInfoWrapper = ({
  representativeGroup,
  identifier,
  isSignatory,
  watch,
  pepInfo,
  control,
  errors,
  testId,
  isMobile,
}: Props) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const isPepRadioGroupName = `${representativeGroup}.${identifier}.pepInfo.isPep`;
  const isPep = watch(`${isPepRadioGroupName}`, `${pepInfo?.isPep}`);
  return (
    <>
      <GridItem s={4} m={4} l={6}>
        <IsPepWrapper>
          <RadioGroup
            label={t('onboarding.v2.owners.isPepLabel')}
            name={isPepRadioGroupName}
            defaultValue={`${pepInfo?.isPep}`}
            size="sm"
            control={control}
            errors={errors}
            required
          >
            <Radio
              value="false"
              label={t('common.form.booleanFalse')}
              testId={`${representativeGroup}.${testId}.isPep.false`}
            />
            <Radio
              value="true"
              label={t('common.form.booleanTrue')}
              testId={`${representativeGroup}.${testId}.isPep.true`}
            />
          </RadioGroup>

          <HoverOverlay
            overlay={
              <Overlay isMobile={isMobile}>
                <Label size="xs" color="invertedPrimary">
                  {t('onboarding.v2.additionalInformation.pepTooltip.1')}
                  <br />
                  <br />
                  {t('onboarding.v2.additionalInformation.pepTooltip.2')}
                  <br />
                  <br />
                  {t('onboarding.v2.additionalInformation.pepTooltip.3')}
                </Label>
              </Overlay>
            }
            testId="pep-info-overlay"
            triggerDistance={theme.spacings[1]}
          >
            <HelpIconWrapper>
              <Icon size="m" name="help" />
            </HelpIconWrapper>
          </HoverOverlay>
        </IsPepWrapper>
      </GridItem>
      {isPep === 'true' && (
        <>
          {!isSignatory && <GridItem s={4} m={4} l={6} />}
          <GridItem s={4} m={4} l={6}>
            <Dropdown
              name={`${representativeGroup}.${identifier}.pepInfo.position`}
              label={t('onboarding.v2.owners.pepPositionLabel')}
              control={control}
              errors={errors}
              rules={{ required: t('common.form.validationMessages.required') }}
              options={pepPositionsOptions}
              getOptionLabel={option => t(`onboarding.v2.owners.pepPosition.${option.value as PepPosition}` as const)}
              getOptionValue={option => option.value}
              defaultValue={pepInfo && pepPositionsOptions.find(option => pepInfo.position === option.value)}
            />
          </GridItem>
          <GridItem s={4} m={4} l={6}>
            <Dropdown
              name={`${representativeGroup}.${identifier}.pepInfo.relation`}
              label={t('onboarding.v2.owners.pepRelationLabel')}
              control={control}
              errors={errors}
              rules={{ required: t('common.form.validationMessages.required') }}
              options={pepRelationsOptions}
              getOptionLabel={option => t(`onboarding.v2.owners.pepRelation.${option.value as PepRelation}` as const)}
              getOptionValue={option => option.value}
              defaultValue={pepInfo && pepRelationsOptions.find(option => pepInfo.relation === option.value)}
            />
          </GridItem>
        </>
      )}
    </>
  );
};
