import { createAction, createReducer } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { RootState } from 'store/store.types';

const GET_FEATURE_TOGGLES = 'GET_FEATURE_TOGGLES';

export type FeatureTogglesConfig = {
  isSignatureProviderSelectionEnabled: boolean;
  isFlexiblePricingEnabled: boolean;
};

type FeatureToggleOptions = {
  countryCode?: string;
  companyNumber?: string;
};

export const fetchFeatureToggles = createAction(GET_FEATURE_TOGGLES, (opts?: FeatureToggleOptions) => {
  return {
    payload: {
      client: 'featureToggleServiceClient',
      request: {
        method: 'GET',
        // url: '',
        params: {
          ...opts,
        },
      },
    },
  };
});

const fetchFeatureTogglesSuccess = createAction<AxiosResponse<{ enabledFeatures: string[] }>>(
  `${GET_FEATURE_TOGGLES}_SUCCESS`
);

const fetchFeatureTogglesFail = createAction(`${GET_FEATURE_TOGGLES}_FAIL`);

const initialState: { featureTogglesConfig: FeatureTogglesConfig } = {
  featureTogglesConfig: {
    isSignatureProviderSelectionEnabled: false,
    isFlexiblePricingEnabled: false,
  },
};

export const featureTogglesReducer = createReducer(initialState, builder =>
  builder
    .addCase(fetchFeatureTogglesSuccess, (state, action) => {
      state.featureTogglesConfig = {
        isSignatureProviderSelectionEnabled: action.payload.data.enabledFeatures.includes('signatureProviderSelection'),
        isFlexiblePricingEnabled: action.payload.data.enabledFeatures.includes('flexiblePricing'),
      };
      return state;
    })
    .addCase(fetchFeatureTogglesFail, state => {
      state.featureTogglesConfig = {
        isSignatureProviderSelectionEnabled: false,
        isFlexiblePricingEnabled: false,
      };
      return state;
    })
);

export const selectFeatureToggles = <T>(selector: (s: FeatureTogglesConfig) => T) => (globalState: RootState) =>
  selector(globalState.featureToggles.featureTogglesConfig);

export const selectIsSignatureProviderSelectionEnabled = selectFeatureToggles(
  state => state.isSignatureProviderSelectionEnabled
);

export const selectIsFlexiblePricingEnabled = selectFeatureToggles(state => state.isFlexiblePricingEnabled);
