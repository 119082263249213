import { Display } from '@loomispay/vault';
import Container from 'common/components/MainLayout/Container';
import { CountryCode } from 'constants/types';
import { Offer } from 'features/onboardingV2/store/types';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import getCurrencyForCountryCode from 'utils/currencyHelper';
import OfferOverviewCashCosts from './sections/OfferOverviewCashCosts';
import OfferOverviewCashDisabledLocations from './sections/OfferOverviewCashDisabledLocations';
import OfferOverviewCashLocationBreakdown from './sections/OfferOverviewCashLocationBreakdown';

export default function OfferOverviewCash({ offer }: { offer: Offer }) {
  const { t } = useTranslation();
  const { stores } = offer;
  const storesWithCashEnabled = stores.filter(store => store.isCashEnabled);
  const storesWithoutCashEnabled = stores.filter(store => !store.isCashEnabled);
  const currency = getCurrencyForCountryCode(offer.countryCode as CountryCode);

  return (
    <section>
      <Display size="s">{t('cashServices.heading')}</Display>

      <Container spacing="3" fullWidth>
        {storesWithCashEnabled.map(store => (
          <Fragment key={store.name}>
            <OfferOverviewCashLocationBreakdown store={store} countryCode={offer.countryCode} />
          </Fragment>
        ))}

        {storesWithoutCashEnabled.length > 0 && (
          <OfferOverviewCashDisabledLocations stores={storesWithoutCashEnabled} />
        )}

        <OfferOverviewCashCosts stores={storesWithCashEnabled} currency={currency} />
      </Container>
    </section>
  );
}
