import { DatePicker } from '@loomispay/vault';
import enGB from 'date-fns/locale/en-GB';
import sv from 'date-fns/locale/sv';
import da from 'date-fns/locale/da';
import es from 'date-fns/locale/es';

import 'react-datepicker/dist/react-datepicker.css';
import { useTranslation } from 'react-i18next';
import { Controller } from 'react-hook-form';

type DatePickerProps = React.ComponentProps<typeof DatePicker>;

const _DatePicker = ({ dateFormat, locale, placeholderText, ...props }: DatePickerProps) => {
  const isRangePicker = !!props.selectsRange || props.startDate !== undefined || props.endDate !== undefined;
  const baseLocale: DatePickerProps['locale'] = {
    'en-GB': enGB,
    sv: sv,
    da: da,
    es: es,
  };

  const { t, i18n } = useTranslation();

  return (
    <Controller
      name={props.name}
      defaultValue={props.defaultValue || ''}
      control={props.control}
      render={({ onChange: _onChange, ..._props }) => {
        // If boolean is passed as defaultValue, we need to map it to string here
        const inputProps = { ..._props, value: _props.value.toString() };
        return (
          <DatePicker
            onChange={(val: string) => {
              _onChange(val);
              props.onChange && props.onChange(val);
            }}
            dateFormat={dateFormat || 'MMM d, yyyy'}
            placeholderText={
              placeholderText ||
              (isRangePicker ? t('common.datepicker.selectRange') : t('common.datepicker.selectDate'))
            }
            locale={locale || baseLocale[i18n.language]}
            {...props}
            {...inputProps}
          />
        );
      }}
    />
  );
};

export { _DatePicker as DatePicker };
