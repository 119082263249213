import { Grid, GridItem } from 'common/components/Grid';
import { Radio, RadioGroup } from 'common/components/forms/Radio/Radio';
import { TextInput } from 'common/components/forms/TextInput/TextInput';
import { CountryCode, ScreenMode } from 'constants/types';
import { OwnerType } from 'features/onboardingV2/store/types';
import { useDocumentTitle } from 'hooks';
import OnboardingModalLayout from 'layout/OnboardingModalLayout';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';
import { isPersonalId, patterns } from 'utils/validation';

export type OwnerForm = {
  fullName: string;
  personalId?: string;
  type?: OwnerType;
  shares?: '25-49' | '50-74' | '75-100' | 'ABO';
};

interface Props {
  existingPersonalIds: (string | undefined)[];
  owner: OwnerForm;
  closeUrl: string;
  mode: ScreenMode;
  onSubmit: (ownerForm: OwnerForm) => void;
  personalIdNeeded: boolean;
  countryCode: CountryCode | undefined;
}

const OwnerEditForm = ({
  owner,
  closeUrl,
  mode,
  onSubmit,
  personalIdNeeded,
  existingPersonalIds,
  countryCode,
}: Props) => {
  const { t } = useTranslation();
  const title =
    mode === ScreenMode.add ? t('onboarding.v2.owners.addOwnerTitle') : t('onboarding.v2.owners.editOwnerTitle');
  useDocumentTitle(title);

  const label = mode === ScreenMode.add ? t('onboarding.v2.owners.addLabel') : t('onboarding.v2.owners.saveLabel');
  const ownerForm = useForm<OwnerForm>({
    mode: 'onChange',
  });
  const isScreenCompleted = ownerForm.formState.isValid;
  const modalGridContentGutter = 16;

  const isValidPersonalId = (value: string) => {
    if (!isPersonalId(countryCode, value)) {
      return t('common.form.validationMessages.invalidPersonalId');
    }
    return existingPersonalIds.includes(value) ? t('common.form.validationMessages.nonUniquePersonalId') : true;
  };

  return (
    <OnboardingModalLayout
      title={title}
      closeUrl={closeUrl}
      label={label}
      isScreenCompleted={isScreenCompleted}
      formId="owner-form"
      gridGutter={modalGridContentGutter}
      noGutter={true}
    >
      <EditForm id="owner-form" onSubmit={ownerForm.handleSubmit(onSubmit)}>
        <Grid gutter={modalGridContentGutter} vGap="6">
          <GridItem s={4} m={4} l={6}>
            <TextInput
              label={t('onboarding.v2.owners.nameInputLabel')}
              name="fullName"
              setValue={(value?: string) => ownerForm.setValue('fullName', value)}
              rules={{
                required: t('common.form.validationMessages.required'),
                pattern: {
                  value: patterns.fullName,
                  message: t('common.form.validationMessages.fullName'),
                },
              }}
              required
              defaultValue={owner.fullName}
              control={ownerForm.control}
              errors={ownerForm.errors}
              testId="fullName"
              trimOnBlur
            />
          </GridItem>
          {personalIdNeeded && (
            <GridItem s={4} m={4} l={6}>
              <TextInput
                label={t('common.personalNumber')}
                name="personalId"
                setValue={(value?: string) => ownerForm.setValue('personalId', value)}
                rules={{
                  required: t('common.form.validationMessages.required'),
                  validate: (value: string) => isValidPersonalId(value),
                }}
                defaultValue={owner.personalId || ''}
                control={ownerForm.control}
                errors={ownerForm.errors}
                testId="personalId"
                trimOnBlur
              />
            </GridItem>
          )}

          <GridItem s={4} m={4} l={6}>
            <RadioGroup
              label={t('onboarding.v2.owners.ownership')}
              name="shares"
              size="sm"
              defaultValue={owner.shares}
              control={ownerForm.control}
              errors={ownerForm.errors}
              required
            >
              <Radio value="25-49" label="25% - 49%" />
              <Radio value="50-74" label="50% - 74%" />
              <Radio value="75-100" label="75% - 100%" />
            </RadioGroup>
          </GridItem>
        </Grid>
      </EditForm>
    </OnboardingModalLayout>
  );
};

const EditForm = styled.form`
  display: flex;
  padding-bottom: ${({ theme }) => theme.spacings['6']};
`;

export default OwnerEditForm;
