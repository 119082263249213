import React, { createContext, useContext, useState } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import * as Sentry from '@sentry/react';
import { datadogRum } from '@datadog/browser-rum';

import { env } from './app/config';
import './index.css';
import App from './app/App';
import store, { persistor } from './store/store';
import { BrowserTracing } from '@sentry/tracing';
import { CaptureConsole as CaptureConsoleIntegration } from '@sentry/integrations';
import { configureAmplify } from './features/login/PasswordlesAmplifyConfigurer';
import ReactModal from 'react-modal';

// MP-4075 - Datadog RUM only on Staging until Cookie Consent (Prod still uses Sentry)
if (env.environment === 'production') {
  Sentry.init({
    dsn: env.sentryDSN,
    release: env.appVersion,
    environment: env.environment,
    integrations: [
      new BrowserTracing(),
      new CaptureConsoleIntegration({
        levels: ['error'],
      }),
    ],
    tracesSampleRate: 1,
  });
} else if (env.environment === 'staging') {
  datadogRum.init({
    applicationId: env.datadogApplicationId,
    clientToken: env.datadogClientToken,
    site: env.datadogSite,
    service: 'onboarding-web',
    env: env.environment,
    version: env.appVersion,
    sessionSampleRate: 100,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    allowedTracingUrls: env.datadogTracingURLS,
  });
}

configureAmplify();

// Temporary context for loader without redux, to cleanup after migration to react 18 with full suspense support
interface AppInterface {
  isLoading: boolean;
  setIsLoading: (flag: boolean) => void;
}

export const AppContext = createContext<AppInterface>({
  isLoading: false,
  setIsLoading: () => undefined,
});

export const AppContextProvider: React.FC = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);

  return <AppContext.Provider value={{ isLoading, setIsLoading }}>{children}</AppContext.Provider>;
};

export const useLoadingContext = () => {
  return useContext(AppContext);
};

window.addEventListener('DOMContentLoaded', function () {
  ReactDOM.render(
    <React.StrictMode>
      <AppContextProvider>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <BrowserRouter>
              <App />
            </BrowserRouter>
          </PersistGate>
        </Provider>
      </AppContextProvider>
    </React.StrictMode>,
    document.getElementById('root')
  );

  ReactModal.setAppElement('#root');
});
