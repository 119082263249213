import { Button } from '@loomispay/vault';
import { CountryCode } from 'constants/types';
import { CompanyLookupForm, CompanySearch } from 'features/common/components/CompanySearch/CompanySearch';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components/macro';
import { formatCompanyNumber } from 'utils/format';
import {
  addStore,
  clearCompanyInformation,
  clearStores,
  fetchCompanyInformation,
  fetchCompanyInformationSuccess,
  fetchOfferConfiguration,
  setCountryCode,
  updateCompanyDataIsManualFallbackPathAvailable,
  updateCompanyDataIsManuallyAdded,
} from '../../../store/sales/actions';
import { getAvailableCountries, getCompanyData, getCountryCode } from '../../../store/sales/selectors';
import { fetchFeatureToggles } from 'features/featureToggles/featureToggles';
import { companyInformationAnomalyWhitelistedCompanyIDs } from '../../../../../app/whitelistedCompanyIDs';

export const CompanyLookup = () => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [existingCompanyName, setExistingCompanyName] = useState<string | undefined>();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const companyData = useSelector(getCompanyData);
  const countryCode = useSelector(getCountryCode) || 'ES';
  const availableCountries = useSelector(getAvailableCountries);

  useEffect(() => {
    // all PAY-only offer creations were moved to sales-tools-web
    if (availableCountries && availableCountries.length === 1 && availableCountries[0] !== 'ES') {
      window.location.href = process.env.REACT_APP_SALES_TOOLS_URL + '/new-offer';
    }
  }, [availableCountries]);

  const companyLookupForm = useForm<CompanyLookupForm>({
    defaultValues: { companyNumber: companyData.companyNumber?.toString() || '' },
  });

  const clearForm = () => {
    companyLookupForm.reset();
    clearState();
  };

  const clearState = () => {
    setErrorMessage(null);
    setExistingCompanyName(undefined);
    dispatch(clearCompanyInformation());
    dispatch(clearStores());
  };

  const enterManualFlow = () => {
    dispatch(updateCompanyDataIsManuallyAdded(true));
  };

  useEffect(() => {
    dispatch(clearCompanyInformation());
    dispatch(clearStores());
  }, [dispatch]);

  const lookupCompany = (formData: CompanyLookupForm) => {
    clearState();
    const companyNumber = formatCompanyNumber(formData.companyNumber);

    getCompanyInformation(companyNumber);
    dispatch(fetchOfferConfiguration());
    dispatch(fetchFeatureToggles({ countryCode }));
  };

  const getCompanyInformation = (companyNumber: string) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const promise = dispatch(fetchCompanyInformation(companyNumber, countryCode)) as any;
    promise
      .then((res: ReturnType<typeof fetchCompanyInformationSuccess>) => {
        const { violations, eligibleForManualFallback, company, onboardingAllowed, flows } = res.payload.data;

        // temporary company specific bypass to allow for offer creation - see MP-5015
        // TODO: remove after target functionality implemented
        const incompleteDataTempFix = companyInformationAnomalyWhitelistedCompanyIDs.includes(companyNumber);

        if (violations?.length > 0) {
          // show violations always
          setErrorMessage(`${violations.join(' ')}`);
        }

        if (!onboardingAllowed && flows.every(flow => !flow.status.isEnabled && flow.status.message)) {
          // all flows disabled and status is: ALREADY_ONBOARDED or ONBOARDING_COMPLETED
          setExistingCompanyName(companyNumber);
          return;
        }

        if (eligibleForManualFallback || incompleteDataTempFix) {
          // Manual fallback is allowed or forced by whitelist
          dispatch(
            addStore({
              name: company?.companyName || '',
              address: company?.address || {},
            })
          );
          dispatch(updateCompanyDataIsManualFallbackPathAvailable(true));
          // always enter manual flow when ES
          if (countryCode === 'ES') {
            enterManualFlow();
          }
        } else if (onboardingAllowed && company) {
          // onboarding is allowed, and we have basic company information from backend
          dispatch(updateCompanyDataIsManualFallbackPathAvailable(false));
          dispatch(
            addStore({
              name: company.companyName,
              address: company.address,
            })
          );
        }
      })
      .catch((data: { error: { response: { status: number } } }) => {
        if (data.error.response.status === 400) {
          setErrorMessage(t('errors.incorrectCompanyNumber'));
        } else {
          setErrorMessage(t('common.serverError'));
        }
        dispatch(updateCompanyDataIsManualFallbackPathAvailable(false));
      });
  };

  const handleCountryChange = (countryCode: CountryCode) => {
    dispatch(setCountryCode(countryCode));
    if (!companyData.isManuallyAdded) {
      clearState();
    }
  };

  return (
    <>
      <CompanySearch
        companyLookupForm={companyLookupForm}
        companyData={companyData}
        availableCountries={['ES']}
        defaultCountryCode={countryCode}
        searchButtonLabel={t('onboarding.v2.companyInfo.lookupButton')}
        findCompanyInformation={lookupCompany}
        handleCountryChange={handleCountryChange}
        handleFormReset={clearForm}
        errorMessage={errorMessage}
        existingCompanyName={existingCompanyName}
      />
      {companyData.isManualFallbackPathAvailable && !companyData.isManuallyAdded && (
        <ManualCompanyFlowButtonHolder>
          <Button
            variant="secondary"
            onClick={enterManualFlow}
            icon="edit"
            iconPosition="left"
            label={t('onboarding.v2.companyInfo.enterManualFlowButton')}
            testId="company-form-manual-flow-button"
          />
        </ManualCompanyFlowButtonHolder>
      )}
    </>
  );
};

const ManualCompanyFlowButtonHolder = styled.div`
  display: flex;
  justify-content: center;
`;
