import { useContext } from 'react';
import { ConfirmationContext } from './ConfirmationProvider';
import { ConfirmationParams } from './confirmationTypes';

export const useConfirmation = () => {
  const openConfirmation = useContext(ConfirmationContext);

  const getConfirmation = (options: ConfirmationParams): Promise<boolean> =>
    new Promise(res => {
      openConfirmation && openConfirmation({ actionCallback: res, ...options });
    });

  return { getConfirmation };
};
