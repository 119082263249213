import { Amplify } from 'aws-amplify';

export function configureAmplify() {
  const cognitoPasswordlessConfig = {
    Auth: {
      region: process.env.REACT_APP_COGNITO_PASSWORDLESS_REGION,
      userPoolId: process.env.REACT_APP_COGNITO_PASSWORDLESS_USER_POOL_ID,
      userPoolWebClientId: process.env.REACT_APP_COGNITO_PASSWORDLESS_USER_POOL_WEBCLIENT_ID,
    },
  };

  Amplify.configure(cognitoPasswordlessConfig);
}
