import { Heading, Paragraph } from '@loomispay/vault';
import { CashServicesGridWrapper } from '../CashServices.styles';
import { Dropdown } from '../../../../../../common/components/forms/Dropdown/Dropdown';
import { CashServicesSectionTypes } from '../types';
import useCashServices from '../useCashServices';
import { useTranslation } from 'react-i18next';
import Container from '../../../../../../common/components/MainLayout/Container';
import { useSelector } from 'react-redux';
import { getStores } from '../../../../store/sales/selectors';

export const CashServicesLoomisOperationsBank = ({
  storeIndex,
  control,
  errors,
  checkSolutionType,
}: CashServicesSectionTypes & { checkSolutionType?: string }) => {
  const { t } = useTranslation();
  const { cashContractLoomisBankDetailsOptions } = useCashServices();
  const locations = useSelector(getStores);

  const loomisBankDetailsAvailable = !!checkSolutionType && 'DIGITAL_CASH' === checkSolutionType;

  return (
    <>
      {loomisBankDetailsAvailable && (
        <section>
          <Heading size="s">{t('cashServices.form.loomisOperationsBank')}</Heading>
          <CashServicesGridWrapper>
            <Container fullWidth spacing="1">
              <Dropdown
                name={`store-${storeIndex}.loomisOperationsBank`}
                label={t('cashServices.form.loomisOperationsBank')}
                control={control}
                errors={errors}
                options={cashContractLoomisBankDetailsOptions}
                defaultValue={
                  cashContractLoomisBankDetailsOptions?.find(
                    option => option.value === locations[storeIndex].cashData?.es?.loomisOperationsBank?.toString()
                  ) || ''
                }
                rules={{ required: t('common.form.validationMessages.required') }}
              />
              <Paragraph size="s" color="secondary">
                {t('cashServices.form.loomisOperationsBank.hint')}
              </Paragraph>
            </Container>
          </CashServicesGridWrapper>
        </section>
      )}
    </>
  );
};
