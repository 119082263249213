import { Offer } from '../../../store/types';
import OfferOverviewProducts from './sections/OfferOverviewProducts';
import OfferOverviewAccessories from './sections/OfferOverviewAccessories';
import OfferOverviewDuration from './sections/OfferOverviewDuration';
import OfferOverviewMultitierPricing from './sections/OfferOverviewMultitierPricing';
import Container from 'common/components/MainLayout/Container';
import OfferOverviewStartDateForm from './forms/OfferOverviewStartDateForm';

export default function OfferOverviewLoomisPay({ offer }: { offer: Offer }) {
  const { contractTerms, stores } = offer;
  const { transactionFee, transactionFees, durationInMonths, startDate, terminalsFreeMonths } = contractTerms || {};

  const accessoriesSelected = stores.flatMap(store => store.storeOfferItems?.addons).length > 0;

  return (
    <section>
      <Container spacing="3" fullWidth>
        <OfferOverviewProducts
          stores={stores}
          terminalsFreeMonths={terminalsFreeMonths}
          durationInMonths={durationInMonths}
        />

        {accessoriesSelected && <OfferOverviewAccessories stores={stores} />}

        <OfferOverviewDuration durationInMonths={durationInMonths} />

        <OfferOverviewMultitierPricing transactionFees={transactionFees} transactionFee={transactionFee} />

        <OfferOverviewStartDateForm offer={offer} startDate={startDate} />
      </Container>
    </section>
  );
}
