import styled from 'styled-components/macro';

type ContainerProps = {
  fileName: string;
  isMobile: boolean;
  multiUpload: boolean;
};

export const UploadWrapper = styled.div<{ isDisabled: boolean }>`
  pointer-events: ${({ isDisabled }) => (isDisabled ? 'none' : 'auto')};
  opacity: ${({ isDisabled }) => (isDisabled ? 0.5 : 1)};
`;

export const Container = styled.div<ContainerProps>`
  display: flex;
  min-width: 280px;
  height: ${({ isMobile }) => (isMobile ? `140px` : `156px`)};
  align-items: center;
  flex-direction: column;
  padding: ${({ fileName, multiUpload, theme }) =>
    !multiUpload && fileName ? `${theme.spacings[1]}` : `${theme.spacings[4]}`};
  box-sizing: border-box;
  border: 1px dashed ${({ fileName, theme }) => (fileName ? theme.color.base.grey.black : theme.color.base.grey[9])};
  border-radius: 8px;
  cursor: pointer;
  .drag-over & {
    background: ${({ theme }) => theme.color.background.tertiaryBg};
  }

  .has-error & {
    border: 1px dashed ${({ theme }) => theme.color.negative};
  }
`;

export const FileContainer = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  padding: ${({ theme }) => theme.spacings[1]} ${({ theme }) => theme.spacings[2]};
  border: 1px solid ${({ theme }) => theme.color.background.tertiaryBg};
  border-radius: 4px;
  background-color: ${({ theme }) => theme.color.background.tertiaryBg};
`;

export const FileList = styled.ul`
  padding: 0;
  margin: 0;
`;

export const FileListItem = styled.li`
  list-style-type: none;
  display: flex;
  gap: 10px;
  align-items: center;
`;

export const UploadText = styled.div`
  margin-top: ${({ theme }) => theme.spacings[1.5]};
`;

export const RemoveFile = styled.div`
  position: absolute;
  top: ${({ theme }) => theme.spacings['0.5']};
  right: ${({ theme }) => theme.spacings['0.5']};
`;
