import libphonenumber, { PhoneNumberFormat } from 'google-libphonenumber';

export const MAX_INT32_VAL = 2147483647;

export const maxValue = (integerDigits: number, fractionalDigits?: number): number => {
  const roundIntegerDigits = Math.round(integerDigits);
  const roundFractionalDigits = fractionalDigits ? Math.round(fractionalDigits) : 0;
  const integerPart =
    roundIntegerDigits > 0 ? '9'.repeat(roundIntegerDigits - Math.max(roundFractionalDigits, 0)) : '0';
  const fractionalPart = roundFractionalDigits > 0 ? `.${'9'.repeat(roundFractionalDigits)}` : '';
  return +parseFloat(`${integerPart}${fractionalPart}`).toFixed(2);
};

export function formatSwedishPersonalId(personalId?: string) {
  if (!personalId) return personalId;

  if (personalId.length === 12) return `${personalId.slice(0, 8)}-${personalId.slice(8)}`;
  if (personalId.length === 10) return `${personalId.slice(0, 6)}-${personalId.slice(6)}`;

  return personalId;
}

export const formatCompanyNumber = (companyNumber: string) => {
  return companyNumber.replace(/-|\s/g, '');
};

const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();

export const formatPhoneNumber = (phoneNumber: string) => {
  const number = phoneUtil.parse(phoneNumber);
  return phoneUtil.format(number, PhoneNumberFormat.E164);
};

export const removeNewline = (value?: string) => {
  if (!value) return value;
  return value.replace(/\n/g, ' ');
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const sortObjProperties = (obj: Record<string, any>) =>
  Object.fromEntries(Object.entries(obj).sort((a, b) => (a[0] > b[0] ? 1 : -1)));
