import { ReactNode } from 'react';
import { ConfirmationProvider } from '../common/components/Modal';
import { VaultProvider } from '@loomispay/vault';

type Props = {
  children: ReactNode;
};

function AppProviders({ children }: Props) {
  return (
    <VaultProvider>
      <ConfirmationProvider>{children}</ConfirmationProvider>
    </VaultProvider>
  );
}

export default AppProviders;
