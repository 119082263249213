import Container from 'common/components/MainLayout/Container';
import { Offer } from 'features/onboardingV2/store/types';
import OfferOverviewCompanyInfo from './sections/OfferOverviewCompanyInfo';
import OfferOverviewTurnover from './sections/OfferOverviewTurnover';
import OfferOverviewLocations from './sections/OfferOverviewLocations';
import OfferOverviewPartner from './sections/OfferOverviewPartner';

export default function OfferOverviewCommon({ offer }: { offer: Offer }) {
  const { companyData, businessCategory, businessSubCategory, turnoverData, stores, partnerData } = offer;
  return (
    <section>
      <Container spacing="3" fullWidth>
        <OfferOverviewCompanyInfo
          companyData={companyData}
          businessCategory={businessCategory}
          businessSubCategory={businessSubCategory}
        />
        <OfferOverviewPartner partnerData={partnerData} />
        <OfferOverviewTurnover turnoverData={turnoverData} currencyCode={companyData.currencyCode} />

        <OfferOverviewLocations stores={stores} />
      </Container>
    </section>
  );
}
