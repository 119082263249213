import { useQuery } from 'react-query';
import {
  fetchAdditionalQuestions,
  fetchContactData,
  fetchOffer,
  fetchOnboardingData,
  fetchOwners,
  fetchSignees,
} from './api';
import { MerchantOnboardingData, OfferContactData } from '../../common/types';
import { AdditionalQuestion, Offer, OwnersResponse, SigningCombinationsResponse } from '../store/types';
import { QueryKeys } from './keys';
import { useQueryWrapper } from './utils';
import { AxiosError } from 'axios';
import { BaseQueryParams, CompanyDataProviderQueryParams, OfferErrorResponse } from './types';

const useOfferContactData = ({ countryCode = 'null', offerRef }: BaseQueryParams) => {
  return useQueryWrapper(
    useQuery<OfferContactData, AxiosError>(
      [QueryKeys.OFFER_CONTACT_DATA],
      () => fetchContactData({ countryCode, offerRef }),
      {
        enabled: !!offerRef,
      }
    )
  );
};

const useOffer = ({ countryCode = 'null', offerRef }: BaseQueryParams) => {
  return useQueryWrapper(
    useQuery<Offer, AxiosError<OfferErrorResponse>>(
      [QueryKeys.OFFER_DATA],
      () => fetchOffer({ countryCode, offerRef }),
      {
        enabled: !!offerRef,
      }
    )
  );
};

const useOnboardingData = ({ countryCode, offerRef }: BaseQueryParams) => {
  return useQueryWrapper(
    useQuery<MerchantOnboardingData, AxiosError>(
      [QueryKeys.ONBOARDING_DATA],
      () => fetchOnboardingData(countryCode, offerRef),
      {
        enabled: !!countryCode && !!offerRef,
      }
    )
  );
};

const useOwners = ({
  countryCode,
  companyNumber,
  shouldFetchOwners = true,
}: CompanyDataProviderQueryParams & { shouldFetchOwners?: boolean }) => {
  return useQueryWrapper(
    useQuery<OwnersResponse, AxiosError>([QueryKeys.OWNERS_DATA], () => fetchOwners({ countryCode, companyNumber }), {
      enabled: !!shouldFetchOwners && !!countryCode && !!companyNumber,
    })
  );
};

const useSignees = ({
  countryCode,
  companyNumber,
  shouldFetchSignees = true,
}: CompanyDataProviderQueryParams & { shouldFetchSignees?: boolean }) => {
  return useQueryWrapper(
    useQuery<SigningCombinationsResponse, AxiosError>(
      [QueryKeys.SIGNEES_DATA],
      () => fetchSignees({ countryCode, companyNumber }),
      {
        enabled: !!shouldFetchSignees && !!countryCode && !!companyNumber,
      }
    )
  );
};

const useAdditionalQuestions = ({ countryCode, offerRef }: BaseQueryParams) => {
  return useQueryWrapper(
    useQuery<AdditionalQuestion[], AxiosError>(
      [QueryKeys.ADDITIONAL_QUESTIONS],
      () => fetchAdditionalQuestions(countryCode, offerRef),
      {
        enabled: !!countryCode && !!offerRef,
      }
    )
  );
};

export { useOfferContactData, useOffer, useOnboardingData, useOwners, useSignees, useAdditionalQuestions };
