import { Button, Display, Text, useMedia } from '@loomispay/vault';
import { useConfirmation } from 'common/components/Modal';
import { Footer } from 'features/common/components/Footer/Footer';
import { removeStore } from 'features/onboardingV2/store/sales/actions';
import { getStores } from 'features/onboardingV2/store/sales/selectors';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath, useNavigate } from 'react-router-dom';
import { CallToActionGridItem, SimpleGridItem, SimpleGridLayout } from '../../../../../common/components/GridLayout';
import { LocationBox } from 'features/common/components/Locations/LocationBox';
import { useSalesFlowNavigation } from '../salesFlowRoutingService';
import { Store } from 'features/common/types';
import { useDocumentTitle } from 'hooks';
import { salesRoutes } from '../../../SalesRouter';
import { Box, BoxRowCentered } from 'common/components/Box';
import { Grid } from 'common/components/Grid';
import styled from 'styled-components/macro';

const LocationsSales = () => {
  const { nextPage, prevPage } = useSalesFlowNavigation();
  const { t } = useTranslation();
  const isMobile = useMedia('mobile');
  useDocumentTitle(t('onboarding.v2.locations.sales.title'));

  const { getConfirmation } = useConfirmation();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const locations: Store[] = useSelector(getStores);
  const isScreenCompleted = locations.length > 0;

  const confirmRemoveLocation = (location: Store, index: number) => async () => {
    const confirm = await getConfirmation({
      title: t('onboarding.v2.locations.sales.removeLocationTitle'),
      description: t('onboarding.v2.locations.sales.removeLocationDescription', { name: location.name }),
    });

    if (confirm) {
      dispatch(removeStore(index));
    }
  };

  return (
    <LayoutContainer>
      <SimpleGridLayout>
        <SimpleGridItem>
          <Display size="s">{t('onboarding.v2.locations.sales.title')}</Display>
          <Text size="s" color={'tertiary'}>
            {t('onboarding.v2.locations.sales.description')}
          </Text>
        </SimpleGridItem>
      </SimpleGridLayout>
      {locations.map((location, i) => {
        return (
          <Fragment key={location.name}>
            <Grid>
              <SimpleGridItem>
                <Box testId={`location.${i}.locationBox`}>
                  <LocationBox
                    location={location}
                    index={i}
                    onDelete={confirmRemoveLocation(location, i)}
                    editUrl={generatePath(salesRoutes.editLocationSales.path, {
                      locationId: i,
                    })}
                  />
                </Box>
              </SimpleGridItem>
            </Grid>
          </Fragment>
        );
      })}
      <Grid>
        <SimpleGridItem>
          <Box>
            <BoxRowCentered height="70px">
              <Button
                variant="tertiary"
                onClick={() => {
                  salesRoutes.addLocation.path && navigate(salesRoutes.addLocation.path);
                }}
                icon="plus"
                iconPosition="left"
                label={t('onboarding.v2.locations.sales.addLocationButton')}
                testId="add-button"
              />
            </BoxRowCentered>
          </Box>
        </SimpleGridItem>
      </Grid>
      <Footer
        isMobile={isMobile}
        footer={
          <CallToActionGridItem isMobile={isMobile}>
            <div>
              <Button
                onClick={() => {
                  navigate(prevPage());
                }}
                variant="tertiary"
                label={t('common.backButton')}
                fullWidth={isMobile}
                icon="arrowLeft"
                iconPosition="left"
              />
            </div>
            <Button
              onClick={() => {
                navigate(nextPage());
              }}
              disabled={!isScreenCompleted}
              label={t('common.continueButton')}
              testId="locationsSales-submitButton"
              fullWidth={isMobile}
            />
          </CallToActionGridItem>
        }
      />
    </LayoutContainer>
  );
};

const LayoutContainer = styled.div`
  display: grid;
  gap: ${({ theme }) => theme.spacings['4']};
`;

export default LocationsSales;
