import { IndicatorLayer } from './IndicatorLayer';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/store.types';
import { useLoadingContext } from '../../../index';

interface Props {
  imageOnly?: boolean;
  selector?: (globalState: RootState) => boolean;
}

export const IndicatorLayerWrapper = ({ selector = state => state.app.isLoading }: Props) => {
  const { isLoading } = useLoadingContext();
  const isVisible = useSelector(selector) || isLoading;
  const body: HTMLElement = document.body;

  if (isVisible) {
    body.style.overflow = 'hidden';
    return <IndicatorLayer imageOnly />;
  }
  body.style.overflow = 'auto';
  return null;
};
