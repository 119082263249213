import { AnyAction, createReducer } from '@reduxjs/toolkit';
import {
  getApplicationConfigs,
  getApplicationConfigsSuccess,
  getVatRatesConfig,
  getVatRatesConfigSuccess,
} from './applicationActions';
import { CountryCode } from '../../constants/types';

export interface VatRate {
  attributes: {
    class: string;
    country: CountryCode;
    description: string;
    value: number;
  };
  id: string;
}

export interface ApplicationConfiguration {
  enabled: boolean;
  attributes?: never;
  id?: number;
}

export interface Applications {
  tables: ApplicationConfiguration;
  printing: ApplicationConfiguration;
}

interface InitialState {
  vatRates: VatRate[];
  applications: Applications;
  hasMultipleLocations: boolean;
}

const applicationConfigDefault = {
  enabled: false,
};
const initialState: InitialState = {
  vatRates: [],
  applications: {
    printing: applicationConfigDefault,
    tables: applicationConfigDefault,
  },
  hasMultipleLocations: false,
};

function saveApplicationConfiguration(application: ApplicationConfiguration, action: AnyAction) {
  application.enabled = true;
  application.id = action.payload.data.data[0].id;
  application.attributes = action.payload.data.data[0].attributes;
}

export const applicationReducer = createReducer(initialState, builder =>
  builder
    .addCase(getVatRatesConfig, state => {
      state.vatRates = [];
    })
    .addCase(getVatRatesConfigSuccess, (state, action: AnyAction) => {
      state.vatRates = action.payload.data.data;
    })
    .addCase(getApplicationConfigs, (state, action: AnyAction) => {
      if (action.payload.request.url.includes('tables')) {
        state.applications.tables = applicationConfigDefault;
      } else if (action.payload.request.url.includes('printing')) {
        state.applications.printing = applicationConfigDefault;
      }
    })
    .addCase(getApplicationConfigsSuccess, (state, action: AnyAction) => {
      if (action.payload.config.url.includes('tables')) {
        if (action.payload.data.data.length > 0) {
          saveApplicationConfiguration(state.applications.tables, action);
        }
      } else if (action.payload.config.url.includes('printing')) {
        if (action.payload.data.data.length > 0) {
          saveApplicationConfiguration(state.applications.printing, action);
        }
      }
    })
);
