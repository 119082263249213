import XRegExp from 'xregexp';
import { CountryCode } from 'constants/types';
import { extractBIC, extractIBAN, isValidBIC, isValidIBAN } from 'ibantools';
import * as dateUtils from 'utils/dateUtils';
import libphonenumber from 'google-libphonenumber';
import { denmark, norway } from 'verifiera';

// Note: If these are changed, make sure they align with backend validations
export const patterns = {
  fullName: /^[^\s\d]+(\s[^\s\d]+)+$/,
  email: /^\S+@\S+\.\S+$/,
  securePassword: /^(?=.*[a-z])(?=.*[0-9]).{8,}$/i,
  pinCode: /^[0-9]{1,6}$/,
  iban: /^[A-Z]{2}[0-9]{2}[a-zA-Z0-9]{1,30}$/,
  bic: /^[A-Z]{6}[A-Z2-9][A-NP-Z0-9]([A-Z0-9]{3})?$/,
  seAccountNumber: /^[0-9]{7,10}$/,
  noAccountNumber: /^\d{11}$/,
  seBankCode: /^[0-9]{4,5}$/,
  // Yay! Even more complicated regex: https://www.regular-expressions.info/unicode.html
  staffMemberName: XRegExp('^[\\p{L}|\\p{M}|\\p{Zs}|\\p{Nd}|\\p{P}]{1,100}$'),
  singleLineText: XRegExp('^[\\p{L}|\\p{M}|\\p{Zs}|\\p{P}|\\p{Sc}|0-9~+=<>^]*$'),
  multiLineText: XRegExp('^[\\p{L}|\\p{M}|\\p{Zs}|\\p{P}|\\p{Sc}|0-9~+=<>^\r\n]*$'),
};

export const isValidSingleLineText = (text: string): boolean => {
  return patterns.singleLineText.test(text);
};

export const isValidMultiLineText = (text: string): boolean => {
  return patterns.multiLineText.test(text);
};

const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();

export const isValidPhoneNumber = (phoneNumber: string): boolean => {
  try {
    return phoneUtil.isValidNumber(phoneUtil.parse(phoneNumber));
  } catch (e) {
    return false;
  }
};

export const isValidIban = (iban: string, countryCode?: CountryCode): boolean => {
  return isValidIBAN(iban) && extractIBAN(iban).countryCode === countryCode;
};

export const isValidBic = (bic: string, countryCode?: CountryCode): boolean => {
  return isValidBIC(bic) && extractBIC(bic).countryCode === countryCode;
};

export const isPersonalId = (market: CountryCode | undefined, id: string): boolean => {
  if (!id) return false;

  switch (market) {
    case 'SE':
      id = id.replace('-', '');
      let checksum = 0;

      if (!id.match(/^[\d]+$/)) return false;

      if (id.length === 10) {
        const year = parseInt(id.slice(0, 2), 10);
        const thisYear = new Date().getFullYear() - 2000;
        if (year < thisYear) {
          id = '20' + id;
        } else {
          id = '19' + id;
        }
      }

      if (
        id.length !== 12 ||
        !dateUtils.isDate(parseInt(id.slice(0, 4), 10), parseInt(id.slice(4, 6), 10), parseInt(id.slice(6, 8), 10))
      ) {
        return false;
      }

      for (let i = 2; i < 12; ++i) {
        const num = parseInt(id[i], 10);

        if (i % 2 !== 0) {
          checksum += num;
        } else {
          // split and add double digits
          checksum += ((num * 2) % 9) + Math.floor(num / 9) * 9;
        }
      }

      if (checksum % 10 === 0) return true;

      return false;
    case 'DK':
      return denmark(id).validate();
    case 'NO':
      const norwayValidator = norway(id);
      return norwayValidator.validate() && norwayValidator.getAge() >= 18;
    case 'ES':
      // DNI - the ID number for Spanish nationals, NIE - the ID number for foreign residents in Spain
      const DNI_REGEX = /^(\d{8})([A-Z])$/;
      const NIE_REGEX = /^[XYZ]\d{7,8}[A-Z]$/;
      const dniLetters = 'TRWAGMYFPDXBNJZSQVHLCKE';

      id = id.toUpperCase().replace(/\s/, '');

      if (!DNI_REGEX.test(id) && !NIE_REGEX.test(id)) return false;

      const nie = id.replace('X', '0').replace('Y', '1').replace('Z', '2');

      const checkLetter = id.substr(-1);
      const charIndex = parseInt(nie.slice(0, -1), 10) % 23;

      return dniLetters.charAt(charIndex) === checkLetter;

    default:
      console.warn('Validation not implemented');
      return false;
  }
};

export const isCompanyNumberValid = (countryCode: CountryCode, companyNumber: string): boolean => {
  switch (countryCode) {
    case 'DK': {
      // https://help.sap.com/docs/SUPPORT_CONTENT/crm/3354674603.html
      const regexp = /^\d{8}$/;
      if (!regexp.test(companyNumber)) {
        return false;
      }

      const digitsMultipliers = [2, 7, 6, 5, 4, 3, 2, 1];
      const digits = companyNumber.split('').map(value => parseInt(value));
      const sum = digits.reduce((acc, digit, index) => {
        return acc + digitsMultipliers[index] * digit;
      }, 0);
      return sum % 11 === 0;
    }
    case 'SE': {
      // https://help.sap.com/docs/SUPPORT_CONTENT/crm/3354674179.html
      const regexp = /^\d{10}$/;
      if (!regexp.test(companyNumber)) {
        return false;
      }

      const calculatedCheckSum = companyNumber
        .split('')
        .reverse()
        .map(value => parseInt(value))
        .reduce((acc, digit, index) => {
          const value = index % 2 === 0 ? digit : digit * 2;
          return acc + (value > 9 ? value - 9 : value);
        }, 0);

      return calculatedCheckSum % 10 === 0;
    }
    case 'ES': {
      // https://help.sap.com/docs/SUPPORT_CONTENT/crm/3354675269.html
      // simplified, no check sum verification - only regexp
      const regexp = /([A-Z]\d{8})|([A-Z]\d{7}[A-Z])|(\d{8}[A-Z])/;
      return regexp.test(companyNumber);
    }
    case 'NO': {
      // https://help.sap.com/docs/SUPPORT_CONTENT/crm/3354674327.html
      const regexp = /^\d{9}$/;
      if (!regexp.test(companyNumber)) {
        return false;
      }
      const digitsMultipliers = [3, 2, 7, 6, 5, 4, 3, 2];
      const digits = companyNumber.split('').map(value => parseInt(value));
      const sum = digits.slice(0, -1).reduce((acc, digit, index) => {
        return acc + digitsMultipliers[index] * digit;
      }, 0);

      const reminder = sum % 11;
      return (11 - reminder) % 10 === digits[digits.length - 1];
    }
  }
};
