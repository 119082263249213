import { reactLocalStorage } from 'reactjs-localstorage';
import { Auth } from 'aws-amplify';
import { CognitoUser, CognitoUserSession } from 'amazon-cognito-identity-js';
import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useInterval } from 'hooks';
import { useDispatch } from 'react-redux';
import { logout } from 'store/appActions';
import { persistor } from 'store/store';

export type SignedInUser = CognitoUser & {
  challengeParam: {
    phone: string;
    secretCode: string;
  };
};

export const getBearerToken = async () => {
  try {
    const session = await Auth.currentSession();
    return session.isValid() ? `Bearer ${session.getAccessToken().getJwtToken()}` : undefined;
  } catch {
    return undefined;
  }
};

function clearLocalStorageWithoutLang() {
  // Not clear the selected language in localstorage to keep it between logins
  const userLanguage = localStorage.getItem('userLanguage');
  localStorage.clear();

  userLanguage && localStorage.setItem('userLanguage', userLanguage);
}

function storeUserData(username: string) {
  reactLocalStorage.set('user', username);
  reactLocalStorage.set('userId', username);
  reactLocalStorage.set('toggleContext', username);
}

export type CognitoAuthStatus = 'PENDING' | 'UNAUTHENTICATED' | 'AUTHENTICATED';

function useCognitoUserProvider() {
  const dispatch = useDispatch();

  const [status, setStatus] = useState<CognitoAuthStatus>('PENDING');
  const [roles, setRoles] = useState<string[]>([]);

  const getValidSession = async () => {
    try {
      const session = await Auth.currentSession();
      return session.isValid() ? session : undefined;
    } catch {
      return undefined;
    }
  };

  function storeSessionData(session?: CognitoUserSession) {
    if (session && session.isValid()) {
      const tokenData = session.getAccessToken().payload;
      setRoles(tokenData['cognito:groups'] || []);
      setStatus('AUTHENTICATED');
    } else {
      setStatus('UNAUTHENTICATED');
      setRoles([]);
    }
  }

  const checkSession = async () => {
    const validSession = await getValidSession();
    storeSessionData(validSession);
  };

  useEffect(() => {
    if (status === 'PENDING') {
      checkSession();
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [status]);

  useInterval(() => {
    checkSession();
  }, 5000);

  async function signIn(offerId: string) {
    try {
      return (await Auth.signIn(offerId)) as SignedInUser;
    } catch (err) {
      // TODO: Visual error handling to be implemented in MA-64
      console.error(err);
      return undefined;
    }
  }

  async function signOut() {
    clearLocalStorageWithoutLang();
    dispatch(logout());
    try {
      // logout cognito user
      await Auth.signOut();
    } catch {}
    storeSessionData();
    return persistor.purge();
  }

  async function answerCustomChallenge(signedInUser: SignedInUser, answer: string) {
    const user: CognitoUser = await Auth.sendCustomChallengeAnswer(signedInUser, answer);
    const session = await getValidSession();
    storeSessionData(session);
    if (session) {
      storeUserData(user.getUsername());
      return true;
    } else {
      return false;
    }
  }

  function isAuthenticated() {
    return status === 'AUTHENTICATED';
  }

  function isOnboardingUser() {
    return roles.indexOf('onboarding') !== -1;
  }

  return useMemo(
    () => ({
      authStatus: status,
      roles,
      isAuthenticated,
      isOnboardingUser,
      signIn,
      answerCustomChallenge,
      signOut,
    }),
    [status, JSON.stringify(roles)]
  );
}

interface AuthState {
  authStatus: CognitoAuthStatus;
  roles: string[];
  isOnboardingUser(): boolean;
  isAuthenticated(): boolean;
  signIn(offerId: string): Promise<SignedInUser | undefined>;
  answerCustomChallenge(user: SignedInUser, answer: string): Promise<boolean>;
  signOut(): Promise<void>;
}

export const AuthContext = createContext<AuthState>({
  authStatus: 'PENDING',
  roles: [],
  isOnboardingUser: () => false,
  isAuthenticated: () => false,
  signIn: async () => undefined,
  signOut: async () => Promise.resolve(),
  answerCustomChallenge: async () => false,
});

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const auth = useCognitoUserProvider();

  return <AuthContext.Provider value={auth}>{auth.authStatus !== 'PENDING' ? children : <></>}</AuthContext.Provider>;
};

export default function useCognitoUser() {
  return useContext(AuthContext);
}
