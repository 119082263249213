import { createAction, PrepareAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { MerchantsList } from './merchantTypes';

const GET_MERCHANTS = 'GET_MERCHANTS';

const SET_CURRENT_MERCHANT = 'SET_CURRENT_MERCHANT';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getMerchants = createAction<PrepareAction<any>>(GET_MERCHANTS, () => ({
  payload: {
    client: 'merchantServiceV2',
    request: {
      method: 'GET',
      url: '/merchant',
    },
  },
}));

export const getMerchantsSuccess = createAction<AxiosResponse<MerchantsList>>(GET_MERCHANTS + '_SUCCESS');

export const getMerchantsFail = createAction(GET_MERCHANTS + '_FAIL');

export const cleanMerchants = createAction('CLEAN_MERCHANTS');

export const setCurrentMerchantReference = createAction<string>(SET_CURRENT_MERCHANT);
