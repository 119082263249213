import { Amount } from '../features/common/types';
import { toFixedFractionalDigits, toLocaleBasedString } from '@loomispay/vault';

/**
 * Converts long money to decimal amount
 * @param amount
 * @returns {number}
 */
export function denormalizeAmount(amount: number | null) {
  return amount == null ? null : amount / 100;
}

/**
 * Converts decimal amount to long money amount
 * @param amount
 * @returns {number}
 */
export function normalizeAmount(amount: number | null) {
  return amount == null ? null : amount * 100;
}

export function format(amount: Amount): string {
  return formatAmount(amount.amount, amount.currencyCode);
}

export function formatNormalizedAmount(amount: number | null, currency?: string, fractionalDigits?: number) {
  return formatAmount(normalizeAmount(amount), currency, fractionalDigits);
}

export function formatAmount(amount: number | null, currency?: string, fractionalDigits?: number) {
  const formattedAmount = denormalizeAmount(amount);
  return `${
    fractionalDigits || currency
      ? toFixedFractionalDigits(toLocaleBasedString(`${formattedAmount}` || ''), fractionalDigits || 2)
      : formattedAmount
  }${currency ? ` ${currency}` : ''}`;
}

export const toNegativeAmount = (num?: number) =>
  num === undefined || num === null ? undefined : Number(-Math.abs(num));

export const localizeAmount = (amount?: number, currencyCode?: string, fractionDigits?: number) => {
  const amountValue = amount || 0;

  return `${
    fractionDigits || (currencyCode && currencyCode.length)
      ? toFixedFractionalDigits(toLocaleBasedString(`${amountValue}` || ''), fractionDigits || 2)
      : amountValue
  }${currencyCode ? ` ${currencyCode}` : ''}`;
};
