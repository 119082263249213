import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export const useDocumentTitle = (title: string, suffix = true) => {
  const { t } = useTranslation();
  const [documentTitle, setDocumentTitle] = useState(title);
  useEffect(() => {
    if (suffix) {
      document.title = `${documentTitle} ${t('common.titleSuffix')}`;
    } else {
      document.title = documentTitle;
    }
  }, [documentTitle, suffix, t]);

  return { documentTitle, setDocumentTitle };
};
