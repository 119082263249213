import { Signer, SigningCombination } from '../../../store/types';
import { CountryCode } from '../../../../../constants/types';

function getSigningCombinationIdUsingFullName(signingCombination: SigningCombination) {
  return [...signingCombination.persons]
    .sort((p1, p2) => p1.fullName.localeCompare(p2.fullName))
    .reduce<string>((acc, curr, i) => {
      return acc + curr.fullName + (i === signingCombination.persons.length - 1 ? '' : ',');
    }, '');
}

function getSigningCombinationIdUsingPersonalId(signingCombination: SigningCombination) {
  return [...signingCombination.persons]
    .sort((p1, p2) => {
      const personalId1 = p1 && p1.personalId ? p1.personalId : '';
      const personalId2 = p2 && p2.personalId ? p2.personalId : '';
      return personalId1.localeCompare(personalId2);
    })
    .reduce<string>((acc, curr, i) => {
      return acc + curr.personalId + (i === signingCombination.persons.length - 1 ? '' : ',');
    }, '');
}

function getSelectedSigningCombinationIdUsingFullName(signatories: Signer[]) {
  return [...signatories]
    .sort((s1, s2) => s1.fullName.localeCompare(s2.fullName))
    .reduce<string>((acc, curr, i) => {
      return acc + curr.fullName + (i === signatories.length - 1 ? '' : ',');
    }, '');
}

function getSelectedSigningCombinationIdUsingPersonalId(signatories: Signer[]) {
  return [...signatories]
    .sort((s1, s2) => {
      const personalId1 = s1 && s1.personalId ? s1.personalId : '';
      const personalId2 = s2 && s2.personalId ? s2.personalId : '';
      return personalId1.localeCompare(personalId2);
    })
    .reduce<string>((acc, curr, i) => {
      return acc + curr.personalId + (i === signatories.length - 1 ? '' : ',');
    }, '');
}

const getSigningCombinationId = (signingCombination: SigningCombination, countryCode?: CountryCode) => {
  switch (countryCode) {
    case 'SE':
      return getSigningCombinationIdUsingPersonalId(signingCombination);
    case 'DK':
    case 'ES':
    case 'NO':
      return getSigningCombinationIdUsingFullName(signingCombination);
    default:
      return null;
  }
};

const getSelectedSigningCombinationId = (signatories: Signer[], countryCode?: CountryCode) => {
  switch (countryCode) {
    case 'SE':
      return getSelectedSigningCombinationIdUsingPersonalId(signatories);
    case 'DK':
    case 'ES':
    case 'NO':
      return getSelectedSigningCombinationIdUsingFullName(signatories);
    default:
      return null;
  }
};

export const getSelectedSigningCombinationIndex = (
  signatories: Signer[],
  signingCombinations: SigningCombination[],
  countryCode?: CountryCode
) => {
  if (signatories && signatories.length > 0 && signingCombinations && signingCombinations.length > 0) {
    const currentSigningCombinationId = getSelectedSigningCombinationId(signatories, countryCode);

    return signingCombinations.findIndex(
      signingCombination => currentSigningCombinationId === getSigningCombinationId(signingCombination, countryCode)
    );
  }
  return -1;
};
