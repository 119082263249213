import { Button, Flag, NavigationActionButtons, Text } from '@loomispay/vault';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getCountryCode } from '../../../../../features/onboardingV2/store/sales/selectors';
import { getFlagByCountry } from 'utils/countrySelection';
import styled from 'styled-components/macro';

export default function NavigationOnboardingSales() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const countryCode = useSelector(getCountryCode);

  return (
    <>
      {countryCode && (
        <CountryIndicator>
          <Flag country={getFlagByCountry(countryCode)} key={countryCode} />
          <Text size="s">{t('onboarding.v2.newOffer')}</Text>
        </CountryIndicator>
      )}

      <NavigationActionButtons>
        <Button variant="tertiary" onClick={() => navigate('/logout')}>
          {t('navigation.logout')}
        </Button>
      </NavigationActionButtons>
    </>
  );
}

const CountryIndicator = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacings['1']};
`;
