import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { CountryCode, ScreenMode } from 'constants/types';
import LocationEditForm, { LocationForm } from 'features/common/components/Locations/LocationEditForm';
import { StoreBase } from 'features/common/types';
import { onboardingRoutes } from '../../../OnboardingRouter';
import { useOnboardingData } from '../../../data/queries';
import { useEffect, useMemo } from 'react';
import { usePartialUpdate } from '../../../data/mutations';

const MerchantLocationEdit = () => {
  const navigate = useNavigate();
  const { countryCode, offerRef, locationId } = useParams() as {
    countryCode: CountryCode;
    offerRef: string;
    locationId: string;
  };
  const { mutate: updateLocation, isSuccess } = usePartialUpdate();

  const locationIndex = parseInt(locationId);

  const { data: onboardingData, isLoading } = useOnboardingData({ countryCode, offerRef });

  useEffect(() => {
    if (isSuccess) {
      navigate(
        generatePath(onboardingRoutes.locationsMerchant.path, {
          countryCode,
          offerRef,
        })
      );
    }
  }, [isSuccess, countryCode, offerRef, navigate]);

  const locationForm: LocationForm = useMemo(() => {
    //TODO: think about changes locationIndex to location reference
    const location = (onboardingData?.stores || []).find((s, i) => i === locationIndex);
    return {
      name: location?.name,
      address: location?.address.address,
      zipCode: location?.address.zipCode,
      city: location?.address.city,
      country: countryCode,
    };
  }, [onboardingData, countryCode, locationIndex]);

  if (isLoading || (onboardingData?.stores?.length && onboardingData.stores.length < 1)) return null;

  const onSubmit = (locationForm: LocationForm) => {
    const location: StoreBase = {
      name: locationForm.name || '',
      address: {
        address: locationForm.address,
        zipCode: locationForm.zipCode,
        city: locationForm.city,
        countryCode,
      },
    };

    if (onboardingData) {
      const updatedStores = onboardingData.stores.map((offer, index) => {
        if (index === locationIndex) return { ...offer, ...location };
        else return offer;
      });
      updateLocation({ countryCode, merchantRef: offerRef, data: { ...onboardingData, stores: updatedStores } });
    }
  };

  return (
    <LocationEditForm
      location={locationForm}
      closeUrl={generatePath(onboardingRoutes.locationsMerchant.path, {
        countryCode,
        offerRef,
        locationId,
      })}
      mode={ScreenMode.edit}
      onSubmit={onSubmit}
      countryCode={countryCode}
    />
  );
};

export default MerchantLocationEdit;
