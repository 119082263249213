import styled from 'styled-components/macro';
import loadingIcon from 'assets/img/loomis-pay_loader_small.gif';
import { spacing } from '../../../constants';

import { useTranslation } from 'react-i18next';
import { Paragraph } from '@loomispay/vault';

type Props = {
  imageOnly: boolean;
};
const Layer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 3000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.94);
`;

const LoadingParagraph = () => {
  const { t } = useTranslation();
  return <Paragraph size="l">{`${t('common.loading')}...`}</Paragraph>;
};

export const IndicatorLayer = ({ imageOnly }: Props) => {
  return (
    <Layer>
      <img alt="Loading" style={{ marginBottom: spacing.s4 }} src={loadingIcon} />
      {!imageOnly && <LoadingParagraph />}
    </Layer>
  );
};
