import { Heading, Paragraph } from '@loomispay/vault';
import Container from 'common/components/MainLayout/Container';
import { FieldErrors, UseFormMethods } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { CompanyDocumentsUpload } from './CompanyDocumentsUpload';
import { CountryCode } from 'constants/types';

type Props = {
  offerRef?: string;
  errors: FieldErrors;
  countryCode: CountryCode;
  register: UseFormMethods['register'];
  setValue: UseFormMethods['setValue'];
};

export const AdditionalDocuments = ({ offerRef, errors, countryCode, register, setValue }: Props) => {
  const { t } = useTranslation();

  return (
    <Container spacing="2">
      <header>
        <Heading sansSerif size="xxs">
          {countryCode === 'ES'
            ? t('onboarding.v2.additionalInformation.companyDocuments')
            : t('onboarding.v2.additionalInformation.supportingDocuments')}
        </Heading>
        <Paragraph size="s">
          {countryCode === 'ES'
            ? t('onboarding.v2.additionalInformation.companyDocuments.description')
            : t('onboarding.v2.additionalInformation.supportingDocuments.description')}
        </Paragraph>
      </header>
      <CompanyDocumentsUpload offerRef={offerRef} errors={errors} register={register} setValue={setValue} />
    </Container>
  );
};
