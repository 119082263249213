import { useMedia } from '@loomispay/vault';
import { FileLike } from '@rpldy/shared';
import { notifications } from 'common/components/Notification/notifications';
import { FileUpload } from 'features/onboardingV2/components/upload/OnboardingFileUpload';
import { useDeleteCompanyDocument, useUploadCompanyDocument } from 'features/onboardingV2/data/mutations';
import { useOnboardingData } from 'features/onboardingV2/data/queries';
import { BaseQueryParams } from 'features/onboardingV2/data/types';
import { OnboardingDocumentCategory } from 'features/onboardingV2/store/types';
import { useCallback, useEffect } from 'react';
import { FieldErrors, UseFormMethods } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

type Props = {
  offerRef?: string;
  errors: FieldErrors;
  register: UseFormMethods['register'];
  setValue: UseFormMethods['setValue'];
};

export const CompanyDocumentsUpload = ({ offerRef, errors, register, setValue }: Props) => {
  const acceptableFileTypes = ['image/png', 'image/jpeg', 'application/pdf'];
  const { t } = useTranslation();
  const { countryCode } = useParams<BaseQueryParams>();
  const { data: onboardingData, refetch } = useOnboardingData({ countryCode, offerRef });

  const globalDocuments = onboardingData?.offerOnboardingGlobalDocuments || [];
  const companyDocuments = globalDocuments.filter(d => d.category === 'COMPANY_DOCUMENT');

  const { mutate: uploadCompanyDocument, isSuccess: companyDocumentUploaded } = useUploadCompanyDocument();
  const { mutate: deleteCompanyDocument, isSuccess: companyDocumentDeleted } = useDeleteCompanyDocument();

  const handleUpload = (category?: OnboardingDocumentCategory) => async (file: FileLike) => {
    try {
      uploadCompanyDocument({
        countryCode,
        merchantRef: offerRef,
        file,
        category,
      });
      notifications.success(t('onboarding.v2.notifications.fileUploadSuccess'));
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      if (
        e?.error?.response?.status === 400 &&
        e?.error?.response?.data?.generalErrors?.includes('file_size_exceeded')
      ) {
        notifications.error(t('errorCodes.file_size_exceeded'));
      } else if (
        e?.error?.response?.status === 400 &&
        e?.error?.response?.data?.generalErrors?.includes('file_is_corrupted')
      ) {
        notifications.error(t('errorCodes.file_is_corrupted'));
      } else {
        notifications.error(t('common.serverError'));
      }
      return false;
    }
    return true;
  };

  const handleCompanyDocRemoval = (fileName?: string) => {
    const ref = globalDocuments.find(d => d.category === 'COMPANY_DOCUMENT' && d.originalFileName === fileName)?.ref;
    return handleRemoval(ref);
  };

  const handleRemoval = useCallback(
    async (documentRef?: string) => {
      try {
        if (documentRef) {
          deleteCompanyDocument({
            countryCode,
            merchantRef: offerRef,
            documentRef,
          });
        }
      } catch (e) {
        notifications.error(t('common.serverError'));
        return false;
      }
      return true;
    },
    [countryCode, deleteCompanyDocument, offerRef, t]
  );

  useEffect(() => {
    // refetch onboarding data when uploaded documents changed
    refetch();
  }, [companyDocumentUploaded, companyDocumentDeleted, refetch]);

  return (
    <FileUpload
      label={t('onboarding.v2.additionalInformation.identification.addFile')}
      acceptedTypes={acceptableFileTypes}
      onUpload={handleUpload('COMPANY_DOCUMENT')}
      onRemove={handleCompanyDocRemoval}
      isMobile={useMedia('mobile')}
      fileList={companyDocuments.map(doc => doc?.originalFileName)}
      name={`${offerRef}.companyDoc`}
      testId={`${offerRef}.global-documents.companyDocUpload`}
      register={register}
      setValue={setValue}
      errors={errors}
      multiUpload
      maxFiles={2}
    />
  );
};
