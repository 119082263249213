import { Heading, Text } from '@loomispay/vault';
import { CompanyData } from 'features/common/types';
import { BusinessCategory, CompanyKeywordOption } from 'features/onboardingV2/store/types';
import { useTranslation } from 'react-i18next';
import { OfferSummaryRow, OfferSummarySectionBox } from '../../OfferOverview.styles';

export default function OfferOverviewCompanyInfo({
  companyData,
  businessCategory,
  businessSubCategory,
}: {
  companyData?: CompanyData;
  businessCategory?: BusinessCategory;
  businessSubCategory?: CompanyKeywordOption;
}) {
  const { t } = useTranslation();

  return (
    <section>
      <Heading size="xxs" sansSerif>
        {t('onboarding.v2.overview.companyInformation')}
      </Heading>
      <OfferSummarySectionBox>
        <OfferSummaryRow>
          <Text size="s">{t('onboarding.v2.overview.companyInformation.organisationNumber')}</Text>
          <Text size="s">{companyData?.companyNumber}</Text>
        </OfferSummaryRow>
        <OfferSummaryRow>
          <Text size="s">{t('onboarding.v2.overview.companyInformation.companyName')}</Text>
          <Text size="s">{companyData?.companyName}</Text>
        </OfferSummaryRow>
        <OfferSummaryRow>
          <Text size="s">{t('onboarding.v2.overview.companyInformation.industry')}</Text>
          <Text size="s">{companyData?.industry}</Text>
        </OfferSummaryRow>
        <OfferSummaryRow>
          <Text size="s">{t('onboarding.v2.overview.companyInformation.address')}</Text>
          <Text size="s">{companyData?.address?.address}</Text>
        </OfferSummaryRow>
        <OfferSummaryRow>
          <Text size="s">{t('onboarding.v2.overview.companyInformation.zipCode')}</Text>
          <Text size="s">{companyData?.address?.zipCode}</Text>
        </OfferSummaryRow>
        <OfferSummaryRow>
          <Text size="s">{t('onboarding.v2.overview.companyInformation.city')}</Text>
          <Text size="s">{companyData?.address?.city}</Text>
        </OfferSummaryRow>
        <OfferSummaryRow>
          <Text size="s">{t('onboarding.v2.overview.companyInformation.registrationDate')}</Text>
          <Text size="s">{companyData?.registrationDate}</Text>
        </OfferSummaryRow>
        <OfferSummaryRow>
          <Text size="s">{t('onboarding.v2.overview.companyInformation.businessType')}</Text>
          <Text size="s">
            {businessCategory ? t(`onboarding.v2.overview.businessType.${businessCategory}` as const) : ''}
            {', '}
            {businessSubCategory ? t(`onboarding.v2.companyInfo.categories.${businessSubCategory}` as const) : ''}
          </Text>
        </OfferSummaryRow>
      </OfferSummarySectionBox>
    </section>
  );
}
