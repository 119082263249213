import styled from 'styled-components/macro';

export const cardWidth = 197;

const getBorderColor = (hasError?: boolean, isSelected?: boolean) => {
  switch (true) {
    case hasError:
      return 'negativeNormalBorder';
    case isSelected:
      return 'defaultNormalBorder';
    default:
      return 'disabledBorder';
  }
};

export const Card = styled.div<{ selected: boolean; withError?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  text-align: center;
  align-items: center;
  border: 1px solid ${({ theme, selected, withError }) => theme.color.border[getBorderColor(withError, selected)]};
  border-radius: 8px;
  width: ${cardWidth}px;
  margin: ${({ theme }) => theme.spacings['1.5']};
  padding: ${({ theme }) => theme.spacings[3]};
  min-height: 260px;
  word-break: break-word;
  overflow-wrap: break-word;
  hyphens: auto;
`;

export const CardPriceWrapper = styled.div`
  justify-self: flex-end;
  margin-top: auto;

  & > div > div {
    justify-content: center;
  }
`;

export const CardControlWrapper = styled.div`
  padding-top: ${({ theme }) => theme.spacings[2]};
  display: flex;
  flex-wrap: nowrap;
  gap: ${({ theme }) => theme.spacings[2]};
  align-items: center;
`;

export const QuantityWrapper = styled.span`
  font-weight: bold;
  flex-basis: 0;
`;
