import { Display, Icon, Paragraph, Text, useMedia } from '@loomispay/vault';
import { Footer } from 'features/onboardingV2/components/OnboardingFooter';
import { useDocumentTitle } from 'hooks';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { useMerchantContext } from 'services/MerchantContext';
import { userService } from 'services/userService';
import styled from 'styled-components/macro';
import OfferOverviewLoomisPay from '../../../components/OfferOverview/LoomisPay/OfferOverviewLoomisPay';

import useCognitoUser from 'features/loginPasswordless/useCognitoUser';
import OfferOverviewCash from 'features/onboardingV2/components/OfferOverview/CashServices/OfferOverviewCash';
import Container from 'common/components/MainLayout/Container';
import OfferOverviewCommon from '../../../components/OfferOverview/Common/OfferOverviewCommon';
import { getOfferLoginRoute, onboardingRoutes } from '../../../OnboardingRouter';
import * as dateUtils from 'utils/dateUtils';
import { useOffer } from '../../../data/queries';
import { useEffect } from 'react';
import axios from 'axios';
import { notifications } from '../../../../../common/components/Notification/notifications';
import { PrivacyNotice } from './PrivacyNotice';

type RouteParameters = {
  offerRef: string;
};

const OverviewMerchant = () => {
  const isMobile = useMedia('mobile');
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { offerRef } = useParams<RouteParameters>() as RouteParameters;

  const { isAuthenticated: isMerchantUserAuthenticated } = useCognitoUser();

  const offerLoginRoute = getOfferLoginRoute(offerRef);
  useDocumentTitle(t('onboarding.v2.merchant.overview.title'));

  const { data: offerData, error } = useOffer({ offerRef });

  const { setCurrentMerchant, merchantContext } = useMerchantContext();

  useEffect(() => {
    if (error && axios.isAxiosError(error)) {
      if (error.response?.status === 401) {
        navigate(getOfferLoginRoute(offerRef));
      } else if (error.response?.status === 404) {
        navigate('/offer-not-found');
      } else {
        notifications.error(t('errors.somethingWentWrong'));
        navigate('/503');
      }
    }
  }, [error, navigate, offerRef, t]);

  if (!offerData) return null;

  const { loomisContactPerson, expiryDate, offerType, merchantRef } = offerData;

  const isCashOnlyOffer = offerType === 'CASH';

  const canShowCashServicesOverview = ['CASH', 'PAY_AND_CASH'].includes(`${offerType}`);

  // TODO: check this function deeper
  async function handleContinue() {
    if (merchantRef && ((await userService.isSalesRepLoggedIn()) || isMerchantUserAuthenticated()) && offerData) {
      if (merchantRef !== merchantContext.merchant?.merchantReference) {
        await setCurrentMerchant(merchantRef);
      }
      navigate(
        generatePath(onboardingRoutes.onboardingDispatcher.path, { countryCode: offerData.countryCode, offerRef })
      );
    } else {
      navigate(offerLoginRoute);
    }
  }

  return (
    <Container>
      <Wrapper>
        {expiryDate && (
          <ExpiryNote>
            <Icon size="m" name="warningCircled" />
            <Text size="s">
              {t('common.merchant.overview.validUntil', {
                expiresAt: dateUtils.formatDate(expiryDate, dateUtils.Format.DD_MMM_YYYY),
              })}
            </Text>
          </ExpiryNote>
        )}
        <section>
          <Display size="s">{t('onboarding.v2.merchant.overview.title')}</Display>
          <Text size="s" color={'tertiary'}>
            {expiryDate && t('onboarding.v2.merchant.overview.description.validUntil') + ' '}
            {t('onboarding.v2.merchant.overview.description.1', {
              contactPersonName: `${loomisContactPerson?.firstName} ${loomisContactPerson?.lastName}. `,
            })}
          </Text>
          {!isMobile && <br />}
          <Paragraph size="s" color="tertiary">
            {t('onboarding.v2.merchant.overview.description.2', {
              contactPersonPhone: loomisContactPerson?.phoneNumber,
              contactPersonEmail: loomisContactPerson?.emailAddress,
            })}
          </Paragraph>
          <Container spacing="4">
            <OfferOverviewCommon offer={offerData} />
            {!isCashOnlyOffer && <OfferOverviewLoomisPay offer={offerData} />}
            {canShowCashServicesOverview && <OfferOverviewCash offer={offerData} />}
          </Container>
        </section>
        <Footer
          isMobile={isMobile}
          nextPage={handleContinue}
          nextButtonLabel={t('onboarding.v2.merchant.overview.nextButton')}
        >
          {offerData.countryCode && <PrivacyNotice countryCode={offerData.countryCode} />}
        </Footer>
      </Wrapper>
    </Container>
  );
};

export const Wrapper = styled.div`
  padding: 32px 0 120px 0;
`;

const ExpiryNote = styled.section`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacings['1']};
  padding-bottom: ${({ theme }) => theme.spacings['2']};
`;

export default OverviewMerchant;
