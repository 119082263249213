import { Component } from 'react';
import styled from 'styled-components/macro';
import { Message } from './Message';
import { MessageType, NotificationBarProps, NotificationBarState } from './types';

const TopBar = styled.div`
  position: fixed;
  bottom: 0;
  right: 0;
  width: 35%;
  z-index: 999999999;
`;

export class NotificationBar extends Component<NotificationBarProps, NotificationBarState> {
  messageIdx = 0;

  constructor(props: NotificationBarProps) {
    super(props);
    this.state = { messages: [] };

    this.onClose = this.onClose.bind(this);
  }

  show(value: MessageType | MessageType[]) {
    if (value) {
      let newMessages: MessageType[] = [];

      if (Array.isArray(value)) {
        for (let i = 0; i < value.length; i++) {
          value[i].id = this.messageIdx++;
          newMessages = [...this.state.messages, ...value];
        }
      } else {
        value.id = this.messageIdx++;
        newMessages = this.state.messages ? [...this.state.messages, value] : [value];
      }

      this.setState({ messages: newMessages });
    }
  }

  clear() {
    this.setState({ messages: [] });
  }

  onClose(message: MessageType) {
    const newMessages = this.state.messages.filter((msg: MessageType) => msg.id !== message.id);
    this.setState({ messages: newMessages });

    if (this.props.onClose) {
      this.props.onClose(message);
    }
  }

  render() {
    return (
      <TopBar id={this.props.id}>
        {this.state.messages.map((message: MessageType) => (
          <Message key={message.id} message={message} onClick={this.props.onClick} onClose={this.onClose} />
        ))}
      </TopBar>
    );
  }
}
