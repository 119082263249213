import { Button, Heading, Logo, NavigationActionButtons, Paragraph } from '@loomispay/vault';
import { Modal } from 'common/components/Modal';
import { ModalButtons } from 'common/components/Modal/Modal';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import useCognitoUser from '../../../../../features/loginPasswordless/useCognitoUser';
import { BaseQueryParams } from '../../../../../features/onboardingV2/data/types';

export default function NavigationOnboardingMerchant() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { offerRef } = useParams<BaseQueryParams>();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const { signOut } = useCognitoUser();

  const onLogout = async () => {
    if (offerRef) {
      await signOut();
      navigate(`/onboarding/v2/${offerRef}`);
    } else {
      navigate('/logout');
    }
  };

  return (
    <>
      <Logo />

      <NavigationActionButtons>
        <Button variant="destructive-teriary" onClick={() => setIsModalOpen(true)}>
          {t('onboarding.saveAndQuit')}
        </Button>
      </NavigationActionButtons>

      <Modal
        isOpen={isModalOpen}
        onAfterClose={() => setIsModalOpen(false)}
        onRequestClose={() => setIsModalOpen(false)}
        shouldCloseOnEsc
        testId="onboarding-save-and-exit-modal"
        style={{ content: { width: '400px' } }}
      >
        <Heading>{t('onboarding.saveAndQuit')}</Heading>
        <Paragraph>{t('onboarding.saveAndQuit.warning')}</Paragraph>
        <ModalButtons>
          <Button variant="tertiary" onClick={() => setIsModalOpen(false)}>
            {t('common.cancel')}
          </Button>
          <Button variant="destructive" onClick={onLogout}>
            {t('onboarding.saveAndQuit')}
          </Button>
        </ModalButtons>
      </Modal>
    </>
  );
}
