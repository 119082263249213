import {
  companyDataProviderClientV2WithoutMiddleware,
  onboardingApiV2ClientWithoutMiddleware,
  onboardingMerchantApiV2ClientWithoutMiddleware,
} from '../../../store/store';
import { MerchantOnboardingData, OfferContactData } from '../../common/types';
import { AdditionalQuestion, Offer, OfferType, OwnersResponse, SigningCombinationsResponse } from '../store/types';
import {
  BaseQueryParams,
  CompanyDataProviderQueryParams,
  DeleteDocumentParams,
  PostAnswersParams,
  PostAnswersResponse,
  PutDocumentParams,
  PutPartialParams,
} from './types';

const finaliseOnboardingRoutes: Record<OfferType, string> = {
  PAY: 'pay',
  CASH: 'cash',
  PAY_AND_CASH: 'pay-and-cash',
};

const fetchOffer = async ({ countryCode, offerRef }: BaseQueryParams): Promise<Offer> =>
  await onboardingApiV2ClientWithoutMiddleware.get(`${countryCode}/offer/${offerRef}`).then(({ data }) => data);

const fetchContactData = async ({ countryCode, offerRef }: BaseQueryParams): Promise<OfferContactData> =>
  await onboardingApiV2ClientWithoutMiddleware
    .get<OfferContactData>(`${countryCode}/offer/${offerRef}/contactData`)
    .then(({ data }) => data);

const fetchOnboardingData = async (countryCode?: string, merchantRef?: string): Promise<MerchantOnboardingData> =>
  await onboardingMerchantApiV2ClientWithoutMiddleware
    .get<MerchantOnboardingData>(`${countryCode}/merchant/${merchantRef}`)
    .then(({ data }) => data);

const fetchAdditionalQuestions = async (countryCode?: string, merchantRef?: string): Promise<AdditionalQuestion[]> =>
  await onboardingMerchantApiV2ClientWithoutMiddleware
    .get<AdditionalQuestion[]>(`${countryCode}/merchant/${merchantRef}/kyc/edd/questions`)
    .then(({ data }) => data);

const fetchOwners = async ({ countryCode, companyNumber }: CompanyDataProviderQueryParams): Promise<OwnersResponse> =>
  await companyDataProviderClientV2WithoutMiddleware
    .get(`companyData/owners?businessRegisterNumber=${companyNumber}&country=${countryCode}&licence=PAYFAC`)
    .then(({ data }) => data);

const fetchSignees = async ({
  countryCode,
  companyNumber,
}: CompanyDataProviderQueryParams): Promise<SigningCombinationsResponse> =>
  await companyDataProviderClientV2WithoutMiddleware
    .get(`companyData/signingCombination?businessRegisterNumber=${companyNumber}&country=${countryCode}&licence=PAYFAC`)
    .then(({ data }) => data);

const putPartial = async ({ countryCode, merchantRef, data }: PutPartialParams): Promise<MerchantOnboardingData> =>
  await onboardingMerchantApiV2ClientWithoutMiddleware
    .put<MerchantOnboardingData>(`${countryCode}/merchant/${merchantRef}/partial`, data)
    .then(({ data }) => data);

const finaliseOnboarding = async ({
  countryCode,
  merchantRef,
  data,
  offerType,
}: PutPartialParams & { offerType: OfferType }): Promise<MerchantOnboardingData> =>
  await onboardingMerchantApiV2ClientWithoutMiddleware
    .post<MerchantOnboardingData>(`${countryCode}/merchant/${merchantRef}/${finaliseOnboardingRoutes[offerType]}`, data)
    .then(({ data }) => data);

const uploadCompanyDocument = async ({
  countryCode,
  merchantRef,
  file,
  category = 'COMPANY_DOCUMENT',
}: PutDocumentParams): Promise<File> => {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('category', category);
  return await onboardingMerchantApiV2ClientWithoutMiddleware
    .post<File>(`${countryCode}/merchant/${merchantRef}/document`, formData)
    .then(({ data }) => data);
};

const deleteCompanyDocument = async ({ countryCode, merchantRef, documentRef }: DeleteDocumentParams) => {
  return await onboardingMerchantApiV2ClientWithoutMiddleware.delete(
    `${countryCode}/merchant/${merchantRef}/document/${documentRef}`
  );
};

const uploadPersonalDocument = async ({
  countryCode,
  merchantRef,
  representativeRef,
  file,
  category = 'REPRESENTATIVE_ID_DOCUMENT',
}: PutDocumentParams & { representativeRef: string }): Promise<File> => {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('category', category);
  return await onboardingMerchantApiV2ClientWithoutMiddleware
    .post<File>(`${countryCode}/merchant/${merchantRef}/representative/${representativeRef}/idDocument`, formData)
    .then(({ data }) => data);
};

const deletePersonalDocument = async ({
  countryCode,
  merchantRef,
  representativeRef,
  documentRef,
}: DeleteDocumentParams & { representativeRef: string }) => {
  return await onboardingMerchantApiV2ClientWithoutMiddleware.delete(
    `${countryCode}/merchant/${merchantRef}/representative/${representativeRef}/idDocument/${documentRef}`
  );
};

const assignOfferToMerchant = async ({ countryCode, offerRef }: BaseQueryParams): Promise<number> =>
  await onboardingApiV2ClientWithoutMiddleware
    .post<number>(`${countryCode}/offer/${offerRef}/assignToMerchant`)
    .then(({ status }) => status);

const sendAnswers = async ({ countryCode, merchantRef, data }: PostAnswersParams): Promise<PostAnswersResponse> =>
  await onboardingMerchantApiV2ClientWithoutMiddleware
    .post<PostAnswersResponse>(`${countryCode}/merchant/${merchantRef}/kyc/edd/questions/answers`, data)
    .then(({ data }) => data);

export {
  fetchContactData,
  fetchOffer,
  fetchOnboardingData,
  fetchOwners,
  fetchSignees,
  fetchAdditionalQuestions,
  finaliseOnboarding,
  putPartial,
  assignOfferToMerchant,
  sendAnswers,
  uploadCompanyDocument,
  deleteCompanyDocument,
  uploadPersonalDocument,
  deletePersonalDocument,
};
