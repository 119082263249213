import { Text } from '@loomispay/vault';
import { CompanyData } from 'features/common/types';
import { useTranslation } from 'react-i18next';
import { BorderedBox } from '../BorderedBox/BorderedBox';
import * as dateUtils from 'utils/dateUtils';

export const CompanyDataBox = ({ companyData }: { companyData: CompanyData }) => {
  const { t } = useTranslation();

  return (
    <BorderedBox testId={'company-data'}>
      <BorderedBox.Row>
        <Text size="s">{t('onboarding.v2.companyInfo.companyName')}</Text>
        <Text size="s" color="secondary">
          {companyData.companyName}
        </Text>
      </BorderedBox.Row>
      <BorderedBox.Row>
        <Text size="s">{t('onboarding.v2.companyInfo.industry')}</Text>
        <Text size="s" color="secondary">
          {companyData.industry}
        </Text>
      </BorderedBox.Row>
      <BorderedBox.Row>
        <Text size="s">{t('onboarding.v2.companyInfo.address')}</Text>
        <Text size="s" color="secondary">
          {companyData.address?.address}
        </Text>
      </BorderedBox.Row>
      <BorderedBox.Row>
        <Text size="s">{t('onboarding.v2.companyInfo.zipCode')}</Text>
        <Text size="s" color="secondary">
          {companyData.address?.zipCode}
        </Text>
      </BorderedBox.Row>
      <BorderedBox.Row>
        <Text size="s">{t('onboarding.v2.companyInfo.city')}</Text>
        <Text size="s" color="secondary">
          {companyData.address?.city}
        </Text>
      </BorderedBox.Row>
      <BorderedBox.Row>
        <Text size="s">{t('onboarding.v2.companyInfo.country')}</Text>
        <Text size="s" color="secondary">
          {companyData.address?.countryCode && t(`common.country.${companyData.address.countryCode}` as const)}
        </Text>
      </BorderedBox.Row>
      <BorderedBox.Row>
        <Text size="s">{t('onboarding.v2.companyInfo.registrationDate')}</Text>
        <Text size="s" color="secondary">
          {companyData.registrationDate
            ? dateUtils.formatDate(companyData.registrationDate, dateUtils.Format.YYYY_MM_DD)
            : '-'}
        </Text>
      </BorderedBox.Row>
    </BorderedBox>
  );
};
