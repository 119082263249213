import { Heading, useMedia } from '@loomispay/vault';
import { Radio, RadioGroup } from 'common/components/forms/Radio/Radio';
import { MerchantOnboardingData } from 'features/common/types';
import { Footer } from 'features/onboardingV2/components/OnboardingFooter';
import { Page, Signer, SigningCombination } from 'features/onboardingV2/store/types';
import { Fragment, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components/macro';
import { SigningCombinationsForm } from './SigningCombinations';
import { getSelectedSigningCombinationIndex } from './signingCombinationsService';
import { BoxWrapper } from 'common/components/Box';
import { useOffer, useOnboardingData, useSignees } from '../../../data/queries';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { BaseQueryParams } from '../../../data/types';
import { onboardingRoutes } from '../../../OnboardingRouter';
import { CountryCode } from '../../../../../constants/types';
import { usePartialUpdate } from '../../../data/mutations';

export default function SigningCombinationsFetched() {
  const isMobile = useMedia('mobile');
  const navigate = useNavigate();
  const { countryCode, offerRef } = useParams<BaseQueryParams>();
  const { data: offerOnboardingData } = useOnboardingData({ countryCode, offerRef });
  const { data: offerData } = useOffer({ countryCode, offerRef });
  const { data: signeesData } = useSignees({ countryCode, companyNumber: offerData?.companyData.companyNumber });
  const { mutate: updateSignees, isSuccess: isSuccessUpdated } = usePartialUpdate();

  const signatories = offerOnboardingData?.signatories;
  const hasSigningCombinations = signeesData && signeesData.length > 0;

  useEffect(() => {
    if (isSuccessUpdated) navigate(generatePath(onboardingRoutes.bankInformation.path, { countryCode, offerRef }));
  }, [isSuccessUpdated, countryCode, navigate, offerRef]);

  const { handleSubmit, control, errors } = useForm<SigningCombinationsForm>({ mode: 'onChange' });

  const currentCombination = useMemo(
    () =>
      signatories &&
      signeesData &&
      getSelectedSigningCombinationIndex(signatories, signeesData, countryCode as CountryCode),
    [signatories, signeesData, countryCode]
  );
  if (!signeesData || !signatories || !offerOnboardingData) return null;

  const getMerchantOnboardingData = (signatories: Signer[]) => {
    return {
      ...offerOnboardingData,
      signatories,
      page: 'signingCombinations' as Page,
    };
  };
  const initializeSignersFromSigningCombination = (selectedSignignCombinationIdx: number) => {
    const signingCombination: SigningCombination = signeesData[selectedSignignCombinationIdx];
    const result: Signer[] = signingCombination.persons.map(person => {
      return {
        fullName: person.fullName,
        personalId: person.personalId,
        roles: ['LEGAL_REPRESENTATIVE'],
      };
    });

    const merchantOnboardingData: MerchantOnboardingData = getMerchantOnboardingData(result);
    return merchantOnboardingData;
  };

  const onSubmit = (data: SigningCombinationsForm) => {
    const selectedSigningCombination = parseInt(data.signersGroup);
    const onboardingData = initializeSignersFromSigningCombination(selectedSigningCombination);

    updateSignees({ countryCode, merchantRef: offerRef, data: onboardingData });
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <RadioGroup
          name="signersGroup"
          control={control}
          errors={errors}
          defaultValue={`${currentCombination}`}
          required
        >
          {signeesData?.map((data, i) => {
            const firstNames = data.persons.map(person => person.fullName).join(' & ');

            return (
              <Fragment key={firstNames}>
                <SignerWrapper>
                  <Radio value={`${i}`} testId={`signer.${i}`}>
                    <RadioInputWrapper>
                      <Heading noGutter size="s">
                        {firstNames}
                      </Heading>
                    </RadioInputWrapper>
                  </Radio>
                </SignerWrapper>
              </Fragment>
            );
          })}
        </RadioGroup>

        <Footer
          isMobile={isMobile}
          prevPage={() => generatePath(onboardingRoutes.ownersV2.path, { countryCode, offerRef })}
          isScreenCompleted={hasSigningCombinations}
        />
      </form>
    </>
  );
}

export const SignerWrapper = styled(BoxWrapper)`
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 88px;
  margin-bottom: ${({ theme }) => theme.spacings[1]};
`;

const RadioInputWrapper = styled.div`
  display: flex;
  margin-left: ${({ theme }) => theme.spacings[0.5]};
`;
