import { useTranslation } from 'react-i18next';

import { TextInput } from 'common/components/forms/TextInput/TextInput';
import { patterns } from '../../../../utils/validation';
import styled from 'styled-components/macro';
import { BankAccountScreenProps } from 'features/common/types';
import { SimpleSmallLeftGridItem } from 'common/components/GridLayout';

export default function NoBankAccountScreen({ form, bankInformation }: BankAccountScreenProps) {
  const { t } = useTranslation();
  const accountCheckDigits = [5, 4, 3, 2, 7, 6, 5, 4, 3, 2];
  return (
    <SimpleSmallLeftGridItem>
      <ContentSpacer>
        <TextInput
          label={t('onboarding.v2.bankInformation.accountNumber')}
          name="noAccountNumber"
          inputMode="numeric"
          type="text"
          setValue={(value?: string) => {
            form.setValue('bankCode', value?.slice(0, 4));
            form.setValue('accountNumber', value?.slice(4));
          }}
          rules={{
            required: t('common.form.validationMessages.required'),
            pattern: {
              value: patterns.noAccountNumber,
              message: t('common.form.validationMessages.invalidAccountNumber'),
            },
            validate: {
              validAccountNumber: (accountNumber: string) => {
                const checkBase = accountCheckDigits.reduce(
                  (acc, cv, idx) => acc + cv * parseInt(accountNumber.charAt(idx)),
                  parseInt([...accountNumber][10]) // instead of adding the last digit at the end, let's start counting with it
                );

                return checkBase % 11 === 0 || t('common.form.validationMessages.invalidAccountNumber');
              },
            },
          }}
          defaultValue={`${bankInformation?.bankCode || ''}${bankInformation?.accountNumber || ''}`}
          control={form.control}
          errors={form.errors}
          trimOnBlur
        />
        <div hidden={true}>
          <TextInput
            name="accountNumber"
            defaultValue={bankInformation?.accountNumber || ''}
            label=""
            control={form.control}
            errors={form.errors}
          />
        </div>
        <div hidden={true}>
          <TextInput
            name="bankCode"
            defaultValue={bankInformation?.bankCode || ''}
            label=""
            control={form.control}
            errors={form.errors}
          />
        </div>
        <div hidden={true}>
          <TextInput
            name="bankInformationType"
            defaultValue="NO"
            label=""
            control={form.control}
            errors={form.errors}
          />
        </div>
      </ContentSpacer>
    </SimpleSmallLeftGridItem>
  );
}

const ContentSpacer = styled.div`
  display: grid;
  gap: ${({ theme }) => theme.spacings['2']};
`;
