import styled from 'styled-components/macro';
import { fontSize } from '../../../../constants';
import { spacing } from '../../../../constants';

export const ModalHeaderStyled = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ModalBodyStyled = styled.div`
  font-size: ${fontSize.body.md};
`;

export const ModalFooterStyled = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-top: ${spacing.s3};
  & > :last-child {
    margin-left: ${spacing.s2};
  }
`;
