import { CountryCode, CurrencyCode } from 'constants/types';

export default function getCurrencyForCountryCode(countryCode?: CountryCode): CurrencyCode {
  switch (countryCode) {
    case 'DK':
      return 'DKK';
    case 'SE':
      return 'SEK';
    case 'ES':
      return 'EUR';
    case 'NO':
      return 'NOK';
    default:
      return 'EUR';
  }
}
