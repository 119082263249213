import styled from 'styled-components/macro';
import { ReactComponent as CloseIcon } from 'assets/img/close.svg';
import { color, fontFace, fontSize, lineHeight, spacing } from '../../../../constants';

// DEPRECATED - NEED TO BE REMOVED AFTER SELECT IS UPDATED!!
// WE CAN'T DO IT RIGHT NOW AS DROPDOWN IS USING IT PARTIALLY

export const InputComponent = styled.div``;

export const Label = styled.label`
  display: block;
  margin-bottom: 0;
  font-family: ${fontFace.body};

  strong {
    display: block;
    margin-bottom: ${spacing.s2};
    font-weight: 600;
    line-height: 20px;
  }

  .label-text {
    justify-content: space-between;
    display: flex;
    white-space: nowrap;
  }

  .required-label {
    color: ${color.ash50};
    line-height: 21px;
  }
`;

export const InputWrapper = styled.div`
  position: relative;
`;

export const Input = styled.input`
  box-sizing: border-box;
  width: 100%;
  height: ${({ size }) => (size === 'md' ? '48px' : '32px')};
  padding-right: ${props =>
    props.size === 'md'
      ? `${
          props.displayClearButton
            ? props.currency
              ? spacing.s10
              : spacing.s5
            : props.currency
            ? spacing.s8
            : spacing.s3
        }`
      : `${
          props.displayClearButton
            ? props.currency
              ? spacing.s10
              : spacing.s4
            : props.currency
            ? spacing.s7
            : spacing.s2
        }`};
  padding-left: ${props => (props.size === 'md' ? `${spacing.s3}` : `${spacing.s2}`)};
  border: 1px solid ${({ theme }) => theme.color.border.defaultNormalBorder};
  border-radius: ${props => (props.roundedCorners ? '24px' : 0)};
  outline: none;
  color: ${color.black};
  font-family: ${fontFace.body};
  font-size: ${fontSize.body.sm};
  line-height: ${lineHeight.body.sm};
  transition: border-color 0.3s ease-in;
  background-color: ${color.white};

  &:hover {
    border-color: ${color.ashGray300Hover};
  }

  &:active,
  &:focus {
    border-color: ${color.black};
  }
  &:disabled {
    color: ${color.ashGray400};
    background-color: ${color.ash20};
    border-color: ${color.border};
  }

  .has-error & {
    color: ${color.error};
    border-color: ${color.error};
  }
`;

export const AdjustmentlessNumberInput = styled(Input)`
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  -moz-appearance: textfield;
`;

export const InputArea = styled.textarea`
  box-sizing: border-box;
  width: 100%;
  height: ${({ size }) => (size === 'md' ? '84px' : '48px')};
  padding: ${({ theme }) => theme.spacings[1]};
  border: 1px solid ${color.border};
  font-family: ${fontFace.body};
  font-size: ${fontSize.body.sm};
  line-height: ${lineHeight.body.sm};
  transition: border-color 0.3s ease-in;
  outline: none;
  resize: none;

  &:hover {
    border-color: ${color.ashGray300Hover};
  }

  &:active,
  &:focus {
    border-color: ${color.black};
  }
  &:disabled {
    color: ${color.ashGray400};
    background-color: ${color.ash20};
    border-color: ${color.border};
  }

  .has-error & {
    color: ${color.error};
    border-color: ${color.error};
  }
`;

export const ClearButton = styled(CloseIcon)`
  margin: 0 ${spacing.s1};
  width: ${spacing.exception12};
  height: ${spacing.exception12};
  stroke: ${color.black};
  opacity: 0;
  transition: opacity 0.3s ease-in-out;

  .has-focus & {
    opacity: 1;
    cursor: pointer;
  }

  &:hover {
    transform: scale(1.2);
  }
`;

export const Currency = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.color.secondary};
  height: ${spacing.s3};
  width: ${spacing.s6};
  background: ${({ theme }) => theme.color.invertedPrimary};
  border-radius: ${spacing.s0};
`;

export const IndicatorsContainer = styled.div`
  position: absolute;
  align-items: center;
  display: flex;
  padding-right: ${({ size }) => (size === 'md' ? spacing.s1 : spacing.s0)};
  top: 0;
  height: 100%;
  right: 0;
`;

export const Hint = styled.p`
  font-family: ${fontFace.body};
  font-size: ${fontSize.body.sm};
  line-height: ${lineHeight.body.sm};
  color: ${color.ash60};
  margin: ${spacing.s2} 0 0 0;

  .has-error & {
    color: ${color.error};
  }
`;
