import { BannerWithIcon, Button, Heading, Text } from '@loomispay/vault';
import { Radio, RadioGroup } from 'common/components/forms/Radio/Radio';
import Container from 'common/components/MainLayout/Container';
import { BorderedBox, BorderedBoxRow } from 'features/common/components/BorderedBox/BorderedBox';
import { useDidUpdateEffect } from 'hooks';
import { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components/macro';
import { updateBusinessCategory, updateBusinessSubCategory } from '../../../store/sales/actions';
import { getBusinessCategory, getBusinessSubCategory } from '../../../store/sales/selectors';
import { BusinessCategory, CompanyKeywordOption } from '../../../store/types';

type CompanyTypeForm = {
  businessCategory: BusinessCategory;
};

type CategoryOption = {
  id: string;
  name: CompanyKeywordOption;
  label: string;
  selected: boolean;
};

const CategoriesContainer = styled.div`
  display: flex;
  justify-content: start;
  flex-direction: row;
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.spacings[1]};
  width: 100%;
  margin-top: ${({ theme }) => theme.spacings[1]};
`;

const BusinessCategoriesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: ${({ theme }) => theme.spacings[2]};
`;

const foodCategoryOptions: CompanyKeywordOption[] = [
  'BAR_CLUB',
  'CAFE_BAKERY',
  'FOOD_TRUCK',
  'QUICK_SERVICE_RESTAURANT',
  'FULL_SERVICE_RESTAURANT',
];

const retailCategoryOptions: CompanyKeywordOption[] = [
  'CLOTHES_AND_ACCESSORIES',
  'ELECTRONICS',
  'FLOWERS_AND_GIFTS',
  'FOOD_GROCERIES_BEVERAGES',
  'CONVENIENCE_STORE',
  'SPORTING_GOODS',
  'EYEWEAR',
  'FURNITURE_HOME_GOODS',
  'HARDWARE_EQUIPMENT_MATERIALS',
  'BEAUTY_HEALTH',
  'OTHER_RETAIL',
];

const servicesCategoryOptions: CompanyKeywordOption[] = ['HEALTH_SERVICES'];

export type CategoriesComponentProps = {
  categoryOptions: CategoryOption[];
};

export const Categories = ({ categoryOptions }: CategoriesComponentProps) => {
  const { t } = useTranslation();
  const [selectedBusinessType, setSelectedBusinessType] = useState<CompanyKeywordOption>();
  const dispatch = useDispatch();

  return (
    <>
      {categoryOptions.map(option => (
        <div key={option.id}>
          <Button
            testId={option.name}
            variant={option.selected ? 'primary' : 'secondary'}
            size="s"
            label={option.label}
            onClick={() => {
              if (option.selected) {
                dispatch(updateBusinessSubCategory());
                setSelectedBusinessType(undefined);
              } else {
                dispatch(updateBusinessSubCategory(option.name));
                setSelectedBusinessType(option.name);
              }
            }}
          />
        </div>
      ))}
      {selectedBusinessType && (
        <BannerWithIcon type={'neutral'} icon={'infoCircled'}>
          <Text size="s">{t(`onboarding.v2.companyInfo.categories.${selectedBusinessType}.include` as const)}</Text>
          <Text size="s" weight="semiBold">
            {' '}
            {t(`onboarding.v2.companyInfo.categories.${selectedBusinessType}.exclude` as const)}
          </Text>
        </BannerWithIcon>
      )}
    </>
  );
};

export const BusinessType = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const businessCategory = useSelector(getBusinessCategory);
  const businessSubCategory = useSelector(getBusinessSubCategory);

  const { watch, control, errors } = useForm<CompanyTypeForm>({
    mode: 'onChange',
    defaultValues: { businessCategory },
  });

  const formBusinessCategory = watch('businessCategory');

  useDidUpdateEffect(() => {
    if (formBusinessCategory !== businessCategory) {
      dispatch(updateBusinessSubCategory());
      dispatch(updateBusinessCategory(formBusinessCategory));
    }
  }, [formBusinessCategory, businessCategory, dispatch]);

  const categoryOptions = useMemo(() => {
    let activeOptions: CompanyKeywordOption[];

    switch (businessCategory) {
      case 'FOOD_AND_BEVERAGES':
        activeOptions = foodCategoryOptions;
        break;
      case 'RETAIL':
        activeOptions = retailCategoryOptions;
        break;
      case 'SERVICES':
        activeOptions = servicesCategoryOptions;
        break;
      default:
        activeOptions = [];
    }

    return activeOptions.map(optionValue => ({
      id: businessCategory + optionValue,
      name: optionValue,
      label: t(`onboarding.v2.companyInfo.categories.${optionValue}` as const),
      selected: businessSubCategory === optionValue,
    }));
  }, [businessCategory, businessSubCategory, t]);

  return (
    <Container spacing="2" fullWidth>
      <Heading size="xxs" noGutter sansSerif>
        {t('onboarding.v2.companyInfo.businessTypeHeading')}
      </Heading>
      <form>
        <RadioGroup
          size="sm"
          name="businessCategory"
          required
          defaultValue={businessCategory}
          control={control}
          errors={errors}
        >
          <BusinessCategoriesWrapper>
            <BorderedBox>
              <BorderedBoxRow>
                <Radio
                  testId="FOOD_AND_BEVERAGES"
                  value="FOOD_AND_BEVERAGES"
                  label={t('onboarding.v2.companyInfo.foodAndBeverages')}
                />
              </BorderedBoxRow>
              <BorderedBoxRow>
                {businessCategory && businessCategory === 'FOOD_AND_BEVERAGES' && (
                  <CategoriesContainer>
                    <Categories categoryOptions={categoryOptions} />
                  </CategoriesContainer>
                )}
              </BorderedBoxRow>
            </BorderedBox>
            <BorderedBox>
              <BorderedBoxRow>
                <Radio testId="RETAIL" value="RETAIL" label={t('onboarding.v2.companyInfo.retail')} />
              </BorderedBoxRow>
              <BorderedBoxRow>
                {businessCategory && businessCategory === 'RETAIL' && (
                  <CategoriesContainer>
                    <Categories categoryOptions={categoryOptions} />
                  </CategoriesContainer>
                )}
              </BorderedBoxRow>
            </BorderedBox>

            <BorderedBox>
              <BorderedBoxRow>
                <Radio testId="SERVICES" value="SERVICES" label={t('onboarding.v2.companyInfo.services')} />
              </BorderedBoxRow>
              <BorderedBoxRow>
                {businessCategory && businessCategory === 'SERVICES' && (
                  <CategoriesContainer>
                    <Categories categoryOptions={categoryOptions} />
                  </CategoriesContainer>
                )}
              </BorderedBoxRow>
            </BorderedBox>
          </BusinessCategoriesWrapper>
        </RadioGroup>
      </form>
    </Container>
  );
};
