import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getOffer } from '../../../../store/sales/selectors';
import { Control, FieldErrors } from 'react-hook-form';
import Container from '../../../../../../common/components/MainLayout/Container';
import { Toggle } from '../../../../../../common/components/ToggleWrapper/Toggle';
import styled from 'styled-components/macro';

const SelectionWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: ${({ theme }) => theme.spacings[4]};
`;

export default function FlowSupervisionSelection({ control, errors }: { control: Control; errors: FieldErrors }) {
  const { t } = useTranslation();
  const offer = useSelector(getOffer);

  const salesSupervisedFlow = offer.offerType === 'CASH';

  return (
    <section>
      <Container spacing="2">
        <SelectionWrapper>
          <Toggle
            label={t('onboarding.v2.overview.salesSuperVision')}
            control={control}
            errors={errors}
            name={`salesSupervisedFlow`}
            defaultValue={salesSupervisedFlow?.toString() || ''}
          />
        </SelectionWrapper>
      </Container>
    </section>
  );
}
