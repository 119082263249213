import { TextInput } from 'common/components/forms/TextInput/TextInput';
import { Signer } from 'features/onboardingV2/store/types';
import OnboardingModalLayout from 'layout/OnboardingModalLayout';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { patterns } from 'utils/validation';
import { onboardingRoutes } from '../../../OnboardingRouter';
import { Grid, GridItem } from 'common/components/Grid';
import { BaseQueryParams } from '../../../data/types';
import { useOnboardingData } from '../../../data/queries';
import { usePartialUpdate } from '../../../data/mutations';
import { useEffect } from 'react';
import styled from 'styled-components';

type SignerFormFields = { fullName: string };

const SignerFormV2 = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { countryCode, offerRef, signerRef } = useParams<BaseQueryParams & { signerRef: string }>();
  const { formState, control, errors, handleSubmit } = useForm<SignerFormFields>({
    mode: 'onChange',
  });
  const { data: onboardingData } = useOnboardingData({ countryCode, offerRef });

  const { mutate: updateSigner, isSuccess: isSuccessSignerUpdate } = usePartialUpdate();

  const exitPath = generatePath(onboardingRoutes.signingCombinations.path, { countryCode, offerRef });

  useEffect(() => {
    if (isSuccessSignerUpdate) navigate(exitPath);
  }, [isSuccessSignerUpdate, exitPath, navigate]);

  const owners = onboardingData?.owners || [];

  const signersThatAreNotOwners: Signer[] =
    onboardingData?.signatories.filter(signer => !onboardingData?.owners.some(({ ref }) => ref === signer.ref)) || [];

  const isScreenCompleted = formState.isValid;

  const isNameDuplicated = (fullName: string) => {
    if (owners.find(owner => owner.fullName === fullName)) return true;
    if (signersThatAreNotOwners.find(signer => signer.fullName === fullName)) return true;
    return false;
  };

  const addManualSigner = (fullName: string) => [
    ...(onboardingData?.signatories || []),
    { fullName, isManuallyAdded: true, roles: ['LEGAL_REPRESENTATIVE'] } as Signer,
  ];

  const editManualSigner = (fullName: string, signerRef: string) =>
    (onboardingData?.signatories || []).map(signer => (signer.ref === signerRef ? { ...signer, fullName } : signer));

  const updateSignatories = (updatedSignatories: Signer[]) => {
    updateSigner({
      countryCode,
      merchantRef: offerRef,
      data: {
        ...onboardingData,
        signatories: updatedSignatories,
      },
    });
  };

  const onSubmit = ({ fullName }: SignerFormFields) => {
    updateSignatories(signerRef ? editManualSigner(fullName, signerRef) : addManualSigner(fullName));
  };

  const defaultValue =
    signerRef && signersThatAreNotOwners ? signersThatAreNotOwners.find(({ ref }) => ref === signerRef)?.fullName : '';

  return defaultValue !== undefined ? (
    <OnboardingModalLayout
      title={signerRef ? t('onboarding.signerForm.titleEdit') : t('onboarding.signerForm.titleAdd')}
      closeUrl={exitPath}
      isScreenCompleted={isScreenCompleted}
      formId="signer-form"
      gridGutter={16}
      noGutter={true}
      label={signerRef ? t('onboarding.signerForm.titleEdit') : t('onboarding.signerForm.titleAdd')}
    >
      <SignerForm id="signer-form" onSubmit={handleSubmit(onSubmit)}>
        <Grid gutter={16}>
          <GridItem s={4} m={4} l={6}>
            <TextInput
              label={t('onboarding.v2.owners.nameInputLabel')}
              name="fullName"
              rules={{
                required: t('common.form.validationMessages.required'),
                pattern: {
                  value: patterns.fullName,
                  message: t('common.form.validationMessages.fullName'),
                },
                validate: value => (isNameDuplicated(value) ? t('onboarding.signerForm.signerExists') : true),
              }}
              defaultValue={defaultValue}
              control={control}
              errors={errors}
              trimOnBlur
            />
          </GridItem>
        </Grid>
      </SignerForm>
    </OnboardingModalLayout>
  ) : null;
};

export default SignerFormV2;

const SignerForm = styled.form`
  padding-bottom: ${({ theme }) => theme.spacings['6']};
`;
