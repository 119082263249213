import {
  Card,
  CardAddressWrapper,
  CardCashServicesWrapper,
  CardInfoLabels,
  CardNameWrapper,
  CardProductQuantityWrapper,
  CardProductsWrapper,
} from './LocationCard.styles';
import { Icon, Label, Paragraph, Text } from '@loomispay/vault';
import { Store } from 'features/common/types';
import { useTranslation } from 'react-i18next';

type Props = {
  location: Store;
  selectedTerminalsNumber?: number;
  selectedAddonsNumber?: number;
  onClick: () => void;
  selected: boolean;
  testId?: string;
  showAddons?: boolean;
  showOrderedItems?: boolean;
  cashServicesEnabled?: boolean;
  showErrorLabel?: boolean;
};

const LocationCard = ({
  location,
  selectedTerminalsNumber = 0,
  selectedAddonsNumber,
  onClick,
  selected = false,
  testId,
  showAddons = false,
  showOrderedItems = false,
  cashServicesEnabled = false,
  showErrorLabel,
}: Props) => {
  const { t } = useTranslation();

  const productsInfoVisible = showOrderedItems || showAddons;

  return (
    <Card selected={selected} showErrorLabel={showErrorLabel} onClick={onClick} data-testid={testId}>
      <CardNameWrapper>
        <Label size={'l'}>{location.name}</Label>
      </CardNameWrapper>
      <CardAddressWrapper>
        <Text size={'m'}>{`${location.address.address}, ${location.address.zipCode}, ${location.address.city}`}</Text>
      </CardAddressWrapper>
      {productsInfoVisible && (
        <CardProductsWrapper>
          {showOrderedItems && (
            <CardProductQuantityWrapper data-testid={`${testId}.terminalsNumber`}>
              <Icon size={'m'} name={'pos'} />
              <Text>
                {selectedTerminalsNumber && <Text> {`${selectedTerminalsNumber} ${t('upsell.products.new')}`}</Text>}
              </Text>
            </CardProductQuantityWrapper>
          )}
          {showAddons && (
            <CardProductQuantityWrapper data-testid={`${testId}.addonsNumber`}>
              <Icon size={'m'} name={'products'} />
              <Text>{selectedAddonsNumber}</Text>
            </CardProductQuantityWrapper>
          )}
        </CardProductsWrapper>
      )}
      <CardInfoLabels>
        {cashServicesEnabled && (
          <CardCashServicesWrapper>
            <Icon size="s" name="checkmark" color="positive" />
            <Paragraph color="positive" noGutter>
              {t('cashServices.locationHasCashServices')}
            </Paragraph>
          </CardCashServicesWrapper>
        )}
        {showErrorLabel && (
          <CardCashServicesWrapper>
            <Icon size="s" name="negative" color="negative" />
            <Paragraph color="negative" noGutter>
              {t('common.reviewIssues')}
            </Paragraph>
          </CardCashServicesWrapper>
        )}
      </CardInfoLabels>
    </Card>
  );
};

export default LocationCard;
