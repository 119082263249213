import { ReactNode } from 'react';

import { Button, Logo, Paragraph, useMedia } from '@loomispay/vault';
import { ReactComponent as Image } from 'assets/img/login.svg';
import { ContentWrapper, Header, ImageWrapper, Spacer, Wrapper } from './LogInForm.styles';

import { useTranslation } from 'react-i18next';
import { Merchant } from '../../merchants/merchantTypes';
import { useNavigate } from 'react-router-dom';
import { Grid, GridItem } from 'common/components/Grid';

type Props = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSessionCreated?: () => Promise<any>;
  onLoginCompleted?: (merchant?: Merchant) => void;
  loginHeader: ReactNode;
  email?: string;
  hintText?: string;
};

const GridItemLeft = ({ children }: { children: ReactNode }) => (
  <GridItem s={4} m={8} l={7}>
    {children}
  </GridItem>
);
const GridItemRight = ({ children }: { children: ReactNode }) => (
  <GridItem s={4} m={4} l={3} mOffset={2} lOffset={1}>
    {children}
  </GridItem>
);

const LogInForm = ({ loginHeader, hintText }: Props) => {
  const { t } = useTranslation();

  const isDesktop = useMedia('desktop');

  const navigate = useNavigate();

  return (
    <Wrapper>
      <Header isDesktop={isDesktop}>
        <Logo inverted={isDesktop} />
      </Header>

      <Grid gutter={isDesktop ? 0 : 16}>
        {isDesktop && (
          <GridItemLeft>
            <ImageWrapper>
              <Image width="100%" />
            </ImageWrapper>
          </GridItemLeft>
        )}

        <GridItemRight>
          <ContentWrapper>
            {loginHeader}
            <Button onClick={() => navigate('/salesLogin')}>{t('login.loginPersonalAccountButton')}</Button>
            {hintText && (
              <Spacer>
                <Paragraph size="s" color="secondary">
                  {hintText}
                </Paragraph>
              </Spacer>
            )}
          </ContentWrapper>
        </GridItemRight>
      </Grid>
    </Wrapper>
  );
};

export default LogInForm;
