import styled from 'styled-components/macro';

export const PasswordlessLoginGrid = styled.section<{ isDesktop: boolean }>`
  height: 100vh;
  display: grid;
  grid-template-columns: ${({ isDesktop }) => (isDesktop ? '43% 57%' : '100%')};
`;

export const PasswordlessLoginImageWrapper = styled.aside`
  display: flex;
  justify-content: flex-end;
  background-color: ${({ theme }) => theme.color.base.grey.black};
  background-position: center;
  background-repeat: no-repeat;
`;

export const PasswordlessLoginContent = styled.div`
  display: grid;
  flex-direction: column;
  padding: ${({ theme }) => theme.spacings[4]};
  height: 100%;
`;

export const PasswordlessFormWrapper = styled.div<{ isMobile: boolean }>`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings[2]};
  width: ${({ isMobile }) => (isMobile ? 'auto' : '400px')};
  margin: 0 auto;
`;

export const PasswordlessForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings[2]};
`;

export const PasswordlessCodeInputWrapper = styled.div`
  margin: ${({ theme }) => theme.spacings[2]} 0;
`;

export const PasswordlessSpacer = styled.div`
  flex: 1;
`;

export const PasswordlessFormButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: ${({ theme }) => theme.spacings[1]};
`;
