import { Label } from '@loomispay/vault';
import { ReactNode } from 'react';
import styled from 'styled-components/macro';

export const BoxWrapper = styled.div<{ fillHeight?: boolean }>`
  position: relative;
  border: 1px solid ${({ theme }) => theme.color.border.disabledBorder};
  border-radius: 8px;
  padding: ${({ theme }) => theme.spacings['2']};
  height: ${({ fillHeight }) => (fillHeight ? '100%' : 'initial')};
`;

type BoxProps = {
  title?: string;
  children?: ReactNode;
  fillHeight?: boolean;
  testId?: string;
};

export const Box = ({ title, children, fillHeight, testId, ...props }: BoxProps) => (
  <BoxWrapper fillHeight={fillHeight} data-testid={testId} {...props}>
    {title && <Label size="s">{title}</Label>}
    {children}
  </BoxWrapper>
);

type BoxRowCenteredProps = {
  height: string;
  children?: ReactNode;
  testId?: string;
};

const BoxRowCenteredWrapper = styled.div<{ height: string }>`
  min-height: ${({ height }) => height};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const BoxRowCentered = ({ height, children, testId }: BoxRowCenteredProps) => (
  <BoxRowCenteredWrapper height={height} data-testid={testId}>
    {children}
  </BoxRowCenteredWrapper>
);
