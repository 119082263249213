import { ReactNode } from 'react';
import styled from 'styled-components/macro';

type Props = {
  children: ReactNode;
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  width: 100%;
`;

export const FlexSpaceBetween = ({ children }: Props) => {
  return <Wrapper>{children}</Wrapper>;
};
