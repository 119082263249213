import { useTranslation } from 'react-i18next';
import { Paragraph } from '@loomispay/vault';
import { Modal } from 'common/components/Modal';
import { ConfirmationProps } from 'common/components/Modal/Confirmation/confirmationTypes';
import { ModalHeader } from 'common/components/Modal/modalCommons/ModalComponents';
import { customModalStyles } from 'common/components/Modal/Confirmation/Confirmation';

export const OfferSentModal = ({ isOpen }: ConfirmationProps) => {
  const { t } = useTranslation();
  return (
    <Modal style={customModalStyles} isOpen={isOpen}>
      <ModalHeader>{t('onboarding.v2.newOffer')}</ModalHeader>
      <Paragraph size={'m'}>{t('onboarding.v2.overview.offerSent')}</Paragraph>
    </Modal>
  );
};
