import { Text } from '@loomispay/vault';
import Hr from 'common/components/Hr';
import ProductCard, { ProductType } from 'features/common/components/Products/ProductCard/ProductCard';
import { Cost, OfferConfigurationItem, OfferItem } from 'features/common/types';
import { useTranslation } from 'react-i18next';
import { spacing } from '../../../../constants';
import { CardContainer, CardContainerWidthWrapper } from './Products.styles';
import Container from 'common/components/MainLayout/Container';

export const getQuantity = (selectedOfferItems: OfferItem[], code: string) => {
  return selectedOfferItems
    .filter((offerItem: OfferItem) => offerItem.ref === code)
    .map(offerItem => offerItem.quantity)
    .reduce((sum, currentQuantity) => sum + currentQuantity, 0);
};

type Props = {
  storeId: number;
  terminalOptions: OfferConfigurationItem[];
  addonOptions?: OfferConfigurationItem[];
  selectedTerminals: OfferItem[];
  selectedAddons?: OfferItem[];
  currentTerminals?: OfferItem[];
  selectionCallback: (
    name: string,
    code: string,
    productType: ProductType,
    increment: number,
    posTerminalTypeReference?: string,
    loomisModelName?: string,
    initialCost?: Cost | null,
    monthlyCost?: Cost | null
  ) => void;
};

export const ProductsOptions = ({
  storeId,
  terminalOptions,
  addonOptions = [],
  selectedTerminals,
  selectedAddons = [],
  currentTerminals = [],
  selectionCallback,
}: Props) => {
  const { t } = useTranslation();

  return (
    <Container spacing="4">
      <section>
        <Text weight="semiBold" size="m">
          {t('onboarding.common.products.terminals')}
        </Text>
        <CardContainer>
          {terminalOptions.map(terminal => {
            const selectedQuantity = getQuantity(selectedTerminals, terminal.code);
            const currentQuantity =
              currentTerminals.length > 0 ? getQuantity(currentTerminals, terminal.code) : undefined;
            return (
              <ProductCard
                newQuantity={selectedQuantity}
                currentQuantity={currentQuantity}
                code={terminal.code}
                name={terminal.name}
                posTerminalTypeReference={terminal.posTerminalTypeReference}
                loomisModelName={terminal.loomisModelName}
                terminalVersion={terminal.code}
                selectionCallback={selectionCallback}
                monthlyCost={terminal.monthlyCost}
                productType="TERMINAL"
                key={`${storeId}-${terminal.code}`}
              />
            );
          })}
        </CardContainer>
      </section>
      {addonOptions.length > 0 && (
        <>
          <CardContainerWidthWrapper>
            <Hr margin={`${spacing.s2} 0`} />
          </CardContainerWidthWrapper>
          <section>
            <Text weight="semiBold" size="m">
              {t('onboarding.v2.products.addons')}
            </Text>
            <CardContainer>
              {addonOptions.map(addon => {
                const selectedQuantity = getQuantity(selectedAddons, addon.code);
                return (
                  <ProductCard
                    newQuantity={selectedQuantity}
                    code={addon.code}
                    name={addon.name}
                    selectionCallback={selectionCallback}
                    initialCost={addon.initialCost}
                    productType="ADDON"
                    key={addon.code}
                  />
                );
              })}
            </CardContainer>
          </section>
        </>
      )}
    </Container>
  );
};
