import styled from 'styled-components/macro';
import { LoomisTheme, spacings } from '@loomispay/vault';

type CardProps = {
  selected: boolean;
  showErrorLabel?: boolean;
  theme: LoomisTheme;
};

const getCardBorderColor = ({ selected, showErrorLabel, theme }: CardProps) => {
  if (showErrorLabel) return theme.color.border.negativeNormalBorder;
  if (selected) return theme.color.border.defaultNormalBorder;
  return theme.color.background.tertiaryBg;
};

const getCardBackgroundColor = ({ selected, showErrorLabel, theme }: CardProps) => {
  if (selected && showErrorLabel) return theme.color.background.negativeBg;
  if (selected) return theme.color.background.tertiaryBg;
  return theme.color.background.primaryBg;
};

export const LOCATION_CARD_WIDTH = 294;

export const Card = styled.div<CardProps>`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  gap: ${({ theme }) => theme.spacings[1]};
  border: 1px solid ${({ theme, showErrorLabel, selected }) => getCardBorderColor({ selected, showErrorLabel, theme })};
  background-color: ${({ theme, showErrorLabel, selected }) =>
    getCardBackgroundColor({ selected, showErrorLabel, theme })};
  border-radius: 8px;
  min-height: 143px;
  width: ${LOCATION_CARD_WIDTH}px;
  padding: ${spacings['3']} ${spacings['3']};
  word-break: break-word;
  overflow-wrap: break-word;
  hyphens: auto;
  cursor: pointer;
`;

export const CardNameWrapper = styled.div``;

export const CardAddressWrapper = styled.div``;

export const CardProductsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: start;
`;

export const CardProductQuantityWrapper = styled.div`
  padding-right: 12px;
`;

export const CardCashServicesWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacings[1]};
`;

export const CardInfoLabels = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings[1]};
`;
