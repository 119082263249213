/* eslint-disable @typescript-eslint/no-explicit-any */
import { reactLocalStorage } from 'reactjs-localstorage';
import globalStore from 'store/store';
import { Auth } from 'aws-amplify';
import { CognitoUserSession } from 'amazon-cognito-identity-js';
import parseOAuthUserData from './oAuthDataParser';
import { CountryCode } from '../constants/types';

export const USER_DATA_KEY = 'userData';
export const PROVIDER_KEY = 'Provider';

type CognitoSessionDataSelector = 'token' | 'sub' | 'roles' | 'email';

type UserData = {
  email: string;
  firstName: string;
  lastName: string;
};

function storeOAuthUserData(sessionData?: CognitoUserSession) {
  if (sessionData) {
    const { email, firstName, lastName, sub } = parseOAuthUserData(sessionData);
    storeUserData({ email, firstName, lastName, sub, provider: 'OAuth' });
  }
}

function storeUserData({
  email,
  firstName,
  lastName,
  sub,
  provider,
}: {
  email: string;
  firstName?: string;
  lastName?: string;
  sub: string;
  provider: string;
}) {
  //store passed data in local storage, other data will be fetched from auth service when needed
  reactLocalStorage.set(USER_DATA_KEY, JSON.stringify({ email, firstName, lastName }));
  reactLocalStorage.set('userId', sub);
  reactLocalStorage.set('toggleContext', sub);
  reactLocalStorage.set(PROVIDER_KEY, provider);
}

async function isSalesRepLoggedIn() {
  return (await getValidCognitoSession()) !== undefined && isUserGivenRole('Sales');
}

async function getValidCognitoSession() {
  try {
    const session = await Auth.currentSession();
    return session.isValid() ? session : undefined;
  } catch {
    return undefined;
  }
}

type Feature = 'Onboarding';

async function getCountryCode(feature: Feature) {
  const state = globalStore.getState();

  const salesRepLoggedIn = await isSalesRepLoggedIn();
  if (salesRepLoggedIn) {
    if (feature === 'Onboarding') {
      return state.onboardingV2Sales?.offer?.countryCode;
    }
  } else {
    if (feature === 'Onboarding') {
      return state.onboardingV2.merchant.countryCode;
    }
  }

  // should happen only on first screen (when we don't have country code fetched from backend yet)
  return null;
}

async function isUserGivenRole(role: string) {
  //check if user has assigned given role
  return await fetchAuthField('roles').then(
    (roles?: string | number | string[]) => !!roles && Array.isArray(roles) && roles.includes(role.toLowerCase())
  );
}

function getUserData() {
  const userDataJson = reactLocalStorage.get(USER_DATA_KEY) as string;
  return userDataJson ? (JSON.parse(userDataJson) as UserData) : undefined;
}

async function provideBearerToken() {
  const token = await fetchAuthField('token');
  if (token) {
    return `Bearer ${token}`;
  }

  return undefined;
}

async function fetchAuthField(specificField: CognitoSessionDataSelector) {
  try {
    const currentSession = await getValidCognitoSession();
    return currentSession && parseOAuthUserData(currentSession)[specificField];
  } catch {
    return undefined;
  }
}

const storeAvailableCountries = (countries: CountryCode[]) => {
  const countriesString = countries.join(',');
  localStorage.setItem('availableCountries', countriesString);
};

const retrieveAvailableCountries = (): CountryCode[] => {
  const countriesFromLocalStorage = localStorage.getItem('availableCountries');
  if (countriesFromLocalStorage === null) {
    return [];
  }

  return countriesFromLocalStorage.split(',').map(code => code.toUpperCase() as CountryCode);
};

export const userService = {
  storeOAuthUserData,
  isUserGivenRole,
  isSalesRepLoggedIn,
  provideBearerToken,
  getUserData,
  getCountryCode,
  fetchAuthField,
  storeAvailableCountries,
  retrieveAvailableCountries,
};
