import { ReactNode } from 'react';
import styled, { css } from 'styled-components/macro';

export const VisuallyHiddenWrapper = styled.div<{ isHidden?: boolean }>`
  ${({ isHidden }) => {
    return isHidden
      ? css`
          border-width: 0 !important;
          clip: rect(1px, 1px, 1px, 1px) !important;
          height: 1px !important;
          overflow: hidden !important;
          padding: 0 !important;
          position: absolute !important;
          white-space: nowrap !important;
          width: 1px !important;
        `
      : ``;
  }}
`;

export const VisuallyHidden = ({ isHidden = true, children }: { isHidden?: boolean; children: ReactNode }) => {
  return <VisuallyHiddenWrapper isHidden={isHidden}>{children}</VisuallyHiddenWrapper>;
};
