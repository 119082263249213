import { useMedia } from '@loomispay/vault';
import { SpacingType } from 'common/types';
import { ReactNode } from 'react';
import styled from 'styled-components/macro';

export const CONTAINER_DEFAULT_WIDTH = 640;
export const CONTAINER_MINIMUM_PAGE_PADDINGS = 64;

export default function Container({
  children,
  spacing,
  fullWidth,
  centerContent,
}: {
  children: ReactNode;
  spacing?: SpacingType;
  fullWidth?: boolean;
  centerContent?: boolean;
}) {
  const isTablet = useMedia('tablet');
  const isMobile = useMedia('mobile');
  return (
    <ContainerWrapper
      isMobile={isMobile || isTablet}
      spacing={spacing}
      fullWidth={fullWidth}
      centerContent={centerContent}
    >
      {children}
    </ContainerWrapper>
  );
}

const ContainerWrapper = styled.section<{
  isMobile: boolean;
  spacing?: SpacingType;
  fullWidth?: boolean;
  centerContent?: boolean;
}>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: ${({ centerContent }) => (centerContent ? 'center' : 'initial')};
  gap: ${({ spacing }) => `${Number(spacing || 0) * 8}px`};
  width: ${({ isMobile, fullWidth }) => (fullWidth ? '100%' : isMobile ? '92%' : `${CONTAINER_DEFAULT_WIDTH}px`)};
  margin: 0 auto;
`;
