import { useFileInput } from '@rpldy/uploady';
import React, { useRef } from 'react';
import { UseFormMethods } from 'react-hook-form/dist/types';
import { useTranslation } from 'react-i18next';

export type FileInputProps = {
  /**
   * string[] with list af the accepted file formats
   * ie: ['.jpg', '.csv' 'image/*', '.pdf']
   */
  acceptedTypes?: string[];
  name: string;
  register: UseFormMethods['register'];
  handleAdd: (fileName?: string) => void;
};
export const Input = ({ acceptedTypes, name, register, handleAdd }: FileInputProps) => {
  const { t } = useTranslation();
  const accept = acceptedTypes?.join(',');

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleAdd(e.target.files?.[0].name);
  };

  const inputRef = useRef<HTMLInputElement>(null);
  useFileInput(inputRef);

  return (
    <input
      style={{ display: 'none' }}
      type="file"
      accept={accept}
      name={name}
      {...((register(name, {
        required: t('common.form.validationMessages.required'),
      }) as unknown) as UseFormMethods['register'])}
      ref={inputRef}
      onChange={e => handleChange(e)}
      aria-label="file-input"
    />
  );
};
