import { Route } from 'react-router-dom';
import { NotFoundErrorScreen } from 'common/components/Error';
import { ReactElement } from 'react';

type RedirectRoute = {
  to?: string;
  push?: boolean;
};

export type RouteConfig = {
  path: string;
  isPublic?: boolean;
  allowedRoles?: string[];
  hideNavigation?: boolean;
  element: () => ReactElement | null;
} & RedirectRoute;

export const createRouter = (routes: Record<string, RouteConfig>) => () => {
  return (
    <>
      {Object.keys(routes).map(routeName => {
        const route = routes[routeName];
        return <Route key={routeName} path={route.path} element={<route.element />} />;
      })}
      <Route path="*" element={NotFoundErrorScreen} />
    </>
  );
};
