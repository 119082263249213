import { useTranslation } from 'react-i18next';
import { Control, FieldErrors } from 'react-hook-form';
import Container from '../../../../../../common/components/MainLayout/Container';
import styled from 'styled-components/macro';
import { Radio, RadioGroup } from 'common/components/forms/Radio/Radio';
import { selectIsSignatureProviderSelectionEnabled } from 'features/featureToggles/featureToggles';
import { useSelector } from 'react-redux';
import { SignatureProvider } from 'features/onboardingV2/store/types';
import { getOfferConfiguration } from 'features/onboardingV2/store/sales/selectors';

const SelectionWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: ${({ theme }) => theme.spacings[4]};
  margin-top: ${({ theme }) => theme.spacings[3]};
`;

const radioWrapperStyles = `
    flex-direction: column;
    align-items: flex-start;
`;

export default function SignatureProviderSelection({ control, errors }: { control: Control; errors: FieldErrors }) {
  const { t } = useTranslation();
  const isSignatureProviderSelectionEnabled = useSelector(selectIsSignatureProviderSelectionEnabled);
  const { signatureProviderConfig } = useSelector(getOfferConfiguration);

  return isSignatureProviderSelectionEnabled &&
    signatureProviderConfig.defaultProvider &&
    signatureProviderConfig.providers ? (
    <section>
      <Container spacing="2">
        <SelectionWrapper>
          <RadioGroup
            label={t('onboarding.v2.overview.signatureProvider.selection')}
            name="signatureProvider"
            size="sm"
            defaultValue={signatureProviderConfig.defaultProvider}
            control={control}
            errors={errors}
            radioWrapperStyles={radioWrapperStyles}
            required
          >
            {signatureProviderConfig.providers.map(
              (provider: SignatureProvider) =>
                provider && (
                  <Radio
                    key={`signature-provider-${provider}-key`}
                    value={provider}
                    label={t(`onboarding.v2.overview.signatureProvider.provider.${provider}`)}
                  />
                )
            )}
          </RadioGroup>
        </SelectionWrapper>
      </Container>
    </section>
  ) : (
    <></>
  );
}
