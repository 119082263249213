import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { FlowName } from 'constants/types';
import { onboardingBasePath } from '../features/onboardingV2/OnboardingRouter';
import { salesBasePath } from '../features/onboardingV2/SalesRouter';

export default function useFlowName() {
  const [flowName, setFlowName] = useState<FlowName>(null);
  const { pathname } = useLocation();

  useEffect(() => {
    if (pathname.match(salesBasePath)) setFlowName('sales');
    else if (pathname.match(onboardingBasePath)) setFlowName('onboarding');
  }, [pathname]);

  return flowName;
}
