import { Size } from './types';

type SizeMap = Record<Size, string>;

type VariantMap = {
  body: SizeMap;
  heading: SizeMap;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [x: string]: any;
};

export const fontFace = {
  body: 'Roobert, sans-serif',
  heading: 'Helvetica New Text, sans-serif',
  display: 'Ivar Display Bold, sans-serif',
};

export const fontSize: VariantMap = {
  body: {
    xs: '12px',
    sm: '14px',
    md: '16px',
    lg: '18px',
    xl: '24px',
    xxl: '32px',
    xxxl: '40px',
  },

  heading: {
    xs: '20px',
    sm: '24px',
    md: '28px',
    lg: '32px',
    xl: '36px',
    xxl: '42px',
    xxxl: '58px',
  },

  display: {
    // TODO
  },
  // title
  title: '3.5rem',

  // heading
  h1: '2rem',
  h2: '1.5rem',
  h3: '1.25rem',
  subheading: '1rem',

  // body
  xxl: '1.5rem',
  xl: '1.25rem',
  lg: '1.125rem',
  md: '1rem',
  sm: '0.9375rem',
  xs: '0.8125rem',
  xxs: '0.7rem',
};

export const fontWeight = {
  black: 900,
  bold: 700,
  semiBold: 500,
  normal: 400,
  light: 300,
  thin: 100,
};

export const lineHeight: VariantMap = {
  body: {
    xs: '20px',
    sm: '20px',
    md: '24px',
    lg: '24px',
    xl: '32px',
    xxl: '40px',
    xxxl: '48px',
  },
  heading: {
    xs: '32px',
    sm: '32px',
    md: '36px',
    lg: '40px',
    xl: '44px',
    xxl: '56px',
    xxxl: '64px',
  },
};

export const letterSpacing: Partial<VariantMap> = {
  body: {
    xs: 'normal',
    sm: 'normal',
    md: 'normal',
    lg: '-0.1px',
    xl: '-0.2px',
    xxl: '-0.2px',
    xxxl: '-0.2px',
  },
};
