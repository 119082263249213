import { CashServicesFormFieldsES } from './types';

export const cashSolutionTypes = ['BASICA', 'OBOLUS', 'OBOLUS_MONEDA', 'ELECTRUM', 'LC', 'D2S', 'CIT_CMS'] as const;

export const cashContractGuaranteeTypes = ['NONE', 'BANK', 'DEPOSIT', 'DEPOSIT_WITHDRAWAL'] as const;

export const cashContractMonthlyFeeTypes = ['fixed', 'percentage'] as const;

export const cashServicesDataESTemplate: CashServicesFormFieldsES = {
  generalInfo: {
    solutionType: undefined,
    basicSafePointIncluded: undefined,
    isLoomisBeneficiary: undefined,
    insuranceIncluded: undefined,
    changeServiceIncluded: undefined,
  },
  contractDates: {
    startDate: undefined,
    durationInMonths: undefined,
  },
  details: {
    pickupsPerMonth: undefined,
    banknotesLimitValuePerMonth: undefined,
    coinsLimitValuePerMonth: undefined,
    banknotesRechargeExcessWithoutNewPickup: undefined,
    banknotesRechargeExcessWithNewPickup: undefined,
    chargeForEachCoinLimitExcess: undefined,
  },
  changeService: {
    changeWithPickupFee: undefined,
    changeWithoutPickupFee: undefined,
  },
  guarantee: {
    type: undefined,
    amount: undefined,
  },
  clientInsurance: {
    insuranceNumber: undefined,
    insuranceCompany: undefined,
    insuredAmount: undefined,
  },
  instantCredit: {
    banknotesInstantCredit: undefined,
    coinsInstantCredit: undefined,
  },
  fees: {
    minimumMonthlyFee: undefined,
    monthlyFeeType: undefined,
    fixedMonthlyFee: undefined,
    percentageMonthlyFee: undefined,
    upfrontPayment: undefined,
    finalPayment: undefined,
    paymentMethodChosen: undefined,
  },
  loomisOperationsBank: undefined,
} as const;
