/** @deprecated Use purpose badsed theme colors instead */
export const _brand = {
  white: '#FFFFFF',
  black: '#121212',
  loomis: '#EA5A4B',
  lime: '#C2F1A2',
};

/** @deprecated Use purpose badsed theme colors instead */
export const _eggplant = {
  eggplant60: '#4D0669',
  eggplant50: '#76119C',
  eggplant40: '#9700CF',
  eggplant30: '#A053D4',
  eggplant20: '#BC7EF2',
  eggplant10: '#D3A9FC',
};

/** @deprecated Use purpose badsed theme colors instead */
export const _midnight = {
  midnight60: '#0F2B78',
  midnight50: '#153BA3',
  midnight40: '#1748CF',
  midnight30: '#4464FF',
  midnight20: '#6193FF',
  midnight10: '#8BC1F7',
};

/** @deprecated Use purpose badsed theme colors instead */
export const _spongebob = {
  spongebob60: '#B8B100',
  spongebob50: '#D5D900',
  spongebob40: '#E2EE00',
  spongebob30: '#E2EE00',
  spongebob20: '#FBFFA6',
  spongebob10: '#FDFFDB',
};

/** @deprecated Use purpose badsed theme colors instead */
export const _broccoli = {
  broccoli60: '#009E5F',
  broccoli50: '#00BD68',
  broccoli40: '#00D173',
  broccoli30: '#3BE88C',
  broccoli20: '#73FFB5',
  broccoli10: '#C9FFE5',
};

/** @deprecated Use purpose badsed theme colors instead */
export const _lobster = {
  lobster60: '#BA0013',
  lobster50: '#D90015',
  lobster40: '#F20018',
  lobster30: '#FA4D5F',
  lobster20: '#FF7D8D',
  lobster10: '#FFA6B1',
};

/** @deprecated Use purpose badsed theme colors instead */
export const _cheetos = {
  cheetos60: '#C45200',
  cheetos50: '#ED6700',
  cheetos40: '#FF7700',
  cheetos30: '#FF9257',
  cheetos20: '#FFB889',
  cheetos10: '#FFD5B8',
};

/** @deprecated Use purpose badsed theme colors instead */
export const _bear = {
  bear60: '#4F2320',
  bear50: '#643733',
  bear40: '#805455',
  bear30: '#997172',
  bear20: '#B59192',
  bear10: '#D1B4B5',
};

/** @deprecated Use purpose badsed theme colors instead */
export const _gray = {
  gray70: '#282828',
  gray60: '#525252',
  gray50: '#767676',
  gray40: '#A5A5A5',
  gray30: '#C2C2C2',
  gray20: '#E4E4E4',
  gray10: '#F6F6F6',
  gray05: '#FBFBFB',
};

/** @deprecated Use purpose badsed theme colors instead */
export const _base = {
  ..._brand,
  ..._eggplant,
  ..._midnight,
  ..._spongebob,
  ..._broccoli,
  ..._lobster,
  ..._bear,
  ..._cheetos,
  ..._gray,
};

/** @deprecated Use purpose badsed theme colors instead */
export const _foreground = {
  primary: _base.black,
  secondary: _base.gray60,
  tertiary: _base.gray50,
  disabled: _base.gray40,
  linkNormal: _base.midnight40,
  linkPress: _base.midnight60,
  positive: _base.broccoli60,
  warning: _base.spongebob60,
  negative: _base.lobster60,
};

/** @deprecated Use purpose badsed theme colors instead */
export const _background = {
  primaryBg: _base.white,
  secondaryBg: _base.gray05,
  tertiaryBg: _base.gray10,
  positiveBg: _base.broccoli10,
  warningBg: _base.spongebob10,
  negativeBg: _base.lobster10,
};

/** @deprecated Use purpose badsed theme colors instead */
export const _border = {
  focusBorder: _base.midnight10,
  disableBorder: _base.gray20,
  regularNormalBorder: _base.gray30,
  regularHoverBorder: _base.gray40,
  regularPressBorder: _base.gray50,
  regularActiveBorder: _base.gray60,
  positiveNormalBorder: _base.broccoli30,
  positiveHoverBorder: _base.broccoli40,
  positivePressBorder: _base.broccoli50,
  positiveActiveBorder: _base.broccoli60,
  negativeNormalBorder: _base.lobster30,
  negativeHoverBorder: _base.lobster40,
  negativePressBorder: _base.lobster50,
  negativeActiveBorder: _base.lobster60,
};

/** @deprecated Use purpose badsed theme colors instead */
export const _opacity = {
  opacityBlack08: 'rgba(0,0,0,0.8)',
  opacityBlack06: 'rgba(0,0,0,0.6)',
  opacityBlack04: 'rgba(0,0,0,0.4)',
  opacityBlack02: 'rgba(0,0,0,0.2)',
  opacityWhite08: 'rgba(255,255,255,0.8)',
  opacityWhite06: 'rgba(255,255,255,0.6)',
  opacityWhite04: 'rgba(255,255,255,0.4)',
  opacityWhite02: 'rgba(255,255,255,0.2)',
};

/** @deprecated Use purpose badsed theme colors instead */
export const _divider = {
  dividerPrimary: _base.black,
  dividerSecondary: _base.gray40,
  dividerTertiary: _base.gray20,
};

/** @deprecated Use purpose badsed theme colors instead */
export const _interactiveBg = {
  primaryEnableBg: _base.black,
  primaryHoverBg: _base.gray70,
  secondaryEnableBg: _base.gray30,
  secondaryHoverBg: _base.gray40,
  tertiaryEnableBg: _base.white,
  tertiaryHoverBg: _base.gray10,
  destructiveEnableBg: _base.lobster10,
  destructiveHoverBg: _base.lobster20,
  disableBg: _base.gray20,
  focusBg: _base.midnight10,
  activeBg: _base.lime,
};

/** @deprecated Use purpose badsed theme colors instead */
export const _deprecated = {
  // old colors
  /** @deprecated */
  navyBlue: '#282c34',

  /** @deprecated */
  biru400: '#4BB4B4',
  /** @deprecated */
  biru600: '#0E696E',
  /** @deprecated */
  purpur500: '#8E3B70',
  /** @deprecated */
  purpur50: '#faf0f5',
  /** @deprecated */
  sand600: '#6C4000',
  /** @deprecated */
  sand500: '#AC760A',
  /** @deprecated */
  sand200: '#FFDD9F',
  /** @deprecated */
  sand50: '#fbf2dc',

  /** @deprecated */
  red: '#c11212',

  /** @deprecated (use colors from Vault) */
  tomato400: '#F20018',
  /** @deprecated */
  forest400: '#00D16C',
  /** @deprecated (use colors from Vault) */
  primaryBlack: '#121313',

  /** @deprecated (use colors from Vault) */
  ashGray600: '#505354',
  /** @deprecated (use colors from Vault) */
  ashGray500: '#737872',
  /** @deprecated */
  ashGray400: '#AEB4A8',
  /** @deprecated (use colors from Vault) */
  ashGray300: '#CFD1CC',
  /** @deprecated (use colors from Vault) */
  ashGray200: '#E9EBE6',
  /** @deprecated (use colors from Vault) */
  ashGray100: '#F1F2EF',

  /** @deprecated */
  primaryBlackHover: '#000000',
  /** @deprecated */
  ashGray200Hover: '#D9DCD5',
  /** @deprecated */
  ashGray300Hover: '#BEC1BA',
  /** @deprecated */
  ashGray400Hover: '#A8AEA3',
  /** @deprecated */
  ashGray500Hover: '#777C75',
  /** @deprecated */
  ashGray600Hover: '#3C3E40',

  /** @deprecated (use colors from Vault) */
  ash60: '#505354',
  /** @deprecated (use colors from Vault) */
  ash50: '#737872',
  /** @deprecated (use colors from Vault) */
  ash40: '#A6AC9E',
  /** @deprecated (use colors from Vault) */
  ash30: '#CFD1CC',
  /** @deprecated (use colors from Vault) */
  ash20: '#E9EBE6',
  /** @deprecated (use colors from Vault) */
  ash10: '#F1F2EF',

  /** @deprecated */
  primaryWhite: '#FFFFFF',
  /** @deprecated */
  primaryWhiteHover: '#F1F2EF',

  /** @deprecated */
  broccoli500Hover: '#009E5F',
  /** @deprecated */
  lobster10Hover: '#FF7D8D',

  /** @deprecated (use colors from Vault)*/
  eggplant: '#9700CF',
  /** @deprecated (use colors from Vault)*/
  midnightBlue: '#4464FF',
  /** @deprecated */
  lightGrey: '#F4F5F1',
  /** @deprecated (use colors from Vault)*/
  grey: '#A6AC9E',

  /** @deprecated */
  error: _brand.lobster60,
  /** @deprecated */
  border: _brand.ash30,
  /** @deprecated */
  whiteHover: _brand.ash10,
  /** @deprecated */
  link: _brand.midnight40,
};

/** @deprecated Use purpose badsed theme colors instead */
export const color = {
  ..._base,
  ..._deprecated,
  ..._opacity,
  ..._foreground,
  ..._background,
  ..._border,
  ..._interactiveBg,
  ..._divider,
};
