/**
 * Helper file for formatting dates and decouple the date-library from the code.
 * When needing new date functionality, add the new functions here instead of importing luxon directly.
 */
import { DateTime, Zone } from 'luxon';

export enum Format {
  YYYY_MM_DD_HH_MM_SS = 'yyyy-MM-dd HH:mm:ss',
  YYYY_MM_DD_HH_MM = 'yyyy-MM-dd HH:mm',
  YYYY_MM_DDTHH_MM = 'yyyy-MM-dd!HH:mm',
  DD_MMM_HH_MM = 'dd MMM, HH:mm',
  DD_MMMM = 'dd MMMM',
  DD_MMM_YYYY_HH_MM = 'dd MMM yyyy, HH:mm',
  DD_MMMM_YYYY = 'dd-MM-yyyy',
  DD_MM_YYYY = 'dd-LL-yyyy',
  YYYY_MM_DD = 'yyyy-LL-dd',
  YYYY_MM = 'yyyy-MM',
  DD_MMM = 'dd LLL',
  DD_MM = 'dd/MM',
  MMMM = 'MMMM',
  MMM = 'MMM',
  HH_MM = 'HH:mm',
  HH = 'HH',
  DD = 'dd',
  DD_MMM_YYYY = 'dd LLL, yyyy',
}

export const formatDate = (date: string | undefined, format: Format) => {
  const isoDate = date ? new Date(date).toISOString() : new Date().toISOString();
  return DateTime.fromISO(isoDate).toUTC().toFormat(format);
};

export const reformat = (date: string, currentFormat: Format, newFormat: Format) => {
  return DateTime.fromFormat(date, currentFormat).toFormat(newFormat);
};

export const getToday = (format: Format) => DateTime.utc().toFormat(format);

export const getTodayDate = () => DateTime.local();

export const toUnix = (date: string) => DateTime.fromISO(date).toMillis();

export const toPOSFormat = (date: string) => DateTime.fromISO(date).toFormat(Format.YYYY_MM_DD_HH_MM_SS);

const fromISO = DateTime.fromISO;

export const showPeriod = (fromString: string, toString: string) => {
  const from = fromISO(fromString);
  const to = fromISO(toString);
  let fromOutput = '-';
  const toOutput = `${to.toFormat('d')} ${to.toFormat('MMM, yyyy')}`;

  if (!from.hasSame(to, 'year')) {
    fromOutput = `, ${from.toFormat('yyyy')}${fromOutput}`;
  }

  if (!from.hasSame(to, 'month')) {
    fromOutput = ` ${from.toFormat('MMM')}${fromOutput}`;
  }

  if (!from.hasSame(to, 'day')) {
    fromOutput = `${from.toFormat('d')}${fromOutput}`;
  } else {
    fromOutput = '';
  }

  return fromOutput + toOutput;
};

export const isDate = (year: number, month: number, day: number): boolean => {
  month = month - 1; // 0-11 in JavaScript
  const tmpDate = new Date(year, month, day);

  if (tmpDate.getFullYear() === year && month === tmpDate.getMonth() && day === tmpDate.getDate()) return true;

  return false;
};

/**
 * maps local DateTime to ISO date string with provided zone
 * Example: input: 2021-04-14 17:44:23, UTC output: 2021-04-14T17:44:23.000Z
 * @param date local date time in format: YYYY_MM_DD_HH_MM_SS
 * @param zone zone
 */
export const localDateTimeToISOString = (date: string | undefined, zone: string | Zone): string =>
  date ? DateTime.fromFormat(date, Format.YYYY_MM_DD_HH_MM_SS, { zone, setZone: true }).toISO().toString() : '-';
