import { Heading, Text, toFixedFractionalDigits } from '@loomispay/vault';
import { TransactionFees } from 'features/common/types';
import { useTranslation } from 'react-i18next';
import { OfferSummaryRow, OfferSummarySectionBox } from '../../OfferOverview.styles';
import { transactionFeeRulesSort } from '../../../../../../utils/sort';

export default function OfferOverviewMultitierPricing({
  transactionFees,
  transactionFee,
}: {
  transactionFees?: TransactionFees;
  transactionFee?: number;
}) {
  const { t } = useTranslation();

  return (
    <section>
      <Heading size="xxs" sansSerif>
        {t('onboarding.v2.overview.contractTerms.transactionFees')}
      </Heading>
      <OfferSummarySectionBox>
        {transactionFees?.rules
          ?.slice()
          .sort(transactionFeeRulesSort)
          .map((fee, i) => {
            const label = `${fee.cardScheme} ${fee.region} ${fee.cardType} ${fee.cardCustomerType}`.toLowerCase();
            return (
              <OfferSummaryRow key={`fee${i}`}>
                <Text size="s">{label}</Text>
                <Text size="s">{`${toFixedFractionalDigits(+fee.percentage, 2)}%`}</Text>
              </OfferSummaryRow>
            );
          })}
        {transactionFees?.amexFee && (
          <OfferSummaryRow key="amexFee">
            <Text size="s">{t('common.amex')}</Text>
            <Text size="s" data-testid="amex-fee-percentage">{`${toFixedFractionalDigits(
              +transactionFees?.amexFee.percentage,
              2
            )}%`}</Text>
          </OfferSummaryRow>
        )}
        <OfferSummaryRow>
          <Text size="s">
            {transactionFees?.rules && transactionFees.rules.length > 0
              ? t('onboarding.v2.overview.contractTerms.transactionFee.allOtherCards')
              : t('onboarding.v2.overview.contractTerms.transactionFee')}
          </Text>
          <Text size="s">{`${toFixedFractionalDigits(
            +(transactionFees?.defaultFee?.percentage || transactionFee || '0'),
            2
          )}%`}</Text>
        </OfferSummaryRow>
      </OfferSummarySectionBox>
    </section>
  );
}
