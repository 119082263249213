import { CountryCode, DropDownOption } from 'constants/types';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Dropdown } from '../../../../common/components/forms/Dropdown/Dropdown';
import { flagForCountry } from '../../../../services/configService';

type Props = {
  countryCodes: CountryCode[];
  isDisabled?: boolean;
  handleChange: (countryCode: CountryCode) => void;
  defaultCountryCode: CountryCode;
};

export const FlagDropdown = ({ countryCodes, isDisabled, handleChange, defaultCountryCode }: Props) => {
  const { t } = useTranslation();

  const getFlagEmoji = (countryCode: CountryCode) => {
    return flagForCountry[countryCode];
  };

  const countryOptions: DropDownOption<CountryCode>[] = countryCodes.map((code: CountryCode) => ({
    value: code,
    label: `${getFlagEmoji(code)}  ${code}`,
  }));

  const defaultCountryOption = countryOptions.find(option => option.value === defaultCountryCode);

  const dispatch = useDispatch();
  const { control, watch } = useForm();
  const watchFields = watch('country');

  const [selectedCountry, setSelectedCountry] = useState<CountryCode>(defaultCountryCode);

  useEffect(() => {
    if (watchFields && selectedCountry !== watchFields.value) {
      setSelectedCountry(watchFields.value);
      handleChange(watchFields.value);
    }
  }, [watchFields, selectedCountry, handleChange, dispatch]);

  return (
    <Dropdown
      name="country"
      label={t('common.address.country')}
      rules={{ required: t('common.form.validationMessages.required') }}
      options={countryOptions}
      defaultValue={defaultCountryOption}
      control={control}
      isDisabled={isDisabled}
      styles={{
        option: styles => ({ ...styles, width: '4.5rem' }),
        container: styles => ({ ...styles, width: '5.5rem' }),
      }}
    />
  );
};
