import { useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import LogInScreen from 'features/login/LogInScreen';
import LogOut from '../features/logout/LogOut';
import { NotFoundErrorScreen, ServiceUnavailableErrorScreen } from 'common/components/Error';
import RootRouter from './RootRouter';
import OfferNotFound from 'features/onboardingV2/screens/merchant/overview/OfferNotFound';
import { createRouter } from 'common/route/createFeatureRouter';
import OAuthScreen from '../features/login/OAuthScreen';
import SalesRouter, { salesBasePath, salesRoutes } from '../features/onboardingV2/SalesRouter';
import OnboardingRouter, { onboardingBasePath, onboardingRoutes } from '../features/onboardingV2/OnboardingRouter';

const AppRouting = () => {
  const location = useLocation();

  // Whenever user changes screen, we want page scroll back to the top.
  // It's because some of views have a lot of contenta and when we navigate
  // between them, part of content is hidden by progress bar or footer.
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <Routes>
      <Route path="/" element={<RootRouter />} />
      <Route path="/login" element={<LogInScreen />} />
      <Route path="/salesLogin" element={<OAuthScreen />} />
      <Route path="/logout" element={<LogOut />} />
      <Route path={salesBasePath} element={<SalesRouter />}>
        {createRouter(salesRoutes)()}
      </Route>
      <Route path={onboardingBasePath} element={<OnboardingRouter />}>
        {createRouter(onboardingRoutes)()}
      </Route>
      <Route path="/404" element={<NotFoundErrorScreen />} />
      <Route path="/404/:errorCode" element={<NotFoundErrorScreen />} />
      <Route path="/503" element={<ServiceUnavailableErrorScreen />} />
      <Route path="/503/:errorCode" element={<ServiceUnavailableErrorScreen />} />
      <Route path="/offer-not-found" element={<OfferNotFound />} />
      <Route path="*" element={<NotFoundErrorScreen />} />
    </Routes>
  );
};

export default AppRouting;
