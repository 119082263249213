import { Button, Heading, Icon, Paragraph, useMedia } from '@loomispay/vault';
import { VisuallyHidden } from 'common/components/VisuallyHidden';
import { Owner } from 'features/onboardingV2/store/types';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import {
  EllipsisText,
  RepresentativeActionButtons,
  RepresentativeActionButtonsWrapper,
  RepresentativeData,
  RepresentativeDataDetails,
  RepresentativeWrapper,
} from './Representative.styles';

type OwnershipDataProps = Pick<Owner, 'minSharesPercentage' | 'maxSharesPercentage' | 'type'>;
export type RepresentativeBoxProps = {
  children?: ReactNode;
  fullName: string;
  isManuallyAdded?: boolean | null;
  ownershipData?: OwnershipDataProps;
  index?: number;
  onChange?: () => void;
  onDelete?: () => void;
};

function ManuallyAddedLabel(props: { manuallyAdded?: boolean | null }) {
  const { t } = useTranslation();
  return (
    <EllipsisText size="s" color="tertiary">
      {props.manuallyAdded ? t(`onboarding.v2.owners.addedByYou`) : t(`onboarding.v2.owners.addedAutomatically`)}
    </EllipsisText>
  );
}

export default function RepresentativeBox({
  children: optionalCheckbox,
  fullName,
  isManuallyAdded,
  ownershipData,
  index,
  onChange,
  onDelete,
}: RepresentativeBoxProps) {
  const { t } = useTranslation();
  const isMobile = useMedia('mobile');

  return (
    <RepresentativeWrapper>
      <RepresentativeData>
        {optionalCheckbox}
        <RepresentativeDataDetails>
          <Heading noGutter size="s">
            {fullName}
          </Heading>
          {ownershipData && <OwnershipData ownershipData={ownershipData} />}
          {isMobile && <ManuallyAddedLabel manuallyAdded={isManuallyAdded} />}
          {isMobile && onChange && (
            <Button
              icon={'edit'}
              iconPosition={'left'}
              label={t('common.edit')}
              variant="secondary"
              onClick={onChange}
              testId={typeof index === 'number' ? `representative.${index}.edit` : ''}
            />
          )}
        </RepresentativeDataDetails>
      </RepresentativeData>

      <RepresentativeActionButtonsWrapper>
        <RepresentativeActionButtons>
          {!isMobile && onChange && (
            <Button
              variant="tertiary"
              onClick={onChange}
              testId={typeof index === 'number' ? `representative.${index}.edit` : ''}
            >
              <VisuallyHidden>{t('common.edit')}</VisuallyHidden>
              <Icon name="edit" size="m" />
            </Button>
          )}

          {onDelete && (
            <Button
              variant="destructive-teriary"
              onClick={onDelete}
              testId={typeof index === 'number' ? `representative.${index}.delete` : ''}
            >
              <VisuallyHidden>{t('common.delete')}</VisuallyHidden>
              <Icon name="delete" size="m" />
            </Button>
          )}
        </RepresentativeActionButtons>
        {!isMobile && <ManuallyAddedLabel manuallyAdded={isManuallyAdded} />}
      </RepresentativeActionButtonsWrapper>
    </RepresentativeWrapper>
  );
}

const OwnershipData = ({ ownershipData }: { ownershipData: OwnershipDataProps }) => {
  const { type, minSharesPercentage, maxSharesPercentage } = ownershipData;
  const { t } = useTranslation();
  const getSharesPercentage = (ownershipData: OwnershipDataProps) => {
    if (ownershipData.type !== 'BENEFICIARY_OWNER') {
      return '';
    }

    // we do not display 'shares' part of the paragraph when shares are either null or undefined
    if (!Number.isFinite(minSharesPercentage) && !Number.isFinite(maxSharesPercentage)) {
      return '';
    }

    if (minSharesPercentage === maxSharesPercentage) {
      return ` • ${minSharesPercentage}%  ${t('onboarding.v2.owners.shares')}`;
    }

    return ` • ${minSharesPercentage}% - ${maxSharesPercentage}% ${t('onboarding.v2.owners.shares')}`;
  };

  return (
    <Paragraph size="s" noGutter color="tertiary">
      {t(`onboarding.v2.owners.type.${type || 'OTHER_OWNER'}` as const)}
      {getSharesPercentage(ownershipData)}
    </Paragraph>
  );
};
