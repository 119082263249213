import { Heading, Strong, Text } from '@loomispay/vault';
import { OfferItem, Store } from 'features/common/types';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { formatAmount } from 'utils/moneyHelper';
import { OfferSummaryRow, OfferSummaryHr, OfferSummarySectionBox, OfferMonthlyCost } from '../../OfferOverview.styles';
import { getOrderItemTotalPrice, getTotalMonthlyFee } from './utils';

export default function OfferOverviewProducts({
  stores,
  terminalsFreeMonths = 0,
  durationInMonths = 0,
}: {
  stores: Store[];
  terminalsFreeMonths?: number;
  durationInMonths?: number;
}) {
  const isTerminalsFreeMonthsSet = !!terminalsFreeMonths && terminalsFreeMonths > 0;
  const isTerminalsSubscriptionFree = isTerminalsFreeMonthsSet && +terminalsFreeMonths === +durationInMonths;
  const { t } = useTranslation();
  const totalMonthlyFee = useMemo(() => {
    const { amount, currencyCode } = getTotalMonthlyFee('monthly', stores);
    return formatAmount(isTerminalsSubscriptionFree ? 0 : amount, currencyCode);
  }, [stores, isTerminalsSubscriptionFree]);

  return (
    <section>
      <Heading size="xxs" sansSerif>
        {t('onboarding.v2.overview.products')}
      </Heading>
      <OfferSummarySectionBox>
        <OfferSummaryRow>
          <Strong size="s">{t('onboarding.v2.overview.products.item')}</Strong>
          <Strong size="s">{t('onboarding.v2.overview.products.monthlyCost')}</Strong>
        </OfferSummaryRow>
        <OfferSummaryRow />
        {stores.map((store, i) => (
          <div key={`store${i}`}>
            <OfferSummaryRow>
              <Strong size="xs">{store.name}</Strong>
              <div />
            </OfferSummaryRow>
            {store.storeOfferItems?.terminals.map((terminal: OfferItem) => {
              const { amount, currencyCode } = getOrderItemTotalPrice('monthly', terminal);

              return (
                <OfferSummaryRow key={terminal.ref}>
                  <Text size="s">{`${terminal.quantity}x ${terminal.name}`}</Text>
                  <Text size="s">{`${formatAmount(amount, currencyCode)}/${t(
                    'onboarding.v2.overview.products.month'
                  )}`}</Text>
                </OfferSummaryRow>
              );
            })}
          </div>
        ))}

        <OfferSummaryHr />

        <div>
          {isTerminalsFreeMonthsSet && (
            <OfferSummaryRow>
              <Text size="s" weight="semiBold">
                {t('onboarding.v2.overview.products.campaign')}
              </Text>
              <Text size="s" color="positive" weight="semiBold">
                {t('onboarding.v2.overview.products.campaign.terminalFreeMonths', {
                  number: terminalsFreeMonths,
                })}
              </Text>
            </OfferSummaryRow>
          )}
          <OfferSummaryRow>
            <Strong size={'l'}>{t('onboarding.v2.overview.products.monthlyFee')}</Strong>
            <OfferMonthlyCost>
              <Strong size={'l'}>{`${totalMonthlyFee}/${t('onboarding.v2.overview.products.month')}`}</Strong>
              {!isTerminalsSubscriptionFree && (
                <Strong size="l" color="tertiary">
                  {isTerminalsFreeMonthsSet && (
                    <>
                      {t('onboarding.v2.overview.products.afterFreeMonths', {
                        number: terminalsFreeMonths,
                      })}{' '}
                    </>
                  )}
                  {t('onboarding.v2.overview.products.forMonths', {
                    number: durationInMonths - terminalsFreeMonths,
                  })}
                </Strong>
              )}
            </OfferMonthlyCost>
          </OfferSummaryRow>
        </div>
      </OfferSummarySectionBox>
    </section>
  );
}
