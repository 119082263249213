import { ReactNode } from 'react';
import ReactModal, { Styles } from 'react-modal';
import styled from 'styled-components/macro';
import { color } from '../../../constants';

const customStyles: Styles = {
  overlay: {
    zIndex: 2000,
    backgroundColor: color.opacityBlack08,
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  content: {
    zIndex: 100,
    maxWidth: 'calc(100vw - 2rem)',
    maxHeight: 'calc(100vh - 2rem)',
    overflowY: 'auto',
    width: 'fit-content',
    height: 'fit-content',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

interface Props extends ReactModal.Props {
  children: ReactNode;
  onRequestClose?: VoidFunction;
}

export const Modal = ({
  isOpen,
  onRequestClose,
  contentLabel,
  style,
  shouldCloseOnOverlayClick = true,
  children,
  ...restProps
}: Props) => (
  <ReactModal
    isOpen={isOpen}
    onRequestClose={() => onRequestClose && onRequestClose()}
    shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
    style={{
      content: { ...customStyles.content, ...style?.content },
      overlay: { ...customStyles.overlay, ...style?.overlay },
    }}
    // contentLabel is used for screen readers
    contentLabel={contentLabel}
    {...restProps}
  >
    {children}
  </ReactModal>
);

export const ModalButtons = ({ children }: { children: ReactNode }) => {
  return <ModalButtonsWrapper>{children}</ModalButtonsWrapper>;
};

const ModalButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: ${({ theme }) => theme.spacings[2]};
  margin-top: ${({ theme }) => theme.spacings[4]};
`;
