import React, { Suspense } from 'react';
import AppRouting from './AppRouting';
import { DefaultNotificationBarWrapper } from '../common/components/Notification/NotificationBarWrapper';
import { IndicatorLayerWrapper } from '../common/components/IndicatorLayer/IndicatorLayerWrapper';
import AppProviders from './AppProviders';
import './i18n';
import '../store/store.types';
import { env } from './config';
import styled from 'styled-components/macro';
import { Text } from '@loomispay/vault';
import { AuthProvider } from '../features/loginPasswordless/useCognitoUser';
import { QueryClient, QueryClientProvider } from 'react-query';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Infinity,
      retry: false,
    },
  },
});

function App() {
  return (
    <Suspense fallback={<IndicatorLayerWrapper imageOnly />}>
      <QueryClientProvider client={queryClient}>
        <AuthProvider>
          <AppProviders>
            <AppRouting />
            {DefaultNotificationBarWrapper.render()}
            <IndicatorLayerWrapper />
            <AppVersion id="appVersion">
              <Text size="s">{env.appVersion}</Text>
            </AppVersion>
          </AppProviders>
        </AuthProvider>
      </QueryClientProvider>
    </Suspense>
  );
}

const AppVersion = styled.div`
  display: none;
  justify-content: end;
`;

export default App;
