import { TextInput } from '../../../../../common/components/forms/TextInput/TextInput';
import { useTranslation } from 'react-i18next';
import { Control, FieldErrors } from 'react-hook-form';
import styled from 'styled-components/macro';

export const Content = styled.div`
  margin-top: ${({ theme }) => theme.spacings[3]};
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings[3]};
`;

type Props = {
  email: string | undefined;
  phoneNumber: string | undefined;
  name: string;
  control: Control;
  errors: FieldErrors;
};

const ContactDetailsVerification = ({ phoneNumber, email, name, control, errors }: Props) => {
  const { t } = useTranslation();

  return (
    <Content>
      <TextInput
        label={t('onboarding.v2.overview.contactPerson.email')}
        name={`${name}.verifyEmailAddress`}
        defaultValue={email || ''}
        control={control}
        errors={errors}
        disabled
      />
      <TextInput
        label={t('onboarding.v2.overview.contactPerson.phone')}
        name={`${name}.verifyPhoneNumber`}
        defaultValue={phoneNumber || ''}
        control={control}
        errors={errors}
        disabled
      />
    </Content>
  );
};
export default ContactDetailsVerification;
