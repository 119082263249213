import { Heading, Strong, Text } from '@loomispay/vault';
import { OfferItem, Store } from 'features/common/types';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { formatAmount } from 'utils/moneyHelper';
import { OfferSummaryHr, OfferSummaryRow, OfferSummarySectionBox } from '../../OfferOverview.styles';
import { getAddonsTotalCost, getOrderItemTotalPrice } from './utils';

export default function OfferOverviewAccessories({ stores }: { stores: Store[] }) {
  const { t } = useTranslation();
  const addonsTotalCost = useMemo(() => {
    const { amount, currencyCode } = getAddonsTotalCost('upfront', stores);
    return formatAmount(amount, currencyCode);
  }, [stores]);

  return (
    <section>
      <Heading size="xxs" sansSerif>
        {t('onboarding.v2.overview.accessories')}
      </Heading>
      <OfferSummarySectionBox>
        <OfferSummaryRow>
          <Strong size="s">{t('onboarding.v2.overview.accessories.item')}</Strong>
          <Strong size="s">{t('onboarding.v2.overview.accessories.price')}</Strong>
        </OfferSummaryRow>
        <OfferSummaryRow />
        {stores.map((store, i) => (
          <div key={`store${i}`}>
            <OfferSummaryRow>
              <Strong size="xs">{store.name}</Strong>
              <div />
            </OfferSummaryRow>
            {store.storeOfferItems?.addons.map((addon: OfferItem) => {
              const { amount, currencyCode } = getOrderItemTotalPrice('upfront', addon);
              return (
                <OfferSummaryRow key={addon.ref}>
                  <Text size="s">{`${addon.quantity}x ${addon.name}`}</Text>
                  <Text size="s">{`${formatAmount(amount, currencyCode)}`}</Text>
                </OfferSummaryRow>
              );
            })}
          </div>
        ))}

        <OfferSummaryHr />

        <div>
          <OfferSummaryRow>
            <Strong size="l">{t('onboarding.v2.overview.accessories.oneTimeCost')}</Strong>
            <Strong size="l">{`${addonsTotalCost}`}</Strong>
          </OfferSummaryRow>
        </div>
      </OfferSummarySectionBox>
    </section>
  );
}
