import { Heading } from '@loomispay/vault';
import { Dropdown } from 'common/components/forms/Dropdown/Dropdown';
import { TextInput } from 'common/components/forms/TextInput/TextInput';
import { Toggle } from 'common/components/ToggleWrapper/Toggle';
import { getStores } from 'features/onboardingV2/store/sales/selectors';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { CashServicesGridWrapper } from '../CashServices.styles';
import { CashServicesSectionTypes } from '../types';
import useCashServices from '../useCashServices';
import { MAX_INT32_VAL } from 'utils/format';

export default function CashServicesGeneralInfo({
  storeIndex,
  control,
  errors,
  checkSolutionType,
}: CashServicesSectionTypes & { checkSolutionType?: string }) {
  const { t } = useTranslation();
  const { cashContractTypesOptions } = useCashServices();
  const locations = useSelector(getStores);

  const cashDataGeneralInfo = locations[storeIndex].cashData?.es?.generalInfo;

  const basicSafePointOptionAvailable = !!checkSolutionType && 'DIGITAL_CASH' === checkSolutionType;

  return (
    <section>
      <Heading size="s">{t('cashServices.generalInfo')}</Heading>
      <CashServicesGridWrapper>
        <Dropdown
          name={`store-${storeIndex}.generalInfo.solutionType`}
          label={t('cashServices.form.solutionChosen')}
          control={control}
          errors={errors}
          rules={{ required: t('common.form.validationMessages.required') }}
          options={cashContractTypesOptions}
          defaultValue={
            cashContractTypesOptions.find(
              solution => solution.value === cashDataGeneralInfo?.solutionType?.toString()
            ) || ''
          }
        />

        <TextInput
          name={`store-${storeIndex}.details.pickupsPerMonth`}
          label={t('cashServices.form.pickupsPerMonth')}
          control={control}
          errors={errors}
          rules={{ required: t('common.form.validationMessages.required') }}
          defaultValue={locations[storeIndex].cashData?.es?.details?.pickupsPerMonth?.toString() || ''}
          type="number"
          inputProps={{
            min: 1,
            max: MAX_INT32_VAL,
            step: 1,
          }}
        />

        {basicSafePointOptionAvailable && (
          <Toggle
            name={`store-${storeIndex}.generalInfo.basicSafePointIncluded`}
            label={t('cashServices.form.basicSafePointIncluded')}
            control={control}
            errors={errors}
            defaultValue={cashDataGeneralInfo?.basicSafePointIncluded?.toString() || ''}
          />
        )}
      </CashServicesGridWrapper>
    </section>
  );
}
