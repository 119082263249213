import { CONTAINER_DEFAULT_WIDTH, CONTAINER_MINIMUM_PAGE_PADDINGS } from 'common/components/MainLayout/Container';
import { ReactNode, useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { LOCATION_CARD_WIDTH } from './LocationCard.styles';

export default function LocationsSidebar({ children }: { children: ReactNode }) {
  const [displayOnLeft, setDisplayOnLeft] = useState<boolean>();

  useEffect(() => {
    const handleResize = () => {
      const spaceOnLeft = (window.innerWidth - CONTAINER_DEFAULT_WIDTH) / 2;
      const canFitSidebar =
        spaceOnLeft >= LOCATION_CARD_WIDTH + LOCATION_CARDS_OFFSET + CONTAINER_MINIMUM_PAGE_PADDINGS;
      setDisplayOnLeft(canFitSidebar);
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return <LocationSidebarWrapper displayOnLeft={displayOnLeft}>{children}</LocationSidebarWrapper>;
}

const LOCATION_CARDS_OFFSET = 52;

const LocationSidebarWrapper = styled.aside<{ displayOnLeft?: boolean }>`
  position: ${({ displayOnLeft }) => (displayOnLeft ? 'absolute' : 'static')};
  top: 96px;
  left: -${LOCATION_CARDS_OFFSET + LOCATION_CARD_WIDTH}px;
  flex-direction: ${({ displayOnLeft }) => (displayOnLeft ? 'column' : 'row')};
  gap: ${({ theme }) => theme.spacings[2]};
  overflow-y: auto;
  padding-bottom: ${({ displayOnLeft, theme }) => (displayOnLeft ? 0 : theme.spacings[2])};
  display: ${({ displayOnLeft }) => (displayOnLeft !== undefined ? 'flex' : 'none')};

  & > * {
    flex-shrink: 0;
  }
`;
