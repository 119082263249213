import SeBankAccount from './components/bankInfo/SeBankAccount';
import InternationalBankAccount from './components/bankInfo/InternationalBankAccount';
import NoBankAccount from './components/bankInfo/NoBankAccount';

export const bankAccountForm = {
  SE: {
    bankInfoScreen: SeBankAccount,
  },
  NO: {
    bankInfoScreen: NoBankAccount,
  },
  INTERNATIONAL: {
    bankInfoScreen: InternationalBankAccount,
  },
};
