import { Logo, useMedia } from '@loomispay/vault';
import {
  PasswordlessFormWrapper,
  PasswordlessLoginContent,
  PasswordlessLoginGrid,
  PasswordlessLoginImageWrapper,
  PasswordlessSpacer,
} from 'features/loginPasswordless/LoginPasswordless.styles';
import OnboardingIllustration from './onboardingIllustration.png';
import { useDocumentTitle } from 'hooks';
import React, { useEffect, useState } from 'react';
import LoginPasswordlessStepTwo from './steps/LoginPasswordlessStepTwo';
import LoginPasswordlessStepOne from './steps/LoginPasswordlessStepOne';
import { useTranslation } from 'react-i18next';
import useCognitoUser from './useCognitoUser';
import { useDispatch } from 'react-redux';
import { ContactPerson } from 'features/common/types';
import { configureAmplify } from '../login/PasswordlesAmplifyConfigurer';

export default function LoginPasswordless({
  onAuthSuccess,
  contactPerson,
  username,
  userPhoneNumber,
  stepOneHeader,
  stepOneDescription,
}: {
  onAuthSuccess: () => Promise<void>;
  contactPerson: ContactPerson;
  username: string;
  userPhoneNumber: string;
  stepOneHeader?: string;
  stepOneDescription?: string;
}) {
  const { t } = useTranslation();
  const [step, setStep] = useState(0);
  const isDesktop = useMedia('desktop');
  const isMobile = useMedia('mobile');
  const dispatch = useDispatch();

  configureAmplify();

  const { authStatus, signOut } = useCognitoUser();
  useDocumentTitle(t('login.title'), false);

  const [sessionCleared, setSessionCleared] = useState(false);

  useEffect(() => {
    const clearSession = async () => {
      await signOut(); // Log out any existing session
      setSessionCleared(true);
    };

    clearSession();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    if (sessionCleared && authStatus === 'AUTHENTICATED') {
      onAuthSuccess();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authStatus, sessionCleared]);

  return sessionCleared ? (
    <PasswordlessLoginGrid isDesktop={isDesktop}>
      <PasswordlessLoginContent>
        <PasswordlessSpacer>
          <Logo />
        </PasswordlessSpacer>

        <PasswordlessFormWrapper isMobile={isMobile}>
          {step === 0 && !!userPhoneNumber && (
            <LoginPasswordlessStepOne
              setNextStep={() => setStep(1)}
              userPhoneNumber={userPhoneNumber}
              contactPerson={contactPerson}
              header={stepOneHeader}
              description={stepOneDescription}
            />
          )}
          {step === 1 && <LoginPasswordlessStepTwo username={username} phoneNumber={userPhoneNumber} />}
        </PasswordlessFormWrapper>

        <PasswordlessSpacer />
      </PasswordlessLoginContent>
      {isDesktop && <PasswordlessLoginImageWrapper style={{ backgroundImage: `url(${OnboardingIllustration})` }} />}
    </PasswordlessLoginGrid>
  ) : null;
}
