import { TextInput } from 'common/components/forms/TextInput/TextInput';
import { CountryCode, ScreenMode } from 'constants/types';
import { CountryDropdown } from 'features/onboardingV2/components/CountryDropdown';
import { useDocumentTitle } from 'hooks';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import OnboardingModalLayout from 'layout/OnboardingModalLayout';
import { Grid, GridItem } from 'common/components/Grid';
import styled from 'styled-components/macro';

export type LocationForm = {
  name: string | undefined;
  address: string | undefined;
  zipCode: string | undefined;
  city: string | undefined;
  country: string | undefined;
};

interface Props {
  location: LocationForm;
  closeUrl: string;
  mode: ScreenMode;
  onSubmit: (locationForm: LocationForm) => void;
  countryCode?: CountryCode;
}

const LocationEdit = ({ location, closeUrl, mode, onSubmit, countryCode }: Props) => {
  const { t } = useTranslation();

  const locationForm = useForm<LocationForm>({
    mode: 'onChange',
  });
  const isScreenCompleted = locationForm.formState.isValid;
  const title = mode === ScreenMode.edit ? t('common.locations.edit.title') : t('common.locations.add.title');
  useDocumentTitle(title);

  const label = mode === ScreenMode.edit ? t('common.save') : t('common.add');

  const modalGridContentGutter = 16;

  return (
    <OnboardingModalLayout
      title={title}
      closeUrl={closeUrl}
      label={label}
      isScreenCompleted={isScreenCompleted}
      formId="location-form"
      gridGutter={modalGridContentGutter}
      noGutter={true}
    >
      <LocationEditForm id="location-form" onSubmit={locationForm.handleSubmit(onSubmit)}>
        <Grid gutter={modalGridContentGutter} vGap="6">
          <GridItem s={4} m={4} l={6}>
            <TextInput
              label={t('common.locations.locationName')}
              name="name"
              setValue={(value?: string) => locationForm.setValue('name', value)}
              rules={{ required: t('common.form.validationMessages.required') }}
              defaultValue={location?.name || ''}
              control={locationForm.control}
              errors={locationForm.errors}
              testId={'location-name'}
              trimOnBlur
            />
          </GridItem>
          <GridItem s={0} m={0} l={6} />

          <GridItem s={4} m={4} l={6}>
            <TextInput
              label={t('common.locations.streetName')}
              name="address"
              setValue={(value?: string) => locationForm.setValue('address', value)}
              rules={{ required: t('common.form.validationMessages.required') }}
              defaultValue={location?.address || ''}
              control={locationForm.control}
              errors={locationForm.errors}
              testId={'street-name'}
              trimOnBlur
            />
          </GridItem>
          <GridItem s={4} m={4} l={6}>
            <TextInput
              label={t('common.address.zipCode')}
              name="zipCode"
              setValue={(value?: string) => locationForm.setValue('zipCode', value)}
              rules={{ required: t('common.form.validationMessages.required') }}
              defaultValue={location?.zipCode || ''}
              control={locationForm.control}
              errors={locationForm.errors}
              testId={'zip-code'}
              trimOnBlur
            />
          </GridItem>

          <GridItem s={4} m={4} l={6}>
            <TextInput
              label={t('common.address.city')}
              name="city"
              setValue={(value?: string) => locationForm.setValue('city', value)}
              rules={{ required: t('common.form.validationMessages.required') }}
              defaultValue={location?.city || ''}
              control={locationForm.control}
              errors={locationForm.errors}
              testId={'city'}
              trimOnBlur
            />
          </GridItem>
          <GridItem s={4} m={4} l={6}>
            <CountryDropdown
              control={locationForm.control}
              errors={locationForm.errors}
              countryCodes={countryCode ? [countryCode] : []}
            />
          </GridItem>
        </Grid>
      </LocationEditForm>
    </OnboardingModalLayout>
  );
};

const LocationEditForm = styled.form`
  display: flex;
  padding-bottom: ${({ theme }) => theme.spacings['6']};
`;

export default LocationEdit;
