import { spacing } from 'constants/spacings';
import styled from 'styled-components/macro';

export const CheckboxIcon = styled.span`
  position: relative;
  height: 20px;
  width: 20px;
  border: 1px solid ${({ theme }) => theme.color.border.defaultNormalBorder};
  border-radius: 4px;
  flex-shrink: 0;

  > svg {
    display: none;
    position: absolute;
    width: 16px;
    height: 16px;
    top: 50%;
    left 50%;
    transform: translate3d(-50%, -50%, 0);
  }
`;

export const Label = styled.label<{
  disabled?: boolean;
}>`
  position: relative;
  display: flex;
  align-items: center;
  cursor: ${({ disabled }) => (disabled ? 'normal' : 'pointer')};

  ${({ disabled, theme }) => {
    if (!disabled) {
      return `
        &:hover {
          ${CheckboxIcon} {
            background-color: ${theme.color.background.secondaryBg};
            border: 1px solid ${theme.color.border.focusBorder};
          }
        }
      `;
    }
  }};
`;

export const CheckboxInput = styled.input`
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;

  &:checked ~ ${CheckboxIcon} {
    svg {
      display: block;
      > path {
        fill: ${({ disabled, theme }) => (disabled ? theme.color.border.disabledBorder : 'black')};
      }
    }
  }

  &:focus ~ ${CheckboxIcon} {
    outline: 1px solid ${({ theme }) => theme.color.border.focusBorder};
  }

  &:disabled ~ ${CheckboxIcon} {
    border: 1px solid ${({ theme }) => theme.color.border.disabledBorder};
  }
`;

export const CheckboxLabelWrapper = styled.div<{ labelVisuallyHidden?: boolean }>`
  margin-left: ${({ theme, labelVisuallyHidden }) => (labelVisuallyHidden ? 0 : theme.spacings[1])};
`;

export const ErrorSpacing = styled.div`
  margin-top: ${spacing.s1};
`;
