import { DropDownOption } from 'constants/types';
import { Store } from 'features/common/types';
import { updateMultipleStores } from 'features/onboardingV2/store/sales/actions';
import { getCashOfferConfiguration, getStores } from 'features/onboardingV2/store/sales/selectors';
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  CashContractGuaranteeType,
  CashContractMonthlyFeeType,
  CashContractPaymentMethods,
  CashServicesFormFieldsES,
  CashSolutionTypes,
  LoomisBankDetails,
} from './types';

export default function useCashServices() {
  const dispatch = useDispatch();
  const locations = useSelector(getStores);
  const cashOfferConfig = useSelector(getCashOfferConfiguration);

  const cashContractTypesOptions = useMemo(() => {
    return Object.entries(cashOfferConfig?.solutionTypes || {}).map(solutionType => ({
      value: solutionType[0],
      label: solutionType[1],
    }));
  }, [cashOfferConfig?.solutionTypes]);

  const cashContractPaymentMethodOptions = useMemo(() => {
    return Object.entries(cashOfferConfig?.paymentMethods || {}).map(([value, label]) => ({
      value,
      label,
    }));
  }, [cashOfferConfig?.paymentMethods]);

  const cashContractLoomisBankDetailsOptions = useMemo(() => {
    return cashOfferConfig?.loomisBankDetails?.map(({ loomisBankName }) => ({
      value: loomisBankName,
      label: loomisBankName,
    }));
  }, [cashOfferConfig?.loomisBankDetails]);

  const updateStoresDataInRedux = (data: Record<string, CashServicesFormFieldsES>) => {
    const storesCopy = JSON.parse(JSON.stringify(locations)) as Store[];

    Object.keys(data).forEach((storeKey, storeIndex) => {
      const {
        generalInfo,
        contractDates,
        details,
        changeService,
        guarantee,
        clientInsurance,
        instantCredit,
        loomisOperationsBank,
        fees,
        isCashEnabled,
      } = data[storeKey];
      const store = storesCopy[storeIndex] as Store;

      // Ignore form field groups that are not referring to cash data
      if (!storeKey.startsWith('store-')) return false;

      if (!isCashEnabled) {
        store.isCashEnabled = false;
        store.cashData = null;
        return;
      }

      const { value: solutionType } = generalInfo.solutionType as DropDownOption<keyof CashSolutionTypes>;
      const { value: paymentMethodChosen } = fees.paymentMethodChosen as DropDownOption<CashContractPaymentMethods>;
      const { value: monthlyFeeType } = fees.monthlyFeeType as DropDownOption<CashContractMonthlyFeeType>;

      store.isCashEnabled = true;
      store.cashData = {
        es: {
          generalInfo: {
            solutionType,
            basicSafePointIncluded: !!generalInfo.basicSafePointIncluded,
            isLoomisBeneficiary: !!generalInfo.isLoomisBeneficiary,
            insuranceIncluded: !!generalInfo.insuranceIncluded,
            changeServiceIncluded: !!generalInfo.changeServiceIncluded,
          },
          contractDates: {
            startDate: contractDates.startDate,
            durationInMonths: contractDates.durationInMonths,
          },
          details: {
            pickupsPerMonth: details.pickupsPerMonth,
            banknotesLimitValuePerMonth: details.banknotesLimitValuePerMonth,
            coinsLimitValuePerMonth: details.coinsLimitValuePerMonth,
            banknotesRechargeExcessWithoutNewPickup: details.banknotesRechargeExcessWithoutNewPickup,
            banknotesRechargeExcessWithNewPickup: details.banknotesRechargeExcessWithNewPickup,
            chargeForEachCoinLimitExcess: details.chargeForEachCoinLimitExcess,
          },
          instantCredit: {
            banknotesInstantCredit: instantCredit.banknotesInstantCredit,
            coinsInstantCredit: instantCredit.coinsInstantCredit,
          },
          loomisOperationsBank: loomisOperationsBank,
          fees: {
            monthlyFeeType,
            fixedMonthlyFee: monthlyFeeType === 'fixed' ? fees.fixedMonthlyFee : undefined,
            percentageMonthlyFee: monthlyFeeType === 'percentage' ? fees.percentageMonthlyFee : undefined,
            minimumMonthlyFee: monthlyFeeType === 'percentage' ? fees.minimumMonthlyFee : undefined,
            upfrontPayment: fees.upfrontPayment,
            finalPayment: fees.finalPayment,
            paymentMethodChosen,
          },
        },
      };

      // Add change service data only if toggle is selected
      if (generalInfo.changeServiceIncluded && changeService && store.cashData.es) {
        store.cashData.es.changeService = {
          changeWithPickupFee: changeService.changeWithPickupFee,
          changeWithoutPickupFee: changeService.changeWithoutPickupFee,
        };
      }

      // Add client insurance data only if conditions are met: no insurance and Loomis as beneficiary
      if (!generalInfo.insuranceIncluded && generalInfo.isLoomisBeneficiary && clientInsurance && store.cashData.es) {
        store.cashData.es.clientInsurance = {
          insuranceNumber: clientInsurance.insuranceNumber,
          insuranceCompany: clientInsurance.insuranceCompany,
          insuredAmount: clientInsurance.insuredAmount,
        };
      }

      // Digital Cash Solution
      if (solutionType === 'DIGITAL_CASH' && store.cashData.es) {
        if (guarantee) {
          const { value: guaranteeType } = guarantee.type as DropDownOption<CashContractGuaranteeType>;
          store.cashData.es.guarantee = {
            type: guaranteeType,
            amount: guaranteeType !== 'NONE' ? guarantee.amount : undefined,
          };
        }

        if (loomisOperationsBank) {
          store.cashData.es.loomisOperationsBank = (loomisOperationsBank as DropDownOption<LoomisBankDetails>).value;
        }
      }
    });

    dispatch(updateMultipleStores(storesCopy));
  };

  return {
    cashOfferConfig,
    cashContractTypesOptions,
    cashContractPaymentMethodOptions,
    cashContractLoomisBankDetailsOptions,
    updateStoresDataInRedux,
  };
}
