import { Dropdown } from 'common/components/forms/Dropdown/Dropdown';
import { CountryCode } from 'constants/types';
import { Control, FieldErrors } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

type Props = {
  control: Control;
  errors: FieldErrors;
  countryCodes: CountryCode[];
};
type CountryDropdownOption = { code?: CountryCode; name?: string };

export const CountryDropdown = ({ control, errors, countryCodes }: Props) => {
  const { t } = useTranslation();

  const options: CountryDropdownOption[] = countryCodes.map((code: CountryCode) => {
    return {
      code: code,
      name: code ? t(`common.country.${code}` as const) : '',
    };
  });

  return (
    <Dropdown
      label={t('common.address.country')}
      name="countryCode"
      options={options}
      getOptionLabel={({ name }) => name}
      getOptionValue={({ code }) => code}
      defaultValue={options[0]}
      control={control}
      errors={errors}
      isDisabled
    />
  );
};
