import { useTranslation } from 'react-i18next';
import { Control, FieldErrors } from 'react-hook-form';
import { TextInput } from '../../../../../../common/components/forms/TextInput/TextInput';
import { Heading } from '@loomispay/vault';
import { useSelector } from 'react-redux';
import { getOffer } from '../../../../store/sales/selectors';

export default function CommentForLogistics({ control, errors }: { control: Control; errors: FieldErrors }) {
  const { t } = useTranslation();
  const offer = useSelector(getOffer);
  const maxLength = 500;

  return (
    <>
      <Heading sansSerif size="xxs">
        {t('onboarding.v2.overview.commentForLogisticsHeading')}
      </Heading>
      <TextInput
        testId="comment-for-logistics"
        label=""
        textArea
        name="commentForLogistics"
        defaultValue={offer.commentForLogistics?.toString() || ''}
        control={control}
        errors={errors}
        hintText={t('onboarding.v2.overview.commentForLogisticsHint')}
        rules={{
          maxLength: {
            value: maxLength,
            message: t('common.maxLengthExceeded', {
              maxLength: maxLength,
            }),
          },
        }}
      />
    </>
  );
}
