import { useCallback, useEffect } from 'react';
import { Auth } from 'aws-amplify';
import { userService } from 'services/userService';
import { useNavigate } from 'react-router';
import { useDispatch } from 'react-redux';
import { configureSales } from './applicationActions';
import { CognitoUserSession } from 'amazon-cognito-identity-js';
import { CountryCode, supportedCountryCodes } from '../../constants/types';
import { configureAmplify } from './SalesToolsAmplifyConfigurer';
import { fetchFeatureToggles } from 'features/featureToggles/featureToggles';
import { salesBasePath } from 'features/onboardingV2/SalesRouter';

const OAuthScreen = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  configureAmplify();

  const getSession = async () => {
    try {
      const session = await Auth.currentSession();
      return session.isValid() ? session : false;
    } catch {
      return false;
    }
  };

  const updateAvailableCountries = useCallback(async () => {
    const getUserCountriesFromRoles = (roles: string[]): CountryCode[] => {
      if (roles.includes('technical_support')) {
        return supportedCountryCodes.map(code => code as CountryCode);
      } else {
        return roles
          .filter(role => supportedCountryCodes.includes(role.toUpperCase() as CountryCode))
          .map(code => code as CountryCode);
      }
    };

    const roles = (await userService.fetchAuthField('roles')) as string[];
    userService.storeAvailableCountries(getUserCountriesFromRoles(roles));
  }, []);

  const initiateOAuth = useCallback(async () => {
    const session = await getSession();
    if (session) {
      await dispatch(configureSales());

      const currentUser = await Auth.currentAuthenticatedUser();
      const refreshToken = currentUser.getSignInUserSession().getRefreshToken();

      // eslint-disable-next-line
      currentUser.refreshSession(refreshToken, async (err: any, refreshedSession: CognitoUserSession) => {
        if (!err) {
          userService.storeOAuthUserData(refreshedSession);
          await updateAvailableCountries();
          dispatch(fetchFeatureToggles());
        }
        navigate(salesBasePath);
      });
    } else {
      // Sign out to delete Amplify session (it may redirect to '/' - TODO MP-2236)
      Auth.signOut().finally(() => {
        // Use Cognito federated sign in with specified provider for more UX friendly experience during login
        const customProvider = `${process.env.REACT_APP_COGNITO_OAUTH_CUSTOM_PROVIDER}`;
        Auth.federatedSignIn({ customProvider });
      });
    }
  }, [navigate, updateAvailableCountries, dispatch]);

  useEffect(() => {
    initiateOAuth();
  }, [initiateOAuth]);

  return <></>;
};

export default OAuthScreen;
