import { Button, Display, NAVIGATION_WITH_PROGRESS_HEIGHT, Paragraph } from '@loomispay/vault';
import { SimpleGridItem, SimpleGridLayout } from 'common/components/GridLayout';
import { useDocumentTitle } from 'hooks';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components/macro';
import { onboardingRoutes } from '../../OnboardingRouter';
import { useOnboardingData } from '../../data/queries';
import { usePartialUpdate } from '../../data/mutations';
import { useCallback, useEffect } from 'react';

const Wrapper = styled.div`
  height: calc(100vh - ${NAVIGATION_WITH_PROGRESS_HEIGHT});
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

const WelcomeScreen = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  useDocumentTitle(t('onboarding.v2.intro.heading'));
  const { countryCode, offerRef } = useParams();
  const { data: onboardingData, isLoading } = useOnboardingData({ countryCode, offerRef });
  const { mutate: updatePage, isSuccess } = usePartialUpdate();

  const onProceed = useCallback(() => {
    navigate(generatePath(onboardingRoutes.locationsMerchant.path, { countryCode, offerRef }));
  }, [navigate, countryCode, offerRef]);

  const changePage = () => {
    if (onboardingData)
      updatePage({ countryCode, merchantRef: offerRef, data: { ...onboardingData, page: 'locations' } });
  };

  useEffect(() => {
    if (isSuccess) {
      onProceed();
    }
  }, [isSuccess, onProceed]);

  return !isLoading ? (
    <Wrapper>
      <SimpleGridLayout>
        <SimpleGridItem>
          <Display size="m">{t('onboarding.v2.intro.heading')}</Display>
          <Paragraph size="xl">{t('onboarding.v2.intro.body')}</Paragraph>
          <Button
            testId="welcome-continue-button"
            icon="arrowRight"
            iconPosition="right"
            label={t('onboarding.v2.intro.button')}
            onClick={changePage}
          />
        </SimpleGridItem>
      </SimpleGridLayout>
    </Wrapper>
  ) : null;
};
export default WelcomeScreen;
