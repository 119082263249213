import { Heading } from '@loomispay/vault';
import { TextInput } from 'common/components/forms/TextInput/TextInput';
import { getStores } from 'features/onboardingV2/store/sales/selectors';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { CashServicesGridWrapper } from '../CashServices.styles';
import { CashServicesSectionTypes } from '../types';
import { maxValue } from 'utils/format';

export default function CashServicesDetails({ storeIndex, control, errors }: CashServicesSectionTypes) {
  const { t } = useTranslation();
  const locations = useSelector(getStores);

  const cashDataDetails = locations[storeIndex].cashData?.es?.details;

  return (
    <section>
      <Heading size="s">{t('cashServices.details')}</Heading>
      <CashServicesGridWrapper>
        <TextInput
          label={t('cashServices.form.banknotesLimitCountPerMonth')}
          name={`store-${storeIndex}.details.banknotesLimitValuePerMonth`}
          defaultValue={cashDataDetails?.banknotesLimitValuePerMonth?.toString() || '30000'}
          control={control}
          errors={errors}
          rules={{
            required: t('common.form.validationMessages.required'),
          }}
          type="number"
          currency="EUR"
          inputProps={{
            min: 0,
            max: maxValue(6, 0),
            step: 1,
          }}
        />

        <TextInput
          label={t('cashServices.form.coinsLimitCountPerMonth')}
          name={`store-${storeIndex}.details.coinsLimitValuePerMonth`}
          defaultValue={cashDataDetails?.coinsLimitValuePerMonth?.toString() || '100'}
          control={control}
          errors={errors}
          rules={{
            required: t('common.form.validationMessages.required'),
          }}
          type="number"
          currency="EUR"
          inputProps={{
            min: 0,
            max: maxValue(6, 0),
            step: 1,
          }}
        />

        <TextInput
          label={t('cashServices.form.banknotesRechargeExcessWithoutNewPickup')}
          name={`store-${storeIndex}.details.banknotesRechargeExcessWithoutNewPickup`}
          defaultValue={cashDataDetails?.banknotesRechargeExcessWithoutNewPickup?.toString() || '35'}
          control={control}
          errors={errors}
          rules={{
            required: t('common.form.validationMessages.required'),
          }}
          type="number"
          currency="EUR"
          inputProps={{
            min: 0,
            max: maxValue(5, 0),
            step: 1,
          }}
        />

        <TextInput
          label={t('cashServices.form.banknotesRechargeExcessWithNewPickup')}
          name={`store-${storeIndex}.details.banknotesRechargeExcessWithNewPickup`}
          defaultValue={cashDataDetails?.banknotesRechargeExcessWithNewPickup?.toString() || '60'}
          control={control}
          errors={errors}
          rules={{
            required: t('common.form.validationMessages.required'),
          }}
          type="number"
          currency="EUR"
          inputProps={{
            min: 0,
            max: maxValue(5, 0),
            step: 1,
          }}
        />

        <TextInput
          label={t('cashServices.form.forEachCoinValueLimitExcess')}
          name={`store-${storeIndex}.details.chargeForEachCoinLimitExcess`}
          defaultValue={cashDataDetails?.chargeForEachCoinLimitExcess?.toString() || '1'}
          control={control}
          errors={errors}
          rules={{
            required: t('common.form.validationMessages.required'),
          }}
          type="number"
          currency="EUR"
          inputProps={{
            min: 0,
            max: maxValue(5, 0),
            step: 1,
          }}
        />
      </CashServicesGridWrapper>
    </section>
  );
}
