import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useSalesFlowNavigation } from './salesFlowRoutingService';
import { useEffect } from 'react';
import { clearSalesOnboarding, setCountryCode, upsertAvailableCountry } from '../../store/sales/actions';
import { getAvailableCountries } from '../../store/sales/selectors';
import { userService } from '../../../../services/userService';
import { reactLocalStorage } from 'reactjs-localstorage';

const SalesDispatcher = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const availableCountries = useSelector(getAvailableCountries);
  const { firstPage } = useSalesFlowNavigation();

  useEffect(() => {
    dispatch(clearSalesOnboarding());

    const upsertAvailableCountries = () => {
      // availableCountries from Cognito users are stored in OAuthScreen.tsx
      userService.retrieveAvailableCountries().forEach(countryCode => dispatch(upsertAvailableCountry(countryCode)));
    };

    upsertAvailableCountries();
  }, [dispatch]);

  useEffect(() => {
    // temporary code: creating offers moved to sales-tools-web. Onboarding-web will be decommissioned.
    // but don't redirect test user so we can keep cypress merchant tests
    const currentUserId = reactLocalStorage.get<string>('userId');
    if (currentUserId === '49b1d103-528f-4ae8-81bf-5097d9b99e92') {
      dispatch(setCountryCode(availableCountries[0]));
      navigate(firstPage());
    } else {
      window.location.href = process.env.REACT_APP_SALES_TOOLS_URL + '/new-offer/';
    }
  }, [dispatch, availableCountries, navigate, firstPage]);

  return null;
};

export default SalesDispatcher;
