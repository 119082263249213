import { cardSchemes, cardTypes, regions, TransactionFee, TransactionFeeConfigRule } from '../features/common/types';

export const transactionFeeRulesSort = (a: TransactionFee, b: TransactionFee) => {
  return (
    cardTypes.indexOf(a.cardType) - cardTypes.indexOf(b.cardType) ||
    regions.indexOf(a.region) - regions.indexOf(b.region) ||
    cardSchemes.indexOf(a.cardScheme) - cardSchemes.indexOf(b.cardScheme)
  );
};

export const transactionFeeConfigRulesSort = (a: TransactionFeeConfigRule, b: TransactionFeeConfigRule) => {
  return (
    cardTypes.indexOf(a.cardType) - cardTypes.indexOf(b.cardType) ||
    regions.indexOf(a.region) - regions.indexOf(b.region) ||
    cardSchemes.indexOf(a.cardScheme) - cardSchemes.indexOf(b.cardScheme)
  );
};
