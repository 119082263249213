import { Banner, Button, Text } from '@loomispay/vault';
import { TextInput } from 'common/components/forms/TextInput/TextInput';
import Container from 'common/components/MainLayout/Container';
import { CountryCode } from 'constants/types';
import { FlagDropdown } from 'features/common/components/FlagDropdown/FlagDropdown';
import { CompanyData } from 'features/common/types';
import { UseFormMethods } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';
import { CompanyDataBox } from './CompanyDataBox';

export type CompanyLookupForm = {
  companyNumber: string;
};

type Props = {
  companyLookupForm: UseFormMethods<CompanyLookupForm>;
  companyData: CompanyData;
  availableCountries: CountryCode[];
  defaultCountryCode: CountryCode;
  searchButtonLabel: string;
  errorMessage: string | null;
  existingCompanyName?: string;
  handleCountryChange: (countryCode: CountryCode) => void;
  handleFormReset?: () => void;
  findCompanyInformation: (formData: CompanyLookupForm) => void;
};

export const CompanySearch = ({
  companyLookupForm,
  companyData,
  availableCountries,
  defaultCountryCode,
  searchButtonLabel,
  errorMessage,
  existingCompanyName,
  handleCountryChange,
  handleFormReset,
  findCompanyInformation,
}: Props) => {
  const { t } = useTranslation();

  return (
    <Container spacing="3" fullWidth>
      <OrganizationForm onSubmit={companyLookupForm.handleSubmit(findCompanyInformation)}>
        <FlagDropdown
          countryCodes={availableCountries}
          handleChange={handleCountryChange}
          isDisabled={availableCountries.length <= 1}
          defaultCountryCode={defaultCountryCode}
        />

        <CompanyIdInput>
          <TextInput
            label={t('onboarding.v2.companyInfo.companyNumberLabel')}
            name="companyNumber"
            normalizeValue={(value: string) => value.trim()}
            setValue={(value?: string) => companyLookupForm.setValue('companyNumber', value, { shouldDirty: true })}
            defaultValue={companyData.companyNumber?.toString() || ''}
            control={companyLookupForm.control}
            errors={companyLookupForm.errors}
            disabled={companyData.isManuallyAdded}
            rules={{
              required: t('common.form.validationMessages.required'),
            }}
            trimOnBlur
          />
        </CompanyIdInput>

        <CompanyDataActionCol>
          {companyData.isManuallyAdded ? (
            <Button
              variant="destructive"
              size="s"
              label={t('onboarding.v2.companyInfo.resetFormButton')}
              testId="company-form-reset-button"
              onClick={handleFormReset}
            />
          ) : (
            <Button type="submit" size="s" label={searchButtonLabel} testId="company-lookup-submit-button" />
          )}
        </CompanyDataActionCol>
      </OrganizationForm>

      {errorMessage && <Banner type="negative">{errorMessage}</Banner>}

      {existingCompanyName && (
        <ExistingOfferBox>
          <ExistingOfferBoxRow>
            <Text size="xl" weight="semiBold">
              {existingCompanyName}
            </Text>
          </ExistingOfferBoxRow>
          <ExistingOfferBoxRow>
            <Text size="m">{t('onboarding.v2.companyInfo.offerAlreadyPlaced')}</Text>
          </ExistingOfferBoxRow>
        </ExistingOfferBox>
      )}

      {!companyData.isManuallyAdded && companyData.companyName && <CompanyDataBox companyData={companyData} />}
    </Container>
  );
};

const OrganizationForm = styled.form`
  display: flex;
  gap: ${({ theme }) => theme.spacings[4]};
  align-items: flex-start;
`;

const CompanyIdInput = styled.div`
  flex: 2;
`;

const ExistingOfferBox = styled.div`
  border: 1px solid ${({ theme }) => theme.color.border.disabledBorder};
  border-radius: 8px;
  padding: ${({ theme }) => theme.spacings[3]};
`;

const ExistingOfferBoxRow = styled.div`
  &:not(:last-child) {
    margin-bottom: ${({ theme }) => theme.spacings[1.5]};
  }
`;

const CompanyDataActionCol = styled.div`
  padding-top: ${({ theme }) => theme.spacings[0.5]};
  margin-top: ${({ theme }) => theme.spacings[3]};
`;
