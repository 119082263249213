import useFlowName from 'hooks/useFlowName';
import NavigationOnboardingMerchant from './Onboarding/NavigationOnboardingMerchant';
import NavigationOnboardingSales from './Onboarding/NavigationOnboardingSales';
import { useFlowForRole, useFlowProgressBar } from './useFlowProgressBar';
import { Navigation as NavigationVault } from '@loomispay/vault';

export default function Navigation() {
  const flowName = useFlowName();
  const { progress } = useFlowProgressBar(useFlowForRole(flowName));

  const getNavigationContent = () => {
    switch (flowName) {
      case 'onboarding':
        return <NavigationOnboardingMerchant />;
      case 'sales':
        return <NavigationOnboardingSales />;
      default:
        return null;
    }
  };

  return <NavigationVault progress={progress || undefined}>{getNavigationContent()}</NavigationVault>;
}
