import { useDispatch, useSelector } from 'react-redux';
import { notifications } from 'common/components/Notification/notifications';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Merchant, MerchantsList, MerchantStatus } from 'features/merchants/merchantTypes';
import { Applications, VatRate } from 'features/login/applicationReducer';
import { getMerchants, setCurrentMerchantReference } from 'features/merchants/merchantActions';

import { setCountryCode } from '../features/onboardingV2/store/merchant/actions';
import { reactLocalStorage } from 'reactjs-localstorage';
import useCognitoUser from 'features/loginPasswordless/useCognitoUser';
import { RootState } from 'store/store.types';
import { userService } from './userService';

export interface MerchantContext {
  merchant?: Merchant;
  vatRates: VatRate[];
  applications: Applications;
  hasMultipleLocations: boolean;
}

export const onboardingOngoingMerchantStatuses: MerchantStatus[] = ['NEW', 'ONBOARDING_IN_PROGRESS'];

export function useMerchantContext() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { isAuthenticated: isCognitoUserAuthenticated } = useCognitoUser();

  const merchantContext: MerchantContext = {
    merchant: useSelector((state: RootState) =>
      state.merchants.data.merchants.find(
        merchant => merchant.merchantReference === state.merchants.currentMerchantReference
      )
    ),
    vatRates: useSelector((state: RootState) => state.applicationConfig.vatRates),
    applications: useSelector((state: RootState) => state.applicationConfig.applications),
    hasMultipleLocations: useSelector((state: RootState) => state.applicationConfig.hasMultipleLocations),
  };
  const availableMerchants = useSelector((state: RootState) => state.merchants.data.merchants);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const setCurrentMerchantContext = async (merchant: Merchant) => {
    await dispatch(setCurrentMerchantReference(merchant.merchantReference));
    await dispatch(setCountryCode(merchant.countryCode));
    reactLocalStorage.set('merchantRef', merchant.merchantReference);
  };

  //TODO: it's some old implementation, to refactor or remove in teh future
  const loadMerchants = async (merchantReference?: string, onFailure = logout): Promise<Merchant | undefined> => {
    if ((await userService.isSalesRepLoggedIn()) || !isCognitoUserAuthenticated()) return;
    try {
      const getMerchantsResponse = await dispatch(getMerchants());
      const getMerchantsResponseData = getMerchantsResponse.payload.data as MerchantsList;

      const merchants = getMerchantsResponseData.merchants;
      const merchant = merchants.find(
        merchant =>
          merchant.merchantReference ===
          (merchantReference || getMerchantsResponse.payload.data.defaultMerchantReference)
      );

      if (merchant) {
        await setCurrentMerchantContext(merchant);
      }
      return merchant;
    } catch (err) {
      onFailure();
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const logout = () => {
    notifications.error(t('common.serverError'));
    navigate('/login');
  };

  const setCurrentMerchant = async (merchantReference?: string): Promise<Merchant | undefined> => {
    if (!isCognitoUserAuthenticated()) return;
    const merchant = availableMerchants.find((merchant: Merchant) => merchant.merchantReference === merchantReference);
    if (merchant) {
      await setCurrentMerchantContext(merchant);
    }
    return merchant;
  };

  const isOnboardingOngoing = () => {
    return merchantContext.merchant
      ? onboardingOngoingMerchantStatuses.includes(merchantContext.merchant.status)
      : false;
  };

  return {
    merchantContext,
    availableMerchants,
    loadMerchants,
    setCurrentMerchant,
    isOnboardingOngoing,
  };
}
