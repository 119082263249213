import { Button, Heading, Label, Paragraph } from '@loomispay/vault';
import { StoreBase, StoreOfferItems } from 'features/common/types';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components/macro';

type EditButtonPosition = 'BottomLeft' | 'TopRight';
type Props = {
  editable?: boolean;
  location: StoreBase;
  items?: StoreOfferItems;
  breakAddressLine?: boolean;
  onDelete?: () => void;
  index: number | string;
  editUrl: string;
  editButtonPosition?: EditButtonPosition;
};

const Wrapper = styled.div<{ editButtonPosition: EditButtonPosition }>`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: ${({ editButtonPosition }) => (editButtonPosition === 'BottomLeft' ? 'column' : 'row')};
`;

const ContentWrapper = styled.div`
  display: flex;
`;

const CompanyData = styled.div`
  width: 100%;
`;

const AddressWrapper = styled.div`
  flex-basis: 50%;
  flex-grow: 1;
`;

const HardwareWrapper = styled.div`
  flex-basis: 50%;
  flex-grow: 1;
`;
const Buttons = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacings['1']};
  white-space: nowrap;
`;

export const LocationBox = ({
  editable = true,
  location,
  items,
  onDelete,
  index,
  editUrl,
  editButtonPosition = 'TopRight',
  breakAddressLine = false,
}: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Wrapper editButtonPosition={editButtonPosition}>
      <CompanyData>
        <Heading size="m">{location.name}</Heading>
        <ContentWrapper>
          <AddressWrapper>
            <Label size="s">{t('common.address.addressLine')}</Label>
            <Paragraph size="s">
              {`${location.address.address}`}
              {breakAddressLine ? <br /> : ', '}
              {`${location.address.zipCode}`}
              {breakAddressLine ? <br /> : ', '}
              {`${location.address.city} `}
            </Paragraph>
          </AddressWrapper>
          {items && (
            <HardwareWrapper>
              <Label size="s">{t('common.hardware')}</Label>
              <Paragraph size="s">
                {items.terminals.map(terminal => (
                  <span key={terminal.ref}>
                    {`${terminal.quantity}x ${terminal.name}`}
                    <br />
                  </span>
                ))}
                {items.addons?.map(addon => (
                  <span key={addon.ref}>
                    {`${addon.quantity}x ${addon.name}`}
                    <br />
                  </span>
                ))}
              </Paragraph>
            </HardwareWrapper>
          )}
        </ContentWrapper>
      </CompanyData>
      {editable && (
        <Buttons>
          <Button
            size="s"
            variant="secondary"
            label={t('common.locations.edit.button')}
            onClick={() => {
              navigate(editUrl);
            }}
            testId={`location.${index}.editButton`}
          />
          {onDelete && (
            <Button
              variant="tertiary"
              size="s"
              icon="delete"
              iconPosition="left"
              onClick={onDelete}
              testId={`location.${index}.deleteButton`}
            />
          )}
        </Buttons>
      )}
    </Wrapper>
  );
};
