import { RootState } from 'store/store.types';
import { OnboardingState, Representative } from '../types';
import { initialState } from './reducer';

type OnboardingSelector<T> = (store: OnboardingState) => T;

const createSelector = <T>(selector: OnboardingSelector<T>) => (globalState: RootState) =>
  selector(globalState.onboardingV2.merchant || initialState);

export const getOffer = createSelector(state => state.offer);
export const getCountryCode = createSelector(state => state.offer.countryCode);
export const getCompanyData = createSelector(state => state.offer.companyData);
export const getContactPerson = createSelector(state => state.offer.contactPerson);
export const getOfferContactData = createSelector(state => state.offerContactData);
export const getOfferConfiguration = createSelector(state => state.offerConfiguration);

export const getBankInformation = createSelector(state => state.onboardingData.bankInformation);

export const getInvoiceInformation = createSelector(state => state.onboardingData.invoiceInformation);

export const getShippingInformationData = createSelector(state => state.onboardingData.shippingInformationV2);

// NOTE: Here its assumed we have the resolved offer object. Good to keep in mind once we get to save & quit
export const getExpirationDate = createSelector(state => state.offer.expiryDate);

export const getOwners = createSelector(state => state.onboardingData.owners);

export const getSigningCombinations = createSelector(state => state.signingCombinations);

export const getSigners = createSelector(state => state.onboardingData.signatories);

export const getSignersThatAreNotOwners = createSelector(state =>
  state.onboardingData.signatories.filter(
    signer => state.onboardingData.owners.findIndex(owner => owner.ref === signer.ref) === -1
  )
);

export const getManualSigners = createSelector(state => state.manualSigners);

export const getOnboardingData = createSelector(state => state.onboardingData);

export const getVerificationStatus = createSelector(state => state.verificationStatus);

export const getAdditionalQuestions = createSelector(state => state.additionalQuestions);

export const getStores = createSelector(state => state.onboardingData.stores);

export const getRepresentative = (representativeRef: string) =>
  createSelector(state => {
    return (
      (state.onboardingData.owners.find(o => o.ref === representativeRef) as Representative) ||
      (state.onboardingData.signatories.find(s => s.ref === representativeRef) as Representative)
    );
  });

export const getNotaryDetails = createSelector(state => state.onboardingData.notaryDetails);

export const getGlobalDocuments = createSelector(state => state.onboardingData.offerOnboardingGlobalDocuments || []);
