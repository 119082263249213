import { useTranslation } from 'react-i18next';
import { Controller } from 'react-hook-form';
import { isValidSingleLineText, isValidMultiLineText } from 'utils/validation';
import { Text, Toggle as ToggleVault } from '@loomispay/vault';
import { useEffect, useState } from 'react';
import useInputErrors from '../forms/TextInput/useInputErrors';
import { TextInputProps } from '../forms/TextInput/types';

type ToggleVaultProps = React.ComponentProps<typeof ToggleVault>;

export const Toggle = ({
  type = 'text',
  disabled = false,
  hintText = '',
  label,
  name,
  defaultValue,
  errors,
  children,
  testId = '',
  textArea = false,
  skipSafeTextValidation = false,
  control,
  onChange,
  ...props
}: TextInputProps & { onChange?: (val: string) => void } & ToggleVaultProps) => {
  const { t } = useTranslation();
  const [toggleValue, setToggleValue] = useState<boolean>(defaultValue === 'true');
  const { errorMessage, getErrorMessage } = useInputErrors(errors, name);
  useEffect(() => {
    getErrorMessage();
  }, [errors, getErrorMessage]);

  return (
    <Controller
      name={name}
      defaultValue={defaultValue === 'true'}
      {...{
        ...props,
        ...(type === 'text' &&
          !skipSafeTextValidation && {
            rules: {
              ...props.rules,
              validate: value => {
                if (textArea && !isValidMultiLineText(value)) {
                  return t('common.form.validationMessages.unsafeText');
                }
                if (!textArea && !isValidSingleLineText(value)) {
                  return t('common.form.validationMessages.unsafeText');
                }
                if (props.rules?.validate instanceof Function) {
                  return props.rules?.validate(value);
                }
              },
            },
          }),
      }}
      control={control}
      render={({ onChange: _onChange, ..._props }) => {
        // If boolean is passed as defaultValue, we need to map it to string here
        const inputProps = { ..._props, value: _props.value.toString() };
        return (
          <ToggleVault
            onChange={val => {
              _onChange(val);
              setToggleValue(val);
              onChange && onChange(val);
            }}
            disabled={disabled}
            hintText={hintText}
            errorText={errorMessage}
            label={label}
            testId={testId || name}
            inputProps={inputProps}
            ref={_props.ref}
          >
            {children || <Text>{toggleValue ? t('common.yes') : t('common.no')}</Text>}
          </ToggleVault>
        );
      }}
    />
  );
};
