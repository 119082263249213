import { Heading } from '@loomispay/vault';
import { TextInput } from 'common/components/forms/TextInput/TextInput';
import { Toggle } from 'common/components/ToggleWrapper/Toggle';
import { getStores } from 'features/onboardingV2/store/sales/selectors';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { CashServicesGridWrapper } from '../CashServices.styles';
import { CashServicesSectionTypes } from '../types';
import { MAX_INT32_VAL } from 'utils/format';

export default function CashServicesChangeService({
  storeIndex,
  control,
  errors,
  changeServiceEnabled,
}: CashServicesSectionTypes & { changeServiceEnabled?: boolean }) {
  const { t } = useTranslation();
  const locations = useSelector(getStores);

  const cashDataChangeService = locations[storeIndex].cashData?.es?.changeService;

  return (
    <>
      <section>
        <Heading size="s">{t('cashServices.changeService')}</Heading>
        <CashServicesGridWrapper>
          <Toggle
            name={`store-${storeIndex}.generalInfo.changeServiceIncluded`}
            label={t('cashServices.form.clientWantsChange')}
            control={control}
            errors={errors}
            defaultValue={locations[storeIndex].cashData?.es?.generalInfo?.changeServiceIncluded?.toString() || ''}
          />

          {changeServiceEnabled && (
            <>
              <TextInput
                label={t('cashServices.form.changeWithoutPickupFee')}
                name={`store-${storeIndex}.changeService.changeWithoutPickupFee`}
                defaultValue={cashDataChangeService?.changeWithoutPickupFee?.toString() || '45'}
                control={control}
                errors={errors}
                rules={{
                  required: t('common.form.validationMessages.required'),
                }}
                type="number"
                currency="EUR"
                inputProps={{
                  min: 0,
                  max: MAX_INT32_VAL,
                  step: 1,
                }}
              />
              <TextInput
                label={t('cashServices.form.changeWithPickupFee')}
                name={`store-${storeIndex}.changeService.changeWithPickupFee`}
                defaultValue={cashDataChangeService?.changeWithPickupFee?.toString() || '5'}
                control={control}
                errors={errors}
                rules={{
                  required: t('common.form.validationMessages.required'),
                }}
                type="number"
                currency="EUR"
                inputProps={{
                  min: 0,
                  max: MAX_INT32_VAL,
                  step: 1,
                }}
              />
            </>
          )}
        </CashServicesGridWrapper>
      </section>
    </>
  );
}
