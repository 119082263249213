import { Heading } from '@loomispay/vault';
import { Dropdown } from 'common/components/forms/Dropdown/Dropdown';
import { TextInput } from 'common/components/forms/TextInput/TextInput';
import { DropDownOption } from 'constants/types';
import { getStores } from 'features/onboardingV2/store/sales/selectors';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { CashServicesGridWrapper } from '../CashServices.styles';
import { cashContractGuaranteeTypes } from '../fixtures';
import { CashContractGuaranteeType, CashServicesSectionTypes } from '../types';
import { maxValue } from 'utils/format';

export default function CashServicesGuarantee({
  storeIndex,
  control,
  errors,
  checkSolutionType,
  guaranteeType = cashContractGuaranteeTypes[0],
}: CashServicesSectionTypes & { checkSolutionType?: string; guaranteeType?: CashContractGuaranteeType }) {
  const { t } = useTranslation();
  const locations = useSelector(getStores);

  const cashDataGuarantee = locations[storeIndex].cashData?.es?.guarantee;

  const guaranteeEnabled = !!checkSolutionType && ['DIGITAL_CASH'].includes(checkSolutionType);

  const guaranteeTypeOptions: DropDownOption<CashContractGuaranteeType>[] = cashContractGuaranteeTypes.map(type => {
    return {
      value: type,
      label: t(`cashServices.form.guaranteeType.${type}`),
    };
  });

  return (
    <>
      {guaranteeEnabled && (
        <section>
          <Heading size="s">{t('cashServices.guarantee')}</Heading>
          <CashServicesGridWrapper>
            <Dropdown
              name={`store-${storeIndex}.guarantee.type`}
              label={t('cashServices.form.guaranteeType')}
              control={control}
              errors={errors}
              options={guaranteeTypeOptions}
              defaultValue={
                guaranteeTypeOptions.find(option => option.value === cashDataGuarantee?.type?.toString()) ||
                guaranteeTypeOptions[0]
              }
            />
            {guaranteeType !== 'NONE' && (
              <TextInput
                name={`store-${storeIndex}.guarantee.amount`}
                label={t('cashServices.form.guaranteeAmount')}
                defaultValue={cashDataGuarantee?.amount?.toString() || ''}
                control={control}
                errors={errors}
                type="number"
                currency="EUR"
                rules={{
                  min: {
                    value: 0,
                    message: t('common.form.validationMessages.numberTooLow'),
                  },
                  required: t('common.form.validationMessages.required'),
                }}
                inputProps={{
                  min: 0,
                  max: maxValue(9, 2),
                  step: 1,
                }}
              />
            )}
          </CashServicesGridWrapper>
        </section>
      )}
    </>
  );
}
