import { RootState } from 'store/store.types';
import { SalesOfferState } from '../types';
import { initialState } from './reducer';

type OfferSelector<T> = (store: SalesOfferState) => T;

const createSelector = <T>(selector: OfferSelector<T>) => (globalState: RootState) =>
  selector(globalState.onboardingV2Sales || initialState);

/** Sales Flow */

export const getOffer = createSelector(state => state.offer);
export const getCountryCode = createSelector(state => state.offer.countryCode);
export const getCompanyData = createSelector(state => state.offer.companyData);

export const getCompanyAvailableFlows = createSelector(state => state.availableFlows);

export const getOfferConfiguration = createSelector(state => state.offerConfiguration);
export const getCashOfferConfiguration = createSelector(state => state.cashOfferConfiguration);

export const getSelectedTerminalsByStoreId = (storeId: number) =>
  createSelector(state => state.offer.stores[storeId]?.storeOfferItems?.terminals || []);

export const getSelectedAddonsByStoreId = (storeId: number) =>
  createSelector(state => state.offer.stores[storeId]?.storeOfferItems?.addons || []);

export const getContractTerms = createSelector(state => state.offer.contractTerms);

export const getOfferType = createSelector(state => state.offer.offerType);
export const getOfferRef = createSelector(state => state.offer.ref);
export const getBusinessCategory = createSelector(state => state.offer.businessCategory);
export const getBusinessSubCategory = createSelector<string | undefined>(state => state.offer.businessSubCategory);
export const getTurnoverData = createSelector(state => state.offer.turnoverData);
export const getSignatureProvider = createSelector(state => state.offer.signatureProvider);

export const getContactPerson = createSelector(state => state.offer.contactPerson);
export const getContactPersonEmail = createSelector(state => state.offer.contactPerson?.emailAddress);

// NOTE: Here its assumed we have the resolved offer object. Good to keep in mind once we get to save & quit
export const getExpirationDate = createSelector(state => state.offer.expiryDate);

export const getStores = createSelector(state => state.offer.stores);

export const getAvailableCountries = createSelector(state => state.availableCountries);

export const getPartnerId = createSelector(state => state.offer.partnerData?.partnerId);
