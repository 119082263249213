import { useTranslation } from 'react-i18next';

import { TextInput } from 'common/components/forms/TextInput/TextInput';
import { patterns } from '../../../../utils/validation';
import { SimpleSmallLeftGridItem, SimpleSmallRightGridItem } from '../../../../common/components/GridLayout';
import styled from 'styled-components/macro';
import { parse as parseSeBankAccountNumbers } from 'kontonummer';
import { useState } from 'react';
import { Checkbox } from 'common/components/forms/Checkbox/Checkbox';
import { VisuallyHidden } from 'common/components/VisuallyHidden';
import { BankAccountScreenProps } from 'features/common/types';

function isFiveDigitSwedbankAccount(value: string) {
  return value.startsWith('8');
}

export default function SeBankAccountScreen({ form, bankInformation }: BankAccountScreenProps) {
  const { t } = useTranslation();
  const [shouldShowApprovalPrompt, setShouldShowApprovalPrompt] = useState<boolean>(false);

  return (
    <>
      <SimpleSmallLeftGridItem>
        <ContentSpacer>
          <TextInput
            label={t('onboarding.v2.bankInformation.clearingNumber')}
            name="bankCode"
            inputMode="numeric"
            type="text"
            setValue={(value?: string) => form.setValue('bankCode', value)}
            normalizeValue={(value: string) => value.toUpperCase().trim()}
            rules={{
              required: t('common.form.validationMessages.required'),
              pattern: {
                value: patterns.seBankCode,
                message: t('common.form.validationMessages.invalidClearingNumberFormat'),
              },
              validate: {
                validClearingNumber: (clearingCode: string) => {
                  if (isFiveDigitSwedbankAccount(clearingCode) && clearingCode.length !== 5) {
                    return t('common.form.validationMessages.invalidFiveDigitSwedbankClearingNumber');
                  } else if (!isFiveDigitSwedbankAccount(clearingCode) && clearingCode.length !== 4) {
                    return t('common.form.validationMessages.invalidStandardClearingNumber');
                  }
                  if (form.getValues('accountNumber')) form.trigger('accountNumber');
                  return true;
                },
              },
            }}
            defaultValue={bankInformation?.bankCode || ''}
            control={form.control}
            errors={form.errors}
            trimOnBlur
          />
          <VisuallyHidden isHidden={!shouldShowApprovalPrompt}>
            <Checkbox
              name="disableExtendedAccountNumberValidation"
              label={t('onboarding.v2.bankInformation.bankInformationApproval')}
              defaultChecked={false}
              onChange={() => {
                form.trigger('accountNumber');
              }}
              control={form.control}
              errors={form.errors}
              testId="disableExtendedAccountNumberValidation"
            />
          </VisuallyHidden>
        </ContentSpacer>
      </SimpleSmallLeftGridItem>
      <SimpleSmallRightGridItem>
        <TextInput
          label={t('onboarding.v2.bankInformation.accountNumber')}
          name="accountNumber"
          inputMode="numeric"
          type="text"
          setValue={(value?: string) => form.setValue('accountNumber', value)}
          normalizeValue={(value: string) => value.toUpperCase().trim()}
          rules={{
            required: t('common.form.validationMessages.required'),
            pattern: {
              value: patterns.seAccountNumber,
              message: t('common.form.validationMessages.invalidAccountNumber'),
            },
            validate: {
              validAccountNumber: (accountNumber: string) => {
                if (
                  !form.getValues('disableExtendedAccountNumberValidation') &&
                  form.getValues('bankCode') &&
                  accountNumber.length >= 7 &&
                  accountNumber.length <= 10
                ) {
                  try {
                    const accountNumberValidator = parseSeBankAccountNumbers(
                      form.getValues('bankCode'),
                      accountNumber,
                      { mode: 'lax' }
                    );
                    setShouldShowApprovalPrompt(false);
                    return (
                      accountNumberValidator.valid ||
                      t('common.form.validationMessages.invalidAccountNumberForGivenClearingNumber', {
                        bankName: accountNumberValidator.bankName,
                      })
                    );
                  } catch (e) {
                    setShouldShowApprovalPrompt(true);
                    return t('common.form.validationMessages.invalidAccountAndClearingNumbersCombination');
                  }
                } else {
                  return true;
                }
              },
            },
          }}
          defaultValue={bankInformation?.accountNumber || ''}
          control={form.control}
          errors={form.errors}
          trimOnBlur
        />
      </SimpleSmallRightGridItem>

      <div hidden={true}>
        <TextInput name="bankInformationType" defaultValue="SE" label="" control={form.control} errors={form.errors} />
      </div>
    </>
  );
}

const ContentSpacer = styled.div`
  display: grid;
  gap: ${({ theme }) => theme.spacings['2']};
`;
