import { useEffect, useRef } from 'react';

export const useIsInitialRender = () => {
  const isInitalRender = useRef<boolean>(true);
  useEffect(() => {
    isInitalRender.current = false;
  }, []);

  return isInitalRender;
};
