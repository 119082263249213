import styled from 'styled-components/macro';

export const CashServicesFormWrapper = styled.div<{ isVisible: boolean }>`
  display: ${({ isVisible }) => (isVisible ? 'grid' : 'none')};
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings[8]};
`;

export const CashServicesGridWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: ${({ theme }) => theme.spacings[4]};
`;

export const CashServicesSettingsEnabler = styled.div`
  padding-bottom: ${({ theme }) => theme.spacings[6]};
  border-bottom: 1px solid ${({ theme }) => theme.color.base.opacityBlack[2]};
`;
