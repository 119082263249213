import { Button, Heading, Paragraph, useMedia } from '@loomispay/vault';
import { useConfirmation } from 'common/components/Modal';
import { Footer } from 'features/onboardingV2/components/OnboardingFooter';
import { useDocumentTitle, useIsInitialRender } from 'hooks';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import Container from 'common/components/MainLayout/Container';
import RepresentativeBox from 'features/onboardingV2/components/RepresentativeBox/RepresentativeBox';
import useOwnersView from './useOwnersView';
import { Fragment } from 'react';
import { onboardingRoutes } from 'features/onboardingV2/OnboardingRouter';
import { BaseQueryParams } from 'features/onboardingV2/data/types';

const OwnersScreen = () => {
  const { countryCode, offerRef } = useParams<BaseQueryParams>();
  const { owners, hasFetchedOwners, addingMoreOwnersDisabled, deleteOwner } = useOwnersView();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { getConfirmation } = useConfirmation();

  const isInitialRender = useIsInitialRender();
  const isScreenCompleted = !isInitialRender.current && owners.length > 0;
  const isMobile = useMedia('mobile');
  useDocumentTitle(t('onboarding.v2.owners.companyOwnership'));

  const prevPage = () => generatePath(onboardingRoutes.locationsMerchant.path, { countryCode, offerRef });
  const nextPage = () => navigate(generatePath(onboardingRoutes.signingCombinations.path, { countryCode, offerRef }));

  const confirmRemoveOwner = (fullName: string, index: number) => async () => {
    const confirm = await getConfirmation({
      title: t('onboarding.v2.owners.removeOwnerButton'),
      description: t('onboarding.v2.owners.confirmRemoval', { name: fullName }),
    });

    if (confirm && index !== undefined) {
      deleteOwner(index);
    }
  };

  return !hasFetchedOwners ? null : (
    <Container spacing="4" centerContent>
      <header>
        <Heading size={isMobile ? 'l' : 'xl'}>{t('onboarding.v2.owners.companyOwnership')}</Heading>
        <Paragraph size="m" color="tertiary" noGutter>
          {t('onboarding.v2.owners.description')}
        </Paragraph>
      </header>

      {owners.map(({ fullName, minSharesPercentage, maxSharesPercentage, type, isManuallyAdded }, i: number) => {
        return (
          <Fragment key={i}>
            <RepresentativeBox
              fullName={fullName}
              ownershipData={{ maxSharesPercentage, minSharesPercentage, type }}
              onDelete={confirmRemoveOwner(fullName, i)}
              onChange={() => {
                navigate(generatePath(onboardingRoutes.editOwner.path, { countryCode, offerRef, ownerId: `${i}` }));
              }}
              isManuallyAdded={isManuallyAdded}
              index={i}
            />
          </Fragment>
        );
      })}
      <Footer isMobile={isMobile} prevPage={prevPage} nextPage={nextPage} isScreenCompleted={isScreenCompleted} />
      <Button
        variant="secondary"
        icon="plus"
        iconPosition="left"
        disabled={addingMoreOwnersDisabled}
        onClick={() => {
          onboardingRoutes.addOwner.path &&
            navigate(generatePath(onboardingRoutes.addOwner.path, { countryCode, offerRef }));
        }}
        label={t('onboarding.v2.owners.addOwnerButton')}
        testId="owners-add-button"
      />
    </Container>
  );
};

export default OwnersScreen;
