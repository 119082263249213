import SingleColumn from 'layout/SingleColumn';
import { useTranslation } from 'react-i18next';
import { Button, Display, Paragraph } from '@loomispay/vault';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { setContractTerms } from '../../store/sales/actions';
import { getContractTerms, getOfferConfiguration } from '../../store/sales/selectors';
import { DropdownOption } from '../../components/pepForm';
import { Dropdown } from '../../../../common/components/forms/Dropdown/Dropdown';
import Hr from '../../../../common/components/Hr';
import { PricingRow } from './OnboardingPricing.styles';
import { Column } from '../../../../layout/flexLayouts';
import { spacing } from '../../../../constants';
import { useSalesFlowNavigation } from './salesFlowRoutingService';
import { useDocumentTitle } from 'hooks';
import { useEffect } from 'react';
import { TextInput } from 'common/components/forms/TextInput/TextInput';

type IContractTermsForm = {
  duration: DropdownOption<number>;
  terminalsFreeMonths: number;
};

const OnboardingPricing = () => {
  const { nextPage, prevPage } = useSalesFlowNavigation();
  const { t } = useTranslation();
  useDocumentTitle(t('onboarding.v2.offer.contractTerms.duration.title'));
  const { handleSubmit, setValue, control, errors, formState, watch } = useForm<IContractTermsForm>({
    mode: 'onChange',
  });

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const contractTerms = useSelector(getContractTerms);
  const { durationInMonths } = contractTerms;

  const offerConfiguration = useSelector(getOfferConfiguration);
  const contractDurationOptions = offerConfiguration.contractDurationOptions.map(option => ({
    value: option,
    label: option,
  }));

  const formContractDuration = watch(
    'duration',
    contractDurationOptions.find(opt => opt.value === durationInMonths)
  );
  const formContractFreeMonths = watch('terminalsFreeMonths');

  const screenCompleted = formState.isValid && formContractDuration && formContractDuration?.value !== 0;

  const onSubmit = (contractTermsFormData: IContractTermsForm) => {
    const contractTerms = {
      durationInMonths: contractTermsFormData.duration.value,
      terminalsFreeMonths: contractTermsFormData.terminalsFreeMonths,
    };
    dispatch(setContractTerms(contractTerms));

    navigate(nextPage());
  };

  useEffect(() => {
    if (formContractDuration && formContractFreeMonths) {
      if (formContractDuration.value < +formContractFreeMonths) {
        setValue('terminalsFreeMonths', formContractDuration.value);
      } else if (+formContractFreeMonths < 0) {
        setValue('terminalsFreeMonths', 0);
      }
    }
  }, [formContractDuration, formContractFreeMonths, setValue]);

  return (
    <SingleColumn
      footer={
        <>
          <Button
            onClick={() => {
              navigate(prevPage());
            }}
            variant={'tertiary'}
            label={t('common.backButton')}
            icon={'arrowLeft'}
            iconPosition={'left'}
          />
          <Button
            form="onboarding-pricing"
            type="submit"
            label={t('common.continueButton')}
            disabled={!screenCompleted}
            testId={'onboardingPricing_submitButton'}
          />
        </>
      }
    >
      <form id="onboarding-pricing" onSubmit={handleSubmit(onSubmit)}>
        <>
          <Display size={'s'}>{t('onboarding.v2.offer.contractTerms.duration.title')}</Display>
          <Paragraph size="s" color="tertiary">
            {t('onboarding.v2.offer.contractTerms.duration.info')}
          </Paragraph>
          <PricingRow>
            <Column>
              <Dropdown
                name="duration"
                label={t('onboarding.v2.offer.contractTerms.duration.label')}
                control={control}
                errors={errors}
                rules={{ required: t('common.form.validationMessages.required') }}
                options={contractDurationOptions}
                getOptionLabel={option => option.label}
                getOptionValue={option => option.value}
                defaultValue={contractDurationOptions.find(option => option.value === contractTerms.durationInMonths)}
              />
            </Column>
          </PricingRow>
          <Hr margin={`${spacing.s2} 0`} />
        </>
        <Display size={'s'}>{t('onboarding.v2.offer.contractTerms.freeMonths.title')}</Display>
        <Paragraph size="s" color="tertiary">
          {t('onboarding.v2.offer.contractTerms.freeMonths.info', {
            terminalFreeMonthsMinContractDuration: formContractDuration?.value || 0,
          })}
        </Paragraph>
        <PricingRow>
          <Column>
            <TextInput
              label={t('onboarding.v2.offer.contractTerms.freeMonths.label')}
              name="terminalsFreeMonths"
              type="number"
              control={control}
              errors={errors}
              rules={{ required: t('common.form.validationMessages.required') }}
              inputProps={{ step: 1, min: 0, max: formContractDuration?.value }}
              defaultValue={`${contractTerms.terminalsFreeMonths}` || '0'}
              disabled={formContractDuration?.value === undefined}
            />
          </Column>
        </PricingRow>
      </form>
    </SingleColumn>
  );
};

export default OnboardingPricing;
