import styled from 'styled-components/macro';

type Props = {
  color?: string;
  size?: number;
  margin?: string;
  children?: never;
};

const HorizontalLine = styled.div<Props>`
  width: 100%;
  border-top: 1px solid ${({ theme, color }) => color || theme.color.border.disabledBorder};
  margin: ${({ theme, margin }) => margin || `${theme.spacings[4]} 0`};
`;

const Hr = ({ color, size = 1, margin }: Props) => <HorizontalLine color={color} size={size} margin={margin} />;

export default Hr;
