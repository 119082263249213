import { Heading, Paragraph } from '@loomispay/vault';
import Container from 'common/components/MainLayout/Container';
import { notifications } from 'common/components/Notification/notifications';
import { useDocumentTitle } from 'hooks';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import SigningCombinationsFetched from './SigningCombinationsFetched';
import SigningCombinationsManualV2 from './SigningCombinationsManualV2';
import { BaseQueryParams } from '../../../data/types';
import { useParams } from 'react-router-dom';
import { useOffer, useOnboardingData, useSignees } from '../../../data/queries';
import { signersAnomalyWhitelistedCompanyIDs } from '../../../../../app/whitelistedCompanyIDs';

export type SigningCombinationsForm = {
  signersGroup: string;
};

export default function SigningCombinations() {
  const { t } = useTranslation();
  const { countryCode, offerRef } = useParams<BaseQueryParams>();
  useDocumentTitle(t('onboarding.v2.signingCombinations.title'));
  const { data: offerOnboardingData } = useOnboardingData({ countryCode, offerRef });
  const { data: offerData } = useOffer({ countryCode, offerRef });
  const companyForm = offerData?.companyData.companyForm;
  const companyNumber = offerData?.companyData.companyNumber;
  const { data: signeesData } = useSignees({ countryCode, companyNumber });
  const hasSigningCombinations = signeesData && signeesData.length > 0;
  const isSpanishSoleTrader = countryCode === 'ES' && companyForm === 'Autónomo';

  const isManualFallback =
    (countryCode === 'ES' ||
      !hasSigningCombinations ||
      signersAnomalyWhitelistedCompanyIDs.includes(offerData?.companyData.companyNumber)) ??
    false;

  // If merchant is from Spain and is single owner, we're assuming it's the person that can sign the contract
  useEffect(() => {
    if (signeesData && !hasSigningCombinations && !isManualFallback) {
      notifications.error(t('onboarding.v2.signingCombinations.empty'));
    }
  }, [
    signeesData,
    offerOnboardingData,
    hasSigningCombinations,
    isSpanishSoleTrader,
    offerOnboardingData?.owners,
    t,
    countryCode,
    isManualFallback,
  ]);

  return !signeesData ? null : (
    <Container spacing="1">
      <header>
        <Heading size="xl">{t('onboarding.v2.signingCombinations.title')}</Heading>
        <Paragraph color={'tertiary'}>{t('onboarding.v2.signingCombinations.description')}</Paragraph>
      </header>
      {(isManualFallback && <SigningCombinationsManualV2 />) ||
        (hasSigningCombinations && <SigningCombinationsFetched />)}
    </Container>
  );
}
