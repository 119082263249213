import { BannerWithIcon, Button, Text, useMedia } from '@loomispay/vault';
import { Checkbox } from 'common/components/forms/Checkbox/Checkbox';
import Form from 'common/components/forms/Form';
import Container from 'common/components/MainLayout/Container';
import { useConfirmation } from 'common/components/Modal';
import { Footer } from 'features/onboardingV2/components/OnboardingFooter';
import RepresentativeBox from 'features/onboardingV2/components/RepresentativeBox/RepresentativeBox';
import { Owner, Representative, Signer } from 'features/onboardingV2/store/types';
import { Fragment, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { onboardingRoutes } from '../../../OnboardingRouter';
import { BaseQueryParams } from '../../../data/types';
import { useOffer, useOnboardingData, useSignees } from '../../../data/queries';
import { usePartialUpdate } from '../../../data/mutations';

export default function SigningCombinationsManualV2() {
  const isMobile = useMedia('mobile');
  const { countryCode, offerRef } = useParams<BaseQueryParams>();
  const { getConfirmation } = useConfirmation();
  const navigate = useNavigate();
  const { handleSubmit, control, errors, watch } = useForm();
  const { t } = useTranslation();
  const { data: onboardingData } = useOnboardingData({ countryCode, offerRef });
  const { data: offerData } = useOffer({ countryCode, offerRef });
  const { data: fetchedSigningCombinations } = useSignees({
    countryCode,
    companyNumber: offerData?.companyData.companyNumber,
  });
  const { mutate: updatedSignatories, isSuccess: isSuccessUpdated } = usePartialUpdate();
  const [isRemoved, setIsRemoved] = useState(false);
  let signerIndex = 0;

  useEffect(() => {
    if (isSuccessUpdated) {
      if (isRemoved) setIsRemoved(false);
      else navigate(generatePath(onboardingRoutes.bankInformation.path, { countryCode, offerRef }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccessUpdated, navigate, countryCode, offerRef]);

  const signers = onboardingData?.signatories || [];
  const owners = onboardingData?.owners || [];

  // signees based on signing combinations (unique names list - fullname is the only reliable field to filter them)
  const uniqueFetchedSignees = [
    ...new Map(
      fetchedSigningCombinations
        ?.flatMap(({ persons }) => (persons.length ? persons : []))
        .map(person => [person['fullName'], person])
    ).values(),
  ];

  // we filter out signees that were already added as owners (on previous owners screen) from above signees
  const uniqueFetchedNonOwnerSignees = uniqueFetchedSignees.filter(
    signee => !owners.some(owner => owner.fullName === signee.fullName)
  );

  // signees that were added manually on this screen by the merchant
  const manuallyAddedSigners: Signer[] =
    signers.filter(
      signer =>
        !owners.some(({ ref }) => ref === signer.ref) &&
        !uniqueFetchedSignees.some(({ fullName }) => fullName === signer.fullName)
    ) || [];

  // remove quotes from strings compared to field names (react hook form removes them when creating formData)
  const removeFieldNameUnsafeChars = (str: string) => str.replace(/['"]/g, '');

  const checkIfSelectedAsSigner = (representative: Representative) =>
    !!signers.find(signer => signer.fullName === representative?.fullName);

  const deleteManualSigner = (fullName: string) => {
    getConfirmation({
      title: t('onboarding.signerForm.titleDelete'),
      description: t('onboarding.signerForm.confirmRemoval', { name: fullName }),
    }).then(confirmed => {
      if (confirmed) {
        setIsRemoved(true);
        updatedSignatories({
          countryCode,
          merchantRef: offerRef,
          data: { ...onboardingData, signatories: signers.filter(signer => signer.fullName !== fullName) },
        });
      }
    });
  };

  function mapFormDataToSelectedSignatories(formData: Record<string, boolean>) {
    const representatives = [...uniqueFetchedSignees, ...owners, ...manuallyAddedSigners];
    const selected: Signer[] = representatives
      .filter(representative => formData[removeFieldNameUnsafeChars(representative.fullName)])
      .map(
        (e: Owner | Signer) =>
          ({ ref: e.ref, fullName: e.fullName, personalId: e.personalId, isManuallyAdded: e.isManuallyAdded } as Signer)
      );

    return selected;
  }

  const onSubmit = (formData: Record<string, boolean>) => {
    const selectedSignatories = mapFormDataToSelectedSignatories(formData);
    // temporary fix to mark owner as manually added if he was selected as signer in manual flow
    // to block auto approve in kyc and require documents in additional information page
    // we cannot mark isManuallyAdded only on signer, because on backend owners and signers are merged
    // and isManuallyAdded flag on signer is ignored
    const modifiedOwners = owners.map(owner => {
      return {
        ...owner,
        isManuallyAdded:
          selectedSignatories.findIndex(signer => signer.ref && signer.ref === owner.ref) !== -1 ||
          owner.isManuallyAdded,
      };
    });

    if (selectedSignatories.length > 0) {
      updatedSignatories({
        countryCode,
        merchantRef: offerRef,
        data: { ...onboardingData, owners: modifiedOwners, signatories: selectedSignatories },
      });
    }
  };

  return (
    <Container fullWidth spacing="4" centerContent>
      {!!fetchedSigningCombinations?.length && (
        <BannerWithIcon type="neutral" icon="infoCircled">
          <Text size="s">{t('onboarding.v2.signingCombinations.fetchedOptions.select')}</Text>
          <ul>
            {fetchedSigningCombinations?.map(({ persons }, i) => (
              <li key={`signing-combination-${i}`} data-testid={`signing-combination-${i}`}>
                {persons.map(({ fullName }) => fullName).join(', ')}
              </li>
            ))}
          </ul>
          <Text size="s">{t('onboarding.v2.signingCombinations.fetchedOptions.compose')}</Text>
        </BannerWithIcon>
      )}
      <Form onSubmit={handleSubmit(onSubmit)} spacing="3">
        {uniqueFetchedNonOwnerSignees.map(signee => (
          <Fragment key={signee.fullName}>
            <RepresentativeBox fullName={signee.fullName} isManuallyAdded={false}>
              <Checkbox
                name={signee.fullName}
                label={signee.fullName}
                defaultChecked={checkIfSelectedAsSigner(signee)}
                control={control}
                errors={errors}
                testId={`signer.${signerIndex++}`}
                labelVisuallyHidden
              />
            </RepresentativeBox>
          </Fragment>
        ))}
        {owners.map(owner => (
          <Fragment key={owner.fullName}>
            <RepresentativeBox
              fullName={owner.fullName}
              ownershipData={{ type: owner.type }}
              isManuallyAdded={owner.isManuallyAdded}
            >
              <Checkbox
                name={owner.fullName}
                label={owner.fullName}
                defaultChecked={checkIfSelectedAsSigner(owner)}
                control={control}
                errors={errors}
                testId={`signer.${signerIndex++}`}
                labelVisuallyHidden
              />
            </RepresentativeBox>
          </Fragment>
        ))}
        {manuallyAddedSigners.map(signer => (
          <Fragment key={signer.fullName}>
            <RepresentativeBox
              fullName={signer.fullName}
              isManuallyAdded={signer.isManuallyAdded}
              onChange={() =>
                navigate(
                  generatePath(onboardingRoutes.signerFormV2Edit.path, {
                    countryCode,
                    offerRef,
                    signerRef: signer.ref,
                  })
                )
              }
              onDelete={() => deleteManualSigner(signer.fullName)}
            >
              <Checkbox
                name={signer.fullName}
                label={signer.fullName}
                defaultChecked={true}
                control={control}
                errors={errors}
                testId={`signer.${signerIndex++}`}
                labelVisuallyHidden
              />
            </RepresentativeBox>
          </Fragment>
        ))}
        <Footer
          isMobile={isMobile}
          prevPage={() => generatePath(onboardingRoutes.ownersV2.path, { countryCode, offerRef })}
          isScreenCompleted={Object.values(watch()).some(value => value)}
        />
      </Form>
      <Button
        variant="secondary"
        icon="plus"
        iconPosition="left"
        onClick={() => {
          navigate(generatePath(onboardingRoutes.signerFormV2New.path, { countryCode, offerRef }));
        }}
        label={t('onboarding.signerForm.titleAdd')}
      />
    </Container>
  );
}
