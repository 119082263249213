import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';
import { env, links } from 'app/config';
import axios from 'axios';
import { featureTogglesReducer } from 'features/featureToggles/featureToggles';
import { applicationReducer } from 'features/login/applicationReducer';
import { merchantsReducer } from 'features/merchants/merchantReducer';
import { onboardingV2Reducers } from 'features/onboardingV2/store/reducer';
import { reactLocalStorage } from 'reactjs-localstorage';
import { combineReducers } from 'redux';
import { multiClientMiddleware } from 'redux-axios-middleware';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { userService } from '../services/userService';
import { LOGOUT } from './appActions';
import { appReducer } from './appReducer';
import { getBearerToken } from 'features/loginPasswordless/useCognitoUser';
import { onboardingV2SalesReducer } from 'features/onboardingV2/store/sales/reducer';

const MERCHANT_PORTAL_API_V2 = `${links.merchantPortalUrl}/merchant-portal/api/v2/`;
const MERCHANT_PORTAL_ONBOARDING_API_V2_WITHOUT_PARAMS = `${links.merchantPortalUrl}/merchant-portal/onboarding/api/v2/`;
const MERCHANT_PORTAL_ONBOARDING_API_V2 = `${links.merchantPortalUrl}/merchant-portal/onboarding/api/v2/{countryCode}`;
const MERCHANT_PORTAL_ONBOARDING_MERCHANT_API_V2 = `${links.merchantPortalUrl}/merchant-portal/onboarding/api/v2/{countryCode}/merchant/{merchantRef}`;
const MERCHANT_PORTAL_MERCHANT_API_V2 = `${links.merchantPortalUrl}/merchant-portal/api/v2/merchant/{merchantRef}/`;
const ONBOARDING_SERVICE_PUBLIC_API = `${links.merchantPortalUrl}/merchant-portal/public/`;

const FEATURE_TOGGLE_SERVICE_API = `${links.merchantPortalUrl}/merchant-portal/public/feature-toggles`;

const COMPANY_DATA_PROVIDER_V1 = `${links.companyDataProviderUrl}/companyDataProvider/public/api/v1/`;
const COMPANY_DATA_PROVIDER_V2 = `${links.companyDataProviderUrl}/companyDataProvider/public/api/v2/`;

const persistConfig = {
  key: 'root',
  storage: storage,
  whitelist: ['onboardingV2Sales', 'applicationConfig', 'merchants', 'navigation', 'productImports', 'featureToggles'],
};

/**
 * All requests to a backend API V2 have to be invoked in a "merchant" context
 *
 * @param req
 */
function handleRequestToApiV2(req) {
  if (req.baseURL === MERCHANT_PORTAL_MERCHANT_API_V2 || MERCHANT_PORTAL_ONBOARDING_MERCHANT_API_V2) {
    req.baseURL = req.baseURL.replace('{merchantRef}', reactLocalStorage.get('merchantRef'));
  }
}

function addToggleContextToRequest(req) {
  const toggleContext = reactLocalStorage.get('toggleContext');
  if (toggleContext) {
    req.headers['Toggle-Context'] = toggleContext;
  }
}

function addUserLanguageToRequest(req) {
  const userLanguage = reactLocalStorage.get('userLanguage');
  if (userLanguage) {
    req.headers['Accept-Language'] = userLanguage;
  }
}

function addEnvCustomizationsToRequest(req) {
  if (env.showTestTransactions) {
    req.params = { test: 1, ...req.params };
  }
}

async function addAuthToRequest(req) {
  req.headers['Authorization'] = await getBearerToken();
}

/**
 * All requests to a backend onboarding API V2 should have "countryCode" context
 * @param req
 */
async function addCountryContextToRequest(req) {
  if (!req.baseURL.includes('{countryCode}')) {
    return;
  }

  if (
    req.baseURL.includes(MERCHANT_PORTAL_ONBOARDING_API_V2) ||
    req.baseURL.includes(MERCHANT_PORTAL_ONBOARDING_MERCHANT_API_V2)
  ) {
    // TODO: check getCountryCode and remove redux
    req.baseURL = req.baseURL.replace('{countryCode}', await userService.getCountryCode('Onboarding'));
  }
}

const prepareRequest = async config => {
  await addCountryContextToRequest(config);
  addToggleContextToRequest(config);
  addUserLanguageToRequest(config);
  addEnvCustomizationsToRequest(config);
  handleRequestToApiV2(config);
  await addAuthToRequest(config);
  return config;
};

export const merchantServiceApiV2Client = axios.create({
  baseURL: MERCHANT_PORTAL_API_V2,
  withCredentials: true,
  options: {
    responseType: 'json',
  },
});

export const onboardingApiV2Client = axios.create({
  baseURL: MERCHANT_PORTAL_ONBOARDING_API_V2,
  withCredentials: true,
  options: {
    responseType: 'json',
  },
});

export const onboardingMerchantApiV2Client = axios.create({
  baseURL: MERCHANT_PORTAL_ONBOARDING_MERCHANT_API_V2,
  withCredentials: true,
  options: {
    responseType: 'json',
  },
});

export const onboardingApiV2ClientWithoutMiddleware = axios.create({
  baseURL: MERCHANT_PORTAL_ONBOARDING_API_V2_WITHOUT_PARAMS,
  withCredentials: true,
  options: {
    responseType: 'json',
  },
});

onboardingApiV2ClientWithoutMiddleware.interceptors.request.use(prepareRequest);

export const onboardingMerchantApiV2ClientWithoutMiddleware = axios.create({
  baseURL: MERCHANT_PORTAL_ONBOARDING_API_V2_WITHOUT_PARAMS,
  withCredentials: true,
  options: {
    responseType: 'json',
  },
});

onboardingMerchantApiV2ClientWithoutMiddleware.interceptors.request.use(prepareRequest);

const merchantServiceMerchantApiV2Client = axios.create({
  baseURL: MERCHANT_PORTAL_MERCHANT_API_V2,
  withCredentials: true,
  options: {
    responseType: 'json',
  },
});

export const onboardingServicePublicClient = axios.create({
  baseURL: ONBOARDING_SERVICE_PUBLIC_API,
  withCredentials: true,
  options: {
    responseType: 'json',
  },
});

const companyDataProviderClient = axios.create({
  baseURL: COMPANY_DATA_PROVIDER_V1,
  withCredentials: true,
  options: {
    responseType: 'json',
  },
});

export const companyDataProviderClientV2 = axios.create({
  baseURL: COMPANY_DATA_PROVIDER_V2,
  withCredentials: true,
  options: {
    responseType: 'json',
  },
});

export const companyDataProviderClientV2WithoutMiddleware = axios.create({
  baseURL: COMPANY_DATA_PROVIDER_V2,
  withCredentials: true,
  options: {
    responseType: 'json',
  },
});

companyDataProviderClientV2WithoutMiddleware.interceptors.request.use(prepareRequest);

const featureToggleServiceClient = axios.create({
  baseURL: FEATURE_TOGGLE_SERVICE_API,
  withCredentials: true,
  options: {
    responseType: 'json',
  },
});

const middlewareConfig = {
  returnRejectedPromiseOnError: true,
  interceptors: {
    request: [
      {
        //TODO: this way of preparing request is not the best
        success: async (_, req) => {
          await addCountryContextToRequest(req);
          addToggleContextToRequest(req);
          addUserLanguageToRequest(req);
          addEnvCustomizationsToRequest(req);
          handleRequestToApiV2(req);
          await addAuthToRequest(req);
          return req;
        },
      },
    ],
    response: [
      {
        error: async ({ dispatch }, error) => {
          if (!(error.response && error.response.status === 401)) {
            // do not log 401 errors
            Sentry.captureException(error);
          }
          return Promise.reject(error);
        },
      },
    ],
  },
};

const reducers = combineReducers({
  applicationConfig: applicationReducer,
  onboardingV2: onboardingV2Reducers,
  onboardingV2Sales: onboardingV2SalesReducer,
  merchants: merchantsReducer,
  app: appReducer,
  featureToggles: featureTogglesReducer,
});

export const rootReducer = (state, action) => {
  let newState = state;

  if (action.type === LOGOUT) {
    newState = {
      onboardingV2: {
        merchant: state.onboardingV2.merchant,
      },
    };
  }

  return reducers(newState, action);
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export function createStore(opts = {}) {
  return configureStore({
    devTools: !env.isProd,
    reducer: persistedReducer,
    middleware: [
      ...getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [
            'IMPORT_PRODUCTS',
            'INSPECT_SPREADSHEET',
            'persist/PERSIST',
            'persist/PURGE',
            'UPLOAD_IMAGE',
          ],
        },
      }),
      multiClientMiddleware(
        {
          default: {
            client: merchantServiceMerchantApiV2Client,
          },
          onboardingMerchantV2: {
            client: onboardingMerchantApiV2Client,
          },
          onboardingV2: {
            client: onboardingApiV2Client,
          },
          merchantServiceV2: {
            client: merchantServiceApiV2Client,
          },
          onboardingServicePublicClient: {
            client: onboardingServicePublicClient,
          },
          companyDataProvider: {
            client: companyDataProviderClient,
          },
          companyDataProviderV2: {
            client: companyDataProviderClientV2,
          },
          featureToggleServiceClient: {
            client: featureToggleServiceClient,
          },
        },
        middlewareConfig
      ),
    ],
    ...opts,
  });
}

const globalStore = createStore();

export const persistor = persistStore(globalStore);

export default globalStore;
