import { Heading, Text } from '@loomispay/vault';
import { useTranslation } from 'react-i18next';
import { OfferSummaryRow, OfferSummarySectionBox } from '../../OfferOverview.styles';

export default function OfferOverviewDuration({ durationInMonths }: { durationInMonths?: number }) {
  const { t } = useTranslation();

  return (
    <section>
      <Heading size="xxs" sansSerif>
        {t('onboarding.v2.overview.contractTerms.duration')}
      </Heading>
      <OfferSummarySectionBox>
        <OfferSummaryRow>
          <Text size="s">{t('onboarding.v2.overview.contractTerms.duration')}</Text>
          <Text size="s">{`${durationInMonths} ${t('common.months')}`}</Text>
        </OfferSummaryRow>
      </OfferSummarySectionBox>
    </section>
  );
}
